import React, { useState, useRef, useEffect } from 'react';
import PanelItem from './PanelItem';
import ContextMenu from 'common/components/menu/contextMenu/ContextMenu';
import EmptyTags from 'common/components/tags/EmptyTags';
import EmptyGroup from 'common/components/groups/EmptyGroups';
import { CSSTransition } from 'react-transition-group';

import 'animate.css';

import './ControlPanel.scss';

function showSubMenu(items, emptyView, coordinates) {
    // const eventParentNode = getContextMenuParent(ev);
    const customStyles = {
        maxHeight: (document.body.getBoundingClientRect().height - 27) / 10 + 'rem',
        overflowY: 'auto',
    };

    return (
        <ContextMenu
            elements={items}
            customStyles={customStyles}
            className={'children-drop-down'}
            emptyView={emptyView}
            coordinates={coordinates}
        />
    )
}

function getContextMenuParent(ev) {
    let currentElement = ev.target;
    while (!currentElement.classList.contains('expandable')) {
        currentElement = currentElement.parentNode;
    }
    return currentElement;
}

function calculateContextMenuPosition(ev) {
    const el = getContextMenuParent(ev);
    const bBox = el.getBoundingClientRect();
    return {
        x: bBox.right,
        y: null
    }
}

function ControlPanel(props) {
    const ref = useRef(null);
    const [isTagsMenuVisible, setIsTagsMenuVisible] = useState(false);
    const [isGroupsMenuVisible, setIsGroupsMenuVisible] = useState(false);
    const [subMenuLocation, setSubMenuLocation] = useState({ x: 0, y: 0 });
    const isControlPanelVisible = props.contextMenu.isControlPanelVisible;
    const subMenuStyles = { width: '21rem' };

    const handleClickOutside = (event) => {
        // 'cycle-box' allows for multi-select behavior
        if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains('cycle-box')) {
            props.actions.doCloseContextMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return <>
        <div className={'control-panel-wrapper-outer centered'}>
            <CSSTransition
                in={isControlPanelVisible}
                // key={'control-panel-wrapper-transition-group'}
                timeout={200}
                classNames={'fade-in-transition'}
                unmountOnExit
            >
                <div ref={ref} key='control-panel-wrapper' className='control-panel-wrapper'>
                    <ul key={'control-panel-wrapper-ul'} className='panel-btn'>
                        {props.items.map((item, index) => {
                            let className = item.tags ? 'tags ctr-panel-item' : 'ctr-panel-item';
                            let onClick = !item.isInactive ? item.onClick : () => { };
                            const isExpandable = item.tags || item.groups;
                            let subMenu = null;

                            if (item.tags) {
                                onClick = (ev) => {
                                    ev.stopPropagation();
                                    setIsTagsMenuVisible(!isTagsMenuVisible);
                                    setIsGroupsMenuVisible(false);
                                    setSubMenuLocation(calculateContextMenuPosition(ev));
                                }
                                if (isTagsMenuVisible) {
                                    subMenu = showSubMenu(item.buttons, <EmptyTags style={subMenuStyles} />, subMenuLocation)
                                }
                            } else if (item.groups) {
                                onClick = (ev) => {
                                    ev.stopPropagation();
                                    setIsGroupsMenuVisible(!isGroupsMenuVisible);
                                    setIsTagsMenuVisible(false);
                                    setSubMenuLocation(calculateContextMenuPosition(ev));
                                }
                                if (isGroupsMenuVisible) {
                                    subMenu = showSubMenu(item.buttons, <EmptyGroup style={subMenuStyles} />, subMenuLocation)
                                }
                            }

                            if (item.isInactive) {
                                className += ' inactive-item';
                            }

                            return <span key={'ctr-panel-wrapper-' + index}>
                                <PanelItem
                                    index={index}
                                    key={'ctr-panel-item-' + index}
                                    title={item.title}
                                    tags={item.tags ? props.tags : null}
                                    className={className}
                                    isExpandable={isExpandable}
                                    onClick={onClick}
                                />
                                <span
                                    onMouseLeave={() => {
                                        setIsTagsMenuVisible(false);
                                        setIsGroupsMenuVisible(false);
                                    }}
                                >
                                    {subMenu}
                                </span>
                            </span>
                        })}
                    </ul>
                </div>
            </CSSTransition>
        </div>
    </>
}

export default ControlPanel;