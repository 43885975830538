import React, { useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './PanTool.scss';

const panToolId = INK_EDITOR_CONFIG.controls.pan;

function PanTool(props) {
    const { toolId, setToolId } = useContext(CanvasContext);
    const isActive = toolId === panToolId;

    return <div className={'pan-tool ink-tool'}>
        <i
            className={isActive ? 'icon active' : 'icon icon-pan'}
            // ref={node => this.$node = node} onClick={this.selectTool}
            onClick={() => setToolId(panToolId)}
        />
    </div>
}

export default PanTool;