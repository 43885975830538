import React from 'react';
import ContentLoader from 'react-content-loader'

import './DocumentListPreloader.scss';

function DocumentListPreloader({
    width = 50,
    row = 2,
    padding = 5,
    borderRadius = 4,
    maxHeight,
    ...props
}) {
    const list = [];
    let column = Math.floor((width) / 333);
    let index = 0;
    let height = 0;

    for (let i = 1; i <= row; i++) {
        for (let j = 0; j < column; j++) {
            const itemWidth = (width - padding) / column;
            const x = padding + j * (itemWidth + padding);
            const height1 = Math.min(itemWidth * .9, 320);
            const height2 = 30;
            const height3 = 10;
            const space =
                padding + height1 + (padding / 2 + height2) + height3 + padding * 4;
            const y1 = padding * 2 + space * (i - 1);
            const y2 = y1 + padding + height1;

            list.push(<rect
                key={`preloader-image-${index}`}
                x={x}
                y={y1}
                rx={borderRadius}
                ry={borderRadius}
                width={itemWidth}
                height={height1}
            />);

            list.push(<rect
                key={`preloader-text-${index}`}
                x={x} y={y2} rx={0} ry={0} width={itemWidth} height={height2} />)


            if (i === row) {
                height = y2 + height2;
            }
            index++;
        }
    }

    return (<div className={'preloader-wrapper'} >
        <ContentLoader
            uniqueKey={'preloader-key'}
            backgroundColor={'#EEE'}
            foregroundColor={'#AAA'}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
            {...props}
        >
            {list}
        </ContentLoader>
    </div>
    )
}

export default DocumentListPreloader;