import React from 'react';
import { useIntl } from 'react-intl';
import ModalBox from 'common/components/modalBox/ModalBox';

import './DeleteDocument.scss';

const parseTitle = (props) => {
    if (props.label) {
        return props.label;
    }

    let docTitles = props.selectedItems.map(it => it.label).join(', ');
    return docTitles;
}

const getDeleteDocArray = (selection) => {
    if (selection.id) {
        return [selection.id];
    }
    return [...selection.selectedIds];
}

function DeleteDocument(props) {
    const { formatMessage } = useIntl();
    const { actions } = props;
    const options = {
        id: 'modal-delete-document',
        title: formatMessage({ id: 'global:delete' }),
        buttons: [
            {
                onClick: () => { actions.home.deleteDocuments(getDeleteDocArray(selection)) },
                title: formatMessage({ id: 'global:delete' }),
                id: 'btn-delete-document',
                isSubmit: true
            }
        ]
    };

    const { selection } = props;

    return <ModalBox
        isSpinnerVisible={props.modalState.isSpinnerVisible}
        onDestroy={props.onDestroy}
        {...options}  >
        <h1>
            {formatMessage(
                { id: 'modalbox:removeDocument:message' },
                {
                    docTitle: parseTitle(selection),
                    span: chunks => <span key={'delete-message'} className='doc-name'>{chunks}</span>,
                })}
        </h1>
    </ModalBox>
}

export default DeleteDocument;