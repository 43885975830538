import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { AUTH_PATH } from 'constants/constants';

const PrivateRoute = (props) => {
    const { component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                props.isLoggedIn ? (
                    <div>
                        <Component {...routeProps} />
                    </div>
                ) : (
                        <Redirect
                            to={{
                                pathname: AUTH_PATH,
                                state: { from: routeProps.location }
                            }}
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;