import {
    MONT_BLANC_STREAM, MATE_STREAM, WEB_STREAM,
    INKSPACE_STREAM, BAMBOO_STREAM, COLLABORATION_STREAM
} from 'constants/constants';
import { getGroupProps } from 'common/contextMenuHelpers/ContextMenuHelpers';

function allButtons(props) {
    const { formatMessage } = props;


    return [
        { id: 'rename', title: formatMessage({ id: 'global:rename' }), onClick: () => props.actions.doRenameGroup(props.contextMenu.selectedGroups[0]) },
        {
            id: 'export', title: formatMessage({ id: 'global:export' }),
            onClick: () => props.actions.doShowExportModal(props.contextMenu.selectedItems)
        },
        { id: 'delete', title: formatMessage({ id: 'global:delete' }), onClick: () => props.actions.doDeleteDocsAndGroups(props.contextMenu) },
        { id: 'groups', title: formatMessage({ id: 'global:groups' }), onClick: props.toggleGroupView, groups: [] },
        { id: 'tags', title: formatMessage({ id: 'global:tags' }), onClick: props.toggleTagView, tags: [] }
    ];
}

function hasSelectedDifferentStreamsWithGroups(selectedStream) {
    let hasDifferentGroupStreams = false;

    const montBlanc = selectedStream[MONT_BLANC_STREAM];
    const mate = selectedStream[MATE_STREAM];
    const inkspace = selectedStream[INKSPACE_STREAM];

    const hasClassicMateGroups = mate || inkspace;

    if (hasClassicMateGroups && montBlanc) {
        hasDifferentGroupStreams = true;
    }

    return hasDifferentGroupStreams;
}

function hasBpDocuments(selectedStream) {
    let hasSelectedNonMateItem = false;

    if (selectedStream[BAMBOO_STREAM]) {
        hasSelectedNonMateItem = true;
    }

    return hasSelectedNonMateItem;
}

function getButtonsToShow(props) {
    const actionsToRemove = new Set();
    let buttonsToShow = allButtons(props).map(a => ({ ...a }));
    const { selectedItems, selectedGroups, selectedDocTypes } = props.contextMenu;

    const hasSelectedDocuments = selectedItems && selectedItems.length > 0;
    const hasSelectedGroup = selectedGroups && selectedGroups.length > 0;
    const hasSeveralGroupStreams = hasSelectedDifferentStreamsWithGroups(selectedDocTypes);
    const hasSelectedBpDocument = hasBpDocuments(selectedDocTypes);
    const hasSelectedDocWithoutGroupSupport = selectedDocTypes[WEB_STREAM] || selectedDocTypes[COLLABORATION_STREAM];

    //Remove Groups
    if (hasSeveralGroupStreams || hasSelectedDocWithoutGroupSupport) {
        actionsToRemove.add('groups')
    }

    if (hasSelectedBpDocument) {
        actionsToRemove.add('tags')
        actionsToRemove.add('groups')
    }

    if (hasSelectedGroup) {
        actionsToRemove.add('export')
        actionsToRemove.add('groups')

        const groupProps = getGroupProps(selectedGroups, props.groups);
        if (groupProps.hasEmptyGroup) {
            buttonsToShow.find(el => el.id.includes('tag')).isInactive = true
        }
    }

    if (hasSelectedDocuments && hasSelectedGroup) {
        actionsToRemove.add('delete')
        actionsToRemove.add('rename')
    }

    if (selectedGroups.length !== 1) {
        actionsToRemove.add('rename')
    }

    if (actionsToRemove.size > 0) {
        buttonsToShow = buttonsToShow.filter(button => !actionsToRemove.has(button.id))
    }

    return buttonsToShow;
}


function ControlPanelMenu(props) {
    const buttons = getButtonsToShow(props);

    return buttons;
}

export default ControlPanelMenu;