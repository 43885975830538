import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { REGISTER_VIEW, RESET_PASS_VIEW, MAX_PASS_LENGTH } from 'constants/constants';

function Login(props) {
    const { formatMessage } = useIntl();
    const emailRef = useRef();
    const passwordRef = useRef();
    const emailErrorClass = (props.visibleErrors.email.length === 0 ? '' : 'not-valid-item ');
    const passwordErrorClass = (props.visibleErrors.password.length === 0 ? '' : 'not-valid-item ');

    // Auto user/pass fill leaves the submit button inactive
    useEffect(() => {
        emailRef.current.focus();
        passwordRef.current.focus();
    }, [])

    return (
        <div className='container-full form-wrapper'>
            <div>
                <h1>
                    {formatMessage({ id: 'auth:login:title' })}
                </h1>
                <p>{formatMessage({ id: 'auth:login:allWacom:text' })}</p>
            </div>

            <form id='sign-in' name='login' onSubmit={props.handleSubmit} noValidate>
                <div className='input-wrapper'>
                    <label htmlFor='email'
                        className={emailErrorClass}>
                        {formatMessage({ id: 'auth:email:label' })}
                    </label>
                    <input type='email' id='email' name='email'
                        className={emailErrorClass + 'required-input-email'}
                        value={props.email}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur}
                        ref={emailRef} />
                    {emailErrorClass ? <p className={'not-valid-item'}>
                        {formatMessage({ id: 'auth:email:invalid' })}
                    </p> : ''}
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='password'
                        className={passwordErrorClass}>
                        {formatMessage({ id: 'auth:password:label' })}
                    </label>
                    <input type='password' id='password' name='password'
                        className={passwordErrorClass + 'required-password'}
                        value={props.password}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur}
                        ref={passwordRef}
                        maxLength={MAX_PASS_LENGTH} />
                </div>

                <div className='input-wrapper change-margin'>
                    <span className={'blueButton'} onClick={() => props.changeChildComponent(RESET_PASS_VIEW)}>
                        {formatMessage({ id: 'auth:login:forgotPassword:link' })}
                    </span>
                </div>

                <button type='submit'
                    className={(props.isSubmitActive ? '' : 'disable-btn ') + 'submit-btn btn-default'}
                    disabled={!props.isSubmitActive}>
                    {formatMessage({ id: 'auth:login:button' })}
                </button>

                <p className='no-account-box'>
                    <span className='no-account-message'>
                        {formatMessage({ id: 'auth:login:createNewAccount:label' })}
                    </span>
                    <span className={'blueButton'} onClick={() => props.changeChildComponent(REGISTER_VIEW)}>
                        {formatMessage({ id: 'auth:login:signup:button' })}
                    </span>
                </p>
            </form>
        </div>)
}

export default Login;