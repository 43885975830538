export const getCollaborationMenuItem = (item, actions, isWill2EditMode, formatMessage, duplicate = true) => {
  let titleKey = 'context-menu:startCollaboration';
  const itemCopy = { ...item, duplicate };

  const collaboration = {
    id: 'ctrl-button-startCollaboration',
    title: formatMessage({ id: titleKey }),
    className: 'doc-ctrl-button',
    callback: () => actions.doStartCollaboration(itemCopy, isWill2EditMode)
  };

  return collaboration;
}

export const getDuplicateAndEditItem = (item, actions, formatMessage) => {
  return {
    id: 'button-duplicateAndEdit',
    title: formatMessage({ id: 'global:duplicateAndEdit' }),
    className: 'doc-ctrl-button',
    callback: () => actions.doCopyDocument(item)
  };
}

export const getEditItem = (item, actions, pageId, formatMessage) => {
  return {
    id: 'ctrl-button-Edit',
    title: formatMessage({ id: 'context-menu:edit' }),
    className: 'doc-ctrl-button',
    callback: () => actions.doEdit(item, pageId)
  };
}