import React, { useRef, useContext } from 'react';
import useDropdown from 'common/components/menu/dropDown/use-dropdown';
import DropDown from 'common/components/menu/dropDown/DropDown';
import DropDownContent from 'common/components/menu/dropDown/DropDownContent';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';
import ZoomItem from './ZoomItem';


import './ZoomTool.scss';

function PrintZoomList(props) {
    let zoomFactors = [...INK_EDITOR_CONFIG.zoom];
    
    if (props.baseScale && zoomFactors.findIndex(x => x.scaleFactor === props.baseScale) === -1) {
        zoomFactors.push({
            label: `${Math.floor(props.baseScale * 100)}%`,
            scaleFactor: props.baseScale
        });

        zoomFactors = zoomFactors.sort((a, b) => b.scaleFactor - a.scaleFactor );
    };

    return zoomFactors.map(el => <ZoomItem key={el.scaleFactor} data={el} onChange={props.onChange} />);
}


function ZoomTool(props) {
    const { scale, setScale, baseScale } = useContext(CanvasContext);
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    const [isDropOpen, toggleDrop] = useDropdown(triggerRef, contentRef);

    return <div className={'zoom-wrapper ink-tool'}>
        <DropDown>
            <div ref={contentRef}>
                <DropDownContent
                    transitionsEffects={{ transitionName: 'slide-in-bottom' }}
                    show={isDropOpen}
                    className='dropdown-content'
                    position={'bottom'}
                >
                    <ul className={'scaleFactor-list'}>
                        <PrintZoomList onChange={scale => setScale(scale)} baseScale={baseScale} />
                    </ul>
                </DropDownContent>
                <div ref={triggerRef}
                    className={'zoom-wrapper-children'}
                    onClick={toggleDrop}
                >
                    <span className={'scale-factor'}>
                        {`${Math.floor(scale * 100)}%`}
                    </span>
                </div>
            </div>
        </DropDown>
    </div>
}

export default ZoomTool;