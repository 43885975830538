const messages = {
  'documents:empty:noresult:noresult' : 'NENHUM RESULTADO',
  'documents:empty:noresult:noresultfound' : 'Nenhum resultado encontrado para os seus critérios.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Após conectar seu aparelho ao aplicativo Ink Space e efetuar login com sua conta da Wacom, você verá todo o seu conteúdo aqui.',
  'documents:empty:stream:explanation1:webstream' : 'Experimente o Ink Collaboration.  Compartilhe e colabore com outros em suas criações. Experimente hoje',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Após conectar o Bamboo Paper ao seu Wacom ID, você verá todo o seu conteúdo aí.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Tome notas. Faça mais. Como papel, mas melhor.',
  'documents:empty:stream:tryitnow' : 'EXPERIMENTE AGORA',
  'documents:empty:stream:learnmore' : 'SAIBA MAIS',
  'documents:empty:group' : 'O seu grupo está vazio',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Fluxo de colaboração',

  'filter:result' : 'RESULTADOS PARA',
  'filter:search' : 'PESQUISAR...',

  'footer:navigation:privacy' : 'PRIVACIDADE',
  'footer:navigation:termOfUse' : 'TERMOS DE USO',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Preferências de cookies',

  'global:convertTo' : 'Converter para {language}',
  'global:convertToText' : 'Converter para texto',

  'context-menu:createNewCanvas' : 'Criar nova tela',
  'context-menu:createNewGroup' : 'Criar grupo',
  'context-menu:startNewCollaboration' : 'Iniciar nova colaboração',
  'context-menu:startCollaboration' : 'Iniciar colaboração',
  'context-menu:edit' : 'Editar',

  'global:delete' : 'Excluir',
  'global:rename' : 'Renomear',
  'global:export' : 'Exportar',
  'global:tags' : 'Tags',
  'global:groups': 'Grupos',
  'global:shareLink' : 'COMPARTILHAR LINK',
  'global:copy' : 'Copiar',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Duplicar e editar',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'SALVAR',
  'global:dontsave' : 'NÃO SALVAR',
  'global:proceed' : 'CONTINUAR',
  'global:enterName' : 'INSERIR NOME',
  'global:browserUnsupported' : 'Navegador incompatível',

  'global:language' : 'IDIOMA',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Fazer logoff',
  'global:login' : 'Logon',
  'global:myAccount' : 'Minha conta',

  'inputField:existsName' : 'Erro! O nome já existe!',
  'inputField:incorrectName' : 'Erro! O nome está incorreto!',
  'inputField:notInRange' : 'Erro! O nome deve conter entre ( {minLength} – {maxLength} ) caracteres!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Use os navegadores Chrome ou Firefox para se conectar a esta colaboração. Obrigado',
  'modalbox:browser:unsupport:sf:text': 'Use os navegadores Chrome ou Safari para se conectar a esta colaboração. Obrigado',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Erro!',
  'modalbox:error:buttonTitle' : 'Fechar',
  'modalbox:exportError:message' : 'A ação de exportação não está disponível no momento',
  'modalbox:createTag:buttons:title' : 'CRIAR',
  'modalbox:createTag:title' : 'CRIAR NOVA TAG',
  'modalbox:deleteTag:message' : 'Você está prestes a excluir <span>"{tag}"</span>. Esta ação é irreversível.',
  'modalbox:removeDocument:message' : 'Você está prestes a excluir <span>"{docTitle}"</span>. Esta ação é irreversível.',
  'modalbox:shareLink:buttons:stopSharing' : 'Parar de compartilhar',
  'modalbox:sessionFull:title' : 'COLABORAÇÃO LOTADA',
  'modalbox:sessionFull:text' : 'Infelizmente, a sessão de colaboração que você está tentando acessar está lotada.',
  'modalbox:leaveSession:title' : 'Sair da sessão',
  'modalbox:leaveSession:buttons:leave' : 'SAIR',
  'modalbox:leaveSession:text' : 'Deseja sair da sessão de colaboração?',
  'modalbox:endSession:title' : 'Encerrar sessão',
  'modalbox:endSession:buttons:end' : 'Encerrar',
  'modalbox:endSession:text' : 'Deseja encerrar a sessão de colaboração atual? O progresso atual será automaticamente salvo em seu Web Stream.',
  'modalbox:sessionEnded:title' : 'Sessão encerrada',
  'modalbox:sessionEnded:text' : 'Deseja salvar uma cópia da sessão de colaboração em seu Web Stream?',
  'modalbox:accessDenied:title' : 'Acesso negado',
  'modalbox:accessDenied:buttons:goBack' : 'Voltar',
  'modalbox:accessDenied:text' : 'Infelizmente, a sessão de colaboração que você está tentando acessar expirou.',
  'modalbox:collaborationStarted:title' : 'Colaboração iniciada',
  'modalbox:collaborationStarted:warn' : 'Você deve manter o Ink Collaboration aberto.  Se fechar esta guia ou mudar para uma outra, o Ink Collaboration será encerrado.',
  'modalbox:collaborationStarted:text' : 'Sua sessão de colaboração foi iniciada. Você pode convidar até 10 participantes fornecendo-lhes a URL da colaboração em seu navegador.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Não mostrar',
  'modalbox:duplicateAndEdit:text' : 'Será colocada uma nova versão ampliada da página no seu Web Stream do Ink Space sem alterar a original',
  'modalbox:saveChanges:title' : 'Salvar alterações',
  'modalbox:saveChanges:text' : 'Deseja salvar as alterações ao documento antes de fechar?',
  'modalbox:collaborationError:title' : 'Erro de colaboração!',
  'modalbox:collaborationError:buttons:close' : 'Fechar',
  'modalbox:collaborationError:text' : 'O navegador que você está utilizando não é compatível com o Ink Collaboration.  Esse recurso funciona melhor com Firefox /v55+/, Chrome /v60+/ e Safari /v11+/',
  'modalbox:WebGLError:title' : 'Ocorreu um erro.',
  'modalbox:WebGLError:text' : 'Ocorreu um erro e você precisa recarregar o conteúdo utilizando o botão "Recarregar" na mensagem acima.',
  'modalbox:collaborationJoinFailed:title': 'Não foi possível estabelecer conexão',
  'modalbox:collaborationJoinFailed:text': 'A sessão que você está tentando acessar não pode ser acessada. Tente novamente mais tarde.',
  'modalbox:connectionLost:title': 'Conexão perdida',
  'modalbox:connectionLost:text': 'A conexão foi perdida, confira a sua conexão com a internet e tente novamente.',
  'modalbox:phoneDetection:title': 'Usar aplicativo móvel',
  'modalbox:phoneDetection:btn:open': 'Abrir',
  'modalbox:phoneDetection:btn:no-thanks': 'Não, obrigado',
  'modalbox:phoneDetection:msg': 'Recomendamos o uso do nosso aplicativo móvel dedicado no seu telefone para desfrutar de uma melhor experiência.',
  'modalbox:chooseVectorOrRaster:title' : 'Selecionar modo de edição',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Anotar',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vetorizar',
  'modalbox:chooseVectorOrRaster:text' : 'O conteúdo que está tentando editar é baseado em raster e, para manter a aparência original, recomendamos substituí-lo. Além disso, você pode optar por convertê-lo em vetor, mas o resultado poderá ser diferente do original dependendo das ferramentas utilizadas.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Excluir grupo',
  'modalbox:deleteGroup:title:multiple' : 'Excluir grupos',
  'modalbox:deleteGroup:button:removeGroup' : 'Remover grupo',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Remover grupos',
  'modalbox:deleteGroup:message' : 'Deseja excluir apenas o grupo <span>"{titles}"</span> ou também o respectivo conteúdo? Esta ação é irreversível.',
  'modalbox:deleteGroup:message:multiple': 'Deseja excluir apenas os grupos <span>"{titles}"</span> ou também o respectivo conteúdo? Esta ação é irreversível.',
  'modalbox:deleteGroup:button:removeAll': 'Excluir tudo',
  'modalbox:deleteGroup:button:cancel': 'Cancelar',
  'modalbox:deletePage:title': 'Excluir página',
  'modalbox:deletePage:message': 'Deseja excluir <span>"{titles}"</span> ou remover deste grupo?',
  'modalbox:deletePage:removePage': 'Remover do grupo',
  'modalbox:group:rename': 'Renomear grupo ',
  'mailbox:exportLimit:title': 'Limite atingido',
  'mailbox:exportLimit:message': 'A quantidade máxima de {maxExportPages} notas foi excedida. Reduza a quantidade de notas selecionadas e tente novamente.',

  'toast:groupAddedSuccess:single': 'Página adicionada com êxito a "{groupName}"',
  'toast:groupAddedSuccess:plural': 'Páginas adicionadas com êxito a "{groupName}"',
  'toast:pagesRemovedFromGroup:single': 'Página removida com êxito de "{groupName}"',
  'toast:pagesRemovedFromGroup:plural': 'Páginas removidas com êxito de "{groupName}"',
  'toast:groupCreated': '"{groupName}" foi criado com êxito',
  'toast:groupRemoved': '"{groupName}" foi removido com êxito',
  'toast:groupRemoved:andPages': '"{groupName}" e páginas foram removidos com êxito',
  'toast:groupRemoved:plural': 'Os grupos selecionados foram removidos com êxito',
  'toast:noteRemoved:single': 'Página excluída com êxito',
  'toast:noteRemoved:plural': 'Páginas excluídas com êxito',

  'sorter:ascending' : 'Crescente',
  'sorter:date' : 'Data',
  'sorter:descending' : 'Decrescente',
  'sorter:name' : 'Nome',
  'sorter:size' : 'Tamanho',
  'sorter:sortby' : 'Classificar Por',
  'sorter:type' : 'Tipo',

  'tagContainer:createTag' : 'CRIAR ETIQUETA',
  'tagContainer:noTags' : 'Sem etiquetas',
  'tagContainer:noTagsMessage' : 'Quando você criar etiquetas, elas serão exibidas aqui',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'Depois de criar grupos, eles aparecerão aqui',

  'export:asDoc' : 'como DOC',
  'export:asPsd' : 'como PSD',
  'export:asJpg' : 'como JPG',
  'export:asPdf' : 'como PDF',
  'export:asPng' : 'como PNG',
  'export:asSvg' : 'como SVG',
  'export:asWill' : 'como WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'como PowerPoint',

  // Ink to Video
  'video:generating' : 'Gerando o seu vídeo',
  'video:timeNotice' : 'Isso pode demorar um pouco',
  'video:estimatedTime.one': 'Isso pode demorar até um minuto',
  'video:estimatedTime.many': 'Isso pode demorar até <span>{minutes}</span>minutos',
  'video:fileReady' : 'Seu arquivo está pronto',
  'video:download'  : 'Download',
  'video:expirationMessage' : 'O download será excluído automaticamente após <span>{days}</span>dias',
  'video:error' : 'Falha ao gerar o vídeo',

  // Session Expired Prompt
  'session:expired:title': 'Sessão expirada',
  'session:expired:message': 'A sua sessão expirou. Faça logon novamente.',
  'session:expired:button': 'Logon',

  // Auth module
  'auth:email:label' : 'E-mail',
  'auth:email:taken' : 'O endereço de e-mail não está disponível.',
  'auth:email:notRegisterd' : 'O endereço de e-mail não está registrado.',
  'auth:email:invalid' : 'E-mail inválido',
  'auth:password:label' : 'Senha',
  'auth:password:reenter:label' : 'Reinsira a senha',
  'auth:password:mismatch:label' : 'As senhas não são iguais',
  'auth:password:tooEasy:label' : 'A senha deve conter pelo menos {charLength} caracteres e 3 dos seguintes: Uma letra maiúscula, uma letra minúscula, um número, um símbolo especial.',
  'auth:firstName:label' : 'Nome',
  'auth:lastName:label' : 'Sobrenome',
  'auth:country:label' : 'País',
  'auth:country:select' : 'Selecionar país',
  'auth:language:label' : 'Idioma',
  'auth:language:select' : 'Selecionar idioma',
  'auth:termsAndConditions:label' : 'Você leu e concordou com os <a href="{termsHref}">Termos de uso</a> da Wacom.',
  'auth:privacyPolicy:label' : 'Você leu e concordou com a <a href="{privacyHref}">política de privacidade</a> e a <a href="{cookieHref}">política de cookies</a> da Wacom.',
  'auth:login:invalid' : 'Login ou senha inválidos.',
  'auth:login:title' : 'Conecte-se com Wacom ID',
  'auth:login:allWacom:text' : 'Sua conta para tudo relacionado à Wacom.',
  'auth:login:forgotPassword:link' : 'Esquecer senha',
  'auth:login:button' : 'Login',
  'auth:login:signup:button' : 'Registrar-se',
  'auth:login:createNewAccount:label' : 'Você não tem uma conta?',
  'auth:login:alreadyHaveAccount:label' : 'Já tem uma conta?',
  'auth:passwordReset:title' : 'Redefinir senha',
  'auth:passwordReset:text' : 'Insira seu endereço de e-mail que você usou para sua Wacom ID. Vamos te enviar um link para redefinir sua senha.',
  'auth:passwordReset:reset:button' : 'Redefinir',
  'auth:passwordReset:signIn:button' : 'Entrar',
  'auth:passwordReset:back:button' : 'Voltar para',
  'auth:passwordReset:success:text' : 'Um e-mail foi enviado para seu endereço de e-mail.',
  'auth:register:title' : 'Criar seu perfil',
  'auth:register:success:title1' : 'Parabéns!',
  'auth:register:success:title2' : 'Você concluiu seu registro',
  'auth:register:success:message' : 'Para continuar, confira seu e-mail e confirme seu registro. Então conecte-se, para acessar o painel.',
  'auth:errors:generic' : 'Algo deu errado. Tente novamente.',
  'auth:policyUpdate:title': 'Nossos Termos de Uso e Política de Privacidade mudaram desde a última vez que você se conectou',

  // Welcome screens
  'welcome:screen1:header': 'Recursos do Ink Collaboration',
  'welcome:screen1:text': 'Com o Ink Collaboration, você pode trabalhar de forma colaborativa com qualquer pessoa e em qualquer lugar. Comece uma tela a partir do seu conteúdo ou uma nova e comece a pintar com outras pessoas.',
  'welcome:screen2:header': 'Sincronização e compartilhamento de conteúdos',
  'welcome:screen2:text': 'Com a Wacom ID e seus aplicativos conectados à nuvem, seu conteúdo está disponível on-line a qualquer hora e em qualquer lugar. Exporte para outros formatos para continuar trabalhando em suas ferramentas de software e aplicativos favoritos.',
  'welcome:screen3:header': 'Acesse todo o seu conteúdo',
  'welcome:screen3:text': 'Sua Wacom ID desbloqueia muitos novos recursos e fluxos de trabalho para seu conteúdo. Acesse de qualquer lugar, compartilhe com outras pessoas ou mantenha seu conteúdo com um backup on-line para mais segurança. A Wacom ID é a sua chave para a nuvem.',
  'welcome:button:next': 'Próximo',
  'welcome:button:done': 'Concluído',

  // Migration screen
  'migration:screen1:text1': 'Seu conteúdo está sendo otimizado. Aguarde.',
  'migration:screen1:text2': 'Processando...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Atualização do serviço',
  'whatIsNew:cloudMigration:mainText': 'Estamos fazendo melhorias nos nossos Serviços em nuvem. Pode haver um curto atraso para ver ou usar seu conteúdo. Obrigado pela paciência.',
  'whatIsNew:cloudMigration:cloudTeam': '"- Equipe Wacom Cloud"',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Resumo',
  'semantic:sidebar:confidence': 'confiança',
  'semantic:sidebar:reference': 'Referência',

  // Semantic-ink icon description
  'semantic:type:organization': 'organização',
  'semantic:type:topic': 'tópico',
  'semantic:type:person': 'pessoa',
  'semantic:type:location': 'localização',
  'semantic:type:event': 'evento',
  'semantic:error:header': 'Sem reconhecimento de Semantic Ink',
  'semantic:error:text': 'Sua nota não precisa de nenhum aprimoramento com o Semantic Ink.',

  // Download my data
  'dataDownload:profile:option': 'Os meus dados',
  'dataDownload:tabs:downloadData': 'Descarregar dados',
  'dataDownload:tabs:availableData': 'Dados disponíveis',
  'dataDownload:tabs:downloadData:description': 'Exportar e descarregar uma cópia do conteúdo da tua conta Inkspace.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Selecione...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Selecionar tudo',
  'dataDownload:processing:description': 'O descarregamento dos teus ficheiros pode demorar algum tempo. Assim que o teu descarregamento estiver pronto, receberás um e-mail com uma ligação de descarregamento, ou poderás fazer o descarregamento a partir daqui.',
  'dataDownload:processing': 'A processar...',
  'dataDownload:request:button': 'Descarregar dados',
  'dataDownload:tabs:availableData:description': 'Descarregar os teus dados',
  'dataDownload:tabs:availableData:archiveFor': 'Arquivo para ',
  'global:myData': 'Os meus dados'
}

export default messages;