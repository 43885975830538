import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useGlobal from 'store';
import { changeEditModePage } from 'common/components/utilities/queryHelper';

import WillCanvasEditor from './WillCanvas/WillCanvasEditor';
import Brand from 'common/components/brand/Brand';
import SaveChanges from '../components/SaveChanges/SaveChanges';
import { BASE_HOME_LOCATION } from 'constants/constants';

function InkEditor(props) {
    const brand = <Brand brandName='Brand' className={'ink-mode'} />;
    const params = useParams();
    const history = useHistory();
    const { docId, pageId } = params;
    const [globalState, globalActions] = useGlobal();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const { showGeneticErrorMessage } = globalActions.home;
    const { inkEditor } = globalState;
    const { clearInkEditorState } = globalActions.inkEditor;

    useEffect(() => {
        return () => {
            clearInkEditorState();
        }
    }, [clearInkEditorState]);

    useEffect(() => {
        globalActions.inkEditor.getDocument(docId, pageId);
    }, [docId, pageId, globalActions]);

    const { width, height, isFetching, inkModels } = inkEditor;
    const images = (inkEditor && inkEditor.pageContents)
        ? inkEditor.pageContents.assets.filter(item => item.type !== 'ink')
        : [];
    const pageIds = inkEditor?.pageContents?.allPageIds;

    const updateDocument = (inkModel) => {
        globalActions.inkEditor.updateInkModel(docId, pageId, inkModel);
    }

    const goHome = () => {
        const originApplicationName = props.history?.location?.state?.originApplicationName;
        if (originApplicationName) {
            history.push(`/?applicationName=${originApplicationName}`);
        } else {
            history.push(BASE_HOME_LOCATION);
        }
    };

    const onPageChange = (newPage) => {
        // Page changes should preserve history state
        history.push(changeEditModePage(docId, newPage), history.location?.state);
    }

    const onCloseButtonClick = () => {
        let hasChanges = false;

        Object.keys(inkEditor.changedPages).forEach(key => {
            hasChanges |= inkEditor.changedPages[key];
        });

        if (hasChanges) {
            setIsSaveModalOpen(true);
        } else {
            goHome();
        }
    }

    const onModalClose = () => {
        setIsSaveModalOpen(false);
    }

    const onSaveChanges = () => {
        globalActions.inkEditor.updateMultiplePages(docId, goHome);
    }

    return <>
        <WillCanvasEditor
            brand={brand}
            inkModels={inkModels}
            images={images}
            isFetching={isFetching}
            updateInk={updateDocument}
            pageId={pageId}
            pageIds={pageIds}
            width={width}
            height={height}
            showError={showGeneticErrorMessage}
            onPageChange={onPageChange}
            setActivePage={globalActions.inkEditor.setActivePage}
            onClose={onCloseButtonClick} />

        {isSaveModalOpen && <SaveChanges
            onClose={onModalClose}
            onSaveChanges={onSaveChanges}
            onDiscardChanges={goHome}
            isSpinnerVisible={globalState.isSpinnerVisible}
        />}
    </>
}

export default InkEditor;