import React from 'react'
import './Brand.scss'

function Brand(props) {
    let className = `brand-container ${props.className ?? ''}`
    return (
        <div className={className} onClick={() => props?.onClick && props.onClick()}>
            <div className={'brand-logo'} />
        </div>
    )
}

export default Brand;