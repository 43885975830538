import React, { useState } from 'react';
import ContextMenu from './ContextMenu'

import './ContextMenuItem.scss';

const contextMenuClass = 'context-menu-item';

function ContextMenuItem(props) {
    const [isChildVisible, setIsChildVisible] = useState(false);
    const { title, onClick, id, className, buttons, emptyView, isInactive } = props;

    return <li
        id={id}
        className={`${className} ${contextMenuClass} ${isInactive ? ' inactive-item' : ''}`}
        onMouseEnter={() => setIsChildVisible((buttons && buttons.length > 0) || !!emptyView)}
        // onMouseLeave={() => setIsChildVisible(false)}
        onDoubleClick={ev => ev.preventDefault()}
        onClick={ev => {
            if (!isInactive) onClick(ev)
        }}
    >
        <span>{title}</span>
        {isChildVisible && !isInactive
            ? <ContextMenu emptyView={emptyView}
                isChild
                // style={customStyles}
                className={'children-drop-down'}
                elements={buttons} /> : null}
    </li>
}

export default ContextMenuItem;