const messages = {
  'documents:empty:noresult:noresult' : '結果なし',
  'documents:empty:noresult:noresultfound' : '検索基準に一致する結果がありませんでした。',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'デバイスをInk Spaceアプリと接続してから、Wacom IDでサインアップすれば、ここにコンテンツがすべて表示されます。',
  'documents:empty:stream:explanation1:webstream' : 'Ink Collaboration をお試しください。他のクリエイターと制作物を共有して共同で作業を行うことができます。すぐに試す',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Wacom ID を使用して Bamboo Paper に接続すると、すべてのコンテンツが表示されます。',
  'documents:empty:stream:explanation1:wacom_notes' : 'メモを取り、それ以上を行う。紙の上の感覚そのままで、さらに優れた機能。メモを取り、それ以上を行う。紙の上の感覚そのままで、さらに優れた機能。',
  'documents:empty:stream:tryitnow' : 'すぐに試す',
  'documents:empty:stream:learnmore' : '詳細を見る',
  'documents:empty:group' : 'グループは空です',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes Stream',
  'streams:collaboration': 'コラボレーションの流れ',

  'filter:result' : '結果：',
  'filter:search' : '検索...',

  'footer:navigation:privacy' : 'プライバシーポリシー',
  'footer:navigation:termOfUse' : '利用規約',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie環境設定',

  'global:convertTo' : '以下に変換： {language}',
  'global:convertToText' : 'テキストに変換',

  'context-menu:createNewCanvas' : '新しいキャンバスの作成',
  'context-menu:createNewGroup' : 'グループの作成',
  'context-menu:startNewCollaboration' : '新しいコラボレーションの開始',
  'context-menu:startCollaboration' : 'コラボレーションの開始',
  'context-menu:edit' : '編集',

  'global:delete' : '削除',
  'global:rename' : '名前変更',
  'global:export' : 'エクスポート',
  'global:tags' : 'タグ',
  'global:groups': 'グループ',
  'global:shareLink' : 'リンクを共有',
  'global:copy' : 'コピー',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : '複製して編集',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : '保存',
  'global:dontsave' : '保存しない',
  'global:proceed' : '次へ進む',
  'global:enterName' : '名前の入力',
  'global:browserUnsupported' : 'ブラウザーがサポートされていません',

  'global:language' : '言語',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'ログアウト',
  'global:login' : 'ログイン',
  'global:myAccount' : 'マイアカウント',

  'inputField:existsName' : 'エラー。この名前はすでに使用されています。',
  'inputField:incorrectName' : 'エラー。名前が正しくありません。',
  'inputField:notInRange' : 'エラー。名前は ( {minLength} - {maxLength} ) 文字の範囲でなければなりません。',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'このコラボレーションに接続するには Chrome または Firefox ブラウザーを使用してください。ありがとうございました',
  'modalbox:browser:unsupport:sf:text': 'このコラボレーションに接続するには Chrome または Safari ブラウザーを使用してください。ありがとうございました',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'エラー。',
  'modalbox:error:buttonTitle' : '閉じる',
  'modalbox:exportError:message' : 'エクスポートアクションは現在利用できません',
  'modalbox:createTag:buttons:title' : '作成',
  'modalbox:createTag:title' : '新しいタグを作成',
  'modalbox:deleteTag:message' : '<span>"{tag}"</span> を削除しようとしています。このアクションは元に戻せません。',
  'modalbox:removeDocument:message' : '<span>"{docTitle}"</span> を削除しようとしています。このアクションは元に戻せません。',
  'modalbox:shareLink:buttons:stopSharing' : '共有を停止',
  'modalbox:sessionFull:title' : 'コラボレーションが満員です',
  'modalbox:sessionFull:text' : 'アクセスしようとしているコラボレーション セッションは満員です。',
  'modalbox:leaveSession:title' : 'セッションの離脱',
  'modalbox:leaveSession:buttons:leave' : '離脱',
  'modalbox:leaveSession:text' : 'コラボレーション セッションを離脱しますか？',
  'modalbox:endSession:title' : 'セッションの終了',
  'modalbox:endSession:buttons:end' : '終了',
  'modalbox:endSession:text' : '現在のコラボレーション セッションを終了しますか？現在までの作業内容は、Web Stream に自動的に保存されます',
  'modalbox:sessionEnded:title' : 'セッションが終了しました',
  'modalbox:sessionEnded:text' : 'Web Stream のコラボレーション セッションのコピーを保存しますか？',
  'modalbox:accessDenied:title' : 'アクセス拒否',
  'modalbox:accessDenied:buttons:goBack' : '戻る',
  'modalbox:accessDenied:text' : 'アクセスしようとしているコラボレーション セッションは終了しています。',
  'modalbox:collaborationStarted:title' : 'コラボレーションが開始されました',
  'modalbox:collaborationStarted:warn' : 'Ink Collaboration は開いたままにしておく必要があります。このタブを閉じるか別のタブに切り替えると、Ink Collaboration は終了します。',
  'modalbox:collaborationStarted:text' : ' コラボレーション セッションが開始されました。ブラウザーからコラボレーション URL を送ることで、最大 10 人の参加者を招待できます',
  'modalbox:duplicateAndEdit:buttons:dontshow' : '表示しない',
  'modalbox:duplicateAndEdit:text' : 'オリジナルを変更することなく、新たに拡張されたページが Ink Space Web Stream に配置されます',
  'modalbox:saveChanges:title' : '変更の保存',
  'modalbox:saveChanges:text' : '終了する前にドキュメントへの変更を保存しますか？',
  'modalbox:collaborationError:title' : 'コラボレーション エラー!',
  'modalbox:collaborationError:buttons:close' : '閉じる',
  'modalbox:collaborationError:text' : 'Ink Collaboration は、お使いのブラウザーをサポートしていません。この機能は、Firefox /v55+/、Chrome /v60+/、Safari /v11+/ で最適に利用できます。',
  'modalbox:WebGLError:title' : 'エラーが発生しました',
  'modalbox:WebGLError:text' : 'エラーが発生したため、メッセージ上部の「Reload」ボタンを使ってコンテンツを再読み込みする必要があります。',
  'modalbox:collaborationJoinFailed:title': '接続を確立できません',
  'modalbox:collaborationJoinFailed:text': 'アクセスしようとしているセッションにアクセスできません。後でやり直してください。',
  'modalbox:connectionLost:title': '接続が失われました',
  'modalbox:connectionLost:text': '接続が失われました。インターネット接続を確認し、やり直してください。',
  'modalbox:phoneDetection:title': 'モバイル アプリを使用する',
  'modalbox:phoneDetection:btn:open': '開く',
  'modalbox:phoneDetection:btn:no-thanks': '使用しない',
  'modalbox:phoneDetection:msg': 'より快適な操作のため、スマートフォンで専用モバイル アプリをお使いいただくことをお勧めします。',
  'modalbox:chooseVectorOrRaster:title' : '編集モードの選択',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'アノテーションの作成',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'ベクター形式に変換',
  'modalbox:chooseVectorOrRaster:text' : '編集しようとしているコンテンツはラスター形式です。元の外観を維持するにはラスター形式のままアノテーションを追加することをお勧めします。ベクター形式に変換することもできますが、使用するツールによっては外観が変わる可能性があります。',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'グループを削除する',
  'modalbox:deleteGroup:title:multiple' : 'グループを削除する',
  'modalbox:deleteGroup:button:removeGroup' : 'グループを削除する',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'グループを削除する',
  'modalbox:deleteGroup:message' : 'このグループ <span>"{titles}"</span> のみを削除しますか、あるいは中身も削除しますか?この操作は元に戻せません。',
  'modalbox:deleteGroup:message:multiple': 'このグループ <span>"{titles}"</span> のみを削除しますか、あるいは中身も削除しますか?この操作は元に戻せません。',
  'modalbox:deleteGroup:button:removeAll': 'すべて削除',
  'modalbox:deleteGroup:button:cancel': 'キャンセル',
  'modalbox:deletePage:title': 'ページを削除',
  'modalbox:deletePage:message': '<span>"{titles}"</span> を削除しますか、それともこのグループから削除しますか?',
  'modalbox:deletePage:removePage': 'グループから削除する',
  'modalbox:group:rename': 'グループの名前を変更する ',
  'mailbox:exportLimit:title': '制限に達しました',
  'mailbox:exportLimit:message': 'ノートの最大数 {maxExportPages} を越えています。選択済みのノートの数を減らしてやり直してください。',

  'toast:groupAddedSuccess:single': 'ページが "{groupName}" に追加されました',
  'toast:groupAddedSuccess:plural': 'ページが "{groupName}" に追加されました',
  'toast:pagesRemovedFromGroup:single': 'ページが "{groupName}" から削除されました',
  'toast:pagesRemovedFromGroup:plural': 'ページが "{groupName}" から削除されました',
  'toast:groupCreated': '"{groupName}" が作成されました',
  'toast:groupRemoved': '"{groupName}" が削除されました',
  'toast:groupRemoved:andPages': '"{groupName}" とページが削除されました',
  'toast:groupRemoved:plural': '選択したグループが削除されました',
  'toast:noteRemoved:single': 'ページを削除しました',
  'toast:noteRemoved:plural': 'ページを削除しました',



  'sorter:ascending' : '昇順',
  'sorter:date' : '日付',
  'sorter:descending' : '降順',
  'sorter:name' : '名前',
  'sorter:size' : 'サイズ',
  'sorter:sortby' : '並び替え：',
  'sorter:type' : 'タイプ',

  'tagContainer:createTag' : 'タグを作成',
  'tagContainer:noTags' : 'タグがありません',
  'tagContainer:noTagsMessage' : 'タグを作成するとここに表示されます',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'グループを作成するとここに表示されます',

  'export:asDoc' : 'DOCとして',
  'export:asPsd' : 'PSDとして',
  'export:asJpg' : 'JPGとして',
  'export:asPdf' : 'PDFとして',
  'export:asPng' : 'PNGとして',
  'export:asSvg' : 'SVG として',
  'export:asWill' : 'WILLとして',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'PowerPointとして',

  // Ink to Video
  'video:generating' : '動画を作成する',
  'video:timeNotice' : 'しばらくお待ちください',
  'video:estimatedTime.one': '1 分間お待ちください',
  'video:estimatedTime.many': '<span>{minutes}</span>分間お待ちください',
  'video:fileReady' : 'ファイルが準備できました',
  'video:download'  : 'ダウンロード',
  'video:expirationMessage' : 'ダウンロードは <span>{days}</span>日後に自動的に削除されます',
  'video:error' : '動画を作成できませんでした',

  // Session Expired Prompt
  'session:expired:title': 'セッションの期限切れ',
  'session:expired:message': 'セッション期限が切れています。再度ログインしてください。',
  'session:expired:button': 'ログイン',

  // Auth module
  'auth:email:label' : 'メールアドレス',
  'auth:email:taken' : 'メールアドレスが使用できません。',
  'auth:email:notRegisterd' : 'メールアドレスが登録されていません。',
  'auth:email:invalid' : '無効なメール',
  'auth:password:label' : 'パスワード',
  'auth:password:reenter:label' : 'パスワードを再入力',
  'auth:password:mismatch:label' : 'パスワードが一致しません',
  'auth:password:tooEasy:label' : 'パスワードは{charLength}文字以上で、次のうち3つが含まれる必要があります:大文字、小文字、数字、特殊記号。',
  'auth:firstName:label' : '名',
  'auth:lastName:label' : '姓',
  'auth:country:label' : '国',
  'auth:country:select' : '国を選択',
  'auth:language:label' : '言語',
  'auth:language:select' : '言語を選択',
  'auth:termsAndConditions:label' : 'ワコムの<a href="{termsHref}">利用規約</a>を読み、同意しました。',
  'auth:privacyPolicy:label' : 'ワコムの<a href="{privacyHref}">プライバシーポリシー</a>および<a href="{cookieHref}">クッキーポリシー</a>を読み、同意しました。',
  'auth:login:invalid' : 'ログインまたはパスワードが無効です。',
  'auth:login:title' : 'Wacom IDにサインイン',
  'auth:login:allWacom:text' : 'あなたのアカウントはすべてWacomに属します。',
  'auth:login:forgotPassword:link' : 'パスワードを忘れました',
  'auth:login:button' : 'ログイン',
  'auth:login:signup:button' : '登録',
  'auth:login:createNewAccount:label' : 'アカウントを持っていませんか。',
  'auth:login:alreadyHaveAccount:label' : 'すでにアカウントを持っていますか。',
  'auth:passwordReset:title' : 'パスワードのリセット',
  'auth:passwordReset:text' : 'Wacom IDに使用したメールアドレスを入力します。その後パスワードをリセットするためのリンクが送られます。',
  'auth:passwordReset:reset:button' : 'リセット',
  'auth:passwordReset:signIn:button' : 'サインイン',
  'auth:passwordReset:back:button' : '戻る',
  'auth:passwordReset:success:text' : 'お使いのメールアドレスにメールが送信されました。',
  'auth:register:title' : 'プロフィールを作成',
  'auth:register:success:title1' : 'おめでとう!',
  'auth:register:success:title2' : '登録が終了しました',
  'auth:register:success:message' : '続けるには、メールをチェックして登録を確認し、ダッシュボードにアクセスするためにサインインします。',
  'auth:errors:generic' : '問題が発生しました。もう一度やり直してください。',
  'auth:policyUpdate:title': '前回のサインイン以降、利用規約およびプライバシーポリシーが変更されました',

  // Welcome screens
  'welcome:screen1:header': 'Ink Collaborationの特徴',
  'welcome:screen1:text': 'Ink Collaborationを利用することで、どこでも誰とでもコラボレーションが可能となります。自分のコンテンツでキャンバスを開始するか、新しいキャンバスを作成して他の人と一緒にペン入れが行えます。',
  'welcome:screen2:header': 'コンテンツの同期と共有',
  'welcome:screen2:text': 'Wacom IDとクラウドに接続されたアプリを使用することで、コンテンツがオンラインでいつでも、どこからでも利用可能となります。これを他の形式にエクスポートすれば、好きなソフトウェアとアプリで作業を続行できます。',
  'welcome:screen3:header': '全コンテンツにアクセス',
  'welcome:screen3:text': 'Wacom IDはコンテンツに使用できる多くの機能とワークフローを利用可能とします。Wacom IDこそがクラウドへの鍵となり、どこからでもアクセスして、他の人と共有したり、安全のためにオンラインでコンテンツのバックアップしたりすることができるようになります。 ',
  'welcome:button:next': '次へ',
  'welcome:button:done': '完了',

  // Migration screen
  'migration:screen1:text1': 'コンテンツの最適化を実施中。お待ちください。',
  'migration:screen1:text2': '処理中...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'サービスアップデート',
  'whatIsNew:cloudMigration:mainText': '現在、クラウドサービスの改善を実施中です。その間、コンテンツの表示または使用に多少の遅延が発生する可能性があります。ご理解に感謝します。',
  'whatIsNew:cloudMigration:cloudTeam': '"- Wacom Cloudチーム"',

  // Semantic-ink
  'semantic:sidebar:abstract': 'アブストラクト',
  'semantic:sidebar:confidence': '信頼',
  'semantic:sidebar:reference': 'リファレンス',

  // Semantic-ink icon description
  'semantic:type:organization': '組織',
  'semantic:type:topic': 'テーマ',
  'semantic:type:person': '人物',
  'semantic:type:location': '場所',
  'semantic:type:event': 'イベント',
  'semantic:error:header': 'Semantic Ink認識なし',
  'semantic:error:text': 'メモはSemantic Ink強化を必要としていません。',
  
  // Download my data
  'dataDownload:profile:option': 'マイデータ',
  'dataDownload:tabs:downloadData': 'データをダウンロード',
  'dataDownload:tabs:availableData': '利用可能なデータ',
  'dataDownload:tabs:downloadData:description': 'お使いのInk Spaceアカウントのコンテンツのコピーをエクスポートしてダウンロードしましょう。',
  'dataDownload:tabs:downloadData:chooseProduct': '選択してください...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'すべて選択',
  'dataDownload:processing:description': 'ファイルがダウンロードできるようになるまで多少時間がかかる可能性があります。ダウンロードの準備が完了すると、ダウンロード用のリンクが記載されたメールが送信されます。または、ここからもダウンロード可能です。',
  'dataDownload:processing': '処理中...',
  'dataDownload:request:button': 'データをダウンロード',
  'dataDownload:tabs:availableData:description': 'データをダウンロード',
  'dataDownload:tabs:availableData:archiveFor': '次のためにアーカイブ化: ',
  'global:myData': 'マイデータ'
}

export default messages;