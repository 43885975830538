import { Matrix, Point } from 'digital-ink';
import { PinchEvent } from 'dom-transformer';

const MIN_SCALE_FACTOR = 0.1;
const MAX_SCALE_FACTOR = 8;
const MARGIN = 50;

class Lens {
	constructor(canvas, canvasWrapper, refresh, abort) {
		this.canvas = canvas;
		this.canvasWrapper = canvasWrapper;
		this.abort = abort;
		this.scaleFactor = 1;
		this.isPrimaryMouseButtonEnable = false;
		this.viewCenter = {
			x: this.canvas.surface.width / 2,
			y: this.canvas.surface.height / 2
		};

		let matrix = new Matrix();

		Object.defineProperty(this, "transform", {
			get: function () {
				return matrix;
			},
			set: function (value) {
				matrix = value;

				refresh(matrix);
			},
			enumerable: true
		});

		this.initEvents();
		this.enable();

		this.fit();
	}

	initEvents() {
		let lastPoint;

		this.onZoom = this.zoom.bind(this);

		this.onPanStart = function onPanStart(e) {
			const isPrimary = this.isPrimaryMouseButtonEnable && e.buttons === 1;

			if (e.buttons === 2 || isPrimary) lastPoint = { x: e.clientX, y: e.clientY };
		}.bind(this);

		this.onPan = function onPan(e) {
			const isPrimary = this.isPrimaryMouseButtonEnable && e.buttons === 1;

			if (e.buttons !== 2 && !isPrimary) return;
			if (!lastPoint) return;

			let delta = { x: e.clientX - lastPoint.x, y: e.clientY - lastPoint.y };
			lastPoint = { x: e.clientX, y: e.clientY };

			this.pan(delta);
		}.bind(this);

		this.onPanEnd = function onPanEnd(e) {
			lastPoint = null;
		};

		let pincher = PinchEvent.register(this.canvas.surface);
		pincher.reset(new Point(0, 0));

		this.onPinchStart = function onPinchStart(e) {
			this.abort();
		}.bind(this);

		this.onPinch = function onPinch(e) {
			this.zoom(e.detail.pin, e.detail.scale);
			this.pan(e.detail.translation);
		}.bind(this);
	}

	toggleOnPrimaryMouseButton(isEnabled) {
		this.isPrimaryMouseButtonEnable = isEnabled;
	}

	enable() {
		this.canvas.surface.addEventListener("wheel", this.onZoom, { passive: true });

		this.canvas.surface.addEventListener("mousedown", this.onPanStart);
		document.addEventListener("mousemove", this.onPan);
		document.addEventListener("mouseup", this.onPanEnd);

		this.canvas.surface.addEventListener("pinchstart", this.onPinchStart);
		this.canvas.surface.addEventListener("pinch", this.onPinch);
	}

	disable() {
		this.canvas.surface.removeEventListener("wheel", this.onZoom);

		this.canvas.surface.removeEventListener("mousedown", this.onPanStart);
		document.removeEventListener("mousemove", this.onPan);
		document.removeEventListener("mouseup", this.onPanEnd);

		this.canvas.surface.removeEventListener("pinchstart", this.onPinchStart);
		this.canvas.surface.removeEventListener("pinch", this.onPinch);
	}

	fit() {
		let node = this.canvasWrapper;

		let sceneBounds = { width: node.offsetWidth - MARGIN, height: node.offsetHeight - MARGIN };
		let center = { x: sceneBounds.width / 2, y: sceneBounds.height / 2 };

		let scaleFactor = 1;

		if (this.canvas.bounds.width >= sceneBounds.width || this.canvas.bounds.height >= sceneBounds.height) {
			if (this.canvas.bounds.width > this.canvas.bounds.height) {
				scaleFactor = sceneBounds.width / this.canvas.bounds.width;

				if (this.canvas.bounds.height * scaleFactor >= sceneBounds.height)
					scaleFactor = sceneBounds.height / this.canvas.bounds.height;
			}
			else {
				const scaleX = sceneBounds.width / this.canvas.bounds.width;
				const scaleY = sceneBounds.height / this.canvas.bounds.height;
				scaleFactor = Math.min(scaleX, scaleY);
			}
		}

		// let rect = this.canvas.bounds.transform(Matrix.fromScale(scaleFactor));

		// let tx = Math.floor(center.x - rect.width / 2);
		// let ty = Math.floor(center.y - rect.height / 2 + MARGIN / 2);
		const tx = Math.floor(center.x - (this.canvas.bounds.width / 2) + MARGIN / 2);
		const ty = Math.floor(center.y - (this.canvas.bounds.height / 2) + MARGIN / 2);

		this.transform = Matrix.fromMatrix({ a: scaleFactor, d: scaleFactor, tx, ty });
		this.scaleFactor = scaleFactor;
	}

	zoom(e, scaleFactor) {
		// let offsetParent = this.canvasWrapper.offsetParent.getBoundingClientRect();
		let pos = !scaleFactor ? e : { x: this.viewCenter.x, y: this.viewCenter.y };

		// translate the point to canvas coordinate system
		pos = this.transform.transformPoint({
			x: pos.x - this.viewCenter.x,
			y: pos.y - this.viewCenter.y
		});

		let factor = scaleFactor ? scaleFactor : (e.deltaY > 0) ? 0.97 : 1.03;

		if (this.transform.a * factor < MIN_SCALE_FACTOR)
			factor = MIN_SCALE_FACTOR / this.transform.a;
		else if (this.transform.a * factor > MAX_SCALE_FACTOR)
			factor = MAX_SCALE_FACTOR / this.transform.a;

		let scale = this.transform.scale(factor, pos);

		this.scaleFactor = factor;
		this.transform = scale;
	}

	setScale(scale) {
		// TODO: check for rotation
		this.transform = Matrix.fromMatrix({
			a: scale,
			d: scale,
			tx: this.transform.tx,
			ty: this.transform.ty
		});
	}

	pan(delta) {
		let translation = this.transform.translate(delta);

		this.transform = translation;
	}

	reset() {
		this.transform = new Matrix();
	}

	modelToView(modelRect) {
		return modelRect;
	}

	viewToModel(viewRect) {
		return viewRect;
	}
}

export default Lens
