import React from 'react'
import { useIntl } from 'react-intl';
import { LOGIN_VIEW } from 'constants/constants';

import finishedImage from '../assets/finished.png';

function RegistrationFinished(props) {
    const { formatMessage } = useIntl();

    return (
        <div className='container-full form-wrapper'>
            <h1>
                {formatMessage({ id: 'auth:register:success:title1' })}
                <br />
                {formatMessage({ id: 'auth:register:success:title2' })}
            </h1>
            <p>
                {formatMessage({ id: 'auth:register:success:message' })}
            </p>

            <div className='img-wrapper center'>
                <img src={finishedImage} alt='Finished' />
            </div>

            <div className='inner-button-wrapper center'>
                <span onClick={() => props.changeChildComponent(LOGIN_VIEW)} className='btn-default'>
                    {formatMessage({ id: 'auth:passwordReset:signIn:button' })}
                </span>
            </div>
        </div>
    )
}

export default RegistrationFinished;