import React, { useState, useEffect, useCallback } from 'react';
import useGlobal from 'store';
import { useHistory, useParams } from 'react-router-dom';
import AuthWrapped from '../components/AuthWrapper';
import Login from '../components/Login';
import Register from '../components/Register';
import PasswordReset from '../components/PasswordReset';
import PasswordSent from '../components/PasswordSent';
import RegistrationFinished from '../components/RegistrationFinished';
import {
  authLocalizationMappings, MIN_PASS_LENGTH, LOGIN_VIEW,
  REGISTER_VIEW, RESET_PASS_VIEW, RESET_PASS_SUCCESS_VIEW, REGISTER_SUCCESS_VIEW, HOME_PATH_WITH_APP_NAME,
  WELCOME_PATH, AUTH_PATH_WITH_PARAMS
} from 'constants/constants';

import { shouldShowWelcomeScreen } from 'common/welcomeScreenHelper';


export const REQUIRED = 'required'
export const VALID_EMAIL = 'valid-email'
export const INSECURE_PASS = 'insecure-pass'
export const NOT_DEFAULT = 'not-default'
export const MATCH_PASS = 'match-pass'
export const IS_CHECKED = 'is-checked'

const DEFAULT = 'default'
const filedRules = {
  email: [VALID_EMAIL],
  password: [REQUIRED, INSECURE_PASS],
  passReenter: [REQUIRED, MATCH_PASS],
  firstName: [REQUIRED],
  lastName: [REQUIRED],
  language: [NOT_DEFAULT],
  country: [NOT_DEFAULT],
  termsOfUse: [IS_CHECKED],
  privacyPolicy: [IS_CHECKED]
};

let viewValidationFields = {};
viewValidationFields[LOGIN_VIEW] = ['email', 'password'];
viewValidationFields[REGISTER_VIEW] = [
  'email', 'firstName',
  'lastName', 'password',
  'passReenter', 'language',
  'country', 'termsOfUse',
  'privacyPolicy'
];

viewValidationFields[RESET_PASS_VIEW] = ['email'];

function AuthContainer(props) {
  const params = useParams();
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const { setActiveView } = globalActions.auth;
  const { auth: authState } = globalState;
  const { isLoggedIn, isRefreshingToken, activeView } = authState;

  const [state, setState] = useState({
    email: '',
    user: '',
    password: '',
    passReenter: '',
    firstName: '',
    lastName: '',
    country: DEFAULT,
    language: DEFAULT,
    termsOfUse: false,
    privacyPolicy: false,
    // if the copy is not deep the validation breaks
    errors: JSON.parse(JSON.stringify(filedRules)),
    dirtyFields: [],
    visibleErrors: {
      email: [],
      user: [],
      password: [],
      passReenter: [],
      firstName: [],
      lastName: []
    },
    isSubmitActive: false,
  });

  const changeChildComponent = useCallback((newView) => {
    const newRoute = AUTH_PATH_WITH_PARAMS.replace(':view?', newView);
    history.push(newRoute);

    const newState = {
      ...state,
      email: '',
      user: '',
      password: '',
      passReenter: '',
      firstName: '',
      lastName: '',
      country: DEFAULT,
      language: DEFAULT,
      termsOfUse: false,
      privacyPolicy: false,
      dirtyFields: [],
      visibleErrors: {
        email: [],
        user: [],
        password: [],
        passReenter: [],
        firstName: [],
        lastName: []
      }
    }

    if (newView === RESET_PASS_VIEW) {
      newState.email = state.email;
    }

    setState(newState);
  }, [history, state]);

  const handleValidation = useCallback((targetName, targetValue, viewProp, state) => {
    const view = viewProp || activeView;
    const stateChanges = { errors: state.errors, visibleErrors: state.visibleErrors };
    const fieldsToValidate = viewValidationFields[view];
    const validationCopy = {};

    // Get the default errors for the current element
    // and all errors in the state for all other errors
    for (const key of fieldsToValidate) {
      let value = undefined
      if (key === targetName) {
        value = filedRules[key]
      } else {
        value = state.errors[key]
      }
      validationCopy[key] = new Set(value)
    }

    for (let fieldName of fieldsToValidate) {
      if (fieldName === targetName) {
        const fieldRules = validationCopy[fieldName]
        for (let rule of fieldRules) {
          let isRuleOk = false
          if (rule === REQUIRED) {
            isRuleOk = isValidRequiredField(targetValue)
          } else if (rule === VALID_EMAIL) {
            isRuleOk = isValidEmail(targetValue)
          } else if (rule === NOT_DEFAULT) {
            isRuleOk = isValidDropdown(targetValue)
          } else if (rule === MATCH_PASS) {
            isRuleOk = targetValue === state.password
            // Password security sould not be validated on login
          } else if (rule === INSECURE_PASS) {
            if (view === LOGIN_VIEW) isRuleOk = true;
            else { isRuleOk = isSecurePassword(targetValue) }
          } else if (rule === IS_CHECKED) {
            isRuleOk = targetValue === true
          }

          if (isRuleOk) {
            validationCopy[fieldName].delete(rule)
          }
        }

        stateChanges.errors[fieldName] = [...validationCopy[fieldName]]

        if (stateChanges.errors[fieldName].length !== 0 && state.dirtyFields.includes(targetName)) {
          stateChanges.visibleErrors[fieldName] = stateChanges.errors[fieldName]
        } else {
          stateChanges.visibleErrors[fieldName] = []
        }
      }
    }

    const hasError = hasErrors(validationCopy)
    if (hasError) {
      if (state.isSubmitActive) {
        stateChanges.isSubmitActive = false;
      }
    } else {
      if (!state.isSubmitActive) {
        stateChanges.isSubmitActive = true;
      }
    }

    setState({ ...state, ...stateChanges })
  }, [activeView]);

  const revalidateRequiredFields = useCallback((viewKey) => {
    const fieldsToValidate = viewValidationFields[viewKey]
    if (fieldsToValidate) {
      for (const field of fieldsToValidate) {
        handleValidation(field, state[field], viewKey, state)
      }
    }
  }, [handleValidation, state]);

  useEffect(() => {
    if (!authState.nomenclature) {
      globalActions.auth.fetchNomenclature();
    }
  }, [authState.nomenclature, globalActions]);

  useEffect(() => {
    const referrerAddress = history?.location?.state?.from?.pathname;
    if (isLoggedIn && !isRefreshingToken) {
      if (referrerAddress) {
        history.push(referrerAddress)
      } else {
        if (shouldShowWelcomeScreen()) {
          history.push(WELCOME_PATH);
        } else {
          history.push(HOME_PATH_WITH_APP_NAME);
        }
      }
    }
  }, [isLoggedIn, isRefreshingToken, globalActions.auth, history])

  useEffect(() => {
    if (params.view && activeView !== params.view) {
      setActiveView(params.view);
      revalidateRequiredFields(params.view);
    }
  }, [params, activeView, setActiveView, changeChildComponent, revalidateRequiredFields]);

  const handleFieldChange = (ev) => {
    const newState = { ...state, [ev.target.name]: ev.target.value };
    handleValidation(ev.target.name, ev.target.value, null, newState)
  }

  const handleCheckboxChange = (ev) => {
    const newState = { ...state, [ev.target.name]: ev.target.checked };
    handleValidation(ev.target.name, ev.target.checked, null, newState)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()

    if (activeView === LOGIN_VIEW) {
      globalActions.auth.fetchLogin(state.email, state.password)
    } else if (activeView === REGISTER_VIEW) {
      const registrationForm = {
        email: state.email,
        password: state.password,
        firstName: state.firstName,
        lastName: state.lastName,
        country: state.country,
        language: state.language
      }

      globalActions.auth.fetchRegistration(registrationForm,
        () => changeChildComponent(REGISTER_SUCCESS_VIEW))
    } else if (activeView === RESET_PASS_VIEW) {
      globalActions.auth.fetchPasswordReset(state.email, (path) => history.push(path))
    }
  }

  const handleFieldBlur = (ev) => {
    const targetName = ev.target.name
    const targetValue = ev.target.value
    const stateChanges = {}
    if (targetValue && !state.dirtyFields.includes(targetName)) {
      stateChanges.dirtyFields = [...state.dirtyFields, targetName]
      stateChanges.visibleErrors = JSON.parse(JSON.stringify(state.visibleErrors))
      stateChanges.visibleErrors[targetName] = state.errors[targetName]

      setState({ ...state, ...stateChanges });
    }
  }


  const isValidEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase()) > 0;
  }

  const isSecurePassword = (password) => {
    // The original Triphon way
    var strength = 0;

    if (password.length < MIN_PASS_LENGTH) {
      return false;
    }

    // If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1;

    // If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1;

    // If it has one special character, increase strength value.
    if (password.match(/([^A-Za-z0-9])/)) strength += 1;

    if (strength < 3) {
      return false;
    }

    return true;
  }

  const hasErrors = (errors) => {
    let isFaulted = false
    for (const ind in errors) {
      if (errors[ind].size > 0) {
        isFaulted |= true
      }
    }

    return isFaulted !== false
  }

  const isValidRequiredField = (value) => {
    if (!value) return false
    if (value.length === 0) return false
    return true
  }

  const isValidDropdown = (value) => {
    return value !== DEFAULT
  }

  const getChildComponent = (view, propsToPass) => {
    let child = undefined

    if (view === LOGIN_VIEW) {
      child = <Login {...propsToPass} />
    } else if (view === REGISTER_VIEW) {
      child = <Register {...propsToPass} />
    } else if (view === RESET_PASS_VIEW) {
      child = <PasswordReset {...propsToPass} />
    } else if (view === RESET_PASS_SUCCESS_VIEW) {
      child = <PasswordSent {...propsToPass} />
    } else if (view === REGISTER_SUCCESS_VIEW) {
      child = <RegistrationFinished {...propsToPass} />
    }

    return child;
  }


  const stateWithEventHandlers = {
    ...state,
    handleFieldChange: handleFieldChange,
    handleCheckboxChange: handleCheckboxChange,
    handleSubmit: handleSubmit,
    changeChildComponent: changeChildComponent,
    handleFieldBlur: handleFieldBlur,
    locale: globalState.localization.locale,
    nomenclature: globalState.auth.nomenclature || {}
  }

  const child = getChildComponent(activeView, stateWithEventHandlers);
  const childProps = {
    ...state,
    child,
    shouldShowLoader: globalState.auth.isFetching || globalState.auth.isRefreshingToken,
    errorsFromApi: globalState.auth.loginActiveErrors.map(err => authLocalizationMappings[err] || 'auth:errors:generic').filter(it => it) || []
  }

  return <AuthWrapped {...childProps} />
}

export default AuthContainer;