import React from 'react';
import { useIntl } from 'react-intl';

import './EmptyGroups.scss';


function EmptyGroups(props) {
    const { style } = props;
    const { formatMessage } = useIntl();

    return <div className='no-groups' style={style}>
        <p className='body'>{formatMessage({ id: 'groupContainer:noGroupsMessage' })}</p>
    </div>
}

export default EmptyGroups;