import React from 'react';
import src from './img_check.png';

function ActiveCheck() {
    return <img style={{
        width: '1.1rem',
        height: '0.8rem'
    }} 
    src={src}
    alt='active-check-mark' />
}

export default ActiveCheck;