import React from 'react';
import { useIntl } from 'react-intl';
import ModalBox from 'common/components/modalBox/ModalBox';

function DeleteTag(props) {
    const { formatMessage } = useIntl();
    const { modalState, actions } = props;
    const selectedTag = modalState.selectedTag;

    const buttons = [{
        onClick: () => {
            actions.home.deleteTag(selectedTag.id);
        },
        id: 'delete-tag',
        title: formatMessage({ id: 'global:delete' }),
        isSubmit: true
    }];

    const h1MessageHtml = formatMessage(
        { id: 'modalbox:deleteTag:message' },
        {
            tag: selectedTag.label,
            span: chunks => <span key={'delete-message'} className="tag-name">{chunks}</span>,
        }
    );

    return <ModalBox title={formatMessage({ id: 'global:delete' })}
        buttons={buttons}
        onDestroy={props.onDestroy}
    >
        {h1MessageHtml}
    </ModalBox>
};

export default DeleteTag;