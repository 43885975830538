const messages = {
  'documents:empty:noresult:noresult' : 'BRAK WYNIKÓW',
  'documents:empty:noresult:noresultfound' : 'Nie znaleziono wyników pasujących do podanych kryteriów.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Gdy urządzenie jest podłączone do aplikacji Ink Space i zalogujesz się za pomocą identyfikatora Wacom, zobaczysz tutaj całą swoją treść.',
  'documents:empty:stream:explanation1:webstream' : 'Wypróbuj funkcję Ink Collaboration.  Udostępniaj swoje dzieła innym użytkownikom w ramach współpracy. Wypróbuj dzisiaj',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Kiedy połączysz aplikację Bamboo Paper z identyfikatorem Wacom ID, wszystkie Twoje treści będą widoczne tutaj.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Sporządzaj notatki. Rób więcej. Jak na papierze, ale o wiele lepiej.',
  'documents:empty:stream:tryitnow' : 'Wypróbuj teraz',
  'documents:empty:stream:learnmore' : 'DOWIEDZ SIĘ WIĘCEJ',
  'documents:empty:group' : 'Grupa jest pusta',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Strumień współpracy',

  'filter:result' : 'WYNIKI DLA',
  'filter:search' : 'SZUKAJ...',

  'footer:navigation:privacy' : 'PRYWATNOŚĆ',
  'footer:navigation:termOfUse' : 'WARUNKI UŻYTKOWANIA',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Preferencje dotyczące plików cookie',

  'global:convertTo' : 'ZAMIEŃ NA {language}',
  'global:convertToText' : 'Zamień na tekst',

  'context-menu:createNewCanvas' : 'Utwórz nową kanwę',
  'context-menu:createNewGroup' : 'Utwórz grupę',
  'context-menu:startNewCollaboration' : 'Rozpocznij nową współpracę',
  'context-menu:startCollaboration' : 'Rozpocznij współpracę',
  'context-menu:edit' : 'Edytuj',

  'global:delete' : 'Usuń',
  'global:rename' : 'Zmień nazwę',
  'global:export' : 'Eksportuj',
  'global:tags' : 'Tagi',
  'global:groups': 'Grupy',
  'global:shareLink' : 'Udostępnij link',
  'global:copy' : 'Kopiuj',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Zduplikuj i edytuj',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Zapisz',
  'global:dontsave' : 'Nie zapisuj',
  'global:proceed' : 'KONTYNUUJ',
  'global:enterName' : 'WPROWADŹ NAZWĘ',
  'global:browserUnsupported' : 'Nieobsługiwana przeglądarka',

  'global:language' : 'JĘZYK',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Wyloguj',
  'global:login' : 'Zaloguj się',
  'global:myAccount' : 'Moje konto',

  'inputField:existsName' : 'Wystąpił błąd. Nazwa już istnieje.',
  'inputField:incorrectName' : 'Wystąpił błąd. Nazwa jest nieprawidłowa.',
  'inputField:notInRange' : 'Wystąpił błąd. Nazwa musi mieć od {minLength} do {maxLength} znaków.',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Użyj przeglądarki Chrome lub Firefox, aby połączyć się z tą sesją współpracy. Dziękujemy',
  'modalbox:browser:unsupport:sf:text': 'Użyj przeglądarki Chrome lub Safari, aby połączyć się z tą sesją współpracy. Dziękujemy',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Wystąpił błąd.',
  'modalbox:error:buttonTitle' : 'Zamknij',
  'modalbox:exportError:message' : 'Funkcja eksportowania jest w tej chwili niedostępna.',
  'modalbox:createTag:buttons:title' : 'UTWÓRZ',
  'modalbox:createTag:title' : 'UTWÓRZ NOWY TAG',
  'modalbox:deleteTag:message' : 'Zaraz usuniesz <span>„{tag}”</span>. To działanie jest nieodwracalne.',
  'modalbox:removeDocument:message' : 'Zaraz usuniesz <span>„{docTitle}”</span>. To działanie jest nieodwracalne.',
  'modalbox:shareLink:buttons:stopSharing' : 'Przestań udostępniać',
  'modalbox:sessionFull:title' : 'Brak miejsc w sesji współpracy',
  'modalbox:sessionFull:text' : 'Brak miejsc w sesji współpracy',
  'modalbox:leaveSession:title' : 'Opuść sesję',
  'modalbox:leaveSession:buttons:leave' : 'Opuść',
  'modalbox:leaveSession:text' : 'Czy chcesz opuścić sesję współpracy?',
  'modalbox:endSession:title' : 'Zakończ sesję',
  'modalbox:endSession:buttons:end' : 'Zakończ',
  'modalbox:endSession:text' : 'Czy chcesz zakończyć bieżącą sesję współpracy? Bieżące postępy zostaną automatycznie zapisane w strumieniu internetowym.',
  'modalbox:sessionEnded:title' : 'Sesja zakończona',
  'modalbox:sessionEnded:text' : 'Czy chcesz zapisać kopię sesji współpracy w strumieniu internetowym?',
  'modalbox:accessDenied:title' : 'Odmowa dostępu',
  'modalbox:accessDenied:buttons:goBack' : 'Wstecz',
  'modalbox:accessDenied:text' : 'Niestety, sesja współpracy, do której próbujesz uzyskać dostęp, wygasła.',
  'modalbox:collaborationStarted:title' : 'Rozpoczęto współpracę',
  'modalbox:collaborationStarted:warn' : 'Zakładka Ink Collaboration musi pozostać otwarta.  Jeśli zamkniesz tę zakładkę lub przejdziesz do innej, działanie funkcji Ink Collaboration zostanie przerwane.',
  'modalbox:collaborationStarted:text' : ' Rozpoczęła się sesja współpracy. Możesz zaprosić maksymalnie 10 uczestników, przekazując im adres URL współpracy z przeglądarki.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Nie pokazuj',
  'modalbox:duplicateAndEdit:text' : 'W strumieniu internetowym Ink Space zostanie umieszczona nowa, rozszerzona wersja strony. Oryginalna wersja pozostanie bez zmian.',
  'modalbox:saveChanges:title' : 'Zapisz zmiany',
  'modalbox:saveChanges:text' : 'Czy chcesz zapisać zmiany w dokumencie przed zamknięciem?',
  'modalbox:collaborationError:title' : 'Błąd funkcji Collaboration!',
  'modalbox:collaborationError:buttons:close' : 'Zamknij',
  'modalbox:collaborationError:text' : 'Twoja przeglądarka nie jest obsługiwana przez funkcję Ink Collaboration.  Ta funkcja działa w przeglądarkach Firefox od wersji 55 wzwyż, Chrome od wersji 60 wzwyż oraz Safari od wersji 11 wzwyż',
  'modalbox:WebGLError:title' : 'Wystąpił błąd',
  'modalbox:WebGLError:text' : 'Wystąpił błąd. Załaduj ponownie treść przy użyciu przycisku „Załaduj ponownie” z powyższego komunikatu.',
  'modalbox:collaborationJoinFailed:title': 'Nie udało się nawiązać połączenia',
  'modalbox:collaborationJoinFailed:text': 'Nie można uzyskać dostępu do tej sesji. Spróbuj ponownie później.',
  'modalbox:connectionLost:title': 'Utracono połączenie',
  'modalbox:connectionLost:text': 'Nastąpiła utrata połączenia. Sprawdź połączenie z Internetem i spróbuj ponownie.',
  'modalbox:phoneDetection:title': 'Użyj aplikacji mobilnej',
  'modalbox:phoneDetection:btn:open': 'Otwórz',
  'modalbox:phoneDetection:btn:no-thanks': 'Nie, dziękuję',
  'modalbox:phoneDetection:msg': 'Zalecamy korzystanie z naszej specjalnej aplikacji mobilnej, która zapewnia większe możliwości.',
  'modalbox:chooseVectorOrRaster:title' : 'Wybierz tryb edycji',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Adnotacja',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Wektoryzacja',
  'modalbox:chooseVectorOrRaster:text' : 'Próbujesz edytować grafikę rastrową. Aby zachować jej oryginalny wygląd, zaleca się stosowanie adnotacji. Możesz również zamienić grafikę na format wektorowy, ale efekt końcowy może różnić się od oryginału, zależnie od użytych narzędzi.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Usuń grupę',
  'modalbox:deleteGroup:title:multiple' : 'Usuń grupy',
  'modalbox:deleteGroup:button:removeGroup' : 'Usuń grupę',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Usuń grupy',
  'modalbox:deleteGroup:message' : 'Czy chcesz usunąć tylko grupę <span>„{titles}”</span>, czy również jej zawartość? Czynność ta jest nieodwracalna.',
  'modalbox:deleteGroup:message:multiple': 'Czy chcesz usunąć tylko grupy <span>„{titles}”</span>, czy również zawartość? Czynność ta jest nieodwracalna.',
  'modalbox:deleteGroup:button:removeAll': 'Usuń wszystko',
  'modalbox:deleteGroup:button:cancel': 'Anuluj',
  'modalbox:deletePage:title': 'Usuń stronę',
  'modalbox:deletePage:message': 'Czy chcesz usunąć <span>„{titles}”</span> całkowicie, czy tylko z tej grupy?',
  'modalbox:deletePage:removePage': 'Usuń z grupy',
  'modalbox:group:rename': 'Zmień nazwę grupy',
  'mailbox:exportLimit:title': 'Osiągnięto limit',
  'mailbox:exportLimit:message': 'Przekroczono maksymalną liczbę {maxExportPages} notatek. Ogranicz liczbę wybranych notatek i spróbuj ponownie.',

  'toast:groupAddedSuccess:single': 'Strona dodana do „{groupName}”',
  'toast:groupAddedSuccess:plural': 'Strony dodane do „{groupName}”',
  'toast:pagesRemovedFromGroup:single': 'Strona usunięta z „{groupName}”',
  'toast:pagesRemovedFromGroup:plural': 'Strony usunięte z „{groupName}”',
  'toast:groupCreated': 'Utworzono „{groupName}”',
  'toast:groupRemoved': 'Usunięto „{groupName}”',
  'toast:groupRemoved:andPages': '„{groupName}” oraz strony zostały usunięte',
  'toast:groupRemoved:plural': 'Wybrane grupy zostały usunięte',
  'toast:noteRemoved:single': 'Strona usunięta',
  'toast:noteRemoved:plural': 'Strony usunięte',

  'sorter:ascending' : 'Rosnaco',
  'sorter:date' : 'Data',
  'sorter:descending' : 'Malejąco',
  'sorter:name' : 'Nazwa',
  'sorter:size' : 'Rozmiar',
  'sorter:sortby' : 'Sortuj według:',
  'sorter:type' : 'Rodzaj',

  'tagContainer:createTag' : 'UTWÓRZ TAG',
  'tagContainer:noTags' : 'Brak znaczników',
  'tagContainer:noTagsMessage' : 'Utworzone znaczniki pojawią się w tym miejscu',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'Utworzone grupy pojawią się w tym miejscu',

  'export:asDoc' : 'jako DOC',
  'export:asPsd' : 'jako PSD',
  'export:asJpg' : 'jako JPG',
  'export:asPdf' : 'jako PDF',
  'export:asPng' : 'jako PNG',
  'export:asSvg' : 'jako SVG',
  'export:asWill' : 'jako WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'jako PowerPoint',

  // Ink to Video
  'video:generating' : 'Generowanie filmu',
  'video:timeNotice' : 'Może to potrwać chwilę',
  'video:estimatedTime.one': 'Może to potrwać do minuty',
  'video:estimatedTime.many': 'Może to potrwać do <span>{minutes}</span>minut',
  'video:fileReady' : 'Plik jest gotowy',
  'video:download'  : 'Pobierz',
  'video:expirationMessage' : 'Pobrany plik zostanie automatycznie usunięty po <span>{days}</span>dniach',
  'video:error' : 'Wygenerowanie filmu nie powiodło się',

  // Session Expired Prompt
  'session:expired:title': 'Sesja wygasła',
  'session:expired:message': 'Twoja sesja wygasła. Zaloguj się ponownie.',
  'session:expired:button': 'Zaloguj się',

  // Auth module
  'auth:email:label' : 'E-mail',
  'auth:email:taken' : 'Adres e-mail jest niedostępny.',
  'auth:email:notRegisterd' : 'Adres e-mail nie jest zarejestrowany.',
  'auth:email:invalid' : 'Nieprawidłowy adres e-mail',
  'auth:password:label' : 'Hasło',
  'auth:password:reenter:label' : 'Ponownie wprowadź hasło',
  'auth:password:mismatch:label' : 'Hasła nie są takie same',
  'auth:password:tooEasy:label' : 'Hasło musi zawierać co najmniej {charLength} znaków oraz 3 z poniższych: wielką literę, małą literę, cyfrę, znak specjalny.',
  'auth:firstName:label' : 'Imię',
  'auth:lastName:label' : 'Nazwisko',
  'auth:country:label' : 'Kraj',
  'auth:country:select' : 'Wybierz kraj',
  'auth:language:label' : 'Język',
  'auth:language:select' : 'Wybierz język',
  'auth:termsAndConditions:label' : 'Użytkownik przeczytał i wyraził zgodę na <a href="{termsHref}">Warunki użytkowania</a> firmy Wacom.',
  'auth:privacyPolicy:label' : 'Użytkownik przeczytał i wyraził zgodę na <a href="{privacyHref}">Politykę prywatności</a> i <a href="{cookieHref}">Politykę dotyczącą plików cookie</a> firmy Wacom.',
  'auth:login:invalid' : 'Nieprawidłowy login lub hasło.',
  'auth:login:title' : 'Zaloguj się na konto Wacom ID',
  'auth:login:allWacom:text' : 'Twoje konto do wszystkich usług Wacom.',
  'auth:login:forgotPassword:link' : 'Zapomniałem hasła',
  'auth:login:button' : 'Login',
  'auth:login:signup:button' : 'Zarejestruj się',
  'auth:login:createNewAccount:label' : 'Nie masz konta?',
  'auth:login:alreadyHaveAccount:label' : 'Masz już konto?',
  'auth:passwordReset:title' : 'Resetowanie hasła',
  'auth:passwordReset:text' : 'Wprowadź adres e-mail, którego użyłeś dla konta Wacom ID. Wyślemy Ci link do zresetowania hasła.',
  'auth:passwordReset:reset:button' : 'Reset',
  'auth:passwordReset:signIn:button' : 'Zaloguj',
  'auth:passwordReset:back:button' : 'Wstecz',
  'auth:passwordReset:success:text' : 'E-mail został wysłany na Twój adres e-mail.',
  'auth:register:title' : 'Stwórz swój profil',
  'auth:register:success:title1' : 'Gratulacje!',
  'auth:register:success:title2' : 'Ukończyłeś rejestrację',
  'auth:register:success:message' : 'Aby kontynuować, sprawdź swój adres e-mail w celu potwierdzenia rejestracji, a następnie zaloguj się, aby uzyskać dostęp do swojego panelu informacyjnego.',
  'auth:errors:generic' : 'Coś poszło nie tak. Spróbuj ponownie.',
  'auth:policyUpdate:title': 'Nasze Warunki użytkowania i Polityka prywatności uległy zmianie od czasu ostatniego logowania',

  // Welcome screens
  'welcome:screen1:header': 'Funkcje Ink Collaboration',
  'welcome:screen1:text': 'Ink Collaboration umożliwia współpracę z dowolnymi osobami, w dowolnym miejscu. Utwórz obraz wykorzystując własne treści lub utwórz nowy obraz i używaj atramentu cyfrowego, współpracując z innymi.',
  'welcome:screen2:header': 'Synchronizacja i udostępnianie treści',
  'welcome:screen2:text': 'Dzięki identyfikatorowi Wacom ID oraz aplikacjom połączonym z chmurą treści są dostępne w Internecie w dowolnym czasie i miejscu.  Eksportuj do innych formatów i kontynuuj pracę za pomocą swoich ulubionych narzędzi i aplikacji.',
  'welcome:screen3:header': 'Uzyskaj dostęp do wszystkich swoich treści',
  'welcome:screen3:text': 'Identyfikator Wacom ID odblokowuje wiele nowych funkcji oraz przepływy pracy dla Twoich treści.  Dostęp w dowolnym miejscu, możliwość udostępniania innym i kopia zapasowa treści w Internecie.  Identyfikator Wacom ID to Twój klucz do chmury. ',
  'welcome:button:next': 'Dalej',
  'welcome:button:done': 'Gotowe',

  // Migration screen
  'migration:screen1:text1': 'Trwa optymalizacja Twoich treści. Czekaj.',
  'migration:screen1:text2': 'Trwa przetwarzanie...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Aktualizacja usługi',
  'whatIsNew:cloudMigration:mainText': 'Wprowadzamy ulepszenia do naszych rozwiązań chmurowych. Może wystąpić krótkie opóźnienie w wyświetlaniu lub użytkowaniu Twoich treści. Dziękujemy za cierpliwość.',
  'whatIsNew:cloudMigration:cloudTeam': '„– Wacom Cloud Team”',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Skrót',
  'semantic:sidebar:confidence': 'zaufanie',
  'semantic:sidebar:reference': 'Odwołanie',

  // Semantic-ink icon description
  'semantic:type:organization': 'organizacja',
  'semantic:type:topic': 'temat',
  'semantic:type:person': 'osoba',
  'semantic:type:location': 'lokalizacja',
  'semantic:type:event': 'wydarzenie',
  'semantic:error:header': 'Nie rozpoznano Semantic Ink',
  'semantic:error:text': 'Twoja notatka nie potrzebuje żadnych ulepszeń Semantic Ink.',

  // Download my data
  'dataDownload:profile:option': 'Moje dane',
  'dataDownload:tabs:downloadData': 'Pobierz dane',
  'dataDownload:tabs:availableData': 'Dostępne dane',
  'dataDownload:tabs:downloadData:description': 'Wyeksportuj i pobierz kopię zawartości swojego konta Inkspace.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Proszę wybrać...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Zaznacz wszystkie',
  'dataDownload:processing:description': 'Pobieranie plików może chwilę potrwać. Gdy pliki będą gotowe do pobrania, otrzymasz wiadomość e-mail z linkiem do pobrania pliku lub możesz je pobrać z tego miejsca.',
  'dataDownload:processing': 'Trwa przetwarzanie...',
  'dataDownload:request:button': 'Pobierz dane',
  'dataDownload:tabs:availableData:description': 'Pobierz swoje dane',
  'dataDownload:tabs:availableData:archiveFor': 'Archiwum dla ',
  'global:myData': 'Moje dane'
}

export default messages;