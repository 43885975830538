import React, { useState, useEffect, useRef } from 'react'

export const WindowContext = React.createContext(null)

function WindowContextProvider(props) {
    const isFirstRun = useRef(true);
    const [windowIsActive, setWindowIsActive] = useState(true);
    const { action } = props;


    function handleActivity(forcedFlag) {
        if (typeof forcedFlag === 'boolean') {
            return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
        }

        return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
    }

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
        else if (windowIsActive) {
            action();
        }
    }, [windowIsActive, action])

    useEffect(() => {
        const handleActivityFalse = () => handleActivity(false)
        const handleActivityTrue = () => handleActivity(true)

        document.addEventListener('visibilitychange', handleActivity)
        document.addEventListener('blur', handleActivityFalse)
        window.addEventListener('blur', handleActivityFalse)
        window.addEventListener('focus', handleActivityTrue)
        document.addEventListener('focus', handleActivityTrue)

        return () => {
            window.removeEventListener('blur', handleActivity)
            document.removeEventListener('blur', handleActivityFalse)
            window.removeEventListener('focus', handleActivityFalse)
            document.removeEventListener('focus', handleActivityTrue)
            document.removeEventListener('visibilitychange', handleActivityTrue)
        }
    }, [])

    return <WindowContext.Provider value={{ windowIsActive }}>{props.children}</WindowContext.Provider>
}

export default WindowContextProvider;