import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useGlobal from 'store';
import CanvasContext from 'routes/inkEditor/context/CanvasContext';
import WillCanvas from 'routes/inkEditor/containers/WillCanvas/WillCanvas';
import SemanticContext from '../../context/SemanticContext';
import { INK_EDITOR_CONFIG, BASE_HOME_LOCATION } from 'constants/constants';


const panToolId = INK_EDITOR_CONFIG.controls.pan;

function SemanticCanvasWrapper(props) {
    const { setToolId } = useContext(CanvasContext);
    const { selectedElement, setSelectedElement } = useContext(SemanticContext);
    const params = useParams();
    const { docId, pageId } = params;
    const [globalState, globalActions] = useGlobal();
    const { isFetching, width, height } = globalState.inkEditor;
    const history = useHistory();
    const { showGeneticErrorMessage } = globalActions.home;

    useEffect(() => {
        setToolId(panToolId);
    }, [setToolId]);

    useEffect(() => {
        globalActions.inkEditor.getDocument(docId, pageId);
    }, [docId, pageId, globalActions]);


    const inkModels = globalState.inkEditor.inkModels ?? [];
    const images = (globalState.inkEditor && globalState.inkEditor.pageContents)
        ? globalState.inkEditor.pageContents.assets.filter(item => item.type !== 'ink')
        : [];

    const goHome = () => {
        history.push(BASE_HOME_LOCATION);
    };

    return <div>
        <WillCanvas
            activeSemanticElement={selectedElement}
            selectSematicEntity={setSelectedElement}
            isSematicInfoVisible={props.isSematicInfoVisible}
            inkModels={inkModels}
            semanticData={props.semanticData}
            images={images}
            isFetching={isFetching}
            onClose={goHome}
            pageId={props.pageId}
            pageIds={props.pageIds}
            width={width}
            height={height}
            showError={showGeneticErrorMessage}
            onPageChange={props.onPageChange} />
    </div>
}

export default SemanticCanvasWrapper;