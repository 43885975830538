import { LOCAL_STORAGE } from 'constants/constants'

export const changeLocale = (store, locale) => {
    window.localStore.setItem(LOCAL_STORAGE.LOCALE, locale)
    store.setState({ localization: { ...store.state.localization, locale } })

    // IWN-1460: TrustArc localization issue
    if (window.truste) {
        window.location.reload()
    }
}

export const changeGenericErrorMessage = (store, message) => {
    store.setState({ localization: { ...store.state.localization, genericErrorMessage: message } })
}