import React, {
    useState,
    useEffect,
    // useRef
} from 'react';
import { useIntl } from 'react-intl';
import './ApplicationListItem.scss';
// import ApplicationListItem from './ApplicationListItem';
import DownloadListItem from './DownloadListItem';
import Header from '../../common/components/header/Header';
import Brand from '../../common/components/brand/Brand';
import Footer from 'common/components/footer/Footer';
// import { Line } from 'rc-progress';
import {
    PUBLIC_URL,
    // LANGUAGES
} from 'constants/constants';

import useGlobal from 'store';

import './DownloadDataComponent.scss'

// const createTab = 'create';
const availableTab = 'available';
const symbolsMap = {
    web: 'w',
    inkspace: 'a',
    bamboopaper: 'b',
    montblanc: ' '
};

const streamLocalizationMessagesMap = {
    'web': 'streams:inkspaceWebStream',
    'inkspace': 'streams:inkspaceAppStream',
    'bamboopaper': 'streams:inkspaceBambooPaperStream',
    'montblanc': 'streams:inkspaceMontBlanc'
};

function mapDownloadLinks(links) {
    const result = {};

    for (const key in links) {
        const keyLower = key.toLowerCase();
        const index = keyLower.indexOf('dataurl')

        if (index > 0) {
            const cleanKey = keyLower.substring(0, index);
            const link = links[key];
            if (link) {
                result[cleanKey] = links[key];
            }
        }
    }

    return result;
}

// function getApplicationNameFromFolders(folders) {
//     const applicationNames = [];

//     folders.forEach(element => {
//         let appName = element.link.replace('_', '');
//         if (appName === 'mate') appName = 'inkspace';

//         // Show only applications that 
//         if (element.visible && symbolsMap[appName]) {
//             applicationNames.push({
//                 name: appName,
//                 symbol: symbolsMap[appName]
//             });
//         }
//     });

//     return applicationNames;
// }


function DownloadDataComponent(props) {
    const { formatMessage } = useIntl();
    const [globalState, globalActions] = useGlobal();
    // const pollingHandle = useRef();
    const [activeTab, setActiveTab] = useState(availableTab);
    // const [selectedApplications, setSelectedApplications] = useState([]);
    const {
        dataDownload,
        // folders
    } = globalState;
    const {
        // abortDownloadStatus,
        fetchDownloadStatus,
        // abortCreateZip,
        // fetchCreateZip
    } = globalActions.downloadData;

    // const percentage = dataDownload.bambooPaperProgress;
    // const isProgressVisible = percentage !== null;
    // const applicationNames = getApplicationNameFromFolders(folders);
    // const isAllSelected = selectedApplications.length === applicationNames.length;
    // const isDownloadButtonActive = selectedApplications.length > 0 && !isProgressVisible;
    const downloadLinks = mapDownloadLinks(dataDownload);

    const handleTabClick = ev => {
        setActiveTab(ev.target.dataset.key);
    }
    // const handleOnDownloadClick = () => {
    //     const shortLocale = globalState.localization.locale;
    //     const locale = LANGUAGES[shortLocale].locale;
    //     fetchCreateZip(selectedApplications, locale);

    //     pollingHandle.current = setInterval(() => {
    //         fetchDownloadStatus();
    //     }, 3000);
    // }

    // const handleCheckboxToggle = ev => {
    //     const streamName = ev.target.name;
    //     let newAppNames;

    //     // Manage add all
    //     if (streamName === 'all') {
    //         if (selectedApplications.length > 0) {
    //             newAppNames = []
    //         } else {
    //             newAppNames = applicationNames.map(item => item.name);
    //         }
    //     } else {
    //         const isAddOperation = !selectedApplications.includes(streamName);
    //         if (isAddOperation) {
    //             newAppNames = [...selectedApplications, streamName]
    //         } else {
    //             newAppNames = selectedApplications.filter(item => item !== streamName);
    //         }
    //     }

    //     setSelectedApplications(newAppNames);
    // }

    // const clearPolling = () => {
    //     if (pollingHandle.current) {
    //         clearInterval(pollingHandle.current);
    //         pollingHandle.current = null;
    //     }
    // }

    // useEffect(() => {
    //     fetchDownloadStatus();

    //     return () => {
    //         abortCreateZip();
    //         abortDownloadStatus();

    //         clearPolling();
    //     }
    // }, [fetchDownloadStatus, abortCreateZip, abortDownloadStatus]);

    // useEffect(() => {
    //     if (percentage === 100 && pollingHandle.current) {
    //         clearPolling();
    //     }
    // }, [percentage])

    useEffect(() => {
        fetchDownloadStatus();
    }, [fetchDownloadStatus]);

    return <div className={'main-block'}>
        <Header>
            <Brand onClick={() => props.history.push(PUBLIC_URL)} />
        </Header>
        <div className={'tab-header'}>
            <ul>
                {/* <li
                    key={createTab}
                    data-key={createTab}
                    className={`tab-name tab-name-${activeTab === createTab ? 'active' : 'inactive'}`}
                    onClick={handleTabClick}>
                    {formatMessage({ id: 'dataDownload:tabs:downloadData' })}
                </li> */}
                <li key={availableTab}
                    data-key={availableTab}
                    className={`tab-name tab-name-${activeTab === availableTab ? 'active' : 'inactive'}`}
                    onClick={handleTabClick}>
                    {formatMessage({ id: 'dataDownload:tabs:availableData' })}
                </li>
            </ul>
        </div>
        <div className={'main-wrapper'}>
            <div>
                {/* {activeTab === createTab ? <div className={`content-block content-block-${createTab}`}>
                    <div className={'tab-description'}>
                        {formatMessage({ id: 'dataDownload:tabs:downloadData:description' })}
                    </div>
                    <div className={'product-choice'}>
                        <div className={'product-choice-head'}>
                            <span className={'product-choice-head-text'}>
                                {formatMessage({ id: 'dataDownload:tabs:downloadData:chooseProduct' })}
                            </span>
                            <span className={'product-choice-all'}>
                                <label id={'product-choice-text'} htmlFor={'all-products-checkbox'}>
                                    {formatMessage({ id: 'dataDownload:tabs:downloadData:chooseProduct:all' })}
                                </label>
                                <input id={'all-products-checkbox'}
                                    type='checkbox'
                                    name={'all'}
                                    onChange={handleCheckboxToggle}
                                    checked={isAllSelected}
                                ></input>
                            </span>
                        </div>
                        <div className={'application-choices'}>
                            {applicationNames.map(element => <ApplicationListItem
                                key={element.name}
                                selectedApplications={selectedApplications}
                                onChange={handleCheckboxToggle} {...element}
                                localizedMessage={formatMessage({ id: streamLocalizationMessagesMap[element.name] })}
                            />)}
                        </div>
                        <div className={'process-disclaimer'}>
                            {formatMessage({ id: 'dataDownload:processing:description' })}
                            <div className={`progress-bar-wrapper ${isProgressVisible ? 'progress-bar-wrapper-visible' : ''}`}>
                                <div className={'processing-progress'}>
                                    <span className={'text'}>
                                        {formatMessage({ id: 'dataDownload:processing' })}
                                    </span>
                                    <span className={'percentage'}>
                                        {parseInt(percentage)}%
                                    </span>
                                </div>
                                <Line percent={percentage} strokeColor="#00AEEF" trailColor="#d9d9d9" />
                            </div>
                        </div>
                        <div className={'download-button-wrapper'}>
                            <button className={'blue-button'}
                                disabled={!isDownloadButtonActive}
                                onClick={handleOnDownloadClick}>
                                {formatMessage({ id: 'dataDownload:request:button' })}
                            </button>
                        </div>
                    </div>
                </div> : ''} */}
                {activeTab === availableTab ? <div className={`content-block content-block-${availableTab}`}>
                    <div className={'tab-description'}>
                        {formatMessage({ id: 'dataDownload:tabs:availableData:description' })}
                    </div>
                    <div className={'application-choices'}>
                        {Object.keys(downloadLinks).map(key =>
                            <DownloadListItem
                                key={key}
                                name={key}
                                downloadLink={downloadLinks[key]}
                                symbol={symbolsMap[key]}
                                localizedMessage={formatMessage({ id: streamLocalizationMessagesMap[key] })}
                                archiveForMessage={formatMessage({ id: 'dataDownload:tabs:availableData:archiveFor' })} />)}
                    </div>
                </div> : ''}
            </div>
        </div>
        <Footer />
    </div>
}

export default DownloadDataComponent;