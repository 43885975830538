import React from 'react';
import EmptyTags from '../components/tags/EmptyTags';
import { getCollaborationMenuItem } from './editMenuHelper';

function WebContextMenu(props) {
  const { formatMessage, actions, item } = props;

  const buttons = [
    {
      id: 'ctrl-button-Edit',
      title: formatMessage({ id: 'context-menu:edit' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doEdit(item)
    },
    {
      id: 'ctrl-button-semantic',
      title: formatMessage({ id: 'global:semantic-ink' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doOpenSemanticView(item)
    },
    {
      id: 'ctrl-button-rename',
      title: formatMessage({ id: 'global:rename' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doShowRenameDocumentModal(item)
    },
    {
      id: 'ctrl-button-export',
      title: formatMessage({ id: 'global:export' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowExportModal([item])
    },
    {
      id: 'ctrl-button-convertToText',
      title: formatMessage({ id: 'global:convertToText' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowInkToTextModal(item)
    },
    {
      id: 'ctrl-button-delete',
      title: formatMessage({ id: 'global:delete' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doDeleteDocument(item)
    },
    {
      id: 'ctrl-button-tags',
      title: formatMessage({ id: 'global:tags' }),
      className: 'doc-ctrl-button children',
      emptyView: <EmptyTags style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    }
  ];

  if (item.will2JsonUrl) {
    const collaboration = getCollaborationMenuItem(item, props.actions, false, formatMessage);

    buttons.splice(1, 0, collaboration);
  }

  return buttons;
}

export default WebContextMenu;
