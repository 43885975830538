import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useGlobal from 'store';
import { useHistory } from 'react-router-dom';
import CanvasTypeMenu from './CanvasTypeMenu';
import PlusButton from './PlusButton';
import ChooseNameModal from './ChooseNameModal';
import {
    COLLABORATION_STREAM, WEB_STREAM, STREAMS_WITH_GROUPS, BAMBOO_STREAM, COLLABORATION_LOADING_PATH
} from 'constants/constants';
import { openEditMode } from 'common/components/utilities/queryHelper'

const EMPTY_VIEW = 'EMPTY_VIEW';
const CREATE_CANVAS_VIEW = 'CREATE_CANVAS_VIEW';
const CREATION_COLLABORATION_VIEW = 'CREATION_COLLABORATION_VIEW';
const CREATE_GROUP_VIEW = 'CREATE_GROUP_VIEW';
const CHOOSE_NAME_VIEW = 'CHOOSE_NAME_VIEW';

// find a better way
const createCanvasClass = 'create-canvas';
const createGroupClass = 'create-group';
const startCollaboration = 'create-collaboration';

const isAppNameWithGroupSupport = (applicationName) => STREAMS_WITH_GROUPS.has(applicationName);
const isPlusButtonVisible = (currentVew, queryParams) =>
    currentVew === EMPTY_VIEW
    && !queryParams.dirId
    && queryParams.applicationName !== BAMBOO_STREAM;

// TODO: Remove useGlobal
function BlankEditorFlow(props) {
    const [globalState, globalActions] = useGlobal();
    const [currentVew, setCurrentView] = useState(EMPTY_VIEW);
    const [newElementType, setNewElementType] = useState();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { queryParams } = props;

    const onItemClick = (ev) => {
        const targetElClasses = ev.currentTarget.className;
        if (currentVew === CREATE_CANVAS_VIEW) {
            setCurrentView(CHOOSE_NAME_VIEW);

            if (targetElClasses.includes(createCanvasClass)) {
                setNewElementType(createCanvasClass);
            } else if (targetElClasses.includes(createGroupClass)) {
                setNewElementType(createGroupClass)
            } else if (targetElClasses.includes(startCollaboration)) {
                setNewElementType(startCollaboration);
                setCurrentView(CHOOSE_NAME_VIEW);
            }
        } else {
            setCurrentView(CHOOSE_NAME_VIEW);
        }
    };

    const onSubmit = (name) => {
        if (newElementType === createGroupClass) {
            globalActions.home.createGroup(name, formatMessage({ id: 'toast:groupCreated' }, { groupName: name }));
            onOuterClick();
        } else if (newElementType === createCanvasClass) {
            const redirectToCanvasCallback = (document, pageId) => history.push(openEditMode(document, pageId))
            globalActions.inkEditor.createBlankDocument(name, null, null, null, redirectToCanvasCallback)
        } else if (newElementType === startCollaboration) {
            history.push(`/collaboration/${COLLABORATION_LOADING_PATH}`);
            globalActions.home.createCollaboration('collaboration', null, name, 1, null, false);
        }
    };

    const onButtonClick = () => {
        globalActions.home.showOverlay();
        setCurrentView(CREATE_CANVAS_VIEW);
    };

    const onOuterClick = () => {
        globalActions.home.hideOverlay();
        setCurrentView(EMPTY_VIEW);
    };

    const isStreamWithGroup = isAppNameWithGroupSupport(props.applicationName);
    const isCollaborationStream = props.applicationName === COLLABORATION_STREAM;
    const isWebStream = props.applicationName === WEB_STREAM;
    const isChooseNameView = currentVew === CREATE_CANVAS_VIEW
        || currentVew === CREATE_GROUP_VIEW
        || currentVew === CREATION_COLLABORATION_VIEW
        || currentVew === CHOOSE_NAME_VIEW;

    let viewToRender = <></>;

    if (isPlusButtonVisible(currentVew, queryParams)) {
        viewToRender = <PlusButton onClick={onButtonClick} />;
    } else if (currentVew === CREATE_CANVAS_VIEW) {
        let menuElements = []
        if (isStreamWithGroup) {
            menuElements.push({
                title: formatMessage({ id: 'context-menu:createNewGroup' }),
                data: 3,
                className: `tag-rename ${createGroupClass}`,
                callback: onItemClick
            })
        }
        if (isWebStream) {
            menuElements.push({
                title: formatMessage({ id: 'context-menu:createNewCanvas' }),
                data: 1,
                className: `tag-rename ${createCanvasClass}`,
                callback: onItemClick
            })
        }
        if (isCollaborationStream) {
            menuElements.push({
                title: formatMessage({ id: 'context-menu:startNewCollaboration' }),
                data: 2,
                className: startCollaboration,
                callback: onItemClick
            })
        }

        viewToRender = <CanvasTypeMenu onOuterClick={onOuterClick} menuElements={menuElements} />
    } else if (isChooseNameView) {
        viewToRender = <ChooseNameModal
            onSubmit={onSubmit}
            onDestroy={onOuterClick}
            isSpinnerVisible={globalState.isSpinnerVisible} />
    }

    return viewToRender
}

export default BlankEditorFlow;