import React, { useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './HighlighterTool.scss';

const highlighterToolId = INK_EDITOR_CONFIG.controls.highlighter;

function HighlighterTool() {
    const { toolId, setToolId } = useContext(CanvasContext);
    const isActive = highlighterToolId === toolId;

    return <div className={'highlight-tool ink-tool'}>
        <i
            className={isActive ? 'icon active' : 'icon icon-highlight '}
            // ref={node => this.$node = node} 
            onClick={() => setToolId(highlighterToolId)}
        />
    </div>
}

export default HighlighterTool;