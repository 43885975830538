// import EmptyTags from '../../components/tags/EmptyTags'

function BambooContextMenu(props) {
  const { formatMessage, actions, item } = props;

  return [
    {
      id: 'ctrl-button-duplicateAndEdit',
      title: formatMessage({ id: 'global:duplicateAndEdit' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doCopyDocument(item)
    },
    // {
    //   id: 'ctrl-button-startCollaboration',
    //   title: formatMessage({ id: 'context-menu:startCollaboration' }),
    //   className: 'doc-ctrl-button',
    //   callback: props.actions.doStartCollaboration
    // },
    {
      id: 'ctrl-button-export',
      title: formatMessage({ id: 'global:export' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowExportModal([item])
    },
    {
      id: 'ctrl-button-convertToText',
      title: formatMessage({ id: 'global:convertToText' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowInkToTextModal(item)
    },
    {
      id: 'ctrl-button-delete',
      title: formatMessage({ id: 'global:delete' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doDeleteDocument(item)
    }//,
    // {
    //   id: 'ctrl-button-tags',
    //   title: <FormattedMessage id={'global:tags'} />,
    //   className: 'doc-ctrl-button children',
    //   emptyView: <EmptyTags style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    // }
  ]
}

export default BambooContextMenu;