import React, { useRef } from 'react'
import Avatar from 'react-avatar';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import DropDown from 'common/components/menu/dropDown/DropDown';
import DropDownContent from 'common/components/menu/dropDown/DropDownContent';
import GenericList from 'common/components/menu/genericList/GenericList';
import GenericListItem from 'common/components/menu/genericList/GenericListItem';
import useDropdown from 'common/components/menu/dropDown/use-dropdown';
import { USER_ACCOUNT_LINK, LANGUAGES } from 'constants/constants';
import { getUserInfo } from 'utils/HttpUtils';

import { DATA_DOWNLOAD_PATH } from 'constants/constants';

import './ProfileComponent.scss'

function ProfileComponent(props) {
    const { firstName, lastName } = props
    const intl = useIntl();
    const { formatMessage } = intl;
    const history = useHistory();

    const triggerRef = useRef(null);
    const contentRef = useRef(null);

    const [isDropOpen, toggleDrop] = useDropdown(triggerRef, contentRef);
    const onClick = () => toggleDrop();

    const onAccountPage = () => {
        let currentLocaleFull = LANGUAGES[intl.locale].locale;

        const userProfile = getUserInfo();
        if (userProfile && userProfile.authCenter) {
            switch (userProfile.authCenter) {
                case 'cn1':
                    currentLocaleFull = 'zh-cn';
                    break;
                case 'ru1':
                    currentLocaleFull = 'ru-ru';
                    break;
                default:
                    break;
            }
        }

        const localeWithDash = currentLocaleFull.replace('_', '-');
        const localizedHref = USER_ACCOUNT_LINK.replace('{locale}', localeWithDash)
        window.open(localizedHref, '_blank')
    };

    const onDownloadMyData = () => {
        history.push(DATA_DOWNLOAD_PATH);
    };


    let elements = [{
        id: 'profile',
        title: formatMessage({ id: 'global:myAccount' }),
        action: onAccountPage,
        iconClass: 'user-profile-icon'
    },
    {
        id: 'data',
        title: formatMessage({ id: 'global:myData' }),
        action: onDownloadMyData,
        iconClass: 'user-data-download'
    },
    {
        id: 'logout',
        title: formatMessage({ id: 'global:logout' }),
        action: props.onLogout,
        iconClass: 'log-out-icon'
    }];

    const avatarSettings = {
        size: '40',
        name: `${firstName} ${lastName}`,
        color: "#CCCCCC",
        round: true
    };

    // const transitionOptions = {
    //     transitionname: 'fade-in',
    //     transitionenter: 'fade-in',
    //     transitionleave: 'fade-in',
    //     transitionappear: 'fade-in',
    //     timeout: 800
    // };

    return (<div className='profile-container'>
        <DropDown>
            <div ref={triggerRef}
                className={'dropdown-trigger'}
                // onClick={() => setShowDropdown(s => !s)}>
                onClick={onClick}>
                <div className={'profile-box'}>
                    <Avatar {...avatarSettings} />
                    <div className={'drop-down-icon-wrapper'} >
                        <div className={'drop-down-icon'} />
                    </div>
                </div>
            </div>
            <div ref={contentRef}>
                <DropDownContent
                    show={isDropOpen}
                    transitionsEffects={{ transitionName: 'slide-in-top' }}
                    className='dropdown-content bottom'
                    position='bottom'>
                    <div>
                        <div className='profile-dropdown-head'>
                            <div className={'profile-user-details'}>
                                <div className={'profile-box'}>
                                    <Avatar {...avatarSettings} />
                                </div>
                                <div className={'full-name-wrap'}>
                                    <span className={'full-name-content'}>
                                        {`${firstName} ${lastName}`}
                                    </span>
                                </div>
                            </div>
                            <GenericList>
                                {
                                    elements.map((element, i) => (
                                        <GenericListItem
                                            key={i}
                                            onClick={element.action}>
                                            <div>
                                                <div className={'profile-dropdown-icon ' + element.iconClass}>
                                                </div>
                                                <div className={'profile-dropdown-text'}>
                                                    {element.title}
                                                </div>
                                            </div>
                                        </GenericListItem>
                                    ))
                                }
                            </GenericList>
                        </div>
                    </div>
                </DropDownContent>
            </div>
        </DropDown>
    </div>)
}

export default ProfileComponent;