import React, { useRef, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  TEMPLATE_WIDTH, TEMPLATE_HEIGHT,
} from 'constants/constants';
// import PlaceholderImage from './../../home/documents/assets/empty-doc.svg';
import PlaceholderImage from './../../../routes/home/documents/assets/empty-doc.svg';
import './LazyImage.scss';


function getBackgroundImageStyle(image) {
  const scaleW = image.width / image.naturalWidth;
  const scaleH = image.height / image.naturalHeight;

  let x = (TEMPLATE_WIDTH - image.naturalWidth) / 2;
  let y = (TEMPLATE_HEIGHT - image.naturalHeight) / 2;

  x = -x * scaleW;
  y = -y * scaleH;

  const width = TEMPLATE_WIDTH * scaleW;
  const height = TEMPLATE_HEIGHT * scaleH;

  return { x, y, width, height }
}

function getDims(width, height, isGroup) {
  if (!width || !height) {
    return {
      width: 'inherit',
      height: 'inherit'
    };
  }

  const offsets = isGroup ? 4 : 0;

  const max = Math.max(width, height);
  return {
    width: `calc(${width / max * 96}% - ${offsets}px)`,
    height: `calc(${height / max * 96}% - ${offsets}px)`
  }
}

// TODO: Fix image layout :(
function LazyImage(props) {
  const { setHeightOnLoaded, isGroup, isEmptyDoc, style } = props;
  const width = props.width ?? 594;
  const height = props.height ?? 841;
  const src = props.src ?? PlaceholderImage;
  const imageStyleProps = style ?? {};
  const parentRef = useRef();
  const isMounted = useRef(null);
  const [imageStyle, setImageStyle] = useState(imageStyleProps);
  const [wrapperStyle, setWrapperStyle] = useState(getDims(width, height));

  useEffect(() => {
    const dims = getDims(width, height, isGroup);
    setWrapperStyle(dims);
  },
    [width, height, isGroup]);


  // Used to fix Firefox and Safari layout issue with flex layout
  // TODO: Find ad better way to position images
  const onImageLoaded = () => {
    if (!setHeightOnLoaded || !isMounted.current) return;

    const image = parentRef?.current?.querySelectorAll('IMG')[0];
    if (image) {
      // Sets the scale of the background image to the scale of the main image
      const { backgroundImage } = imageStyle;
      if (backgroundImage) {
        const { x, y, width, height } = getBackgroundImageStyle(image);

        setImageStyle(style => {
          return {
            ...style,
            backgroundPosition: `${x}px ${y}px`,
            backgroundSize: `${width}px ${height}px`
          }
        });
      }
    }
  }
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  useEffect(() => {
    setImageStyle(style => {
      return {
        ...style,
        ...props.style
      }
    })
  }, [props.style]);

  const imageFinalProps = {
    ...imageStyle,
  };

  if (isGroup) {
    imageFinalProps.height = '100%';
    imageFinalProps.width = '100%';
  } else {
    imageFinalProps.maxHeight = '100%';
    imageFinalProps.maxWidth = '100%';
  }

  let imageComponent = <LazyLoadImage
    style={imageFinalProps}
    visibleByDefault={props.visibleByDefault}
    threshold={props.threshold}
    src={src}
    alt={'canvas-representation'}
    effect={props.effect}
    placeholderSrc={props.placeholderSrc}
    afterLoad={onImageLoaded}
  />

  if (isGroup) {
    let offsetClasses = 'offset';
    let offsetBallanceClasses = 'offset-balance';
    if (!src) {
      offsetClasses += ' offset-empty';
      offsetBallanceClasses += ' offset-balance-empty';
    } else {
      if (isEmptyDoc) {
        imageComponent = <span className={'empty-doc'}>
          {imageComponent}
        </span>
      }
    }

    imageComponent = <span className={offsetBallanceClasses}>
      <span className={offsetClasses}>
        <span className={offsetClasses}>
          <span className={offsetClasses}>
            {imageComponent}
          </span>
        </span>
      </span>
    </span>
  }

  return <div ref={parentRef} className={'lazy-image-ref-wrapper'} style={wrapperStyle}>
    {imageComponent}
  </div>

}

export default LazyImage;
