import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ReactSpinner from 'react-spinjs-fix';
import { Scrollbars } from 'react-custom-scrollbars';
import TagListItem from './TagListItem';
import EmptyTags from './EmptyTags';

import './TagList.scss';

function TagList(props) {
    const { formatMessage } = useIntl();
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
    const wrapper = useRef(null);

    const isTagListEmpty = props.tags.length === 0;

    const newTagButton = <div className={isTagListEmpty ? 'wrapper-no-tags' : null}>
        {isTagListEmpty ? <EmptyTags /> : null}
        <button onClick={props.actions.openCreateNewTagModal} className={isTagListEmpty ? 'new-tag empty' : 'new-tag'}>
            {formatMessage({ id: 'tagContainer:createTag' })}
        </button>
    </div>

    let wrapperClasses = 'tags-wrapper';
    if (isSubMenuVisible) {
        wrapperClasses += ' show-tags';
    }

    let height = 0;
    let contentWrapperStyle = {};
    let listStyles = {};

    if (wrapper.current !== null) {
        const bHeight = 196;
        const wrapperBoundingBox = wrapper.current.getBoundingClientRect();
        height = (window.innerHeight - wrapperBoundingBox.top) - bHeight;

        listStyles.maxHeight = (height / 10) + 'rem';

        if (height < bHeight) {
            // Visualizes the element as starting from the bottom of the parent element
            contentWrapperStyle.top = wrapperBoundingBox.height + 'px';
            contentWrapperStyle.transform = 'translate(0, -100%)';
            listStyles.maxHeight = wrapperBoundingBox.y;
        }
    }

    useEffect(() => {
        const handelClick = (ev) => {
            if (wrapper.current && !wrapper.current.contains(ev.target)) {
                setIsSubMenuVisible(false);
            }
        }

        document.addEventListener('click', handelClick);
        return () => {
            document.removeEventListener('click', handelClick);
        }
    }, [setIsSubMenuVisible, wrapper]);

    useEffect(() => {
        if (props.shouldClose) {
            setIsSubMenuVisible(false)
        }
    }, [props.shouldClose]);

    return <div ref={wrapper} className={wrapperClasses}>
        <span>
            <div className={'tags-header'} onClick={() => setIsSubMenuVisible(!isSubMenuVisible)}>
                <span className={'icon icon-tags'} />
                <span className={'tags-title'} >
                    {formatMessage({ id: 'global:tags' })}
                </span>
            </div>
        </span>
        {isSubMenuVisible &&
            <span>
                <div className={'tags-content display-tags'}
                    style={contentWrapperStyle}>
                    {props.isFetching ? <ReactSpinner /> :
                        <Scrollbars autoHeight autoHide>
                            <ul id='tag-list' style={listStyles}>
                                {props.tags.map(item =>
                                    <TagListItem
                                        item={item}
                                        key={'tag-' + item.id}
                                        removeTag={() => props.actions.doShowDeleteTagModal(item)}
                                        renameTag={() => props.actions.doShowRenameTagModal(item)}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            setIsSubMenuVisible(false);
                                            props.actions.doAddTag(item);
                                        }}
                                    />)
                                }
                            </ul>
                        </Scrollbars>}
                    {!props.isFetching && newTagButton}
                </div>
            </span>
        }
    </div>
}

export default TagList;