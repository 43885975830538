import { v4 as uuidv4 } from 'uuid';
import {
    EXPORT_BASE_URL, WIKI_API_URI,
    STORAGE_API_BASE_URL
} from 'constants/constants';
import { isUrlActive } from 'common/components/utilities/queryHelper';
import { createToast } from './home'

let fetchOpcUrlContext = null;

export const fetchSemantics = async (store, docId, opcUrl, locale, errorMessage) => {
    abortFetchDocumentUrl();
    fetchOpcUrlContext = new AbortController();
    const { signal } = fetchOpcUrlContext;

    store.setState({ semantic: { ...store.state.semantic, isFetching: true } });

    try {
        const shouldFetchUrl = !isUrlActive(opcUrl);

        if (shouldFetchUrl) {
            const opcInfoJson = await store.actions.semantic.fetchOpcUrl(docId, { signal });
            opcUrl = opcInfoJson.temporaryOPCLink;
        }

        await fetchSemanticData(store, opcUrl, docId, locale, signal, errorMessage);
    } catch (err) {
        store.setState({ semantic: { ...store.state.semantic, isFetching: false } });
    }
}

export const abortFetchDocumentUrl = () => {
    if (fetchOpcUrlContext) {
        fetchOpcUrlContext.abort();
        fetchOpcUrlContext = null;
    }
}

const fetchWikipediaData = async (titlesList, locale, abortController) => {
    if (titlesList) {
        try {
            const titles = titlesList.join('|');
            const baseAddressWithLocale = WIKI_API_URI.replace('{0}', locale);
            const requestUrl = baseAddressWithLocale
                + '?action=query&explaintext=1&format=json'
                + '&prop=pageimages|extracts&piprop=original|thumbnail&pithumbsize=400&exintro=1'
                + `&titles=${titles}`
                + '&origin=*'; //`${window.location.origin}`;

            const response = await fetch(requestUrl, {
                // mode: 'no-cors',
                headers: {
                    'Origin': window.location.origin,
                    "Content-Type": "application/json; charset=UTF-8"
                }
            });

            const json = await response.json();
            const elements = Object.values(json.query.pages);
            const orderedElements = [];

            titlesList.forEach(title => {
                const index = elements.findIndex(x => x.title === title);
                orderedElements.push(elements[index]);
            });

            return orderedElements;
        } catch {

        }
    }

    return null;
}

export const fetchOpcUrl = async (store, documentId, options = {}) => {
    let address = `${STORAGE_API_BASE_URL}/document/${documentId}/opc`;

    const getDocFetch = await fetch(address, {
        method: 'GET',
        headers: {
            ...options,
            'Authorization': `Bearer ${store.state.auth.mainToken}`
        }
    });

    return await getDocFetch.json();
}


const fetchSemanticData = async (store, opcUrl, docId, locale, signal, errorMessage) => {
    if (!opcUrl) return;

    store.setState({ semantic: { ...store.state.semantic, data: null, isFetching: true } });

    try {
        let shortLocale = locale.split('_')[0];

        // Chinese users have different privacy policy
        // If the user was registered in China then we grant them access to NER
        // provided by Baidu
        if (shortLocale === 'zn' && store.state.auth?.profile?.country !== 'cn') {
            shortLocale = 'en';
            locale = 'en_US';
        }

        const semanticData = await fetch(`${EXPORT_BASE_URL}/ner/by-url/wacom-doc?locale=${locale}`, {//?locale=${fullLocale}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([opcUrl]),
            signal
        });

        if (signal.aborted) return;

        const semanticJson = await semanticData.json();
        const currentDocument = semanticJson.find(el => el.documentId === docId);

        if (semanticJson.length === 0
            || !currentDocument
            || currentDocument.inkModels.length === 0
            || (currentDocument.inkModels.length > 0 && currentDocument.inkModels[0].semanticEntities.length === 0)
        ) {
            createToast(store, errorMessage);
            store.setState({ semantic: { ...store.state.semantic, isFetching: false } });
            return;
        }

        const semanticEntities = currentDocument.inkModels[0]
            .semanticEntities
            // .filter(el => el.abstract)
            .map(el => { return { ...el, id: uuidv4() } });

        store.setState({ semantic: { ...store.state.semantic, data: semanticEntities, opcUrl, isFetching: false } });

        // Fetch Wikipedia Data
        if (shortLocale !== 'zh') {
            const labels = semanticEntities.map(entity => entity.label);
            const wikiEntities = await fetchWikipediaData(labels, shortLocale, null);

            if (wikiEntities) {
                semanticEntities.forEach((element, index) => {
                    element.abstract = wikiEntities[index]?.extract;
                    element.thumbnail = wikiEntities[index]?.thumbnail?.source;
                });
            }

            store.setState({ semantic: { ...store.state.semantic, data: semanticEntities, opcUrl, isFetching: false } });
        }
    } catch (err) {
        store.setState({ semantic: { ...store.state.semantic, isFetching: false } });
        createToast(store, errorMessage);
    }
}