import { useEffect, useRef, useState } from 'react';
import { SOCKET_SYNC_URL } from 'constants/constants';

function useSyncService(callback, pollingDelay, longDelay, token, appName) {
    const savedCallback = useRef();
    const webSocket = useRef();
    const [pollingInterval, setPollingInterval] = useState(longDelay);
    const applicationName = useRef(null);

    useEffect(() => {
        let timeoutHandle = null;
        const clearLocalTimeout = () => { if (timeoutHandle) clearTimeout(timeoutHandle); };
        try {
            const ws = new WebSocket(SOCKET_SYNC_URL);

            ws.onopen = () => {
                setPollingInterval(longDelay);
                ws.send(`Authentication: Bearer ${token}`);
            };

            ws.onclose = () => {
                setPollingInterval(pollingDelay);
            }

            ws.onmessage = (message) => {
                try {
                    const parsedMessage = JSON.parse(message.data);

                    if (applicationName.current && parsedMessage.applicationName === applicationName.current) {
                        // Used to throttle multiple update requests
                        clearLocalTimeout();
                        timeoutHandle = setTimeout(() => {
                            savedCallback?.current();
                        }, 2000);
                    }
                } catch (err) {
                    console.log(err);
                }
            }

            webSocket.current = ws;
        } catch (err) {
            console.log(err);
            setPollingInterval(pollingDelay);
            clearLocalTimeout();
        }

        return () => {
            webSocket.current?.close();
        }
    }, [token, pollingDelay, longDelay]);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        applicationName.current = appName;
    }, [appName]);

    useEffect(() => {
        const handle = setInterval(savedCallback.current, pollingInterval);

        return () => {
            clearInterval(handle);
        };
    }, [callback, pollingInterval]);
}

export default useSyncService;