import React from 'react';
import ReactSpinner from 'react-spinjs-fix';
import LanguageDropDown from 'common/components/menu/languageDropDown/LanguageDropDown';

import { SPINNER_SETTINGS } from 'constants/constants';
import './InkToTextConverter.scss';


function InkToTextConverter(props) {
    const { changeLanguage, label, activeLabel, text, selectedLanguage, showSpinner, source, rtl } = props;

    return <div className='convert-text-container'>
        <div>
            <textarea dir={rtl ? "rtl" : "ltr"} value={text} readOnly />
        </div>
        <div className='convert-text-language-dropdown'>
            <LanguageDropDown
                languages={source}
                changeLanguage={changeLanguage}
                label={label}
                activeLabel={activeLabel}
                selectedLanguage={selectedLanguage}
                dropdownContentPosition='top-left-modal'
            />
            {showSpinner && <ReactSpinner config={SPINNER_SETTINGS} />}
        </div>
    </div>
}

export default InkToTextConverter;