const messages = {
  'documents:empty:noresult:noresult' : 'no result',
  'documents:empty:noresult:noresultfound' : 'No results found matching your criteria.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:montblanc' : 'Montblanc Stream',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Once your device is connected with the Ink Space App and you sign up with your Wacom ID, you will see all of your content here.',
  'documents:empty:stream:explanation1:webstream' : 'Give Ink Collaboration a try.  Share and collaborate on others with your creations. Try it today',
  'documents:empty:stream:explanation1:montblanc' : 'Welcome to the new Montblanc Hub app 2.0 where we present you a new face and exciting new features. Enjoy!',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Once you connect Bamboo Paper with your Wacom ID you will see all of your content here.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Take notes. Do more. Just like on paper, but better.',
  'documents:empty:stream:tryitnow' : 'Try it now',
  'documents:empty:stream:learnmore' : 'learn more',
  'documents:empty:group' : 'Your group is empty',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Collaboration Stream',

  'filter:result' : 'result for',
  'filter:search' : 'search...',

  'footer:navigation:privacy' : 'Privacy',
  'footer:navigation:termOfUse' : 'Terms of use',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie Preferences',

  'global:convertTo' : 'Convert to {language}',
  'global:convertToText' : 'Convert to text',

  'context-menu:createNewCanvas' : 'Create New Canvas',
  'context-menu:createNewGroup' : 'Create Group',
  'context-menu:startNewCollaboration' : 'Start New Collaboration',
  'context-menu:startCollaboration' : 'Start Collaboration',
  'context-menu:edit' : 'Edit',

  'global:delete' : 'Delete',
  'global:rename' : 'Rename',
  'global:export' : 'Export',
  'global:tags' : 'Tags',
  'global:groups': 'Groups',
  'global:shareLink' : 'Share Link',
  'global:copy' : 'copy',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Duplicate & Edit',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Save',
  'global:dontsave' : 'Don\'t save',
  'global:proceed' : 'Proceed',
  'global:enterName' : 'Enter name',
  'global:browserUnsupported':'Browser not supported',

  'global:language' : 'Language',
  'global:language:english' : 'English',
  'global:language:english:GB' : 'English (GB)',
  'global:language:english:US' : 'English (US)',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Logout',
  'global:login' : 'Login',
  'global:myAccount' : 'My Account',

  'inputField:existsName' : 'Error! The name already exists!',
  'inputField:incorrectName' : 'Error! The name is incorrect!',
  'inputField:notInRange' : 'Error! The name must be in range of ( {minLength} - {maxLength} ) characters!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:ff:text': 'Please use Chrome or Firefox to connect to this Collaboration.',
  'modalbox:browser:unsupport:sf:text': 'Please use Chrome or Safari to connect to this Collaboration.',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Error!',
  'modalbox:error:buttonTitle' : 'Close',
  'modalbox:exportError:message' : 'Export action is not available at the moment!',
  'modalbox:createTag:buttons:title' : 'Create',
  'modalbox:createTag:title' : 'Create new tag',
  'modalbox:deleteTag:message' : 'You are about to delete <span>"{tag}"</span>. This action is irreversible.',
  'modalbox:removeDocument:message' : 'You are about to delete <span>"{docTitle}"</span>. This action is irreversible.',
  'modalbox:shareLink:buttons:stopSharing' : 'stop sharing',
  'modalbox:sessionFull:title' : 'Collaboration full',
  'modalbox:sessionFull:text' : 'Unfortunately the collaboration session you are trying to access is full',
  'modalbox:leaveSession:title' : 'Leave session',
  'modalbox:leaveSession:buttons:leave' : 'Leave',
  'modalbox:leaveSession:text' : 'Do you want to leave the collaboration session? ',
  'modalbox:endSession:title' : 'End session',
  'modalbox:endSession:buttons:end' : 'End',
  'modalbox:endSession:text' : 'Do you want to end the current collaboration session? Current progress will be automatically saved in your Web Stream.',
  'modalbox:sessionEnded:title' : 'Session Ended',
  'modalbox:sessionEnded:text' : 'Do you want to save copy of the collaboration session in your Web Stream?',
  'modalbox:accessDenied:title' : 'Access Denied',
  'modalbox:accessDenied:buttons:goBack' : 'go back',
  'modalbox:accessDenied:text' : 'Unfortunately, the collaboration session you are trying to access has expired.',
  'modalbox:collaborationStarted:title' : 'Collaboration started',
  'modalbox:collaborationStarted:warn' : 'Switching this browser tab or minimising the browser will end your Ink Collaboration.',
  'modalbox:collaborationStarted:text' : 'Your collaboration session started. You could invite up to 10 participants by giving them the collaboration URL from your browser.',
  'modalbox:collaborationError:title' : 'Collaboration error!',
  'modalbox:collaborationError:buttons:close' : 'close',
  'modalbox:collaborationError:text' : 'The browser you are using is not supported by Ink Collaboration.  This feature works best with Firefox /v55+/, Chrome /v60+/ and Safari /v11+/.',
  'modalbox:WebGLError:title' : 'Error Occurred',
  'modalbox:WebGLError:text' : 'An error occurred an you need to reload the content using the "Reload" button in message above.',
  'modalbox:collaborationJoinFailed:title': 'Connection couldn\'t be established',
  'modalbox:collaborationJoinFailed:text': 'The session you are trying to access couldn\'t be accessed. Please try again later.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Don\'t show',
  'modalbox:duplicateAndEdit:text' : 'A new extended version of the page will be placed in your Ink Space Web Stream without changing the original',
  'modalbox:saveChanges:title' : 'Save changes',
  'modalbox:saveChanges:text' : 'Do you want to save changes to the document before closing?',
  'modalbox:connectionLost:title': 'Connection Lost',
  'modalbox:connectionLost:text': 'The connection was lost, please check your internet connection and try again.',
  'modalbox:phoneDetection:title': 'use mobile app',
  'modalbox:phoneDetection:btn:open': 'open',
  'modalbox:phoneDetection:btn:no-thanks': 'no thanks',
  'modalbox:phoneDetection:msg': 'We recommend to use our dedicated mobile app on your phone to enjoy a better experience.',
  'modalbox:chooseVectorOrRaster:title' : 'Choose edit mode',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Annotate',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vectorize',
  'modalbox:chooseVectorOrRaster:text' : 'The content you are trying to edit is raster based and in order to keep the original appearance we recommend to annotate over it. Additionally you could also choose to convert it to vector but the result might differ from the original depending on the tools used.',
  // Groups Strings
  'modalbox:deleteGroup:title' : 'Delete Group',
  'modalbox:deleteGroup:title:multiple' : 'Delete Groups',
  'modalbox:deleteGroup:button:removeGroup' : 'Remove Group',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Remove Groups',
  'modalbox:deleteGroup:message' : 'Do you want to delete the group <span>"{titles}"</span> only or its content as well? This action is irreversible.',
  'modalbox:deleteGroup:message:multiple' : 'Do you want to delete the groups <span>"{titles}"</span> only or its content as well? This action is irreversible.',
  'modalbox:deleteGroup:button:removeAll' : 'Delete all',
  'modalbox:deleteGroup:button:cancel' : 'Cancel',
  'modalbox:deletePage:title' : 'Delete Page',
  'modalbox:deletePage:message' : 'Do you want to delete <span>"{titles}"</span> or remove it from this group?',
  'modalbox:deletePage:removePage' : 'Remove from group',
  'modalbox:group:rename' : 'Rename Group',
  'mailbox:exportLimit:title' : 'Limit reached',
  'mailbox:exportLimit:message' : 'The maximum amount of {maxExportPages} notes have been exceeded. Please, reduce the amount of the selected notes and try again.',

  'toast:groupAddedSuccess:single': 'Page added successfully to "{groupName}"',
  'toast:groupAddedSuccess:plural': 'Pages added successfully to "{groupName}"',
  'toast:pagesRemovedFromGroup:single': 'Page removed successfully from "{groupName}"',
  'toast:pagesRemovedFromGroup:plural': 'Pages removed successfully from "{groupName}"',
  'toast:groupCreated': '"{groupName}" was created successfully',
  'toast:groupRemoved': '"{groupName}" was removed successfully',
  'toast:groupRemoved:andPages': '"{groupName}" and pages were removed successfully',
  'toast:groupRemoved:plural': 'Selected groups were removed successfully',
  'toast:noteRemoved:single': 'Page deleted successfully',
  'toast:noteRemoved:plural': 'Pages deleted successfully',

  'sorter:ascending' : 'Ascending',
  'sorter:date' : 'Date',
  'sorter:descending' : 'Descending',
  'sorter:name' : 'Name',
  'sorter:size' : 'Size',
  'sorter:sortby' : 'sort by',
  'sorter:type' : 'Type',

  'tagContainer:createTag' : 'Create tag',
  'tagContainer:noTags' : 'No tags',
  'tagContainer:noTagsMessage' : 'Once you create tags they will appear here',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage' : 'Once you create groups they will appear here',

  'export:asDoc' : 'as DOC',
  'export:asPsd' : 'as PSD',
  'export:asJpg' : 'as JPG',
  'export:asPdf' : 'as PDF',
  'export:asPng' : 'as PNG',
  'export:asSvg' : 'as SVG',
  'export:asWill' : 'as WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'as PowerPoint',

  // Ink to Video
  'video:fileReady' : 'Your file is ready',
  'video:download'  : 'Open',
  'video:expirationMessage' : 'The download will be automatically deleted after <span>{days}</span>days',
  'video:generating' : 'Generating your video',
  'video:timeNotice' : 'This might take a while',
  'video:error' : 'Video Generation Failed',
  'video:estimatedTime.many': 'This might take up to <span>{minutes}</span>minutes',
  'video:estimatedTime.one': 'This might take up to a minute',

  // Session Expired Prompt
  'session:expired:title': 'Session Expired',
  'session:expired:message': 'Your Session Has Expired. Please Login Again.',
  'session:expired:button': 'Login',

  // Auth module
  'auth:email:label': 'Email',
  'auth:email:taken': 'The email address is not available',
  'auth:email:notRegisterd': 'The email address is not registered',
  'auth:email:invalid': 'Invalid email',
  'auth:password:label': 'Password',
  'auth:password:reenter:label': 'Re-enter Password',
  'auth:password:mismatch:label': 'Passwords do not match',
  'auth:password:tooEasy:label': 'Password must contain at least {charLength} characters and 3 of the following: An uppercase letter, a lowercase letter, a number, a special symbol.',
  'auth:firstName:label': 'First name',
  'auth:lastName:label': 'Last name',
  'auth:country:label': 'Country',
  'auth:country:select': 'Select Country',
  'auth:language:label': 'Language',
  'auth:language:select': 'Select Language',
  'auth:termsAndConditions:label': 'You have read and agreed to Wacom\'s <a href="{termsHref}">Terms of use</a>.',
  'auth:privacyPolicy:label': 'You have read and agreed to Wacom\'s <a href="{privacyHref}">Privacy Policy</a> and <a href="{cookieHref}">Cookie Policy</a>.',
  'auth:login:invalid': 'Invalid login or password',
  'auth:login:title': 'Sign in to Wacom ID',
  'auth:login:allWacom:text': 'Your account to all things Wacom.',
  'auth:login:forgotPassword:link': ' Forgot password',
  'auth:login:button': 'Login',
  'auth:login:signup:button': 'Sign up',
  'auth:login:createNewAccount:label': 'Don’t have an account?',
  'auth:login:alreadyHaveAccount:label': 'Already have an account?',
  'auth:passwordReset:title': 'Password reset',
  'auth:passwordReset:text': 'Enter your email address that you used for Wacom ID. We´ll send you a link to reset your password.',
  'auth:passwordReset:reset:button': 'Reset',
  'auth:passwordReset:signIn:button': 'Sign in',
  'auth:passwordReset:back:button': 'Back to',
  'auth:passwordReset:success:text': 'An email was sent to your email address.',
  'auth:register:title': 'Create your profile',
  'auth:register:success:title1': 'Congrats!',
  'auth:register:success:title2': 'You have finished your registration',
  'auth:register:success:message': 'To continue, please check your email to confirm your sign up, then sign in to access your dashboard.',
  'auth:errors:generic': 'Something went wrong. Please try again.',
  'auth:policyUpdate:title': 'Our Terms of Use & Privacy Policy have changed since your last sign in',

  // Welcome screens
  'welcome:screen1:header': 'Ink Collaboration features',
  'welcome:screen1:text': 'With Ink Collaboration you can work collaboratively with anyone, anywhere. Start a canvas from your content or a new canvas and start inking with others.',
  'welcome:screen2:header': 'Sync and share of content',
  'welcome:screen2:text': 'With Wacom ID and your cloud connected apps, your content is available Online any time, anywhere.  Export into other formats to continue work in your favorite software tools and apps.',
  'welcome:screen3:header': 'Access all your content',
  'welcome:screen3:text': 'Your Wacom ID unlocks many new features and workflows for your content.  Access anywhere, share with others or keep your content backed up online for security.  Wacom ID is your key to the cloud.',
  'welcome:button:next': 'Next',
  'welcome:button:done': 'Done',

  // Migration screen
  'migration:screen1:text1': 'Your content is being optimized. Please wait.',
  'migration:screen1:text2': 'Processing...',

  // What is new screen
  'whatIsNew:cloudMigration:title': ' Service Update',
  'whatIsNew:cloudMigration:mainText': 'We are making improvements to our Cloud Services. There may be a short delay in seeing or using your content. Thank you for your patience.',
  'whatIsNew:cloudMigration:cloudTeam': '- Wacom Cloud Team',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Abstract',
  'semantic:sidebar:confidence': 'confidence',
  'semantic:sidebar:reference': 'Reference',

  // Semantic-ink icon description
  'semantic:type:organization': 'organization',
  'semantic:type:topic': 'topic',
  'semantic:type:person': 'person',
  'semantic:type:location': 'location',
  'semantic:type:event': 'event',
  'semantic:error:header': 'No Semantic Ink Recognition',
  'semantic:error:text': 'Your note doesn’t need any Semantic Ink enhancement.',

  // Download my data
  'dataDownload:profile:option': 'My data',
  'dataDownload:tabs:downloadData': 'Download data',
  'dataDownload:tabs:availableData': 'Available data',
  'dataDownload:tabs:downloadData:description': 'Export and download a copy of your Ink Space account content.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Please select...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Select all',
  'dataDownload:processing:description': 'It may take a while for your files to download. When your download is ready, you will get an email with a download link or you can download from here.',
  'dataDownload:processing': 'Processing...',
  'dataDownload:request:button': 'Download data',
  'dataDownload:tabs:availableData:description': 'Download your data',
  'dataDownload:tabs:availableData:archiveFor': 'Archive for ',
  'global:myData': 'My Data'
}

export default messages;