const messages = {
  'documents:empty:noresult:noresult' : '无结果',
  'documents:empty:noresult:noresultfound' : '未找到符合您的标准的结果。',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : '您的设备一旦与Ink Space App连接并使用Wacom ID登录，您将在此看到您的所有内容。',
  'documents:empty:stream:explanation1:webstream' : '试用Ink Collaboration。利用您的创作分享和协作其他内容。今天试用',
  'documents:empty:stream:explanation1:bamboopaperstream' : '一旦使用您的Wacom用户身份连接Bamboo Paper，您将在此看到所有您的内容。',
  'documents:empty:stream:explanation1:wacom_notes' : '记笔记。完成更多工作。就像在纸上一样，但会更好。',
  'documents:empty:stream:tryitnow' : '现在试用',
  'documents:empty:stream:learnmore' : '了解更多',
  'documents:empty:group' : '您的组是空的',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': '协作流',

  'filter:result' : '符合结果',
  'filter:search' : '搜索...',

  'footer:navigation:privacy' : '隐私',
  'footer:navigation:termOfUse' : '使用条款',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie首选项',

  'global:convertTo' : '转换至 {language}',
  'global:convertToText' : '转换至文本',

  'context-menu:createNewCanvas' : '创建新的画布',
  'context-menu:createNewGroup' : '创建组',
  'context-menu:startNewCollaboration' : '启动新的协作',
  'context-menu:startCollaboration' : '启动协作',
  'context-menu:edit' : '编辑',

  'global:delete' : '删除',
  'global:rename' : '重命名',
  'global:export' : '导出',
  'global:tags' : '标签',
  'global:groups': '组',
  'global:shareLink' : '分享链接',
  'global:copy' : '复制',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : '复制和编辑',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : '保存',
  'global:dontsave' : '不保存',
  'global:proceed' : '继续',
  'global:enterName' : '输入名称',
  'global:browserUnsupported' : '不支持的浏览器',

  'global:language' : '语言',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : '注销',
  'global:login' : '登录',
  'global:myAccount' : '我的帐户',

  'inputField:existsName' : '错误!此名已存在!',
  'inputField:incorrectName' : '错误!名称不正确!',
  'inputField:notInRange' : '错误!名称的字符数必须在 ( {minLength} - {maxLength} ) 范围内!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': '请使用Chrome或Firefox浏览器连接到此Collaboration。谢谢',
  'modalbox:browser:unsupport:sf:text': '请使用Chrome或Safari浏览器连接到此Collaboration。谢谢',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : '错误!',
  'modalbox:error:buttonTitle' : '关闭',
  'modalbox:exportError:message' : '导出动作现在不可用',
  'modalbox:createTag:buttons:title' : '创建',
  'modalbox:createTag:title' : '创建新标签',
  'modalbox:deleteTag:message' : '您将删除 <span>"{tag}"</span>, 本动作不可逆。',
  'modalbox:removeDocument:message' : '您将删除 <span>"{docTitle}"</span>, 本动作不可逆。',
  'modalbox:shareLink:buttons:stopSharing' : '停止分享',
  'modalbox:sessionFull:title' : '协作已满',
  'modalbox:sessionFull:text' : '很遗憾，您尝试访问的协作会话已满。',
  'modalbox:leaveSession:title' : '离开会话',
  'modalbox:leaveSession:buttons:leave' : '离开',
  'modalbox:leaveSession:text' : '是否要离开协作会话？',
  'modalbox:endSession:title' : '结束会话',
  'modalbox:endSession:buttons:end' : '结束',
  'modalbox:endSession:text' : '是否要结束当前的协作会话？当前的进度将自动存储在您的 Web Stream 中。',
  'modalbox:sessionEnded:title' : '会话结束',
  'modalbox:sessionEnded:text' : '是否要将协作会话副本存储在您的 Web Stream 中？',
  'modalbox:accessDenied:title' : '访问被拒绝',
  'modalbox:accessDenied:buttons:goBack' : '返回',
  'modalbox:accessDenied:text' : '很遗憾，您尝试访问的协作会话已到期。',
  'modalbox:collaborationStarted:title' : '协作已启动',
  'modalbox:collaborationStarted:warn' : '您必须保持Ink Collaboration为打开状态。如果您关闭此选项卡，或切换为不同选项卡，Ink Collaboration将会结束。',
  'modalbox:collaborationStarted:text' : ' 您的协作会话已启动。您可以邀请多达 10 名参加者，提供他们浏览器的协作 URL。',
  'modalbox:duplicateAndEdit:buttons:dontshow' : '不显示',
  'modalbox:duplicateAndEdit:text' : '新的页面延长版将放置在您的 Ink Space Web Stream 中，不需更改原始状态',
  'modalbox:saveChanges:title' : '保存更改',
  'modalbox:saveChanges:text' : '是否要在关闭之前保存对文档的更改？',
  'modalbox:collaborationError:title' : '协作错误！',
  'modalbox:collaborationError:buttons:close' : '关闭',
  'modalbox:collaborationError:text' : 'Ink Collaboration不支持您所使用的浏览器。此功能最适用于Firefox /v55+/、Chrome /v60+/和Safari /v11+/',
  'modalbox:WebGLError:title' : '发生错误',
  'modalbox:WebGLError:text' : '发生错误，您需要使用以上消息中的"重载"按钮重载内容。',
  'modalbox:collaborationJoinFailed:title': '连接无法建立',
  'modalbox:collaborationJoinFailed:text': '您尝试访问的会话无法访问。请稍后再试一次。',
  'modalbox:connectionLost:title': '连接丢失',
  'modalbox:connectionLost:text': '连接已丢失，请检查您的Internet连接，并再试一次。',
  'modalbox:phoneDetection:title': '使用移动应用程序',
  'modalbox:phoneDetection:btn:open': '打开',
  'modalbox:phoneDetection:btn:no-thanks': '不，谢谢',
  'modalbox:phoneDetection:msg': '我们建议使用在您的手机上使用我们专用的移动应用程序，以享受更佳的体验。',
  'modalbox:chooseVectorOrRaster:title' : '选择编辑模式',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : '批注',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : '向量化',
  'modalbox:chooseVectorOrRaster:text' : '您尝试编辑的内容为光栅式，为保留原有的外观，我们建议加上批注。此外，您也可以选择将其转换为向量，但是结果可能依所使用的工具而异。',

  // Groups Strings
  'modalbox:deleteGroup:title' : '删除组',
  'modalbox:deleteGroup:title:multiple' : '删除组',
  'modalbox:deleteGroup:button:removeGroup' : '删除组',
  'modalbox:deleteGroup:button:removeGroup:multiple' : '删除组',
  'modalbox:deleteGroup:message' : '是仅删除组 <span>"{titles}"</span>，还是将其内容一起删除？此操作不可撤销。',
  'modalbox:deleteGroup:message:multiple': '是仅删除组<span>“{titles}”</span> ，还是将其内容一起删除？此操作不可撤销。',
  'modalbox:deleteGroup:button:removeAll': '全部删除',
  'modalbox:deleteGroup:button:cancel': '取消',
  'modalbox:deletePage:title': '删除页面',
  'modalbox:deletePage:message': '是删除<span>"{titles}"</span>，还是将其从此组中删除？',
  'modalbox:deletePage:removePage': '从组中删除',
  'modalbox:group:rename': '重命名组 ',
  'mailbox:exportLimit:title': '已达到上限',
  'mailbox:exportLimit:message': '已超过最大记事本数 {maxExportPages}。请减少所选记事本数并重试。',

  'toast:groupAddedSuccess:single': '页面已成功添加到“{groupName}”',
  'toast:groupAddedSuccess:plural': '页面已成功添加到“{groupName}”',
  'toast:pagesRemovedFromGroup:single': '页面已成功从“{groupName}”中删除',
  'toast:pagesRemovedFromGroup:plural': '页面已成功从“{groupName}”中删除',
  'toast:groupCreated': '“{groupName}”已成功创建',
  'toast:groupRemoved': '“{groupName}”已成功删除',
  'toast:groupRemoved:andPages': '“{groupName}”和页面已成功删除',
  'toast:groupRemoved:plural': '所选组已成功删除',
  'toast:noteRemoved:single': '页面已成功删除',
  'toast:noteRemoved:plural': '页面已成功删除',

  'sorter:ascending' : '升序',
  'sorter:date' : '日期',
  'sorter:descending' : '降序',
  'sorter:name' : '名称',
  'sorter:size' : '大小',
  'sorter:sortby' : '排序依据',
  'sorter:type' : '类型',

  'tagContainer:createTag' : '创建标记',
  'tagContainer:noTags' : '无标记',
  'tagContainer:noTagsMessage' : '创建的标记将在这里显示',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': '创建的组将在此处显示',

  'export:asDoc' : '为DOC',
  'export:asPsd' : '为PSD',
  'export:asJpg' : '为JPG',
  'export:asPdf' : '为PDF',
  'export:asPng' : '为PNG',
  'export:asSvg' : '为SVG',
  'export:asWill' : '为WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : '为PowerPoint',

  // Ink to Video
  'video:generating' : '生成视频',
  'video:timeNotice' : '这可能需要点时间',
  'video:estimatedTime.one': '这可能需要一分钟',
  'video:estimatedTime.many': '这可能需要<span>{minutes}</span>分钟',
  'video:fileReady' : '您的文件已就绪',
  'video:download'  : '下载',
  'video:expirationMessage' : '下载内容将会在<span>{days}</span>天后自动删除',
  'video:error' : '视频生成失败',

  // Session Expired Prompt
  'session:expired:title': '会话已到期',
  'session:expired:message': '您的会话已到期。请重新登录。',
  'session:expired:button': '登录',

  // Auth module
  'auth:email:label' : '电子邮件',
  'auth:email:taken' : '无法使用该电子邮件地址。',
  'auth:email:notRegisterd' : '未注册该电子邮件地址。',
  'auth:email:invalid' : '无效的电子邮件格式',
  'auth:password:label' : '密码',
  'auth:password:reenter:label' : '再次输入密码',
  'auth:password:mismatch:label' : '密码不匹配',
  'auth:password:tooEasy:label' : '密码必须包含至少 {charLength} 个字符和以下符号中的三种：一个大写字母、一个小写字母、一个数字、一个特殊符号。',
  'auth:firstName:label' : '名',
  'auth:lastName:label' : '姓',
  'auth:country:label' : '国家',
  'auth:country:select' : '选择国家',
  'auth:language:label' : '语言',
  'auth:language:select' : '选择语言',
  'auth:termsAndConditions:label' : '您已阅读并同意Wacom的<a href="{termsHref}">使用条款</a>。',
  'auth:privacyPolicy:label' : '您已阅读并同意Wacom的<a href="{privacyHref}">隐私政策</a>与<a href="{cookieHref}">Cookie政策</a>。',
  'auth:login:invalid' : '无效的登录或密码。',
  'auth:login:title' : '登录Wacom ID',
  'auth:login:allWacom:text' : '在Wacom所有产品中共用的账户。',
  'auth:login:forgotPassword:link' : '忘记密码',
  'auth:login:button' : '登录',
  'auth:login:signup:button' : '注册',
  'auth:login:createNewAccount:label' : '尚未拥有账户？',
  'auth:login:alreadyHaveAccount:label' : '已拥有账户？',
  'auth:passwordReset:title' : '重置密码',
  'auth:passwordReset:text' : '输入您在Wacom ID中使用的电子邮件地址。我们将寄给您一个重置密码的链接。',
  'auth:passwordReset:reset:button' : '重置',
  'auth:passwordReset:signIn:button' : '登录',
  'auth:passwordReset:back:button' : '返回至',
  'auth:passwordReset:success:text' : '已发送一封电子邮件至您的电子邮件地址。',
  'auth:register:title' : '创建您的个人资料',
  'auth:register:success:title1' : '恭喜您！',
  'auth:register:success:title2' : '您已完成注册',
  'auth:register:success:message' : '若要继续，请检视您的电子邮件并确认您的注册，之后登录至您的仪表板。',
  'auth:errors:generic' : '发生错误。请重试。',
  'auth:policyUpdate:title': '我们的使用条款与隐私政策在您上次登录后有所变动',

  // Welcome screens
  'welcome:screen1:header': 'Ink Collaboration功能',
  'welcome:screen1:text': '利用Ink Collaboration，您可以与任何人协同工作。从您的内容开启一个画布，或者创建一个新的画布并开始与其他人进行墨迹绘图。',
  'welcome:screen2:header': '内容同步和分享',
  'welcome:screen2:text': '使用Wacom ID和云端连接的应用程序，您可以随时随地在线获取您的内容。以其他格式导出内容，以便在喜欢的软件工具和应用程序中继续工作。',
  'welcome:screen3:header': '访问您的所有内容',
  'welcome:screen3:text': 'Wacom ID为您的内容解锁许多新功能和工作流。随时随地访问、与他人共享或为安全起见在线备份您的内容。Wacom ID是云服务的钥匙。 ',
  'welcome:button:next': '下一步',
  'welcome:button:done': '完成',

  // Migration screen
  'migration:screen1:text1': '内容正在优化。请稍候。',
  'migration:screen1:text2': '处理中......',

  // What is new screen
  'whatIsNew:cloudMigration:title': '服务更新',
  'whatIsNew:cloudMigration:mainText': '我们正在对云服务进行改进。查看或使用内容时可能会有短暂延迟。感谢您的耐心。',
  'whatIsNew:cloudMigration:cloudTeam': '“- Wacom云团队”',

  // Semantic-ink
  'semantic:sidebar:abstract': '摘要',
  'semantic:sidebar:confidence': '保密',
  'semantic:sidebar:reference': '参考',

  // Semantic-ink icon description
  'semantic:type:organization': '组织',
  'semantic:type:topic': '主题',
  'semantic:type:person': '个人',
  'semantic:type:location': '位置',
  'semantic:type:event': '活动',
  'semantic:error:header': '无Semantic Ink识别',
  'semantic:error:text': '您的笔记无需任何Semantic Ink增强。',
  
  // Download my data
  'dataDownload:profile:option': '我的数据',
  'dataDownload:tabs:downloadData': '下载数据',
  'dataDownload:tabs:availableData': '可用数据',
  'dataDownload:tabs:downloadData:description': '导出并下载您的Ink Space帐户内容的副本。',
  'dataDownload:tabs:downloadData:chooseProduct': '请选择......',
  'dataDownload:tabs:downloadData:chooseProduct:all': '全选',
  'dataDownload:processing:description': '下载文件可能需要一些时间。当您的下载准备好后，您将收到一封包含下载链接的电子邮件，或者可从此处下载。',
  'dataDownload:processing': '处理中......',
  'dataDownload:request:button': '下载数据',
  'dataDownload:tabs:availableData:description': '下载您的数据',
  'dataDownload:tabs:availableData:archiveFor': '存档 ',
  'global:myData': '我的数据'
}

export default messages;