import React from 'react';
import { initialSelectedDocument, LOGIN_VIEW, LOCAL_STORAGE } from 'constants/constants';
import globalHook from 'use-global-hook';
import { storageFactory } from "utils/StorageFactory";
import * as actions from 'actions';

import { supportedLocales } from '../services/MessagesLoader';

let ___localStorage = {}
let ___sessionStorage = {}

try {
    ___localStorage = window.localStorage
    ___sessionStorage = window.sessionStorage
} catch (err) {
    console.log(err)
}

const localStore = storageFactory(___localStorage);
const sessionStore = storageFactory(___sessionStorage);

window.localStore = localStore
window.sessionStore = sessionStore

const profile = actions.auth.loadProfile();
const expiresAfter = actions.auth.getExpiration(profile);
const hasProfile = !!profile;
const hasExpired = !hasProfile || (profile.access.expiration - Date.now()) <= 0;

const getDefaultLocale = () => {
    let allSupportedLocales = window.navigator.languages || window.navigator.userLanguage || window.navigator.language;

    if (allSupportedLocales instanceof String) {
        allSupportedLocales = [allSupportedLocales];
    }

    for (const fullLocale of allSupportedLocales) {
        if (supportedLocales.has(fullLocale)) {
            return fullLocale;
        }

        const split = fullLocale.split('-');

        if (supportedLocales.has(split[0])) {
            return split[0];
        }
    }

    return 'en';
}

const initialState = {
    initedDocuments: false,
    inkNoteDocuments: null,
    folders: [
        { "link": "web", "name": "web", visible: true },
        { "link": "mate", "name": "mate", visible: true },
        { "link": "montblanc", "name": "montblanc", visible: true },
        { "link": "bamboo_paper", "name": "Bamboo", visible: true },
        { "link": "wacom_notes", "name": "wacom_notes", visible: true },
        { "link": "collaboration", "name": "collaboration", visible: true }
    ],
    documents: [],
    groups: [],
    fullGroupList: [],
    currentDirInfo: {
        id: 'root',
        label: 'root',
        lastModified: '',
        parentId: null,
    },
    isFetchingDocs: false,
    isFetchingGroups: false,
    isFetchingTags: false,
    isSpinnerVisible: false,
    isExportingDocuments: false,
    isDuplicatingDocument: false,
    previewItemIndex: 0,
    preview: {
        document: initialSelectedDocument,
        next: null,
        prev: null,
        pendingDocumentId: null
    },
    hwr: {
        isFetching: false,
        text: ''
    },
    contextMenu: {
        isDocumentContextVisible: false,
        isControlPanelVisible: false,
        selectedItems: [],
        selectedGroups: [],
        selectedIds: new Set(),
        selectedGroupIds: new Set(),
        isSingleDoc: true,
        position: { x: 0, y: 0 },
        selectedDocTypes: [],
        groupProperties: {}
    },
    isOverlayActive: false,
    sort: {
        field: 'date',
        docFiled: 'created',
        groupField: 'lastModified',
        order: 'descending',
        ...(JSON.parse(window.localStore.getItem(LOCAL_STORAGE.SORT)) ?? {})
    },
    localization: {
        locale: window.localStore.getItem(LOCAL_STORAGE.LOCALE) ?? getDefaultLocale()
    },
    location: {
        query: {
            term: null
        }
    },
    tags: [],
    selectedTag: {},
    selectedGroup: {},
    auth: {
        activeView: LOGIN_VIEW,
        isFetching: false,
        isRefreshingToken: false,
        isRefreshTokenInvalid: false,
        hasError: false,
        loginActiveErrors: [],
        mainToken: hasProfile ? profile.access.token : null,
        oldToken: hasProfile ? profile.access.oldToken : null,
        refreshToken: hasProfile ? profile.access.refreshToken : null,
        isLoggedIn: !hasExpired,
        profile: profile,
        isInkspacePlusUser: false,
        expiration: hasProfile ? profile.access.expiration : -1,
        expiresAfter: expiresAfter
    },
    modals: {
        isOpen: false,
        type: null,
        data: {}
    },
    video: {
        guid: '',
        encodeState: '',
        progress: '',
        etaSeconds: '',
        resultLocation: '',
        responseReceived: false
    },
    inkEditor: {
        documentId: null,
        pageContents: null,
        inkModels: [],
        width: 0, // width: 1188,
        height: 0, // height: 864
        activePages: {},
        changedPages: {}
    },
    migration: {
        isFetched: false,
        isStarted: false,
        isReady: false,
        migratedDocuments: 0,
        totalDocuments: 0
    },
    semantic: {
        isFetching: false,
        opcUrl: null,
        data: null
    },
    downloadData: {
        numDocumentsInArchive: 0,
        inkSpaceDataUrl: null,
        montBlancDataUrl: null,
        bambooPaperDataUrl: null,
        webDataUrl: null,
        bambooPaperProgress: null
    }
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;