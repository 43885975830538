import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import useDimensions from 'react-use-dimensions';
import DocumentListItem from './DocumentListItem';
import DocumentContextMenu from './DocumentContextMenu';
import DocumentListPreloader from './DocumentListPreloader';
import { useIntl } from 'react-intl';
import { getContextMenu } from 'common/contextMenuHelpers/ContextMenuHelpers';
import Overlay from './Overlay';
import ScrollableDocumentsList from './../ScrollableDocumentsList';
// import useDebouncedEffect from 'use-debounced-effect';
import {
    INKSPACE_STREAM, CSS_TRANSITION_ENTER_ANIMATION_TIME,
    DOCUMENT_UPDATE_POLLING_INTERVAL, BASE_HOME_LOCATION, BAMBOO_STREAM,
    DOCUMENT_UPDATE_LONG_POLLING_INTERVAL
} from 'constants/constants';
import useGlobal from 'store';
import EmptyStream from './EmptyStream';
import EmptySearchResult from './EmptySearchResult';
import { setOpenedGroup } from 'common/components/utilities/queryHelper';
import { hasContextMenu } from './inkNoteHelper';

// import SyncService from 'services/SyncService';

import './DocumentViewComponent.scss';
// import useInterval from 'services/intervalHook';
import useSyncService from 'services/syncService';


const transitionTime = CSS_TRANSITION_ENTER_ANIMATION_TIME;

function disableDefaultContextMenu(ev) {
    /*
    * Dont prevent default for the overlay ( needed in the DropDown.jsx)
    */
    if (ev.target.classList.contains('overlay')) return;

    if (ev.target.tagName !== 'INPUT' && ev.target.tagName !== 'TEXTAREA') ev.preventDefault();
}

function showContextMenu(props) {
    const { position } = props.contextMenu;

    return <DocumentContextMenu coordinates={position} elements={getContextMenu(props)} actions={props.actions} />
}

function toggleContextMenu(ev, data, action, formatMessage, isSingleSelect) {
    ev.stopPropagation();

    if (!hasContextMenu(data)) return;

    const position = { x: ev.clientX, y: ev.clientY };
    // TODO: Check why this is necessary
    data.formatMessage = formatMessage;
    action(data, position, isSingleSelect);
}

function hasFoundResults(props, areDocsVisible = true) {
    const { documents, isFetchingDocs, groups, query } = props;
    const hasGroups = !!groups.length;
    const isInGroupView = !!query?.dirId;

    if (isFetchingDocs || (areDocsVisible && documents.length) || (hasGroups && !isInGroupView)) {
        return true;
    }

    return false;
}

function shouldShowGroups(props) {
    const { query } = props;
    let shouldShowGroups = true;

    if (query?.tags || query?.mateGroupId) {
        shouldShowGroups = false;
    }

    return shouldShowGroups;
}

function fetchDocsAndGroupsByQuery(globalActions, query, indicate, updateGroupsAndTags) {
    globalActions.home.getDocuments(query, indicate);

    if (updateGroupsAndTags) {
        globalActions.home.getTags();
        globalActions.home.getGroups(query);
    }
};

function shouldShowDocuments(applicationName, dirId) {
    if (applicationName === BAMBOO_STREAM && !dirId) {
        return false;
    }

    return true;
}

function DocumentViewComponent(props) {
    const history = useHistory();
    const { formatMessage } = useIntl();
    // const ref = useRef();
    const oldApplicationName = useRef(null);
    const oldQueryParams = useRef({});
    const [globalState, globalActions] = useGlobal();
    const [ref, allDims] = useDimensions();
    const { documents, groups, fullGroupList, isFetchingDocs, isOverlayActive, contextMenu, tags } = globalState;
    const { width, height } = allDims;
    const contextMenuProps = {
        contextMenu, tags, formatMessage,
        documents, groups, fullGroupList,
        actions: props.actions,
        currentPageId: globalState?.preview?.currentPageId
    };
    const { isDocumentContextVisible } = contextMenu;
    const { queryParams } = props;
    // const [isDocumentSectionVisible, setIsDocumentSectionVisible] = useState(true);

    const childProps = {
        documents, groups, isFetchingDocs,
        query: queryParams,
        isStream: !!queryParams.stream && !queryParams.term && !queryParams.tags,

        // TODO
        // mateGroupId: location.query.mateGroupId,
        stream: queryParams.applicationName
    };

    const openPreviewView = (ev) => {
        // Hide the context menu if opened
        if (contextMenu.isDocumentContextVisible || contextMenu.isControlPanelVisible) {
            globalActions.home.hideContextMenu();
        } else {
            // TODO: Merge into one function
            globalActions.home.selectPreviewDoc(ev.currentTarget.dataset.itemId);
            props.actions.doTogglePreview(ev.currentTarget.dataset.itemId);
        }
    }

    const openGroup = (ev) => {
        const itemId = ev.currentTarget.dataset.itemId;
        const itemApplicationName = ev.currentTarget.dataset.itemApplicationName;
        const newLocation = setOpenedGroup(itemId, itemApplicationName);
        globalActions.home.hideContextMenu();
        history.push(newLocation);
    }

    // Disable default right click
    useEffect(() => {
        document.body.addEventListener('contextmenu', disableDefaultContextMenu, true);
        return () => {
            document.body.removeEventListener('contextmenu', disableDefaultContextMenu, true);
        };
    }, []);

    const wrappedDocumentsUpdate = () => { fetchDocsAndGroupsByQuery(globalActions, childProps.query, false, true); }

    // Initializes the document list
    const { dirId, term, tagId, applicationName } = queryParams;

    useSyncService(
        wrappedDocumentsUpdate, DOCUMENT_UPDATE_POLLING_INTERVAL,
        DOCUMENT_UPDATE_LONG_POLLING_INTERVAL, globalState.auth.mainToken, applicationName
    );

    // If applicationName changes fetch groups and tags
    // otherwise fetch only documents
    useEffect(() => {
        const isApplicationNameDifferent = applicationName !== oldApplicationName.current;
        const shouldFetchDocs = dirId !== oldQueryParams.current.dirId
            || term !== oldQueryParams.current.term
            || tagId !== oldQueryParams.current.tagId
            || dirId !== oldQueryParams.current.dirId
            || isApplicationNameDifferent;

        if (applicationName && shouldFetchDocs) {
            fetchDocsAndGroupsByQuery(globalActions, childProps.query, true, isApplicationNameDifferent);
        }

        oldApplicationName.current = applicationName;
        oldQueryParams.current = childProps.query;
    }, [dirId, term, tagId, childProps.query, oldQueryParams, oldApplicationName, applicationName, globalActions]);

    // Redirect to the default stream if no other info is available
    useEffect(() => {
        if (!applicationName && !term && !dirId) {
            history.replace(BASE_HOME_LOCATION);
        }
    }, [applicationName, term, dirId, history]);

    useEffect(() => {
        globalActions.home.hideContextMenu();
    }, [applicationName, globalActions.home]);

    useEffect(() => {
        globalActions.home.fetchHiddenStreams();
    }, [globalActions.home]);


    const areDocsVisible = shouldShowDocuments(applicationName, dirId);
    const hasResult = hasFoundResults(childProps, areDocsVisible);

    let emptyStream = <></>;
    let emptyStreamTransitionKey = 'no-view-key';

    if (props.isFetchingDocs) {
        emptyStreamTransitionKey = 'fetching-docs';
    }

    if (!hasResult) {
        if (queryParams.dirId) {
            emptyStream = <EmptyStream stream={'group'} />
            emptyStreamTransitionKey = 'empty-group-key';
        }
        else if (queryParams.term || queryParams.tagId) {
            emptyStream = <EmptySearchResult />
            emptyStreamTransitionKey = 'empty-search-key';
        }
        else if (childProps.stream) {
            emptyStream = <EmptyStream stream={childProps.stream} />
            emptyStreamTransitionKey = childProps.stream;
        }
    }

    let filteredDocuments = documents;
    let groupsToShow = [];

    if (shouldShowGroups(childProps)) {
        groupsToShow = [...groups]
    }

    if (hasResult && queryParams.stream === INKSPACE_STREAM && queryParams.mateGroupId) {
        const groupId = queryParams.mateGroupId;
        const selectedGroup = groups.find((item) => item.id === groupId);
        if (selectedGroup) {
            filteredDocuments = documents.filter((item) => selectedGroup.documents.includes(item.id))
        }
    }

    return <div ref={ref} className={'document-view'}>
        {isDocumentContextVisible && showContextMenu(contextMenuProps)}
        <SwitchTransition mode={'out-in'}>
            <CSSTransition key={emptyStreamTransitionKey} in={!hasResult} timeout={transitionTime} classNames={'fade-in-transition'}>
                {emptyStream}
            </CSSTransition>
        </SwitchTransition>
        <CSSTransition in={isFetchingDocs} timeout={transitionTime} classNames={'fade-in-transition'} unmountOnExit>
            <DocumentListPreloader width={width} maxHeight={height} />
        </CSSTransition>
        <CSSTransition in={!isFetchingDocs && hasResult} timeout={transitionTime} classNames={'fade-in-transition'} unmountOnExit>
            <>
                <ScrollableDocumentsList>
                    {groupsToShow.map((item) =>
                        <DocumentListItem
                            key={'group_' + item.id}
                            onClick={openGroup}
                            isActive={!contextMenu.isSingleDoc && contextMenu.selectedGroupIds.has(item.id)}
                            showContextMenu={(ev, item) => toggleContextMenu(ev, item, globalActions.home.showContextMenu, formatMessage, true)}
                            toggleMultiSelectMenu={(ev, item) => toggleContextMenu(ev, item, globalActions.home.showContextMenu, formatMessage, false)}
                            itemData={{ ...item, isMateGroup: true }}
                            fullGroupList={fullGroupList} />
                    )}
                    {areDocsVisible && filteredDocuments.map(document =>
                        <DocumentListItem
                            key={'doc_' + document.id}
                            itemData={document}
                            onClick={openPreviewView}
                            isActive={!contextMenu.isSingleDoc && contextMenu.selectedIds.has(document.id)}
                            isDocumentContextVisible={isDocumentContextVisible}
                            showContextMenu={(ev, item) => toggleContextMenu(ev, item, globalActions.home.showContextMenu, formatMessage, true)}
                            toggleMultiSelectMenu={(ev, item) => toggleContextMenu(ev, item, globalActions.home.showContextMenu, formatMessage, false)}
                        />
                    )}
                </ScrollableDocumentsList>
            </>
        </CSSTransition>
        <CSSTransition in={isOverlayActive} timeout={transitionTime} classNames={'fade-in-transition'} unmountOnExit>
            <Overlay actions={props.actions} />
        </CSSTransition>
    </div>
}


export default DocumentViewComponent;