import React from 'react';
import SorterDropDown from './SorterDropDown';



function Sorter(props) {
    const { order, field } = props.sort;
    const { hideOverlay, showOverlay } = props.actions;

    const setSortField = (field) => {
        props.actions.doSetFilter({ field });
    }

    const setSortOrder = (order) => {
        props.actions.doSetFilter({ order });
    }

    return <>
        <SorterDropDown
            hideOverlay={hideOverlay}
            showOverlay={showOverlay}
            activeField={field}
            activeOrder={order}
            onFieldClick={setSortField}
            onOrderClick={setSortOrder} />
    </>
}

export default Sorter;