const messages = {
  'documents:empty:noresult:noresult': 'SIN RESULTADOS',
  'documents:empty:noresult:noresultfound': 'No se han encontrado resultados que coincidan con estos criterios.',
  'documents:empty:stream:appstream': 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream': 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream': 'Una vez que tu dispositivo esté conectado con la aplicación Ink Space e inicies sesión con tu ID de Wacom, verás aquí todo tu contenido.',
  'documents:empty:stream:explanation1:webstream': 'Prueba Ink Collaboration. Comparte y colabora en el trabajo de otras personas con tus creaciones. Pruébalo hoy',
  'documents:empty:stream:explanation1:bamboopaperstream': 'Cuando te conectes a Bamboo Paper con tu Wacom ID podrás ver todo tu contenido.',
  'documents:empty:stream:explanation1:wacom_notes': 'Toma notas. Haz más. Igual que en papel, pero mejor.',
  'documents:empty:stream:tryitnow': 'Pruébalo ahora',
  'documents:empty:stream:learnmore': 'MÁS INFORMACIÓN',
  'documents:empty:group': 'Tu grupo está vacío',
  'streams:inkspaceAppStream': 'Ink Space App Stream',
  'streams:inkspaceWebStream': 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc': 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Flujo de colaboración',

  'filter:result': 'RESULTADO PARA',
  'filter:search': 'BÚSQUEDA...',

  'footer:navigation:privacy': 'PRIVACIDAD',
  'footer:navigation:termOfUse': 'CONDICIONES DE USO',
  'footer:navigation:cookies': 'Cookies',
  'footer:navigation:cookiePreferences': 'Preferencias de cookies',

  'global:convertTo': 'CONVERTIR A {language}',
  'global:convertToText': 'Convertir a texto',

  'context-menu:createNewCanvas': 'Crear nuevo lienzo',
  'context-menu:createNewGroup': 'Crear grupo',
  'context-menu:startNewCollaboration': 'Iniciar nueva colaboración',
  'context-menu:startCollaboration': 'Iniciar colaboración',
  'context-menu:edit': 'Editar',

  'global:delete': 'Eliminar',
  'global:rename': 'Renombrar',
  'global:export': 'Exportar',
  'global:tags': 'Etiquetas',
  'global:groups': 'Grupos',
  'global:shareLink': 'Compartir enlace',
  'global:copy': 'COPIAR',
  'global:ok': 'OK',
  'global:duplicateAndEdit': 'Duplicar y editar',
  'global:semantic-ink': 'Semantic Ink',

  'global:save': 'Guardar',
  'global:dontsave': 'No guardar',
  'global:proceed': 'CONTINUAR',
  'global:enterName': 'INTRODUCIR NOMBRE',
  'global:browserUnsupported': 'Navegador no compatible',

  'global:language': 'IDIOMA',
  'global:language:english': 'English',
  'global:language:japanese': '日本語',
  'global:language:korean': '한국어',
  'global:language:chineseTraditional': '中文（繁體）',
  'global:language:chineseSimplified': '中文（简体）',
  'global:language:german': 'Deutsch',
  'global:language:french': 'Français',
  'global:language:russian': 'Русский',
  'global:language:dutch': 'Dutch',
  'global:language:italian': 'Italiano',
  'global:language:polish': 'Polski',
  'global:language:portuguese': 'Português',
  'global:language:portuguese_BR': 'português do Brasil',
  'global:language:spanish': 'Español',
  'global:language:danish': 'Dansk',
  'global:language:finnish': 'Suomen kieli',
  'global:language:norwegian': 'Norsk',
  'global:language:swedish': 'Svenska',
  'global:language:arabic': "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian': 'Български',
  'global:language:turkish': 'Türkçe',
  'global:language:vietnamese': 'tiếng Việt',

  'global:logout': 'Cerrar sesión',
  'global:login': 'Iniciar sesión',
  'global:myAccount': 'Mi cuenta',

  'inputField:existsName': 'Error. El nombre ya existe.',
  'inputField:incorrectName': 'Error. El nombre no es correcto.',
  'inputField:notInRange': 'Error. El nombre debe tener entre ({minLength} y {maxLength}) caracteres.',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Usa los navegadores Chrome o Firefox para conectarte a esta colaboración.  Gracias',
  'modalbox:browser:unsupport:sf:text': 'Usa los navegadores Chrome o Safari para conectarte a esta colaboración.  Gracias',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title': 'Error!',
  'modalbox:error:buttonTitle': 'Cerrar',
  'modalbox:exportError:message': 'No se puede exportar en estos momentos.',
  'modalbox:createTag:buttons:title': 'CREAR',
  'modalbox:createTag:title': 'CREAR ETIQUETA NUEVA',
  'modalbox:deleteTag:message': 'Estás a punto de eliminar <span>"{tag}"</span>. Esta acción no se puede deshacer.',
  'modalbox:removeDocument:message': 'Estás a punto de eliminar <span>"{docTitle}"</span>. Esta acción no se puede deshacer.',
  'modalbox:shareLink:buttons:stopSharing': 'Dejar de compartir',
  'modalbox:sessionFull:title': 'Colaboración completa',
  'modalbox:sessionFull:text': 'Desafortunadamente, la sesión de colaboración a la que intentas acceder está completa.',
  'modalbox:leaveSession:title': 'Salir de la sesión',
  'modalbox:leaveSession:buttons:leave': 'Salir',
  'modalbox:leaveSession:text': '¿Quieres salir de la sesión de colaboración?',
  'modalbox:endSession:title': 'Finalizar sesión',
  'modalbox:endSession:buttons:end': 'Finalizar',
  'modalbox:endSession:text': '¿Quieres terminar la sesión de colaboración actual? El progreso actual se guardará automáticamente en tu transmisión en línea.',
  'modalbox:sessionEnded:title': 'La sesión ha finalizado',
  'modalbox:sessionEnded:text': '¿Quieres guardar una copia de la sesión de colaboración en tu transmisión en línea?',
  'modalbox:accessDenied:title': 'Acceso denegado',
  'modalbox:accessDenied:buttons:goBack': 'Volver',
  'modalbox:accessDenied:text': 'Desafortunadamente, la sesión de colaboración a la que intentas acceder ha caducado.',
  'modalbox:collaborationStarted:title': 'La colaboración ha comenzado',
  'modalbox:collaborationStarted:warn': 'Debes mantener Ink Collaboration abierto.  Si cierras esta pestaña o cambias a una pestaña diferente, Ink Collaboration se cerrará.',
  'modalbox:collaborationStarted:text': ' Tu sesión de colaboración ha comenzado. Puedes invitar hasta 10 participantes con la URL de colaboración de tu navegador.',
  'modalbox:collaborationError:title': 'Error de colaboración',
  'modalbox:collaborationError:buttons:close': 'Cerrar',
  'modalbox:collaborationError:text': 'El navegador que estás utilizando no es compatible con Ink Collaboration. Esta característica funciona mejor con Firefox (versión 55 y posteriores), Chrome (versión 60 y posteriores) y Safari (versión 11 y posteriores)',
  'modalbox:duplicateAndEdit:buttons:dontshow': 'No mostrar',
  'modalbox:duplicateAndEdit:text': 'Una nueva versión extendida de la página se ubicará en tu transmisión en línea de Ink Scape sin cambiar el original',
  'modalbox:saveChanges:title': 'Guardar cambios',
  'modalbox:saveChanges:text': '¿Quieres guardar los cambios del documento antes de cerrar?',
  'modalbox:WebGLError:title': 'Se ha producido un error',
  'modalbox:WebGLError:text': 'Se ha producido un error, por lo que debes recargar el contenido con el botón «Recargar» incluido en el mensaje anterior.',
  'modalbox:collaborationJoinFailed:title': 'No se ha podido establecer la conexión',
  'modalbox:collaborationJoinFailed:text': 'No se ha podido acceder a la sesión a la que intentas acceder. Vuelve a intentarlo más tarde.',
  'modalbox:connectionLost:title': 'Se ha perdido la conexión',
  'modalbox:connectionLost:text': 'Se ha perdido la conexión. Comprueba tu conexión a Internet y vuelve a intentarlo.',
  'modalbox:phoneDetection:title': 'Usar aplicación móvil',
  'modalbox:phoneDetection:btn:open': 'Abrir',
  'modalbox:phoneDetection:btn:no-thanks': 'No, gracias',
  'modalbox:phoneDetection:msg': 'Te recomendamos usar nuestra aplicación especial para teléfono móvil para disfrutar de una mejor experiencia.',
  'modalbox:chooseVectorOrRaster:title': 'Elegir modo edición',
  'modalbox:chooseVectorOrRaster:buttons:annotate': 'Anotar',
  'modalbox:chooseVectorOrRaster:buttons:vectorize': 'Vectorizar',
  'modalbox:chooseVectorOrRaster:text': 'El contenido que intentas editar está basado en ráster y, para poder mantener la apariencia original, te recomendamos que anotes sobre él. Asimismo, también podrías elegir convertirlo a formato vectorial, pero el resultado podría diferir del original según las herramientas que uses.',

  // Groups Strings
  'modalbox:deleteGroup:title': 'Eliminar grupo',
  'modalbox:deleteGroup:title:multiple': 'Eliminar grupos',
  'modalbox:deleteGroup:button:removeGroup': 'Eliminar grupo',
  'modalbox:deleteGroup:button:removeGroup:multiple': 'Eliminar grupos',
  'modalbox:deleteGroup:message': '¿Quieres eliminar solo el grupo <span>"{titles}"</span> o también su contenido? Esta acción es irreversible.',
  'modalbox:deleteGroup:message:multiple': '¿Quieres eliminar solo los grupos <span>"{titles}"</span>  o también su contenido? Esta acción es irreversible.',
  'modalbox:deleteGroup:button:removeAll': 'Eliminar todo',
  'modalbox:deleteGroup:button:cancel': 'Cancelar',
  'modalbox:deletePage:title': 'Eliminar página',
  'modalbox:deletePage:message': '¿Quieres eliminar "<span>"{titles}"</span>" o eliminarlo de este grupo?',
  'modalbox:deletePage:removePage': 'Eliminar del grupo',
  'modalbox:group:rename': 'Renombrar grupo',
  'mailbox:exportLimit:title': 'Límite alcanzado',
  'mailbox:exportLimit:message': 'Se ha superado la cantidad máxima de {maxExportPages} notas. Reduce la cantidad de notas seleccionadas y vuelve a intentarlo.',


  'toast:groupAddedSuccess:single': 'Página añadida correctamente a "{groupName}"',
  'toast:groupAddedSuccess:plural': 'Páginas añadidas correctamente a "{groupName}"',
  'toast:pagesRemovedFromGroup:single': 'Página eliminada correctamente de "{groupName}"',
  'toast:pagesRemovedFromGroup:plural': 'Páginas eliminadas correctamente de "{groupName}"',
  'toast:groupCreated': '"{groupName}" se creó correctamente',
  'toast:groupRemoved': '"{groupName}" se eliminó correctamente',
  'toast:groupRemoved:andPages': '"{groupName}" y las páginas se eliminaron correctamente',
  'toast:groupRemoved:plural': 'Los grupos seleccionados se eliminaron correctamente',
  'toast:noteRemoved:single': 'La página se ha eliminado correctamente',
  'toast:noteRemoved:plural': 'Las páginas se han eliminado correctamente',

  'sorter:ascending': 'Ascendente',
  'sorter:date': 'Fecha',
  'sorter:descending': 'Descendente',
  'sorter:name': 'Nombre',
  'sorter:size': 'Tamaño',
  'sorter:sortby': 'Ordenar por',
  'sorter:type': 'Tipo',

  'tagContainer:createTag': 'CREAR ETIQUETA',
  'tagContainer:noTags': 'Sin etiquetas',
  'tagContainer:noTagsMessage': 'Cuando crees etiquetas, aparecerán aquí',

  'groupContainer:noGroups': 'No groups',
  'groupContainer:noGroupsMessage': 'Cuando crees grupos, aparecerán aquí',

  'export:asDoc': 'como DOC',
  'export:asPsd': 'como PSD',
  'export:asJpg': 'como JPG',
  'export:asPdf': 'como PDF',
  'export:asPng': 'como PNG',
  'export:asSvg': 'como SVG',
  'export:asWill': 'como WILL',
  'export:asVideo': 'Ink to Video',
  'export:asPptx': 'como PowerPoint',

  // Ink to Video
  'video:generating': 'Generando tu vídeo',
  'video:timeNotice': 'Esto puede tardar un poco',
  'video:estimatedTime.one': 'Esto puede tardar hasta un minuto',
  'video:estimatedTime.many': 'Esto puede tardar hasta <span>{minutes}</span>minutos',
  'video:fileReady': 'Tu archivo está listo',
  'video:download': 'Descargar',
  'video:expirationMessage': 'La descarga se borrará automáticamente pasados <span>{days}</span>días',
  'video:error': 'Error al generar el vídeo',

  // Session Expired Prompt
  'session:expired:title': 'Sesión caducada',
  'session:expired:message': 'Tu sesión ha caducado. Inicia sesión de nuevo.',
  'session:expired:button': 'Iniciar sesión',

  // Auth module
  'auth:email:label': 'Correo electrónico',
  'auth:email:taken': 'La dirección de correo electrónico no está disponible.',
  'auth:email:notRegisterd': 'La dirección de correo electrónico no está registrada.',
  'auth:email:invalid': 'Correo electrónico no válido',
  'auth:password:label': 'Contraseña',
  'auth:password:reenter:label': 'Repetir contraseña',
  'auth:password:mismatch:label': 'Las contraseñas no coinciden',
  'auth:password:tooEasy:label': 'La contraseña debe contener, como mínimo, {charLength} caracteres y 3 de los siguientes elementos: una mayúscula, una minúscula, un número y un carácter especial.',
  'auth:firstName:label': 'Nombre(s)',
  'auth:lastName:label': 'Apellido(s)',
  'auth:country:label': 'País',
  'auth:country:select': 'Seleccionar país',
  'auth:language:label': 'Idioma',
  'auth:language:select': 'Seleccionar idioma',
  'auth:termsAndConditions:label': 'Has leído y aceptado las <a href="{termsHref}">Condiciones de uso</a> de Wacom.',
  'auth:privacyPolicy:label': 'Has leído y aceptado la <a href="{privacyHref}">Política de privacidad</a> y la <a href="{cookieHref}">Política de cookies</a> de Wacom.',
  'auth:login:invalid': 'Usuario o contraseña no válidos.',
  'auth:login:title': 'Iniciar sesión en la Wacom ID',
  'auth:login:allWacom:text': 'Tu cuenta para todo lo relacionado con Wacom.',
  'auth:login:forgotPassword:link': '¿Has olvidado su contraseña?',
  'auth:login:button': 'Iniciar sesión',
  'auth:login:signup:button': 'Registrarse',
  'auth:login:createNewAccount:label': '¿No tienes una cuenta?',
  'auth:login:alreadyHaveAccount:label': '¿Ya tienes una cuenta?',
  'auth:passwordReset:title': 'Restablecer contraseña',
  'auth:passwordReset:text': 'Introduce la dirección de correo electrónico que utilizaste para tu Wacom ID. Te enviaremos un enlace para restablecer la contraseña.',
  'auth:passwordReset:reset:button': 'Restablecer',
  'auth:passwordReset:signIn:button': 'Registrarse',
  'auth:passwordReset:back:button': 'Regresar',
  'auth:passwordReset:success:text': 'Te hemos enviado un mensaje a tu dirección de correo electrónico.',
  'auth:register:title': 'Crea tu perfil',
  'auth:register:success:title1': '¡Enhorabuena!',
  'auth:register:success:title2': 'Has finalizado el registro.',
  'auth:register:success:message': 'Para continuar, comprueba tu correo electrónico para confirmar tu registro y, a continuación, inicia sesión para acceder al panel de control.',
  'auth:errors:generic': 'Algo ha salido mal. Por favor, inténtalo de nuevo.',
  'auth:policyUpdate:title': 'Nuestras Condiciones de uso y la Política de privacidad han cambiado desde tu último inicio de sesión.',

  // Welcome screens
  'welcome:screen1:header': 'Características de Ink Collaboration',
  'welcome:screen1:text': 'Con Ink Collaboration puedes trabajar de forma colaborativa con cualquier persona y en cualquier lugar. Inicia un lienzo desde tu contenido o crea uno nuevo y comienza a escribir con otros.',
  'welcome:screen2:header': 'Sincroniza y comparte contenidos',
  'welcome:screen2:text': 'Con Wacom ID y tus aplicaciones conectadas a la nube, tu contenido está disponible online en cualquier momento y en cualquier lugar. Exporta a diferentes formatos para seguir trabajando en tus herramientas de software y aplicaciones preferidas.',
  'welcome:screen3:header': 'Acceso a todo tu contenido',
  'welcome:screen3:text': 'Tu Wacom ID desbloquea muchas nuevas características y flujos de trabajo para tu contenido. Accede desde cualquier lugar, comparte con otros o mantén una copia de seguridad de tu contenido online para mayor seguridad. El Wacom ID es tu clave de acceso a la nube. ',
  'welcome:button:next': 'Siguiente',
  'welcome:button:done': 'Listo',

  // Migration screen
  'migration:screen1:text1': 'Tu contenido se está optimizando. Por favor, espera.',
  'migration:screen1:text2': 'Procesando...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Actualización del servicio',
  'whatIsNew:cloudMigration:mainText': 'Estamos haciendo mejoras en nuestros servicios en la nube. Podrías experimentar algunos retrasos breves al visualizar o utilizar tu contenido. Gracias por tu paciencia.',
  'whatIsNew:cloudMigration:cloudTeam': '"- Wacom Cloud Team"',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Resumen',
  'semantic:sidebar:confidence': 'confianza',
  'semantic:sidebar:reference': 'Referencia',

  // Semantic-ink icon description
  'semantic:type:organization': 'organización',
  'semantic:type:topic': 'tema',
  'semantic:type:person': 'persona',
  'semantic:type:location': 'ubicación',
  'semantic:type:event': 'evento',
  'semantic:error:header': 'Sin reconocimiento de Semantic Ink',
  'semantic:error:text': 'Tu nota no necesita ninguna mejora de Semantic Ink.',

  // Download my data
  'dataDownload:profile:option': 'Mis datos',
  'dataDownload:tabs:downloadData': 'Descargar datos',
  'dataDownload:tabs:availableData': 'Datos disponibles',
  'dataDownload:tabs:downloadData:description': 'Exportar y descargar una copia del contenido de tu cuenta Ink Space.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Por favor, selecciona...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Seleccionar todo',
  'dataDownload:processing:description': "Se necesitarán unos minutos para descargar tus archivos. Cuando haya finalizado tu descarga, recibirás un correo electrónico con un enlace de descarga. También puedes iniciar la descarga desde aquí.",
  'dataDownload:processing': 'Procesando...',
  'dataDownload:request:button': 'Mis datos',
  'dataDownload:tabs:availableData:description': 'Descarga tus datos',
  'dataDownload:tabs:availableData:archiveFor': 'Archivo para ',
  'global:myData': 'Mis datos'
}

export default messages;