const messages = {
  'documents:empty:noresult:noresult' : 'no result',
  'documents:empty:noresult:noresultfound' : 'No results found matching your criteria.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Once your device is connected with the Ink Space App and you sign up with your Wacom ID, you will see all of your content here.',
  'documents:empty:stream:explanation1:webstream' : 'Give Ink Collaboration a try.  Share and collaborate on others with your creations. Try it today',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Once you connect Bamboo Paper with your Wacom ID you will see all of your content here.',
  'documents:empty:stream:tryitnow' : 'Try it now',
  'documents:empty:stream:learnmore' : 'learn more',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes Stream',
  'streams:collaboration': 'Collaboration Stream',

  'filter:result' : 'result for',
  'filter:search' : 'search...',

  'footer:navigation:privacy' : 'Privacy',
  'footer:navigation:termOfUse' : 'Terms of use',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie Preferences',

  'global:convertTo' : 'Convert to {language}',
  'global:convertToText' : 'Convert to text',

  'context-menu:createNewCanvas' : 'Create New Canvas',
  'context-menu:startNewCollaboration' : 'Start New Collaboration',
  'context-menu:startCollaboration' : 'Start Collaboration',
  'context-menu:edit' : 'Edit',

  'global:delete' : 'Delete',
  'global:rename' : 'Rename',
  'global:export' : 'Export',
  'global:tags' : 'Tags',
  'global:shareLink' : 'Share Link',
  'global:copy' : 'copy',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Duplicate & Edit',

  'global:save' : 'Save',
  'global:dontsave' : 'Don\'t save',
  'global:proceed' : 'Proceed',
  'global:enterName' : 'Enter name',

  'global:language' : 'Language',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Logout',

  'inputField:existsName' : 'Error! The name already exists!',
  'inputField:incorrectName' : 'Error! The name is incorrect!',
  'inputField:notInRange' : 'Error! The name must be in range of ( {minLength} - {maxLength} ) characters!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Please use Chrome or Firefox to connect to this Collaboration.',
  'modalbox:browser:unsupport:sf:text': 'Please use Chrome or Safari to connect to this Collaboration.',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',
  'modalbox:error:title' : 'Error!',
  'modalbox:error:buttonTitle' : 'Close',
  'modalbox:exportError:message' : 'Export action is not available at the moment!',
  'modalbox:createTag:buttons:title' : 'Create',
  'modalbox:createTag:title' : 'Create new tag',
  'modalbox:deleteTag:message' : 'You are about to delete <span>"{tag}"</span>. This action is irreversible.',
  'modalbox:removeDocument:message' : 'You are about to delete <span>"{docTitle}"</span>. This action is irreversible.',
  'modalbox:shareLink:buttons:stopSharing' : 'stop sharing',
  'modalbox:sessionFull:title' : 'Collaboration full',
  'modalbox:sessionFull:text' : 'Unfortunately the collaboration session you are trying to access is full',
  'modalbox:leaveSession:title' : 'Leave session',
  'modalbox:leaveSession:buttons:leave' : 'Leave',
  'modalbox:leaveSession:text' : 'Do you want to leave the collaboration session? ',
  'modalbox:endSession:title' : 'End session',
  'modalbox:endSession:buttons:end' : 'End',
  'modalbox:endSession:text' : 'Do you want to end the current collaboration session? Current progress will be automatically saved in your Web Stream.',
  'modalbox:sessionEnded:title' : 'Session Ended',
  'modalbox:sessionEnded:text' : 'Do you want to save copy of the collaboration session in your Web Stream?',
  'modalbox:accessDenied:title' : 'Access Denied',
  'modalbox:accessDenied:buttons:goBack' : 'go back',
  'modalbox:accessDenied:text' : 'Unfortunately, the collaboration session you are trying to access has expired.',
  'modalbox:collaborationStarted:title' : 'Collaboration started',
  'modalbox:collaborationStarted:warn' : 'Switching this browser tab or minimising the browser will end your Ink Collaboration.',
  'modalbox:collaborationStarted:text' : 'Your collaboration session started. You could invite up to 10 participants by giving them the collaboration URL from your browser.',
  'modalbox:collaborationError:title' : 'Collaboration error!',
  'modalbox:collaborationError:buttons:close' : 'close',
  'modalbox:collaborationError:text' : 'The browser you are using is not supported by Ink Collaboration.  This feature works best with Firefox /v55+/, Chrome /v60+/ and Safari /v11+/.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Don\'t show',
  'modalbox:duplicateAndEdit:text' : 'A new extended version of the page will be placed in your Ink Space Web Stream without changing the original',
  'modalbox:saveChanges:title' : 'Save changes',
  'modalbox:saveChanges:text' : 'Do you want to save changes to the document before closing?',
  'modalbox:WebGLError:title' : 'Error Occurred',
  'modalbox:WebGLError:buttons:close' : 'OK',
  'modalbox:WebGLError:text' : 'An error occurred an you need to reload the content using the "Reload" button in message above.',
  'modalbox:collaborationJoinFailed:title': 'Connection couldn\'t be established',
  'modalbox:collaborationJoinFailed:buttons:ok': 'OK',
  'modalbox:collaborationJoinFailed:text': 'The session you are trying to access couldn\'t be accessed. Please try again later.',
  'modalbox:phoneDetection:title': 'use mobile app',
  'modalbox:phoneDetection:btn:open': 'open',
  'modalbox:phoneDetection:btn:no-thanks': 'no thanks',
  'modalbox:phoneDetection:msg': 'We recommend to use our dedicated mobile app on your phone to enjoy a better experience.',
  'modalbox:chooseVectorOrRaster:title' : 'Choose edit mode',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Annotate',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vectorize',
  'modalbox:chooseVectorOrRaster:text' : 'The content you are trying to edit is raster based and in order to keep the original appearance we recommend to annotate over it. Additionally you could also choose to convert it to vector but the result might differ from the original depending on the tools used.',

  'sorter:ascending' : 'Ascending',
  'sorter:date' : 'Date',
  'sorter:descending' : 'Descending',
  'sorter:name' : 'Name',
  'sorter:size' : 'Size',
  'sorter:sortby' : 'sort by',
  'sorter:type' : 'Type',

  'tagContainer:createTag' : 'Create tag',
  'tagContainer:noTags' : 'No tags',
  'tagContainer:noTagsMessage' : 'Once you create tags they will appear here',

  'export:asDoc' : 'as DOC',
  'export:asPsd' : 'as PSD',
  'export:asJpg' : 'as JPG',
  'export:asPdf' : 'as PDF',
  'export:asPng' : 'as PNG',
  'export:asSvg' : 'as SVG',
  'export:asWill' : 'as WILL',
  'export:asPptx' : 'as PowerPoint'
}

export default messages;