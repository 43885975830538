import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactSpinner from 'react-spinjs-fix';
import { SPINNER_SETTINGS } from 'constants/constants';
import Footer from 'common/components/footer/Footer'
import logoImage from '../assets/wacom-logo.svg'
import './AuthWrapper.scss'
import 'common/components/whatIsNew/WhatIsNew.scss';

const russiaMessageKey = 'russia-message-dismissed';

function AuthWrapper(props) {
    const { formatMessage } = useIntl();
    const [isRussiaMessageDismissed, setIsRussiaMessageDismissed] = useState(window.localStore.getItem(russiaMessageKey));
    const { shouldShowLoader, errorsFromApi, child } = props;

    const onDismiss = () => {
        window.localStore.setItem(russiaMessageKey, true)
        setIsRussiaMessageDismissed(true);
    };

    return <div className={'auth-main-container'}>
        {!isRussiaMessageDismissed ? <div className='what-is-new-wrapper'>
            <div className='what-is-new-content'>
                <div className='texts-wrapper'>
                    <div className='main-text'>
                        Due to the current situation and for reasons beyond Wacom's control, it is currently not possible for Wacom to offer online services in Russia.
                        If you are impacted, please visit <a href="http://wacom.ru/support">wacom.ru/support</a> for more information.
                    </div>
                    <div className='main-text'>
                        В связи со сложившейся ситуацией и по причинам, не зависящим от компании Wacom,
                        в настоящее время Wacom не имеет возможности предлагать онлайн-сервисы в России.
                        Если Вас затронули ограничения, посетите страницу <a href="http://wacom.ru/support">wacom.ru/support</a> для получения дополнительной информации.
                    </div>
                    <button onClick={onDismiss}>
                        Dismiss
                    </button>
                </div>
            </div>
        </div> : ''}
        <div className={'auth'}>
            {shouldShowLoader ? <div className={'blur force-show'}></div> : null}
            {shouldShowLoader ? <ReactSpinner config={SPINNER_SETTINGS} /> : null}

            {errorsFromApi.length > 0 ?
                <ul className='form-errors fixed-list'>
                    {errorsFromApi.map(item => <li key={item} className={'error-message'}>
                        {formatMessage({ id: item })}
                    </li>)}
                </ul>
                : ''}
            <div className={'auth-wrapper'}>
                <div className={'logo'}>
                    <img src={logoImage} alt="Wacom" />
                </div>
                {child}
            </div>
            <ul className='form-errors'>
            </ul>
            <Footer />
        </div>
    </div>
}

export default AuthWrapper;