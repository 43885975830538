import React, { useState } from 'react';
import SemanticContext from './SemanticContext';

const SemanticContextProvider = ({ children }) => {
    const [selectedElement, setSelectedElement] = useState(null);

    const values = { 
        selectedElement, setSelectedElement
    };

    return <SemanticContext.Provider value={values}>
        {children}
    </SemanticContext.Provider>
}

export default SemanticContextProvider;