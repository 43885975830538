import React from 'react';
import LazyImage from '../../../common/components/lazyImage/LazyImage';
import { hasContextMenu } from './inkNoteHelper';
import { addBpPaperTypeStyle } from 'common/bambooPaperHelper';
import './DocumentListItem.scss';

function DocumentListItem(props) {
    const { itemData } = props;
    const paperTypeIndex = itemData?.paperTypeIndex ?? itemData?.properties?.paperTypeIndex ?? '';
    const documentHtmlId = `document-item-${itemData.id}-${paperTypeIndex}`;
    let className = '';

    let imageWidth = itemData?.width?.value?.value;
    let imageHeight = itemData?.height?.value?.value;

    if (props.isActive && props.isDocumentContextVisible) {
        className = 'active-document';
    } else if (props.isActive && !props.isDocumentContextVisible) {
        className = 'active';
    }

    const getGroupThumbDoc = () => {
        const id = itemData.id;
        const targetIndex = props.fullGroupList.findIndex(gr => gr.id === id);
        if (targetIndex !== -1) {
            const targetGroup = props.fullGroupList[targetIndex];

            if (targetGroup.documents.length > 0) {
                return targetGroup.documents[targetGroup.documents.length - 1];
            }
        }
        return null;
    };

    const isGroup = !!itemData.isMateGroup;
    const title = itemData.label ?? itemData.title;
    // let isEmptyDoc = true;
    let imageUrl = null;
    if (isGroup) {
        const groupDoc = getGroupThumbDoc();
        imageUrl = groupDoc?.thumbUrl

        imageWidth = groupDoc?.width?.value?.value;
        imageHeight = groupDoc?.height?.value?.value;

        // isEmptyDoc = !imageUrl;
    } else {
        imageUrl = itemData.thumbUrl;
        // isEmptyDoc = !itemData.thumbUrl;
    }

    const documentBody = () => {
        const style = addBpPaperTypeStyle(paperTypeIndex);
        const imageComponent = <LazyImage
            width={imageWidth}
            height={imageHeight}
            setHeightOnLoaded={true}
            src={imageUrl}
            style={style}
            isGroup={isGroup}
            effect={'blur'}
            classNames={isGroup ? 'offset-container' : ''} />;
        return imageComponent;
    };

    const itemKey = itemData.id + Date.parse(itemData.lastModified);

    return <>
        <li key={itemKey} id={documentHtmlId} 
            data-item-id={itemData.id}
            data-item-application-name={itemData.applicationName}
            onClick={props.onClick}
            className={className}
            onContextMenu={(ev) => { props.showContextMenu(ev, itemData) }}>
            <div className={'document-wrapper'}>
                <div className={'document-header'}>
                    <span
                        onClick={(ev) => {
                            ev.stopPropagation();

                            props.toggleMultiSelectMenu(ev, itemData);
                        }}
                        className={props.isActive && !props.isDocumentContextVisible ? 'cycle-box icon approve-cycle-active' : 'cycle-box icon approve-cycle'}
                    />
                </div>
                <div className={'document-body'}>
                    <div className={`thumbnail-wrapper`} >
                        {documentBody()}
                    </div>
                </div>
                <div className={'document-footer'}>
                    <span className={'name'}>
                        {title}
                    </span>
                    {hasContextMenu(itemData) && <span className="hit-area" onClick={(ev) => props.showContextMenu(ev, itemData)}>
                        <span className="icon more-btn">
                        </span>
                    </span>}
                </div>
            </div>
        </li>
    </>
}

export default DocumentListItem;