import React from 'react';
import { useIntl } from 'react-intl';
import TextInputModal from 'common/components/modals/TextInputModal';
import { MIN_INPUT_FIELD_LENGTH, MAX_INPUT_FIELD_LENGTH } from 'constants/constants';

function ChooseNameModal(props) {
    const { formatMessage } = useIntl();

    const buttons = [{
        // onClick: () => props.onSubmit(inputValue),
        title: formatMessage({ id: 'global:proceed' }),
        id: 'proceed',
        isSubmit: true
    }];

    const minValidationLen = MIN_INPUT_FIELD_LENGTH;
    const maxValidationLen = MAX_INPUT_FIELD_LENGTH;

    return <TextInputModal
        minLength={minValidationLen}
        maxLength={maxValidationLen}
        title={formatMessage({ id: 'global:enterName' })}
        buttons={buttons}
        onSubmit={props.onSubmit}
        isSpinnerVisible={props.isSpinnerVisible}
        onDestroy={props.onDestroy}
        errorMessage={formatMessage({ id: 'inputField:notInRange' }, {
            minLength: minValidationLen,
            maxLength: maxValidationLen
        })}
    />
}

export default ChooseNameModal; 