import React from 'react';
import { useIntl } from 'react-intl';
import TextInputModal from 'common/components/modals/TextInputModal';
import { MIN_INPUT_FIELD_LENGTH, MAX_INPUT_FIELD_LENGTH } from 'constants/constants';

function RenameDocument(props) {
    const { id, label } = props.document;
    const { formatMessage } = useIntl();
    const { actions } = props;

    const onSubmitAction = (newName) => {
        actions.home.renameDocument(id, newName);
    }

    const buttons = [{
        title: formatMessage({ id: 'global:rename' }),
        id: 'proceed',
        isSubmit: true
    }];

    const minValidationLen = MIN_INPUT_FIELD_LENGTH;
    const maxValidationLen = MAX_INPUT_FIELD_LENGTH;

    return <TextInputModal title={formatMessage({ id: 'global:rename' })}
        minLength={minValidationLen}
        maxLength={maxValidationLen}
        buttons={buttons}
        initialValue={label}
        onSubmit={onSubmitAction}
        onDestroy={props.onDestroy}
        isSpinnerVisible={props.modalState.isSpinnerVisible}
        errorMessage={formatMessage({ id: 'inputField:notInRange' }, {
            minLength: minValidationLen,
            maxLength: maxValidationLen
        })} />
}

export default RenameDocument;