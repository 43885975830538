import {
	StrokeRenderer2D, InkCanvas2D
} from 'digital-ink';
import InkCanvas from './InkCanvas';

class InkCanvasVector extends InkCanvas {
	constructor(canvas, width, height, onRescale) {
		super(canvas, width, height);

		this.canvasWrapper = canvas.parentElement.parentElement;
		this.onRescale = onRescale;

		this.canvas = InkCanvas2D.createInstance(canvas, width, height);
		this.strokesLayer = this.canvas.createLayer(width, height);
		this.eyeDropperLayer = this.canvas.createLayer(width, height);
		this.imageRendererLayer = this.canvas.createLayer(width, height);

		this.strokeRenderer = new StrokeRenderer2D(this.canvas, { width, height });
	}
}

export default InkCanvasVector;