import React from 'react';

import './BaseSideBarIcon.scss';

function BaseSideBarIcon(props) {
    const { name, Icon, semanticFilter, onClick } = props;

    let classNames = 'semantic-icon-holder';

    if (semanticFilter && semanticFilter.has(name)) {
        classNames += ' active';
    }

    return <Icon
        className={classNames}
        onClick={() => onClick && onClick(name)}
    />
}

export default BaseSideBarIcon;