import {
	PathPointContext, Intersector, Selector, BlendMode,
	SensorChannel, Brush2D
} from 'digital-ink';
import BrushPalette from './BrushPalette';

import ValueTransformer from './ValueTransformer';

let context = new PathPointContext();


let config = {
	tools: {
		/* ******* VECTOR TOOLS ******* */
		pen: {
			brush: BrushPalette.circle,

			dynamics: {
				size: {
					value: {
						min: 1,
						max: 1.8
					},

					velocity: {
						min: 5,
						max: 4000,

						// remap: v => ValueTransformer.reverse(v)
						remap: v => ValueTransformer.sigmoid(v, 0.62, true)
					},

					pressure: {
						min: 0.19,
						max: 0.88
					}
				}
			}
		},

		highlighter: {
			brush: BrushPalette.circle,

			dynamics: {
				size: {
					value: {
						min: 2.04,
						max: 34.52,

						remap: v => ValueTransformer.power(v, 1.18)
					},

					velocity: {
						min: 200,
						max: 6000
					},

					pressure: {
						min: 0.19,
						max: 0.88
					}
				},

				rotation: {
					dependencies: [SensorChannel.Type.ROTATION, SensorChannel.Type.AZIMUTH]
				},

				scaleX: {
					dependencies: [SensorChannel.Type.RADIUS_X, SensorChannel.Type.ALTITUDE],

					value: {
						min: 1,
						max: 3
					}
				},

				scaleY: {
					dependencies: [SensorChannel.Type.RADIUS_Y],

					value: {
						min: 1,
						max: 3
					}
				},

				offsetX: {
					dependencies: [SensorChannel.Type.ALTITUDE],

					value: {
						min: 2,
						max: 5
					}
				}
			},

			statics: {
				alpha: 0.3
			}
		},

		pointer: {
			brush: BrushPalette.circle,

			dynamics: {
				size: {
					value: {
						min: 1,
						max: 3.2
					},

					velocity: {
						min: 5,
						max: 210,

						// remap: v => ValueTransformer.reverse(v)
						remap: v => ValueTransformer.sigmoid(v, 0.62, true)
					},

					pressure: {
						min: 0.19,
						max: 0.88
					}
				}
			}
		},

		eraser: undefined,

		eraserVector: {
			brush: BrushPalette.circle,
			blendMode: BlendMode.DESTINATION_OUT,
			intersector: new Intersector(Intersector.Mode.PARTIAL_STROKE),

			dynamics: {
				size: {
					value: {
						min: 8,
						max: 112
					},

					velocity: {
						min: 720,
						max: 3900
					}
				}
			},

			statics: {
				red: 255,
				green: 255,
				blue: 255,
				alpha: 1
			}
		},

		eraserRaster: {
			brush: BrushPalette.circle,
			blendMode: BlendMode.DESTINATION_OUT,

			dynamics: {
				size: {
					value: {
						min: 8,
						max: 112
					},

					velocity: {
						min: 720,
						max: 3900
					}
				}
			},

			statics: {
				red: 255,
				green: 255,
				blue: 255,
				alpha: 1
			}
		},

		eraserWholeStroke: {
			brush: BrushPalette.basic,
			intersector: new Intersector(Intersector.Mode.WHOLE_STROKE),

			statics: {
				size: 3,
				red: 255,
				green: 255,
				blue: 255,
				alpha: 0.5
			}
		},

		selector: {
			brush: BrushPalette.circle,
			selector: new Selector(Selector.Mode.PARTIAL_STROKE),

			statics: {
				size: 2,
				red: 0,
				green: 151,
				blue: 212,
				alpha: 1
			}
		},

		selectorWholeStroke: {
			brush: BrushPalette.circle,
			selector: new Selector(Selector.Mode.WHOLE_STROKE),

			statics: {
				size: 2,
				red: 0,
				green: 151,
				blue: 212,
				alpha: 1
			}
		}
	},

	pipeline: {
		// movingAverageWindowSize: 15,
		// errorThreshold: 0.15,
		// epsilon: 0.1
	},

	getOptions(sample, toolID, color) {
		let toolConfig = this.tools[toolID];

		context.reset(sample, toolConfig.brush, color, toolConfig.dynamics, toolConfig.statics)

		return {
			strokeRenderer: {
				brush: toolConfig.brush,
				color: context.color,
				blendMode: toolConfig.blendMode || BlendMode.SOURCE_OVER
			},

			inkBuilder: Object.assign({brush: toolConfig.brush}, {
				layout: context.layout,
				pathPointCalculator: context.calculate.bind(context),
				pathPointProps: context.statics,
				concatSegments: (this.isRaster && toolConfig.brush instanceof Brush2D)
			})
		};
	}
};


export default config;