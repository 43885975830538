import queryString from 'query-string';
import { orderBy, flow, property, toLower } from 'lodash'
import {
    initialSelectedDocument, UNAUTHORIZED, STORAGE_API_BASE_URL,
    ROOT_DIR_ID, EXPORT_AS_FILE, ZIP_OPC_FILES, HWR_OPC_FILE, VIDEO_PATH, VIDEO_SERVICE_URL,
    BACKEND_API_BASE_URL, PUBLIC_URL, COLLABORATION_INIT_ELEMENT_ID,
    COLLABORATION_BASE_URL, INK_NOTE_URL_BASE, INK_NOTE_FILE_STREAM, BAMBOO_STREAM,
    WILL2_EXPORT_SERVICES_URL, LS_KEY_PROFILE, LS_KEY_PROFILE_OLD, LEGACY_API_BASE_URL,
    LOCAL_STORAGE
} from 'constants/constants';
import { getGroupProps } from 'common/contextMenuHelpers/ContextMenuHelpers';
import { toast, cssTransition } from 'react-toastify';
import fileDownload from 'js-file-download';

export const createToast = (store, toastText) => {
    if (typeof store === 'string') {
        toastText = store;
    }

    if (!toastText) return;

    const slideAnimation = cssTransition({
        enter: 'fadeIn',
        exit: 'fadeOut',
        // default to 750ms, can be omitted
        duration: 800
    });

    toast(toastText, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        closeButton: false,
        transition: slideAnimation
    })
}

const mapDocument = (el) => {
    const mapped = { ...el };
    if (mapped.documentId) {
        mapped.id = mapped.documentId;
        delete mapped.documentId;
    }
    if (!mapped.label) {
        mapped.label = `WCM ${mapped.id.slice(-5)}`;
    }

    if (!mapped.created && mapped.modified) {
        mapped.created = mapped.modified;
    }

    if (mapped.created) {
        mapped.createdEpoch = Date.parse(mapped.created);
    }

    return mapped;
}

const mapGroup = (el) => {
    const mapped = { ...el };

    if (mapped.lastModified) {
        mapped.lastModifiedEpoch = Date.parse(mapped.lastModified);
    }

    return mapped;
}

const changeElementLabel = (element, targetId, newName) => {
    const mappedGroup = { ...element };
    if (mappedGroup.id === targetId) {
        mappedGroup.label = newName;
    }
    return mappedGroup;
}

function getSelectedDocTypes(docs, selectedItems) {
    const selectedDocsInfo = {};

    for (const doc of docs) {
        const selectedItemFilter = selectedItems.filter(selected => selected.id === doc.id);
        if (selectedItemFilter.length > 0) {
            selectedDocsInfo[doc.applicationName] = true;
        }
    }

    return selectedDocsInfo;
}

const selectPreviewIfNecessary = (store) => {
    if (store.state.preview.pendingDocumentId) {
        selectPreviewDoc(store, store.state.preview.pendingDocumentId);
    }
}

const getLodashSortParam = (order) => {
    return order === 'ascending'
        ? 'asc'
        : 'desc'; // TODO sort.order should be shortened to asc/desc
}

const sortDocsAndGroups = (documentsWithDirs, sort = {}) => {
    const order = getLodashSortParam(sort.order);

    if (sort.field === 'date') {
        documentsWithDirs.documents = orderBy(documentsWithDirs.documents, ['createdEpoch'], [order]);
        documentsWithDirs.groups = orderBy(documentsWithDirs.groups, ['lastModifiedEpoch'], [order]);
    }

    if (sort.field === 'name') {
        documentsWithDirs.documents = orderBy(documentsWithDirs.documents, [flow(property('label'), toLower)], [order]);
        documentsWithDirs.groups = orderBy(documentsWithDirs.groups, [flow(property('label'), toLower)], [order]);
    }

    return documentsWithDirs;
}

const manageBambooPaperDocuments = (currentDirectory, documents) => {
    const dirProperties = currentDirectory?.properties;
    const pageOrder = dirProperties?.pageOrder;
    const paperTypeIndex = dirProperties?.paperTypeIndex;

    if (pageOrder || paperTypeIndex) {
        const activeDocumentIds = documents.map(doc => doc.id);
        const parsedPageOrder = pageOrder?.split(',')?.filter(id => activeDocumentIds.includes(id));

        for (let document of documents) {
            if (parsedPageOrder) {
                const indexInFolder = parsedPageOrder.indexOf(document.id);

                if (indexInFolder !== -1) {
                    // Pads the document name for ordering reasons
                    // Otherwise the names of BP pages cannot be ordered by name
                    document.label = (indexInFolder + 1).toString().padStart(3, '0');
                }
            }

            document.paperTypeIndex = paperTypeIndex;
        }
    }
}

const updateDocsAndDirs = (store, documentsWithDirectories) => {
    const groups = documentsWithDirectories.directories.map(mapGroup);
    const mappedDocuments = documentsWithDirectories.documents.map(mapDocument);
    const currentDirInfo = { ...documentsWithDirectories };
    delete currentDirInfo.directories;
    delete currentDirInfo.documents;

    manageBambooPaperDocuments(currentDirInfo, mappedDocuments);

    const filteredDocsAndDirs = sortDocsAndGroups({ documents: mappedDocuments, groups: groups }, store.state.sort);

    store.setState({ documents: filteredDocsAndDirs.documents, groups: filteredDocsAndDirs.groups, isSpinnerVisible: false, isFetchingDocs: false, currentDirInfo, initedDocuments: true });
}

export const setFilter = (store, params) => {
    // Date field don't match the date field
    if (params.field === 'name') {
        params.docFiled = params.groupField = 'label';
    } else if (params.field === 'date') {
        params.docFiled = 'created';
        params.groupField = 'lastModified';
    }

    const state = store.state;
    const newSortParams = { ...state.sort, ...params };

    window.localStore.setItem(LOCAL_STORAGE.SORT, JSON.stringify(newSortParams));

    store.setState({ sort: newSortParams, documents: { ...state, isFetchingDocs: true } });
    const sorted = sortDocsAndGroups({ documents: state.documents, groups: state.groups }, newSortParams);
    store.setState({ documents: sorted.documents, groups: sorted.groups, isSpinnerVisible: false, isFetchingDocs: false });
}

let documentFetchAbortController = null;

export const getDocuments = async (store, queryParams, indicateIsFetching = true) => {
    const dirId = queryParams.dirId ?? ROOT_DIR_ID;

    if (indicateIsFetching) {
        store.setState({ isSpinnerVisible: true, isFetchingDocs: true });
    }

    // InkNoteFiles are pre-fetched
    if (queryParams.applicationName === INK_NOTE_FILE_STREAM) {
        store.setState({
            documents: store.state.inkNoteDocuments,
            groups: [],
            isSpinnerVisible: false,
            isFetchingDocs: false,
            initedDocuments: true
        });

        return;
    }

    const isFilterRequest = queryParams.term || queryParams.tagId;
    // const isPlainTextSearch = !!queryParams.term;

    let requestUrl = STORAGE_API_BASE_URL + `/directory/${dirId}?applicationName=${queryParams.applicationName}`;

    if (isFilterRequest) {
        const params = {
            query: queryParams.term,
            tagId: queryParams.tagId
        };

        // if (!isPlainTextSearch) {
        //     params.applicationName = queryParams.applicationName;
        // }

        requestUrl = `${BACKEND_API_BASE_URL}/full-directory?${queryString.stringify(params)}`;
    }

    try {
        if (documentFetchAbortController) documentFetchAbortController.abort();
        documentFetchAbortController = new AbortController();

        const directoryContentResult = await fetch(requestUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            },
            signal: documentFetchAbortController.signal
        });

        if (directoryContentResult.ok) {
            const initedDocuments = store.state.initedDocuments;
            const documentsWithDirectories = await directoryContentResult.json();

            // Set the applicationName for later use
            documentsWithDirectories.directories.forEach(item => item.applicationName = item.applicationName ?? queryParams.applicationName);
            updateDocsAndDirs(store, documentsWithDirectories);

            if (!initedDocuments) {
                selectPreviewIfNecessary(store);
            }
        } else {
            let errorsList = [];
            if (directoryContentResult.status === 401) {
                errorsList.push(UNAUTHORIZED);
            } else {
                errorsList = await directoryContentResult.json();
            }
        }
    } catch (err) {
        if (err.name !== 'AbortError') {
            console.log(err);
            createToast(store.state.localization.genericErrorMessage);
        }
    }
}

const manageBambooPaperOrderPropertyOnDelete = async (store, bpDoc) => {
    if (!bpDoc?.length) return;

    const groupsWithChanges = {};
    const updatePromises = [];
    const bpDocsIds = bpDoc.map(doc => doc.id)

    const groupsResponse = await fetchGroupsInternal(store, { applicationName: BAMBOO_STREAM });
    const groups = await groupsResponse.json();

    groups.forEach(gr => {
        if (gr?.properties?.pageOrder) {
            const pageOrder = gr.properties.pageOrder.split(',');
            const filteredPageOrder = pageOrder.filter(id => !bpDocsIds.includes(id));

            if (pageOrder.length !== filteredPageOrder.length) {
                groupsWithChanges[gr.id] = {
                    name: gr.label,
                    properties: {
                        ...gr.properties,
                        pageOrder: filteredPageOrder.join(',')
                    }
                };
            }
        }
    });

    for (let key in groupsWithChanges) {
        updatePromises.push(fetch(`${STORAGE_API_BASE_URL}/directory/${key}`, {
            method: 'PATCH',
            body: JSON.stringify(groupsWithChanges[key]),
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            }
        }))
    }

    if (updatePromises.length > 0) {
        await Promise.all(updatePromises);
    }
}

export const deleteDocuments = async (store, docIds) => {
    store.setState({ isSpinnerVisible: true });

    try {
        const documentDelResult = await deleteBulkDocumentsFetch(docIds, store.state.auth.mainToken);

        if (documentDelResult.ok) {
            const bambooPaperDocuments = store.state.documents.filter(doc => docIds.includes(doc.id) && doc.applicationName === BAMBOO_STREAM);
            // Groups for BP documents handle page id
            // We have to remove the pageIds of the deleted documents
            await manageBambooPaperOrderPropertyOnDelete(store, bambooPaperDocuments);
            const filteredDocs = store.state.documents.filter(doc => !docIds.includes(doc.id));
            store.setState({ documents: filteredDocs });
        }
    } catch (err) {
        console.log(err);
        createToast(store.state.localization.genericErrorMessage);
    } finally {
        store.setState({ isSpinnerVisible: false });
        store.actions.modals.hideModal();
        store.actions.home.hideDocumentPreview();
    }
}

let tagFetchAbortController = null;

export const getTags = async (store) => {
    store.setState({ isSpinnerVisible: true });
    try {
        if (tagFetchAbortController) tagFetchAbortController.abort();
        tagFetchAbortController = new AbortController()

        const tagsResult = await fetch(`${STORAGE_API_BASE_URL}/tag`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            },
            signal: tagFetchAbortController.signal
        });

        if (tagsResult.ok) {
            let tags = await tagsResult.json();

            tags = tags.map((tag) => {
                tag.length = tag.documents.length;
                return tag;
            });

            tags = orderBy(tags, ['length', 'label'], 'desc')
            store.setState({ tags: tags, isSpinnerVisible: false });
        } else {
            let errorsList = [];
            if (tagsResult.status === 401) {
                errorsList.push(UNAUTHORIZED);
            } else {
                errorsList = await tagsResult.json();
            }
        }
    } catch (err) {
        if (err.name !== 'AbortError') {
            console.log(err);
        }
        // createToast(store.state.localization.genericErrorMessage);
    }
}

let groupFetchAbortController = null;

const fetchGroupsInternal = (store, queryParams) => {
    if (groupFetchAbortController) groupFetchAbortController.abort();
    groupFetchAbortController = new AbortController();

    return fetch(`${STORAGE_API_BASE_URL}/directory/root/full?flat=true&${queryString.stringify(queryParams)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${store.state.auth.mainToken}`
        },
        signal: groupFetchAbortController.signal
    });
}

export const getGroups = async (store, queryParams) => {
    store.setState({ isSpinnerVisible: true });

    try {
        const groupsResult = await fetchGroupsInternal(store, queryParams);

        if (groupsResult.ok) {
            const groups = await groupsResult.json();
            const filteredGroups = groups.filter(gr => gr.id !== 'root');
            const mappedGroups = filteredGroups.map(gr => {
                gr.documents = gr.documents.map(mapDocument);
                gr.documentIds = gr.documents.map(doc => doc.id);
                return gr;
            });
            store.setState({ fullGroupList: mappedGroups, isSpinnerVisible: false });
        } else {
            let errorsList = [];
            if (groupsResult.status === 401) {
                errorsList.push(UNAUTHORIZED);
            } else {
                errorsList = await groupsResult.json();
            }
        }
    } catch (err) {
        if (err.name !== 'AbortError') {
            console.log(err);
        }
    }
}

export const selectPreviewDoc = async (store, documentId, callback) => {
    // Used when the app is opened with preview url
    if (!store.state.initedDocuments) {
        store.setState({ preview: { ...store.state.preview, pendingDocumentId: documentId } });
        return;
    }

    const { documents } = store.state;
    const targetIndex = documents.findIndex(doc => doc.id === documentId);
    let selectedDocument = store.state.documents[targetIndex];
    let nextDoc = null;
    let prevDoc = null;

    if (!selectedDocument) {
        selectedDocument = initialSelectedDocument;
        if (documents && documents.length > 1) {
            nextDoc = documents[documents.length - 1].id;
            prevDoc = documents[0].id;
        }
    } else {
        if (documents && documents.length > 1) {
            nextDoc = documents[(targetIndex + 1) % documents.length].id;
            prevDoc = documents[(targetIndex + documents.length - 1) % documents.length].id;
        }
    }

    const newPreviewState = { document: selectedDocument, next: nextDoc, prev: prevDoc, pendingDocumentId: null };
    store.setState({ previewItemIndex: targetIndex, isPreviewVisible: true, preview: newPreviewState });

    if (selectedDocument.pageIds.length > 1) {
        await fetchMultiPagePreviews(store, documentId);
    } else if (selectedDocument?.pageIds?.length <= 1 && store.state.preview?.pagePreviews?.length > 1) {
        await cleanMultiPagePreviews(store);
    }

    if (callback && typeof callback === 'function') {
        callback();
    }
}

export const hideDocumentPreview = (store) => {
    store.setState({
        preview: {
            document: { ...initialSelectedDocument },
            next: null,
            prev: null,
            pendingDocumentId: null,
            currentPageId: null
        }
    });
}

export const setCurrentPageId = (store, pageId) => {
    store.setState({
        preview: {
            ...store.state.preview,
            currentPageId: pageId
        }
    });
}

let multiPagePreviewsAbortContext = null;

export const cancelMultiPagePreviewFetch = () => {
    if (multiPagePreviewsAbortContext && !multiPagePreviewsAbortContext.signal.aborted) {
        multiPagePreviewsAbortContext.abort();
    }
}

export const fetchMultiPagePreviews = async (store, documentId) => {
    cancelMultiPagePreviewFetch();
    multiPagePreviewsAbortContext = new AbortController();

    try {
        const documentWithPreviewsResponse = await fetch(`${STORAGE_API_BASE_URL}/document/${documentId}/page-previews`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            },
            signal: multiPagePreviewsAbortContext.signal
        });

        if (documentWithPreviewsResponse.ok) {
            const previews = await documentWithPreviewsResponse.json();
            store.setState({ preview: { ...store.state.preview, pagePreviews: previews } });
        }
    } catch (err) {
        console.log(err);
    }
}

export const cleanMultiPagePreviews = async (store) => {
    store.setState({ preview: { ...store.state.preview, pagePreviews: [] } });
}

export const hidePreviewPopup = async (store) => {
    store.setState({ previewItemIndex: 0, isPreviewVisible: false });
}

export const showContextMenu = async (store, selectedItem, position, isSingleDoc = true) => {
    let selectedDocuments = store.state.contextMenu.selectedItems;
    let selectedGroups = store.state.contextMenu.selectedGroups;
    const documentIds = new Set(store.state.contextMenu.selectedItems.map(doc => doc.id));
    const groupIds = new Set(store.state.contextMenu.selectedGroups.map(gr => gr.id));

    if (selectedItem.isMateGroup) {
        if (!groupIds.has(selectedItem.id)) {
            groupIds.add(selectedItem.id);
            selectedGroups.push(selectedItem);
        } else {
            if (!isSingleDoc) {
                groupIds.delete(selectedItem.id);
                selectedGroups = selectedGroups.filter(el => el.id !== selectedItem.id);
            }
        }
    } else {
        if (!documentIds.has(selectedItem.id)) {
            documentIds.add(selectedItem.id);
            selectedDocuments.push(selectedItem);
        } else {
            if (!isSingleDoc) {
                documentIds.delete(selectedItem.id);
                selectedDocuments = selectedDocuments.filter(el => el.id !== selectedItem.id);
            }
        }
    }

    const isMultiSelect = !isSingleDoc;
    const isOverlayActive = !isMultiSelect;
    const selectedDocTypes = getSelectedDocTypes(store.state.documents, selectedDocuments);
    const groupProps = getGroupProps(store.state.contextMenu.selectedGroups, store.state.groups);


    store.setState({
        isOverlayActive,
        contextMenu: {
            isDocumentContextVisible: !isMultiSelect,
            isControlPanelVisible: isMultiSelect,
            selectedItems: selectedDocuments,
            position,
            isSingleDoc: isSingleDoc,
            selectedIds: documentIds,
            selectedGroups,
            selectedGroupIds: groupIds,
            groupProperties: groupProps,
            selectedDocTypes
        }
    });
}

export const hideContextMenu = (store) => {
    store.setState({
        isOverlayActive: false,
        contextMenu: {
            ...store.state.contextMenu,
            isVisible: false,
            selectedItems: [],
            selectedGroups: [],
            selectedIds: new Set(),
            selectedGroupIds: new Set(),
            isControlPanelVisible: false,
            isDocumentContextVisible: false
        }
    });
}

export const hideSingleDocumentContextMenu = (store) => {
    if (store.state.contextMenu.isDocumentContextVisible) {
        store.setState({
            isOverlayActive: false,
            contextMenu: {
                ...store.state.contextMenu,
                isDocumentContextVisible: false
            }
        });
    }
}

//////// Overlay \\\\\\\
export const showOverlay = async (store) => {
    store.setState({ isOverlayActive: true });
}

export const hideOverlay = async (store) => {
    store.setState({ isOverlayActive: false });
}

//////// Sorter \\\\\\\\
export const setSortOrder = async (store, order) => {
    store.setState({ isOverlayActive: false, sort: { ...store.state.sort, order } });
}

export const setSortField = async (store, field) => {
    store.setState({ isOverlayActive: false, sort: { ...store.state.sort, field } });
}


////// Tag section \\\\\\\
export const createTag = async (store, newTagName) => {
    store.setState({ isSpinnerVisible: true });

    try {
        const tagCreateResult = await fetch(`${STORAGE_API_BASE_URL}/tag`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: newTagName })
        });

        if (tagCreateResult.ok) {
            const tagResponseBody = await tagCreateResult.json();
            store.state.tags.push({
                id: tagResponseBody.id,
                label: newTagName,
                documents: [],
                length: 0
            });
        }

    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }

    store.setState({ isSpinnerVisible: false });
    store.actions.modals.hideModal();
}

export const deleteTag = async (store, tagId) => {
    store.setState({ isSpinnerVisible: true });

    try {
        const tagDeleteResult = await fetch(STORAGE_API_BASE_URL + `/tag/${tagId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            }
        });

        if (tagDeleteResult.ok) {
            const filteredTags = store.state.tags.filter(tag => tag.id !== tagId);
            store.setState({ tags: filteredTags });
        };
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }

    store.setState({ isSpinnerVisible: false });
    store.actions.modals.hideModal();
}

export const renameTag = async (store, tagId, newTagName) => {
    try {
        const tagRenameResult = await fetch(STORAGE_API_BASE_URL + `/tag/${tagId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: newTagName })
        });

        if (tagRenameResult.ok) {
            const filteredTags = store.state.tags.map(tag => changeElementLabel(tag, tagId, newTagName));
            store.setState({ tags: filteredTags });
        };
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }

    store.actions.modals.hideModal();
}

const addRemoveDocsToTag = async (store, tagId, selectedItems, isRemove) => {
    let oldTagsState = store.state.tags;
    try {
        const selectedIds = selectedItems.map(it => it.id);

        const filteredTags = store.state.tags.map(tag => {
            const mappedTag = { ...tag };
            if (mappedTag.id === tagId) {
                if (isRemove) {
                    mappedTag.documents = mappedTag.documents.filter(d => !selectedIds.includes(d));
                    mappedTag.length = mappedTag.documents.length;
                }
                else {
                    mappedTag.documents = [...mappedTag.documents, ...selectedIds];
                    mappedTag.length = mappedTag.documents.length;
                }
            }
            return mappedTag;
        });

        store.setState({ tags: filteredTags });

        const addRemoveDocsToTagResult = await fetch(STORAGE_API_BASE_URL + `/tag/${tagId}/documents`, {
            method: isRemove ? 'DELETE' : 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectedIds)
        });

        if (!addRemoveDocsToTagResult.ok) {
            throw addRemoveDocsToTagResult.error;
        };
    } catch (er) {
        store.setState({ tags: oldTagsState });
        createToast(store.state.localization.genericErrorMessage);
    }
}

export const addDocumentsToTag = async (store, tagId, selectedItems) => {
    return addRemoveDocsToTag(store, tagId, selectedItems, false);
}

export const removeDocumentsFromTag = async (store, tagId, selectedItems) => {
    return addRemoveDocsToTag(store, tagId, selectedItems, true);
}


////// Group section \\\\\\\
export const createGroup = async (store, groupName, toastMessage) => {
    const order = getLodashSortParam(store.state.sort.order);

    try {
        const queryParams = { applicationName: store.state.location.query.applicationName };
        const groupCreateResult = await fetch(`${STORAGE_API_BASE_URL}/directory?${queryString.stringify(queryParams)}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: groupName, parentId: 'root' })
        });

        if (groupCreateResult.ok) {
            const groupResponseBody = await groupCreateResult.json();
            const newGroup = {
                id: groupResponseBody.id,
                label: groupName,
                preview: "previewPlaceholder",
                lastModified: new Date().toISOString(),
                applicationName: queryParams.applicationName
            };
            const newGroupFull = { ...newGroup, documents: [], documentIds: [] };
            const mergedGroups = [...store.state.groups, newGroup];
            const filteredGroups = orderBy(mergedGroups, [store.state.sort.groupField], [order])
            store.setState({ groups: filteredGroups, fullGroupList: [...store.state.fullGroupList, newGroupFull] });
            createToast(toastMessage);
        }
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }

    store.actions.modals.hideModal();
}

const deleteBulkDocumentsFetch = (documentIds, token) => {
    return fetch(STORAGE_API_BASE_URL + '/document', {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(documentIds)
    });
}

const deleteGroupFetch = (groupId, token, applicationName) => {
    return fetch(STORAGE_API_BASE_URL + `/directory/${groupId}?applicationName=${applicationName}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

const getFullGroupById = (id, fullGroupList) => {
    const index = fullGroupList.findIndex(el => el.id === id);
    if (index !== -1) {
        return fullGroupList[index];
    }
    return null;
}

export const removeGroups = async (store, groups, deleteDocuments) => {
    const token = store.state.auth.mainToken;
    try {
        const uniqueDocs = new Set();
        const uniqueGroups = new Set();

        // Manage Documents
        if (deleteDocuments) {
            groups.forEach(group => {
                uniqueGroups.add(group.id);
                const fullGroupInfo = getFullGroupById(group.id, store.state.fullGroupList);
                fullGroupInfo?.documentIds.forEach(docId => uniqueDocs.add(docId))
            });
            if (uniqueDocs.size > 0) {
                await deleteBulkDocumentsFetch([...uniqueDocs], token);
            }
        } else {
            const promises = [];
            groups.forEach(group => {
                uniqueGroups.add(group.id);
                const fullGroupInfo = getFullGroupById(group.id, store.state.fullGroupList);
                fullGroupInfo.documentIds.forEach(docId => uniqueDocs.add(docId))
                if (fullGroupInfo.documentIds.length > 0) {

                    promises.push(addRemoveDocumentsToGroupFetchRequest(group.id, fullGroupInfo.documentIds, group.applicationName, true, token));
                }
            });
            await Promise.all(promises);
        }

        // Manage groups
        const promises = [];
        groups.forEach(group => {
            promises.push(deleteGroupFetch(group.id, token, 'wacom_notes'));
        });
        await Promise.all(promises);

        const filteredGroups = store.state.groups.filter(gr => !uniqueGroups.has(gr.id));
        const filteredAllGroups = store.state.fullGroupList.filter(gr => !uniqueGroups.has(gr.id));
        const filteredDocs = store.state.documents.filter(doc => !uniqueDocs.has(doc.id));

        // store.actions.home.resetContextMenuSelection();
        store.actions.modals.hideModal();
        store.setState({ groups: filteredGroups, documents: filteredDocs, fullGroupList: filteredAllGroups });

    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }

}

export const renameGroup = async (store, groupId, newName, applicationName) => {

    try {
        const groupRenameResult = await fetch(STORAGE_API_BASE_URL + `/directory/${groupId}?applicationName=${applicationName}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: newName })
        });

        if (groupRenameResult.ok) {
            const filteredGroups = store.state.groups.map(gr => changeElementLabel(gr, groupId, newName));
            const filteredAllGroups = store.state.fullGroupList.map(gr => changeElementLabel(gr, groupId, newName));
            store.actions.modals.hideModal();
            store.setState({ groups: filteredGroups, fullGroupList: filteredAllGroups });
        }
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }
}

const addRemoveDocumentsToGroupFetchRequest = (groupId, selectedIds, applicationName, isRemove, token) => {
    return fetch(STORAGE_API_BASE_URL + `/directory/${groupId}/documents?applicationName=${applicationName}`, {
        method: isRemove ? 'DELETE' : 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedIds)
    });
}

const addRemoveDocumentsToGroup = async (store, groupId, selectedItems, applicationName, isRemove, toastMessage) => {
    const oldGroupsState = store.state.fullGroupList;

    try {
        const selectedIds = selectedItems.map(it => it.id);
        const fullGroupUpdated = store.state.fullGroupList.map(gr => {
            const mapped = { ...gr };
            if (mapped.id === groupId) {
                mapped.lastModified = Date.now();
                if (isRemove) {
                    mapped.documentIds = mapped.documentIds.filter(docId => !selectedIds.includes(docId));
                    mapped.documents = mapped.documents.filter(doc => !selectedIds.includes(doc.id));
                } else {
                    mapped.documentIds = [...mapped.documentIds, ...selectedIds];
                    selectedIds.forEach(id => {
                        const indexOfDoc = store.state.documents.findIndex(doc => doc.id === id);
                        if (indexOfDoc !== -1) {
                            mapped.documents.push(store.state.documents[indexOfDoc]);
                        }
                    });
                }
            }
            return mapped;
        });

        store.setState({ fullGroupList: fullGroupUpdated });

        const addRemoveDocsToGroupResult = await addRemoveDocumentsToGroupFetchRequest(groupId, selectedIds, applicationName, isRemove, store.state.auth.mainToken);
        if (addRemoveDocsToGroupResult.ok) {
            createToast(toastMessage);
        } else {
            throw addRemoveDocsToGroupResult.error
        }
    } catch (err) {
        store.setState({ fullGroupList: oldGroupsState });
        createToast(store.state.localization.genericErrorMessage);
    }
}

export const addDocsToGroup = async (store, groupId, selectedItems, applicationName, toastMessage) => {
    return addRemoveDocumentsToGroup(store, groupId, selectedItems, applicationName, false, toastMessage);
}

export const removeDocsFromGroup = async (store, groupId, selectedItems, applicationName, toastMessage) => {
    return addRemoveDocumentsToGroup(store, groupId, selectedItems, applicationName, true, toastMessage);
}

export const requestVideoEncoding = async (store, document) => {
    var newWindow = window.open(`${PUBLIC_URL}${VIDEO_PATH}/loading`, '_blank');
    newWindow.focus();

    const response = await fetch(`${VIDEO_SERVICE_URL}/by-url/wacom-doc`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${store.state.auth.mainToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            fileLocation: document.temporaryOPCLink,
            documentId: document.id
        })
    });

    if (response.ok) {
        const id = await response.json();
        newWindow.location.replace(`${PUBLIC_URL}${VIDEO_PATH}/${id}`);
    } else {
        newWindow.location.replace(`${PUBLIC_URL}${VIDEO_PATH}/error`);
    }
}


export const exportDocuments = async (store, selectedItems, exportType, onFailureMessage) => {
    store.setState({ isExportingDocuments: true });
    const exportTargets = [];

    if (!Array.isArray(selectedItems)) {
        selectedItems = [selectedItems];
    }

    let exportName = 'InkSpaceExport';
    exportType = exportType.toLowerCase();

    const { fullGroupList } = store.state;

    // Get urls for documents in the group
    selectedItems.forEach(item => {
        if (item.isMateGroup) {
            const index = fullGroupList.findIndex(el => el.id === item.id);

            if (index !== -1) {
                const group = fullGroupList[index];

                group.documents.forEach(doc => exportTargets.push({
                    id: doc.id,
                    url: doc.temporaryOPCLink,
                    paperType: doc.paperTypeIndex
                }));
            }
        } else if (item.temporaryOPCLink) {
            exportTargets.push({
                id: item.id,
                url: item.temporaryOPCLink,
                paperType: item.paperTypeIndex
            });
        }
    });

    const hasBpDocument = exportTargets.filter(x => x.paperType).length > 0;

    let exportBodyPayload = hasBpDocument ? exportTargets : exportTargets.map(item => item.url);

    if (exportTargets.length === 1 && selectedItems[0].title) {
        exportName = selectedItems[0].title;
    }

    let exportAddress;

    if (exportType === 'video') {
        store.setState({ isExportingDocuments: false });
        store.actions.modals.hideModal();
        requestVideoEncoding(store, selectedItems[0]);
        return;
    } else if (exportType === 'doc') {
        exportAddress = `${HWR_OPC_FILE}?format=DOC`;
    } else if (exportType === 'will') {
        exportAddress = ZIP_OPC_FILES;

        exportType = 'wdf';
    } else {
        const baseAddress = hasBpDocument ? WILL2_EXPORT_SERVICES_URL : EXPORT_AS_FILE;
        exportAddress = `${baseAddress}?format=${exportType}`;
    }

    try {
        // If the export files is a single wdf just download it from the temporary url
        // without http request to export service
        if (exportBodyPayload?.length === 1 && exportType === 'wdf') {
            const response = await fetch(exportBodyPayload[0]);
            const blob = await response.blob();
            fileDownload(blob, `${exportName}.${exportType}`);
        } else {
            const response = await fetch(exportAddress, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${store.state.auth.mainToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(exportBodyPayload)
            });

            if (response.ok) {
                const responseContentType = response.headers.get('Content-Type');
                let fileExtension = responseContentType.indexOf('zip') === -1 ? exportType : 'zip';

                let filename = `${exportName}.${fileExtension}`;
                if (exportType === 'doc') {
                    filename += 'x';
                }
                const blob = await response.blob();
                fileDownload(blob, filename)
            } else {
                throw response.statusText;
            }
        }
    } catch (error) {
        createToast(onFailureMessage);
    }
    finally {
        store.setState({ isExportingDocuments: false });
    }

    // store.actions.home.resetContextMenuSelection();
    store.actions.modals.hideModal();
}

export const clearRecognizedText = (store, isFetching = false) => {
    store.setState({ hwr: { recognizedText: '', isFetching } });
}


let inkToTextAbortController = null;

export const recognizeText = async (store, selectedItem, locale, onFailureMessage) => {
    if (inkToTextAbortController) {
        inkToTextAbortController.abort();
    }

    inkToTextAbortController = new AbortController();

    store.actions.home.clearRecognizedText(true);

    try {
        const response = await fetch(`${HWR_OPC_FILE}?locale=${locale}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            signal: inkToTextAbortController.signal,
            body: JSON.stringify([selectedItem.temporaryOPCLink])
        });

        if (response.ok) {
            const recognizedText = await response.text();
            store.setState({ hwr: { recognizedText, isFetching: false } });
        } else {
            throw response.statusText;
        }
    } catch (error) {
        store.actions.home.clearRecognizedText(false);
        createToast(onFailureMessage);
    }
}


/////// Document filtering \\\\\\\\\\
let filterDocsAbortController = null;

export const fetchFilteredDocuments = async (store, query) => {
    if (filterDocsAbortController) {
        filterDocsAbortController.abort();
    }

    filterDocsAbortController = new AbortController();

    const response = await fetch(`${BACKEND_API_BASE_URL}/full-directory?query=${query}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${store.state.auth.mainToken}`
        },
        signal: filterDocsAbortController.signal
    });

    if (response.ok) {
        const filteredDirectory = await response.json();
        console.log(filteredDirectory);
        updateDocsAndDirs(store, filteredDirectory);
    }
}

export const cancelFilterDocuments = async (store) => {
    if (filterDocsAbortController) {
        filterDocsAbortController.abort();
    }
}


export const renameDocument = async (store, documentId, newName) => {
    let targetDocument, oldName;

    try {

        const indexOfDocument = store.state.documents.findIndex(doc => doc.id === documentId);
        targetDocument = store.state.documents[indexOfDocument];
        oldName = targetDocument.label;
        targetDocument.label = newName;
        store.setState({ ...store.state.documents });

        const renameRequest = fetch(`${BACKEND_API_BASE_URL}/document/${documentId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: newName
            })
        });

        store.actions.modals.hideModal();
        const renameResult = await renameRequest;
        if (!renameResult.ok) {
            throw new Error((await renameRequest).statusText);
        }
    } catch (er) {
        console.log(er);
        if (targetDocument) {
            targetDocument.label = oldName;
            store.setState({ ...store.state.documents });
        }
    }
}

export const getAuthTokens = () => {
    const tokens = {
        old: window.localStore.getItem(LS_KEY_PROFILE_OLD),
        new: window.localStore.getItem(LS_KEY_PROFILE)
    }

    const stringiFied = JSON.stringify(tokens);
    return btoa(stringiFied);
}

//authTokensIframe sha => 79e9bb533273803c1a9f75d98b6723e33a54ac7724753dbaec7581993b9a05d8
export const tokensQueryParameterKey = '_79e9bb533273803c1a9f75d98b6723e33a54ac7724753dbaec7581993b9a05d8';

export const createCollaboration = (store, applicationName, id, title, version, url, isWill2EditMode) => {
    let requestUrl = `${COLLABORATION_BASE_URL}/external-collaboration/${applicationName}`;

    store.actions.modals.hideModal();
    store.actions.home.hideContextMenu();

    const params = {
        isEmbedded: true,
        owner: store.state.auth.profile.extSub,
        [tokensQueryParameterKey]: getAuthTokens()
    };

    if (id) {
        params.id = id;
    }
    if (version) {
        params.version = version;
    }
    if (title) {
        params.title = encodeURI(title);
    }
    if (url) {
        params.url = btoa(url);
    }
    if (isWill2EditMode) {
        params.isWill2EditMode = isWill2EditMode;
    }

    requestUrl += `?${queryString.stringify(params)}`;

    var ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', requestUrl);
    ifrm.id = COLLABORATION_INIT_ELEMENT_ID;
    ifrm.width = 0;
    ifrm.height = 0;
    ifrm.style.display = 'none'
    document.body.appendChild(ifrm);
}

export const startCollaboration = (store, doc, isWill2EditMode) => {
    // if the id is omitted a new document is created
    if (doc.duplicate) {
        delete doc.id;
    }

    createCollaboration(store, 'collaboration', doc.id, doc.title, doc.versionNumber, doc.will2JsonUrl, isWill2EditMode);
}

const fetchStreamsFromOldCloud = async (oldToken) => {
    try {
        if (!oldToken || !LEGACY_API_BASE_URL) return [];

        const response = await fetch(`${LEGACY_API_BASE_URL}/streams`, {
            headers: {
                'Authorization': `Bearer ${oldToken}`
            }
        });
        const streamsJson = await response.json();
        const streamNames = streamsJson?.map(str => str.name);

        return streamNames;
    } catch (err) {
        console.log(err);
    }
}

const activateStreamInNewCloud = async (applicationName, newToken) => {
    try {
        await fetch(`${STORAGE_API_BASE_URL}/applicationNames/${applicationName}`, {
            headers: {
                'Authorization': `Bearer ${newToken}`
            }
        });
    } catch (err) {
        console.log(err);
    }
}

const migrateMontBlancToNewCloud = async (newApplicationNames, oldStreams, newToken) => {
    const montBlancAppName = 'montblanc';
    if (oldStreams.includes(montBlancAppName) && !newApplicationNames.includes(montBlancAppName)) {
        await activateStreamInNewCloud(montBlancAppName, newToken);
        newApplicationNames.push(montBlancAppName);
    }
}

const fetchApplicationNamesFromNewCloud = async (token) => {
    let result = [];
    try {
        const applicationNamesResponsePromise = await fetch(`${STORAGE_API_BASE_URL}/applicationNames`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        result = await applicationNamesResponsePromise.json();
    } catch (err) { }

    return result;
}

export const fetchHiddenStreams = async (store) => {
    try {
        const newToken = store.state.auth.mainToken;
        const oldToken = store.state.auth.oldToken;

        const applicationNamesPromise = fetchApplicationNamesFromNewCloud(newToken);
        const oldAppStreamsPromise = fetchStreamsFromOldCloud(oldToken);
        let [appNames, oldStreams] = await Promise.all([applicationNamesPromise, oldAppStreamsPromise]);

        await migrateMontBlancToNewCloud(appNames, oldStreams, newToken);

        if (appNames.length > 0) {
            const folders = [...store.state.folders];

            if (appNames && appNames.length > 0) {
                for (const appName of appNames) {
                    const targetFolderIndex = folders.findIndex(el => el.link === appName);
                    if (targetFolderIndex !== -1) {
                        folders[targetFolderIndex].visible = true;
                    }
                }

                store.setState({ folders })
            }
        }
    } catch (err) {
        console.log(err);
    }
}

export const showGeneticErrorMessage = async (store) => {
    createToast(store.state.localization.genericErrorMessage);
}