import React from 'react';
import EmptyTags from '../components/tags/EmptyTags';
import EmptyGroups from '../components/groups/EmptyGroups';
import { getCollaborationMenuItem, getDuplicateAndEditItem, getEditItem } from './editMenuHelper';
import { STREAMS_WITH_DUPLICATE_AND_EDIT, STREAMS_WITH_EDIT, STREAMS_WITH_GROUPS } from 'constants/constants';

function AppContextMenu(props) {
  const { formatMessage, actions, item, currentPageId } = props;

  const buttons = [
    // {
    //   id: 'ctrl-button-Edit',
    //   title: formatMessage({ id: 'context-menu:edit' }),
    //   className: 'doc-ctrl-button',
    //   callback: () => actions.doEdit(item)
    // },
    {
      id: 'ctrl-button-export',
      title: formatMessage({ id: 'global:export' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowExportModal([item])
    },
    {
      id: 'ctrl-button-semantic',
      title: formatMessage({ id: 'global:semantic-ink' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doOpenSemanticView(item)
    },
    {
      id: 'ctrl-button-convertToText',
      title: formatMessage({ id: 'global:convertToText' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doShowInkToTextModal(item)
    },
    {
      id: 'ctrl-button-delete',
      title: formatMessage({ id: 'global:delete' }),
      className: 'doc-ctrl-button',
      callback: () => actions.doDeleteDocument(item)
    },
    {
      id: 'ctrl-button-tags',
      title: formatMessage({ id: 'global:tags' }),
      className: 'doc-ctrl-button children',
      emptyView: <EmptyTags style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    }
  ];

  if (item.will2JsonUrl) {
    const collaboration = getCollaborationMenuItem(item, props.actions, false, formatMessage);

    buttons.splice(0, 0, collaboration);
  }

  if (STREAMS_WITH_DUPLICATE_AND_EDIT.has(item.applicationName)) {
    const duplicate = getDuplicateAndEditItem(item, props.actions, formatMessage);
    buttons.splice(0, 0, duplicate);
  }

  if (STREAMS_WITH_EDIT.has(item.applicationName)) {
    let edit = getEditItem(item, props.actions, currentPageId, formatMessage);


    // If the item is WILL2 the file must be edited in WILL2 mode
    if (item.will2JsonUrl) {
      const collaborationButton = getCollaborationMenuItem(item, props.actions, true, formatMessage, false);
      edit.callback = collaborationButton.callback;
    }

    buttons.splice(0, 0, edit);
  }

  if (STREAMS_WITH_GROUPS.has(item.applicationName)) {
    const groupsItems = {
      id: 'ctrl-button-groups',
      title: formatMessage({ id: 'global:groups' }),
      className: 'doc-ctrl-button children',
      emptyView: <EmptyGroups style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    };

    buttons.splice(buttons.length - 1, 0, groupsItems);
  }

  return buttons;
}


export default AppContextMenu;