import React from 'react';
import ChooseLanguage from '../../containers/ChooseLanguage';
import FooterNavigation from './FooterNavigation';

import './Footer.scss';

function Footer(props) {
    return (
        <div id={'footer'} className='footer-container'>
            <FooterNavigation />
            <div className='footer-language'>

                <ChooseLanguage />
            </div>
            {props.children}
        </div>
    )
}

export default Footer;