import React, { useEffect, useRef, useState } from 'react'
import {
    PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK, COOKIE_NOTICE_LINK,
    CALIFORNIA_PRIVACY_LINK, LANGUAGES
} from 'constants/constants'
import { useIntl } from 'react-intl';
import FooterNavigationItem from './FooterNavigationItem'

import './FooterNavigation.scss'

const getInitialMessages = (formatMessage, locale) => {
    const initialButtons = [
        {
            title: formatMessage({ id: 'footer:navigation:termOfUse' }),
            to: TERMS_OF_USE_LINK
        },
        {
            title: formatMessage({ id: 'footer:navigation:privacy' }),
            to: PRIVACY_POLICY_LINK
        },
        {
            title: formatMessage({ id: 'footer:navigation:cookies' }),
            to: COOKIE_NOTICE_LINK
        }
        // ,
        // {
        //   title: <span className='inkNoteFilesStyle'>Ink Note Stream</span>,
        //   to: `http://inkspacefiles.azurewebsites.net/Home/Files?accounts_session_token=${this.props.sessionId}`
        // }
    ];

    if (locale === 'en') {
        initialButtons.push({
            title: 'California Privacy',
            to: CALIFORNIA_PRIVACY_LINK
        })
    }

    return initialButtons;
}


function FooterNavigation() {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [buttons, setButtons] = useState(getInitialMessages(formatMessage, intl.locale));
    const intervalHandler = useRef();

    let trustArcLocale = intl.locale;
    if (trustArcLocale.indexOf('zh-Han') !== -1) {
        trustArcLocale = LANGUAGES[intl.locale].locale;
    }

    useEffect(() => {
        const handleTrustArc = () => {
            if (window.truste && window.truste.eu && window.truste.eu.clickListener) {

                const cookiePreferencesButton = {
                    title: formatMessage({ id: 'footer:navigation:cookiePreferences' }),
                    callback: () => window.truste.eu.clickListener(),
                    to: '#'
                };

                setButtons(buttons => [...buttons, cookiePreferencesButton]);
            }
        }

        intervalHandler.current = setTimeout(handleTrustArc, 500);

        return () => {
            if (intervalHandler.current) clearTimeout(intervalHandler.current);
        }
    }, [formatMessage, intervalHandler]);

    useEffect(() => {
        setButtons(getInitialMessages(formatMessage, intl.locale))
    }, [intl.locale, formatMessage]);

    const currentLocaleFull = LANGUAGES[intl.locale].locale;
    const localeWithDash = currentLocaleFull.replace('_', '-');

    return <div className='footer-nav'>
        <ul>
            {buttons.map((item, index) => {

                let localizedHref = item.to.replace('{locale}', localeWithDash);


                return (
                    <FooterNavigationItem key={'footer-nav-item' + index}
                        index={index}
                        title={item.title}
                        to={localizedHref}
                        callback={item.callback} />
                )
            })}
        </ul>
    </div>
}

export default FooterNavigation;