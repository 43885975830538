export const storageFactory = (storage) => {
    let inMemoryStorage = {}; 
  
    const isSupported = () => {
      try {
        const key = "___730020411230584196177300204112305841961773002041123058419617____";
        storage.setItem(key, key);
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    }
  
    const getItem = (key) => {
      if (isSupported()) {
        return storage.getItem(key);
      }
      return inMemoryStorage[key] || null;
    }
  
    const setItem = (key, value) => {
      if (isSupported()) {
        storage.setItem(key, value);
      } else {
        inMemoryStorage[key] = value;
      }
    }
  
    const removeItem = (key) => {
      if (isSupported()) {
        storage.removeItem(key);
      } else {
        delete inMemoryStorage[key];
      }
    }
  
    const clear = (key) => {
      if (isSupported()) {
        storage.clear();
      } else {
        inMemoryStorage = {};
      }
    }
    
    const key = (n) => {
      if (isSupported()) {
        return storage.key(n);
      } else {
         return Object.keys(inMemoryStorage)[n] || null;
      }
    }
  
    return {
      getItem,
      setItem,
      removeItem,
      clear,
      key,
    };
  }