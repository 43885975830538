import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

function ScrollableDocumentsList(props) {
    const { children, overlay } = props;
    const className = overlay ? 'doScrollable overlay' : 'doScrollable';

    return <ul className={className} id={'home-scrollable-field'}>
        <Scrollbars autoHide height={props.height}>
            <div className={'scrollable-content'}>
                {children}
            </div>
        </Scrollbars>
    </ul>

}

export default ScrollableDocumentsList;