const messages = {
  'documents:empty:noresult:noresult' : 'NESSUN RISULTATO',
  'documents:empty:noresult:noresultfound' : 'Nessun risultato trovato in base ai criteri impostati.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Una volta che il dispositivo è connesso all’app Ink Space e ti sei registrato con il tuo ID Wacom, potrai visualizzare tutti i tuoi contenuti.',
  'documents:empty:stream:explanation1:webstream' : 'Prova Collaborazione inchiostro.  Condividi e collabora alle tue creazioni con altri utenti. Provala oggi stesso',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Quando avrai collegato Bamboo Paper al tuo Wacom ID, visualizzerai tutti i tuoi contenuti qui.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Prendi appunti. Fai di più. Come sulla carta, ma meglio.',
  'documents:empty:stream:tryitnow' : 'Provala subito',
  'documents:empty:stream:learnmore' : 'PER SAPERNE DI PIÙ',
  'documents:empty:group' : 'Il gruppo è vuoto',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Flusso di collaborazione',

  'filter:result' : 'RISULTATO PER',
  'filter:search' : 'RICERCA...',

  'footer:navigation:privacy' : 'PRIVACY',
  'footer:navigation:termOfUse' : 'Condizioni per l\'utilizzo',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Preferenze sui cookie',

  'global:convertTo' : 'CONVERTI IN {language}',
  'global:convertToText' : 'Converti in testo',

  'context-menu:createNewCanvas' : 'Crea nuova tela',
  'context-menu:createNewGroup' : 'Crea gruppo',
  'context-menu:startNewCollaboration' : 'Avvia nuova collaborazione',
  'context-menu:startCollaboration' : 'Avvia collaborazione',
  'context-menu:edit' : 'Modifica',

  'global:delete' : 'Elimina',
  'global:rename' : 'Rinomina',
  'global:export' : 'Esporta',
  'global:tags' : 'Tag',
  'global:groups': 'Gruppi',
  'global:shareLink' : 'Condividi link',
  'global:copy' : 'COPIA',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Duplica e modifica',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Salva',
  'global:dontsave' : 'Non salvare',
  'global:proceed' : 'CONTINUA',
  'global:enterName' : 'INSERISCI IL NOME',
  'global:browserUnsupported' : 'Browser non supportato',

  'global:language' : 'LINGUA',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Esci',
  'global:login' : 'Accedi',
  'global:myAccount' : 'Il mio account',

  'inputField:existsName' : 'Errore! Il nome esiste già!',
  'inputField:incorrectName' : 'Errore! Il nome non è corretto!',
  'inputField:notInRange' : 'Errore! Il nome deve contenere da {minLength} a {maxLength} caratteri!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Per collegarti a questa Collaborazione, utilizza Chrome o Firefox. Grazie',
  'modalbox:browser:unsupport:sf:text': 'Per collegarti a questa Collaborazione, utilizza Chrome o Safari. Grazie',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Errore!',
  'modalbox:error:buttonTitle' : 'Chiudi',
  'modalbox:exportError:message' : 'L\'azione di esportazione non è al momento disponibile',
  'modalbox:createTag:buttons:title' : 'Crea',
  'modalbox:createTag:title' : 'CREA NUOVO TAG',
  'modalbox:deleteTag:message' : 'Stai per eliminare <span>"{tag}"</span>. Questa operazione è irreversibile.',
  'modalbox:removeDocument:message' : 'Stai per eliminare <span>"{docTitle}"</span>. Questa operazione è irreversibile.',
  'modalbox:shareLink:buttons:stopSharing' : 'Interrompi condivisione',
  'modalbox:sessionFull:title' : 'Collaborazione completa',
  'modalbox:sessionFull:text' : 'Sfortunatamente la sessione di collaborazione a cui stai cercando di accedere è completa.',
  'modalbox:leaveSession:title' : 'Abbandona la sessione',
  'modalbox:leaveSession:buttons:leave' : 'Abbandona',
  'modalbox:leaveSession:text' : 'Vuoi abbandonare la sessione di collaborazione?',
  'modalbox:endSession:title' : 'Termina la sessione',
  'modalbox:endSession:buttons:end' : 'Termina',
  'modalbox:endSession:text' : 'Vuoi terminare la sessione di collaborazione corrente? Gli attuali progressi verranno salvati automaticamente nel tuo Web Stream.',
  'modalbox:sessionEnded:title' : 'Sessione terminata',
  'modalbox:sessionEnded:text' : 'Vuoi salvare una copia della sessione di collaborazione nel tuo Web Stream?',
  'modalbox:accessDenied:title' : 'Accesso negato',
  'modalbox:accessDenied:buttons:goBack' : 'Torna indietro',
  'modalbox:accessDenied:text' : 'Sfortunatamente la sessione di collaborazione a cui stai cercando di accedere è scaduta.',
  'modalbox:collaborationStarted:title' : 'Collaborazione avviata',
  'modalbox:collaborationStarted:warn' : 'Devi mantenere aperto Collaborazione inchiostro.  Se chiudi questa scheda o ne apri un’altra, Collaborazione inchiostro verrà terminato.',
  'modalbox:collaborationStarted:text' : 'La sessione di collaborazione è stata avviata. Puoi invitare fino a 10 partecipanti inviando loro l’URL della collaborazione dal browser.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Non mostrare',
  'modalbox:duplicateAndEdit:text' : 'Una nuova versione estesa della pagina verrà inserita nel tuo Ink Space Web Stream senza modificare l’originale',
  'modalbox:saveChanges:title' : 'Salva modifiche',
  'modalbox:saveChanges:text' : 'Vuoi salvare le modifiche al documento prima di chiuderlo?',
  'modalbox:collaborationError:title' : 'Errore di collaborazione!',
  'modalbox:collaborationError:buttons:close' : 'Chiudi',
  'modalbox:collaborationError:text' : 'Il browser che stai utilizzando non è supportato da Collaborazione inchiostro.  Questa funzione fornisce risultati ottimali se utilizzata con Firefox /55 o versioni successive/, Chrome /60 o versioni successive/ e Safari /11 o versioni successive/',
  'modalbox:WebGLError:title' : 'Si è verificato un errore',
  'modalbox:WebGLError:text' : 'Si è verificato un errore e devi ricaricare i contenuti utilizzando il pulsante “Ricarica” che appare nel messaggio riportato sopra.',
  'modalbox:collaborationJoinFailed:title': 'Impossibile stabilire la connessione',
  'modalbox:collaborationJoinFailed:text': 'Impossibile accedere alla sessione a cui stai tentando di accedere. Riprova più tardi.',
  'modalbox:connectionLost:title': 'Connessione persa',
  'modalbox:connectionLost:text': 'La connessione è stata persa. Controlla la connessione Internet e riprova.',
  'modalbox:phoneDetection:title': 'Utilizza l’app per dispositivi mobili',
  'modalbox:phoneDetection:btn:open': 'Apri',
  'modalbox:phoneDetection:btn:no-thanks': 'No, grazie',
  'modalbox:phoneDetection:msg': 'Per sfruttare un’esperienza migliore consigliamo di utilizzare la nostra app dedicata per dispositivi mobili sul tuo cellulare.',
  'modalbox:chooseVectorOrRaster:title' : 'Scegli la modalità di modifica',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Annotazione',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vettorizzazione',
  'modalbox:chooseVectorOrRaster:text' : 'I contenuti che stai cercando di modificare sono di tipo raster. Per mantenere l’aspetto originale, ti consigliamo di scrivere le note sopra di essi. Inoltre, puoi anche convertirli in formato vettoriale, ma a seconda degli strumenti utilizzati il risultato potrebbe differire dall’originale.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Elimina gruppo',
  'modalbox:deleteGroup:title:multiple' : 'Elimina gruppi',
  'modalbox:deleteGroup:button:removeGroup' : 'Rimuovi gruppo',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Rimuovi gruppi',
  'modalbox:deleteGroup:message' : 'Vuoi eliminare soltanto il gruppo <span>"{titles}"</span> o anche i suoi contenuti? Questa azione è irreversibile.',
  'modalbox:deleteGroup:message:multiple': 'Vuoi eliminare soltanto i gruppi "<span>"{titles}"</span>" o anche i loro contenuti? Questa azione è irreversibile.',
  'modalbox:deleteGroup:button:removeAll': 'Elimina tutto',
  'modalbox:deleteGroup:button:cancel': 'Annulla',
  'modalbox:deletePage:title': 'Elimina pagina',
  'modalbox:deletePage:message': 'Vuoi eliminare <span>"{titles}"</span> o rimuoverla da questo gruppo?',
  'modalbox:deletePage:removePage': 'Rimuovi dal gruppo',
  'modalbox:group:rename': 'Rinomina gruppo',
  'mailbox:exportLimit:title': 'Limite raggiunto',
  'mailbox:exportLimit:message': 'È stata superata la quantità massima di {maxExportPages} note. Riduci il numero di note selezionate e riprova.',

  'toast:groupAddedSuccess:single': 'Pagina aggiunta correttamente a "{groupName}"',
  'toast:groupAddedSuccess:plural': 'Pagine aggiunte correttamente a "{groupName}"',
  'toast:pagesRemovedFromGroup:single': 'Pagina rimossa correttamente da "{groupName}"',
  'toast:pagesRemovedFromGroup:plural': 'Pagine rimosse correttamente da "{groupName}"',
  'toast:groupCreated': '"{groupName}” è stato creato correttamente',
  'toast:groupRemoved': '"{groupName}” è stato rimosso correttamente',
  'toast:groupRemoved:andPages': '"{groupName}” e le pagine sono stati rimossi correttamente',
  'toast:groupRemoved:plural': 'I gruppi selezionati sono stati rimossi correttamente',
  'toast:noteRemoved:single': 'Pagina eliminata correttamente',
  'toast:noteRemoved:plural': 'Pagine eliminate correttamente',


  'sorter:ascending' : 'Ascendente',
  'sorter:date' : 'Data',
  'sorter:descending' : 'Discendente',
  'sorter:name' : 'Nome',
  'sorter:size' : 'Dimensione',
  'sorter:sortby' : 'Ordina in base a',
  'sorter:type' : 'Tipo',

  'tagContainer:createTag' : 'Crea tag',
  'tagContainer:noTags' : 'Nessun tag',
  'tagContainer:noTagsMessage' : 'Quando crei dei tag, appariranno qui',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'Quando crei dei gruppi, appariranno qui',

  'export:asDoc' : 'come DOC',
  'export:asPsd' : 'come PSD',
  'export:asJpg' : 'come JPG',
  'export:asPdf' : 'come PDF',
  'export:asPng' : 'come PNG',
  'export:asSvg' : 'come SVG',
  'export:asWill' : 'come WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'come PowerPoint',

  // Ink to Video
  'video:generating' : 'Creazione del video in corso...',
  'video:timeNotice' : 'Questa operazione potrebbe richiedere alcuni minuti',
  'video:estimatedTime.one': 'Questa operazione potrebbe richiedere un minuto',
  'video:estimatedTime.many': 'Questa operazione potrebbe richiedere <span>{minutes}</span>minuti',
  'video:fileReady' : 'Il file è pronto',
  'video:download'  : 'Scarica',
  'video:expirationMessage' : 'Il download verrà eliminato automaticamente dopo <span>{days}</span>giorni',
  'video:error' : 'Creazione del video non riuscita',

  // Session Expired Prompt
  'session:expired:title': 'Sessione scaduta',
  'session:expired:message': 'La sessione è scaduta. Accedi nuovamente.',
  'session:expired:button': 'Accedi',

  // Auth module
  'auth:email:label' : 'E-mail',
  'auth:email:taken' : 'L’indirizzo e-mail non è disponibile.',
  'auth:email:notRegisterd' : 'L’indirizzo e-mail non è registrato.',
  'auth:email:invalid' : 'E-mail non valida',
  'auth:password:label' : 'Password',
  'auth:password:reenter:label' : 'Reinserisci password',
  'auth:password:mismatch:label' : 'Le password non coincidono',
  'auth:password:tooEasy:label' : 'La password deve contenere almeno {charLength} caratteri e 3 dei seguenti tipi: una lettera maiuscola, una lettera minuscola, un numero, un simbolo speciale.',
  'auth:firstName:label' : 'Nome',
  'auth:lastName:label' : 'Cognome',
  'auth:country:label' : 'Paese',
  'auth:country:select' : 'Seleziona Paese',
  'auth:language:label' : 'Lingua',
  'auth:language:select' : 'Seleziona lingua',
  'auth:termsAndConditions:label' : 'Hai letto e accettato i <a href="{termsHref}">Termini di utilizzo</a> di Wacom.',
  'auth:privacyPolicy:label' : 'Hai letto e accettato la <a href="{privacyHref}">privacy policy</a> e l’<a href="{cookieHref}">informativa sui cookie</a> di Wacom.',
  'auth:login:invalid' : 'Login o password non validi.',
  'auth:login:title' : 'Accedi all’account Wacom ID',
  'auth:login:allWacom:text' : 'Il tuo account per tutto ciò che riguarda Wacom.',
  'auth:login:forgotPassword:link' : 'Password dimenticata',
  'auth:login:button' : 'Login',
  'auth:login:signup:button' : 'Accedi',
  'auth:login:createNewAccount:label' : 'Non hai un account?',
  'auth:login:alreadyHaveAccount:label' : 'Hai già un account?',
  'auth:passwordReset:title' : 'Ripristina password',
  'auth:passwordReset:text' : 'Inserisci il tuo indirizzo e-mail che hai usato per l’account Wacom ID. Ti invieremo un link per ripristinare la password.',
  'auth:passwordReset:reset:button' : 'Ripristina',
  'auth:passwordReset:signIn:button' : 'Accedi',
  'auth:passwordReset:back:button' : 'Torna a',
  'auth:passwordReset:success:text' : 'Ti abbiamo inviato un’e-mail al tuo indirizzo e-mail.',
  'auth:register:title' : 'Crea il tuo profilo',
  'auth:register:success:title1' : 'Congratulazioni!',
  'auth:register:success:title2' : 'Hai completato la registrazione',
  'auth:register:success:message' : 'Per continuare, controlla il tuo indirizzo e-mail per confermare la registrazione e poi effettua l’accesso per accedere alla tua dashboard.',
  'auth:errors:generic' : 'Si è verificato un errore. Riprovare.',
  'auth:policyUpdate:title': 'I nostri termini di utilizzo e la nostra privacy policy sono stati modificati dall’ultima volta che hai effettuato l’accesso',

  // Welcome screens
  'welcome:screen1:header': 'Funzionalità di Ink Collaboration',
  'welcome:screen1:text': 'Con Ink Collaboration puoi lavorare in modo collaborativo con chiunque e ovunque. Inizia un quadro a partire dai tuoi contenuti o un nuovo quadro e avvia l’input penna con altri.',
  'welcome:screen2:header': 'Sincronizzazione e condivisione di contenuti',
  'welcome:screen2:text': 'Con Wacom ID e le tue app collegate al cloud, i tuoi contenuti sono disponibili online sempre e ovunque. Esporta in altri formati per continuare a lavorare nei tuoi strumenti software e app preferiti.',
  'welcome:screen3:header': 'Accedi a tutti i tuoi contenuti',
  'welcome:screen3:text': 'Il tuo Wacom ID sblocca tante nuove funzionalità e flussi di lavoro per i tuoi contenuti. Accedi ovunque, condividi con altri o esegui il backup dei tuoi contenuti online per sicurezza. Wacom ID è la tua chiave di accesso al cloud.',
  'welcome:button:next': 'Avanti',
  'welcome:button:done': 'Fatto',

  // Migration screen
  'migration:screen1:text1': 'Il tuo contenuto è in fase di ottimizzazione. Ti preghiamo di attendere.',
  'migration:screen1:text2': 'Elaborazione in corso...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Aggiornamento del servizio',
  'whatIsNew:cloudMigration:mainText': 'Stiamo apportando dei miglioramenti ai nostri servizi cloud. Potrebbe esserci un breve ritardo nella visualizzazione o nell’utilizzo del tuoi contenuti. Ti ringraziamo per la pazienza.',
  'whatIsNew:cloudMigration:cloudTeam': '"- Il team cloud di Wacom"',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Riassunto',
  'semantic:sidebar:confidence': 'fiducia',
  'semantic:sidebar:reference': 'Riferimento',

  // Semantic-ink icon description
  'semantic:type:organization': 'organizzazione',
  'semantic:type:topic': 'argomento',
  'semantic:type:person': 'persona',
  'semantic:type:location': 'posizione',
  'semantic:type:event': 'evento',
  'semantic:error:header': 'Nessun riconoscimento di Semantic Ink',
  'semantic:error:text': 'La tua nota non ha bisogno di miglioramenti Semantic Ink.',
  
  // Download my data
  'dataDownload:profile:option': 'I miei dati',
  'dataDownload:tabs:downloadData': 'Scarica dati',
  'dataDownload:tabs:availableData': 'Dati disponibili',
  'dataDownload:tabs:downloadData:description': 'Esporta e scarica una copia del contenuto del tuo account Ink Space.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Selezionare...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Selezionare tutto',
  'dataDownload:processing:description': 'Il download dei tuoi file potrebbe richiedere del tempo. Quando il download è pronto, riceverai un’e-mail con un link per il download, oppure puoi scaricarlo qui.',
  'dataDownload:processing': 'Elaborazione in corso...',
  'dataDownload:request:button': 'Scarica dati',
  'dataDownload:tabs:availableData:description': 'Scarica i tuoi dati',
  'dataDownload:tabs:availableData:archiveFor': 'Archivio per ',
  'global:myData': 'I miei dati'
}

export default messages;