import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './EyeDropperOverlay.scss';

const eyeDropperToolId = INK_EDITOR_CONFIG.controls.eyedropper;


function EyeDropperOverlay(props) {
    const imageRef = useRef();
    const [src, setSrc] = useState();
    const [point, setPoint] = useState({ x: -1, y: -1 });
    const radius = 40;
    // TODO: Check if flag is required
    const ready = true;

    const { toolId, prevToolId, setToolId, scale, setColor } = useContext(CanvasContext);
    const isActive = toolId === eyeDropperToolId;
    const classes = isActive ? 'img-overlay visible' : 'img-overlay';
    const { willCanvas } = props;

    const extractColor = (ev) => {
        if (willCanvas) {
            const dropperColor = willCanvas.extractColor(point);

            setColor(dropperColor);
            setToolId(prevToolId);
        }
    }

    useLayoutEffect(() => {
        const renderInlineStyles = (point) => {
            let inlineStyle = {}

            if (ready) {
                const revScale = 1 / scale;
                let scaledRadius = radius * revScale;

                if (willCanvas && point) {
                    let { width, height } = willCanvas.canvas;

                    inlineStyle['clipPath'] = `circle(${scaledRadius}px at ${point.x}px ${point.y}px)`
                    inlineStyle['WebkitClipPath'] = `circle(${scaledRadius}px at ${point.x}px ${point.y}px)`
                    inlineStyle['MozClipPath'] = `circle(${scaledRadius}px at ${point.x}px ${point.y}px)`
                    inlineStyle['transformOrigin'] = `${point.x}px ${point.y}px`
                  
                    if (point.x >= 0 && point.x <= width && point.y >= 0 && point.y <= height) {
                        inlineStyle['visibility'] = 'visible'
                        inlineStyle['display'] = 'block'
                    } else {
                        inlineStyle['visibility'] = 'hidden'
                    }


                } else {
                    inlineStyle['visibility'] = 'hidden'
                }
            } else {
                inlineStyle['display'] = 'none'
            }

            return inlineStyle
        }

        const styles = renderInlineStyles(point);

        for (const key in styles) {
            imageRef.current.style[key] = styles[key];
        }
    }, [point, ready, scale, willCanvas]);

    useEffect(() => {
        if (willCanvas && isActive) {
            // const newSrc = props.willCanvas.canvas.surface.toDataURL();
            const newSrc = willCanvas.getEyeDropperCanvas().toDataURL();

            setSrc(newSrc);
        } else {
            setSrc(null);
        }

        const onMouseMove = (ev) => {
            if (!isActive) return;

            if (willCanvas) {
                const canvasBBox = willCanvas.canvas.surface.getBoundingClientRect();
                const positionOnCanvas = { x: ev.x - canvasBBox.x, y: ev.y - canvasBBox.y };
                const revScale = 1 / scale;

                setPoint({
                    x: positionOnCanvas.x * revScale,
                    y: positionOnCanvas.y * revScale
                });
            }
        }

        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        }
    }, [willCanvas, isActive, scale])



    return <img id={'eye-dropper-overlay'}
        style={{ display: 'none' }}
        alt={'zoom lens'}
        onClick={extractColor}
        src={src}
        ref={imageRef}
        className={classes} />
}

export default EyeDropperOverlay;