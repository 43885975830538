import React, { useRef, useState, useEffect } from 'react';
import './GenericList.scss';

function GenericList(props) {
    const ref = useRef();
    const [listStyles, setListStyles] = useState({});
    const { children, className, classNameOverrides } = props;
    let baseClassName = classNameOverrides
        ? ''
        : 'generic-list'
    let cn = `${baseClassName} ${className || ''}`;

    useEffect(() => {
        if (ref.current) {
            const parent = ref.current?.parentElement;
            if (parent) {
                const style = {
                    maxHeight: `calc(${parent.getBoundingClientRect().bottom}px - 2rem)`
                };

                setListStyles(style);
            }
        }
    }, [ref]);

    return <ul
        ref={ref}
        className={cn}
        style={listStyles}
    >
        {children}
    </ul>
}

export default GenericList;