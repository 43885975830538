import React, { useEffect, useRef } from 'react';
import ReactSpinner from 'react-spinjs-fix';
import { SPINNER_SETTINGS, AUTH_PATH, BASE_HOME_LOCATION } from 'constants/constants';
import useGlobal from 'store';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { URL_VALIDITY_DAYS, GENERATED_RESOURCES_POLLING_INTERVAL } from 'constants/constants';
import { Line } from 'rc-progress';
import Footer from 'common/components/footer/Footer';
import Header from '../../common/components/header/Header';
import ProfileComponent from 'common/components/profile/ProfileComponent';

import './VideoComponent.scss';

const isLoading = 'loading';
const isError = 'error';

const knownActions = ['isLoading', 'error'];

function stopProgressInterval(interval) {
    if (interval) {
        clearInterval(interval);
        interval = null;
    }
};

function VideoComponent(props) {
    const { guid } = useParams();
    const [globalState, globalActions] = useGlobal();
    // const [handle, setHandle] = useState(null);
    const handle = useRef(null);
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { fetchInkToVideoState, encodeState, clearState } = globalActions.video;
    const { resultLocation, responseReceived } = globalState.video;

    useEffect(() => {
        clearState()
    }, [clearState]);

    useEffect(() => {
        const isValidGuidParam = isValidGuid(guid);
        isValidGuidParam && fetchInkToVideoState(guid);
        return () => {
            clearInterval(handle.current);
            handle.current = null;
        }
    }, [fetchInkToVideoState, guid]);

    useEffect(() => {
        const isValidGuidParam = isValidGuid(guid);
        const hasResultUrl = !(!resultLocation && resultLocation !== "");


        if (handle.current === null && isValidGuidParam && !hasResultUrl) {
            const newHandle = setInterval(() => {
                fetchInkToVideoState(guid);
            }, GENERATED_RESOURCES_POLLING_INTERVAL);

            clearInterval(handle.current);
            handle.current = newHandle;
        }
    }, [guid, fetchInkToVideoState, resultLocation]);


    useEffect(() => {
        const isFailed = encodeState && encodeState === 'failed';
        const isReady = encodeState && encodeState === 'done';

        if (isFailed || isReady) {
            stopProgressInterval(handle.current);
        }
    }, [encodeState]);

    const isValidGuid = (guid) => {
        return /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(guid)
    };

    const shouldShowSpinner = () => {
        const isGeneratingToken = guid === isLoading;
        const waitsForInitialTaskCheck = !responseReceived && isValidGuid(guid);

        return isGeneratingToken || waitsForInitialTaskCheck;
    };

    const downloadButton = <a id={'downloadLink'}
        className={'danger'}
        href={globalState.video.resultLocation}
        download="video.mp4">
        {formatMessage({ id: 'video:download' })}
    </a>

    const estimatedTimeLeft = Math.ceil(globalState.video.etaSeconds / 60);
    let timeEstimateTextKey = 'video:timeNotice';
    if (globalState.video.etaSeconds) {
        timeEstimateTextKey = 'video:estimatedTime'

        if (estimatedTimeLeft < 2) {
            timeEstimateTextKey += '.one'
        } else {
            timeEstimateTextKey += '.many'
        }
    }

    const cameraIcon = <div id={'cameraIcon'}></div>

    const expirationMessage = formatMessage(
        { id: 'video:expirationMessage' },
        {
            days: URL_VALIDITY_DAYS,
            span: chunks => <span id={chunks} key={'video:expirationMessage'}>{chunks}&nbsp;</span>
        }
    );

    const timeEstimateMessage = formatMessage(
        { id: timeEstimateTextKey },
        {
            minutes: estimatedTimeLeft,
            span: chunks => <span key={timeEstimateTextKey}>{chunks}&nbsp;</span>
        }
    );

    const downloadMessages = [{ key: 'readyText', value: formatMessage({ id: 'video:fileReady' }) },
    { key: 'downloadButton', value: downloadButton },
    { key: 'expirationMessage', value: expirationMessage }];

    const generatingMessages = [{ key: 'generatingText', value: formatMessage({ id: 'video:generating' }) },
    { key: 'progressBar', value: <div id='progress-bar'><Line percent={globalState.video.progress} strokeColor="#00AEEF" trailColor="#d9d9d9" /></div> },
    { key: 'eta', value: timeEstimateMessage }];

    const failedMessages = [{ key: 'errorText', value: formatMessage({ id: 'video:error' }) }];

    const isExportFail = globalState.video.encodeState === 'failed' || guid === isError || (!isValidGuid(guid) && !knownActions.includes(guid));

    let uiElements = '';
    let elementsToMap = [];

    if (globalState.video.encodeState === 'done') {
        elementsToMap = downloadMessages;
    } else if (isExportFail) {
        elementsToMap = failedMessages;
    } else {
        elementsToMap = generatingMessages;
    }

    uiElements = elementsToMap.map(item => <span key={item.key}>{item.value}</span>);

    const redirectToLogin = () => {
        history.push(AUTH_PATH);
    }

    const redirectToHome = () => {
        history.push(BASE_HOME_LOCATION);
    }

    let profileChild = null;

    if (globalState.auth.profile != null && globalState.auth.isLoggedIn) {
        profileChild = <ProfileComponent lastName={globalState.auth.profile.lastName} firstName={globalState.auth.profile.firstName} />
    } else {
        //TODO: Check is making this a component is worth it
        profileChild = <div className='profile-container'>
            <button id='loginLink' onClick={redirectToLogin}>
                {formatMessage({ id: 'global:login' })}
            </button>
        </div>
    }

    let content = null;
    if (shouldShowSpinner()) {
        content = <div className='content-container'>
            <ReactSpinner config={SPINNER_SETTINGS} />;
        </div>
    } else {
        content = <div className='content-container'>
            <div id={'download-component'}>
                <div id={'download-content-wrapped'} className={'arrange-vertically'}>
                    {cameraIcon}
                    {uiElements}
                </div>
            </div>
        </div>
    }

    return <div id={'ink-to-video-main-container'}>
        <Header>
            <div className='logo'
                onClick={redirectToHome}
            />
            {profileChild}
        </Header>
        {content}
        <Footer />
    </div>
}

export default VideoComponent;