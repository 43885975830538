import React from 'react';
import { useIntl } from 'react-intl'
import { Tooltip } from 'react-tippy';
import { useHistory } from 'react-router-dom';

function FolderElement(props) {
    const history = useHistory();
    const { folder } = props;
    const { formatMessage } = useIntl();
    // const placeholder = formatMessage({ id: 'filter:search' });

    const onClick = () => { history.push(`/?applicationName=${folder.streamId}`)};
    // const mobileBrowsers = () => { return false; };

    return <div className='folder-box'>
        <Tooltip
            title={formatMessage({ id: folder.msg })}
            // trigger={mobileBrowsers() ? 'focus' : 'mouseenter'}
            animation={'fade'}
            arrow={'true'}
            position={'right'}
            size={'big'}
            arrowSize={'small'}
        >
            <span className={`folder ${folder.streamId}-stream ${folder.active ? 'active' : ''}`} onClick={onClick}>
                {folder.text}
            </span>
        </Tooltip>
    </div>
}

export default FolderElement;