import de from 'messages/messages_de'
import en from 'messages/messages_en'
import es from 'messages/messages_es'
import fr from 'messages/messages_fr'
import it from 'messages/messages_it'
import ja from 'messages/messages_ja'
import ko from 'messages/messages_ko'
import nl from 'messages/messages_nl'
import pl from 'messages/messages_pl'
import pt from 'messages/messages_pt'
import ru from 'messages/messages_ru'

import da from 'messages/messages_da'
import fi from 'messages/messages_fi'
import nn from 'messages/messages_nn'
import sv from 'messages/messages_sv'

import chineseSimplified from 'messages/messages_zh-Hans'
import chineseTraditional from 'messages/messages_zh-Hant'

export const messagesLoader = (locale) => {
    const obj = {
        'en': en,
        'de': de,
        'it': it,
        'es': es,
        'fr': fr,
        'ja': ja,
        'ko': ko,
        'nl': nl,
        'pl': pl,
        'pt': pt,
        'ru': ru,
        'da': da,
        'fi': fi,
        'nn': nn,
        'sv': sv,
        'zh-Hant': chineseTraditional,
        'zh-Hans': chineseSimplified
    }
    return obj[locale]
}

export const supportedLocales = new Set([
        'en',
        'de',
        'it',
        'es',
        'fr',
        'ja',
        'ko',
        'nl',
        'pl',
        'pt',
        'ru',
        'da',
        'fi',
        'nn',
        'sv',
        'zh-Hant',
        'zh-Hans'
]);
