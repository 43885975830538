import React, { useEffect, useState, useRef } from 'react';
import ReactSpinner from 'react-spinjs-fix';
import {
    SPINNER_SETTINGS, COLLABORATION_BASE_URL,
    COLLABORATION_INIT_ELEMENT_ID, COLLABORATION_LOADING_PATH,
    HOME_PATH
} from 'constants/constants';
import {
    parseQueryParams
} from 'common/components/utilities/queryHelper';
import { getAuthTokens, tokensQueryParameterKey } from '../../../actions/home';

import './Collaboration.scss'

const cleanInitCollaborationIframe = () => {
    const iframe = document.getElementById(COLLABORATION_INIT_ELEMENT_ID);
    if (iframe) {
        iframe.remove();
    }
}

function Collaboration(props) {
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(true);
    const iframeRef = useRef();
    const iframeUrl = useRef();
    const { roomId } = props.match.params;
    const queryParams = parseQueryParams(props.location.search);
    const isWill2EditMode = queryParams.isWill2EditMode ?? false;

    if (roomId !== COLLABORATION_LOADING_PATH && !iframeUrl.current) {
        iframeUrl.current = `${COLLABORATION_BASE_URL}/ink-editor/${roomId}/collaborate?isEmbedded=true&${tokensQueryParameterKey}=${getAuthTokens()}&isWill2EditMode=${isWill2EditMode}`;
    }

    useEffect(() => {
        return cleanInitCollaborationIframe
    }, [])

    useEffect(() => {
        const onMessage = event => {
            if (!event.origin.endsWith('wacom.com')) return;

            const { data } = event;

            if (data) {
                if (data.type === 'collaboration' && event.data.roomId) {
                    // cleans up the iframe that initializes the collaboration
                    const iframe = document.getElementById(COLLABORATION_INIT_ELEMENT_ID);
                    let isWill2EditMode = true;
                    if (iframe) {
                        isWill2EditMode = iframe.src.contains('isWill2EditMode=true');
                    }

                    props.history.push(`/collaboration/${event.data.roomId}?isWill2EditMode=${isWill2EditMode}`);
                }
                if (data.type === 'collaboration') {
                    if (data.documentSaved !== undefined) {
                        props.history.push(`${HOME_PATH}?applicationName=collaboration`);
                    } else if (data.canvasClosed) {
                        setIsSpinnerVisible(true);
                    }
                } else if (data.type === 'collaboration-saving') {
                    setIsSpinnerVisible(true);
                }
            }
        };

        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [props.history]);

    useEffect(() => {
        const updateTokens = () => {
            if (iframeRef.current) {
                const authTokens = getAuthTokens();
                iframeRef.current.contentWindow.postMessage({ type: 'tokenUpdate', authTokens }, '*');
            }
        }
        const handle = setInterval(updateTokens, 5 * 60 * 1000);

        return () => {
            clearInterval(handle);
        }
    }, []);

    const iFrameStyles = {};

    if (isSpinnerVisible) {
        iFrameStyles.visibility = 'hidden';
    }

    const onIframeLoaded = () => {
        if (isSpinnerVisible) {
            setIsSpinnerVisible(false);
        }

        cleanInitCollaborationIframe();
    }


    return <div className={'collaboration'}>
        {iframeUrl.current && <iframe title={'ink space collaboration'} ref={iframeRef} className={'full-screen'} src={iframeUrl.current} style={iFrameStyles} onLoad={onIframeLoaded} />}
        {isSpinnerVisible && <ReactSpinner config={SPINNER_SETTINGS} />}
    </div>
}

export default Collaboration;