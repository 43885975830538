import React from 'react';
import { useIntl } from 'react-intl';
import ModalBox from 'common/components/modalBox/ModalBox';

import './DuplicateAndEdit.scss';

function DuplicateAndEdit(props) {
    const { formatMessage } = useIntl();
    const onDestroy = props.onDestroy ?? props.onClose;
    const { isDuplicatingDocument } = props.modalState;

    const options = {
        id: 'modal-duplicated-and-edit',
        title: formatMessage({ id: 'global:duplicateAndEdit' }),
        onDestroy: onDestroy,
        isSpinnerVisible: isDuplicatingDocument,
        buttons: [{
            onClick: props.onSubmit,
            title: formatMessage({ id: 'global:ok' }),
            isSubmit: true,
            id: 'duplicate'
        }, {
            onClick: onDestroy,
            title: formatMessage({ id: 'modalbox:duplicateAndEdit:buttons:dontshow' }),
            className: 'secondary-button'
        }]
    };

    return <ModalBox {...options}>
        {formatMessage({ id: 'modalbox:duplicateAndEdit:text' })}
    </ModalBox>
}

export default DuplicateAndEdit;