import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useGlobal from 'store';
import { CONVERT_TO_TEXT_LANGUAGES } from 'constants/constants';
import ModalBox from 'common/components/modalBox/ModalBox';
import InkToTextConverter from './InkToTextConverter';

import './InkToTextConverterComponent.scss'

function InkToTextConverterComponent(props) {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [globalState, globalActions] = useGlobal();
    const { isFetching, recognizedText } = globalState.hwr;
    const [selectedLanguageId, setSelectedLanguageId] = useState(intl.locale);
    const currentLanguageKey = selectedLanguageId ?? intl.locale;
    const modalTitle = formatMessage({ id: 'global:convertToText' });
    let dropDownLabel = formatMessage({ id: 'global:convertTo' },
        { language: formatMessage({ id: CONVERT_TO_TEXT_LANGUAGES[currentLanguageKey].value }) });
    const onRecognizeText = globalActions.home.recognizeText;
    const locale = CONVERT_TO_TEXT_LANGUAGES[currentLanguageKey].locale;
    const onFailureMessage = formatMessage({ id: 'modalbox:exportError:message' });
    const rtl = locale === 'ar'
    const { selection } = props;

    const options = {
        id: 'ink-to-text-modal-container',
        title: modalTitle,
        onDestroy: props.onDestroy
    };

    const requestInkToText = (langId) => {
        setSelectedLanguageId(langId)
    }

    useEffect(() => {
        onRecognizeText(selection, locale, onFailureMessage);
    }, [currentLanguageKey, selection, locale, onFailureMessage, onRecognizeText])

    return <ModalBox {...options}>
        <InkToTextConverter
            changeLanguage={requestInkToText}
            label={dropDownLabel}
            activeLabel={dropDownLabel}
            text={recognizedText}
            rtl={rtl}
            selectedLanguage={currentLanguageKey}
            source={CONVERT_TO_TEXT_LANGUAGES}
            showSpinner={isFetching}
        />
    </ModalBox>
}

export default InkToTextConverterComponent;