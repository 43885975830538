const messages = {
  'documents:empty:noresult:noresult' : 'НЕТ РЕЗУЛЬТАТОВ',
  'documents:empty:noresult:noresultfound' : 'Нет результатов, соответствующих Вашим критериям.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Подключив свое устройство к приложению Ink Space и зарегистрировав аккаунт Wacom ID, Вы увидите здесь весь свой контент.',
  'documents:empty:stream:explanation1:webstream' : 'Испытайте возможности Ink Collaboration.  Используйте возможности совместной работы и обмена идеями с другими людьми во время создания своих проектов. Попробуйте сегодня',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'После подключения Bamboo Paper с помощью идентификатора Wacom ID весь Ваш контент будет отображаться здесь.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Делайте заметки. Делайте больше. Как на бумаге, только лучше.',
  'documents:empty:stream:tryitnow' : 'Попробуйте сейчас',
  'documents:empty:stream:learnmore' : 'УЗНАЙТЕ БОЛЬШЕ',
  'documents:empty:group' : 'Ваша группа пуста',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes Stream',
  'streams:collaboration': 'Потоковая трансляция для совместной работы',

  'filter:result' : 'РЕЗУЛЬТАТ ДЛЯ',
  'filter:search' : 'ПОИСК...',

  'footer:navigation:privacy' : 'КОНФИДЕНЦИАЛЬНОСТЬ',
  'footer:navigation:termOfUse' : 'УСЛОВИЯ ИСПОЛЬЗОВАНИЯ',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Настройки файлов cookie',

  'global:convertTo' : 'КОНВЕРТИРОВАТЬ В {language}',
  'global:convertToText' : 'Конвертировать в текст',

  'context-menu:createNewCanvas' : 'Создать новую рабочую область',
  'context-menu:createNewGroup' : 'Создать группу',
  'context-menu:startNewCollaboration' : 'Начать новую совместную работу',
  'context-menu:startCollaboration' : 'Начать совместную работу',
  'context-menu:edit' : 'Редактировать',

  'global:delete' : 'Удалить',
  'global:rename' : 'Переименовать',
  'global:export' : 'Экспорт',
  'global:tags' : 'Теги',
  'global:groups': 'Группы',
  'global:shareLink' : 'Поделиться ссылкой',
  'global:copy' : 'КОПИР.',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Дублировать и редактировать',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Сохранить',
  'global:dontsave' : 'Не сохранять',
  'global:proceed' : 'ПРОДОЛЖИТЬ',
  'global:enterName' : 'ВВЕДИТЕ ИМЯ',
  'global:browserUnsupported' : 'Браузер не поддерживается',

  'global:language' : 'ЯЗЫК',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Выйти',
  'global:login' : 'Войти в систему',
  'global:myAccount' : 'Моя учетная запись',

  'inputField:existsName' : 'Ошибка! Имя уже существует!',
  'inputField:incorrectName' : 'Ошибка! Неправильное имя!',
  'inputField:notInRange' : 'Ошибка! Количество символов в имени должно составлять от {minLength} до {maxLength}!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Используйте веб-браузер Chrome или Firefox для подключения к сеансу совместной работы. Спасибо',
  'modalbox:browser:unsupport:sf:text': 'Используйте веб-браузер Chrome или Safari для подключения к сеансу совместной работы. Спасибо',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Error!',
  'modalbox:error:buttonTitle' : 'Закрыть',
  'modalbox:exportError:message' : 'Экспорт в настоящее время невозможен',
  'modalbox:createTag:buttons:title' : 'СОЗДАТЬ',
  'modalbox:createTag:title' : 'СОЗДАТЬ НОВЫЙ ТЕГ',
  'modalbox:deleteTag:message' : 'Вы собираетесь удалить <span>"{tag}"</span>. Это действие является необратимым.',
  'modalbox:removeDocument:message' : 'Вы собираетесь удалить <span>"{docTitle}"</span>. Это действие является необратимым.',
  'modalbox:shareLink:buttons:stopSharing' : 'Закрыть совместный доступ',
  'modalbox:sessionFull:title' : 'Достигнуто максимальное количество участников сеанса совместной работы',
  'modalbox:sessionFull:text' : 'К сожалению, к сеансу совместной работы, к которому Вы пытаетесь получить доступ, больше невозможно добавить участников.',
  'modalbox:leaveSession:title' : 'Покинуть сеанс',
  'modalbox:leaveSession:buttons:leave' : 'Покинуть',
  'modalbox:leaveSession:text' : 'Покинуть сеанс совместной работы?',
  'modalbox:endSession:title' : 'ЗАВЕРШИТЬ СЕАНС',
  'modalbox:endSession:buttons:end' : 'Завершить',
  'modalbox:endSession:text' : 'Завершить текущий сеанс совместной работы? Текущий прогресс автоматически сохранится в трансляции веб-портала.',
  'modalbox:sessionEnded:title' : 'Сеанс завершен',
  'modalbox:sessionEnded:text' : 'Сохранить копию сеанса совместной работы в трансляции веб-портала?',
  'modalbox:accessDenied:title' : 'Отказано в доступе',
  'modalbox:accessDenied:buttons:goBack' : 'Назад',
  'modalbox:accessDenied:text' : 'Время сеанса совместной работы, к которому Вы пытаетесь присоединиться, истекло.',
  'modalbox:collaborationStarted:title' : 'Совместная работа начата',
  'modalbox:collaborationStarted:warn' : 'Представление Ink Collaboration должно оставаться открытым.  При закрытии этой вкладки или переключении на другую вкладку сеанс Ink Collaboration будет завершен.',
  'modalbox:collaborationStarted:text' : 'Можно пригласить участников (до 10), поделившись с ними URL-адресом сеанса совместной работы, отображаемым в браузере.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Не отображать',
  'modalbox:duplicateAndEdit:text' : 'Новая расширенная версия страницы будет помещена в Вашу трансляцию веб-портала Ink Space без изменения исходной версии.',
  'modalbox:saveChanges:title' : 'Сохранить изменения',
  'modalbox:saveChanges:text' : 'Сохранить изменения в документе перед закрытием?',
  'modalbox:collaborationError:title' : 'Ошибка функции совместной работы!',
  'modalbox:collaborationError:buttons:close' : 'Закрыть',
  'modalbox:collaborationError:text' : 'Используемый Вами браузер не поддерживается функцией Ink Collaboration.  Эта функция лучше всего работает с Firefox (начиная с версии 55), Chrome (начиная с версии 60) и Safari (начиная с версии 11)',
  'modalbox:WebGLError:title' : 'Произошла ошибка',
  'modalbox:WebGLError:text' : 'Произошла ошибка. Необходимо перезагрузить содержимое, используя кнопку перезагрузки в сообщении выше.',
  'modalbox:collaborationJoinFailed:title': 'Невозможно установить соединение',
  'modalbox:collaborationJoinFailed:text': 'Невозможно получить доступ к сеансу, к которому Вы хотите присоединиться. Повторите попытку позже.',
  'modalbox:connectionLost:title': 'Соединение потеряно',
  'modalbox:connectionLost:text': 'Соединение было потеряно. Проверьте подключение к Интернету и повторите попытку.',
  'modalbox:phoneDetection:title': 'Использовать мобильное приложение',
  'modalbox:phoneDetection:btn:open': 'Открыть',
  'modalbox:phoneDetection:btn:no-thanks': 'Нет, спасибо',
  'modalbox:phoneDetection:msg': 'Для удобства работы рекомендуется использовать наше специальное мобильное приложение на телефоне.',
  'modalbox:chooseVectorOrRaster:title' : 'Выбрать режим редактирования',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Заметки',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Перевести в векторный вид',
  'modalbox:chooseVectorOrRaster:text' : 'Содержимое, которое Вы пытаетесь редактировать, имеет растровый формат, и для сохранения его первоначального вида рекомендуется добавить заметки поверх него. Кроме того, Вы можете также преобразовать его в векторный формат, но результат может отличаться от оригинала в зависимости от используемых инструментов.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Удалить группу',
  'modalbox:deleteGroup:title:multiple' : 'Удалить группы',
  'modalbox:deleteGroup:button:removeGroup' : 'Удалить группу',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Удалить группы',
  'modalbox:deleteGroup:message' : 'Удалить только группу <span>«{titles}»</span> или также и ее содержимое? Это действие нельзя отменить.',
  'modalbox:deleteGroup:message:multiple': 'Удалить только группы <span>«{titles}»</span> или также и их содержимое? Это действие нельзя отменить.',
  'modalbox:deleteGroup:button:removeAll': 'Удалить все',
  'modalbox:deleteGroup:button:cancel': 'Отмена',
  'modalbox:deletePage:title': 'Удалить страницу',
  'modalbox:deletePage:message': 'Удалить <span>«{titles}»</span> или удалить ее из этой группы?',
  'modalbox:deletePage:removePage': 'Удалить из группы',
  'modalbox:group:rename': 'Переименовать группу ',
  'mailbox:exportLimit:title': 'Достигнуто ограничение',
  'mailbox:exportLimit:message': 'Превышено максимально допустимое количество заметок ({maxExportPages}). Выберите меньшее количество заметок и повторите попытку.',

  'toast:groupAddedSuccess:single': 'Страница успешно добавлена в «{groupName}»',
  'toast:groupAddedSuccess:plural': 'Страницы успешно добавлены в «{groupName}»',
  'toast:pagesRemovedFromGroup:single': 'Страница успешно удалена из «{groupName}»',
  'toast:pagesRemovedFromGroup:plural': 'Страницы успешно удалены из «{groupName}»',
  'toast:groupCreated': 'Группа «{groupName}» успешно создана',
  'toast:groupRemoved': 'Группа «{groupName}» успешно удалена',
  'toast:groupRemoved:andPages': 'Группа «{groupName}» и страницы успешно удалены',
  'toast:groupRemoved:plural': 'Выбранные группы успешно удалены',
  'toast:noteRemoved:single': 'Страница успешно удалена',
  'toast:noteRemoved:plural': 'Страницы успешно удалены',

  'sorter:ascending' : 'По возрастанию',
  'sorter:date' : 'Дате',
  'sorter:descending' : 'По убыванию',
  'sorter:name' : 'Названию',
  'sorter:size' : 'Размеру',
  'sorter:sortby' : 'Сортировать по ',
  'sorter:type' : 'Типу',

  'tagContainer:createTag' : 'СОЗДАТЬ ТЕГ',
  'tagContainer:noTags' : 'Нет тегов',
  'tagContainer:noTagsMessage' : 'Созданные теги отображаются здесь',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'Созданные группы отображаются здесь',

  'export:asDoc' : 'как DOC',
  'export:asPsd' : 'как PSD',
  'export:asJpg' : 'как JPG',
  'export:asPdf' : 'как PDF',
  'export:asPng' : 'как PNG',
  'export:asSvg' : 'как SVG',
  'export:asWill' : 'как WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'как PowerPoint',

  // Ink to Video
  'video:generating' : 'Выполняется создание Вашего видео',
  'video:timeNotice' : 'Это может занять некоторое время',
  'video:estimatedTime.one': 'Это может занять до минуты',
  'video:estimatedTime.many': 'Это может занять до <span>{minutes}</span>минут',
  'video:fileReady' : 'Ваш файл готов',
  'video:download'  : 'Скачивание',
  'video:expirationMessage' : 'Файл для скачивания будет автоматически удален через <span>{days}</span>дней',
  'video:error' : 'Не удалось создать видео',

  // Session Expired Prompt
  'session:expired:title': 'Время сеанса истекло',
  'session:expired:message': 'Время Вашего сеанса истекло. Войдите повторно.',
  'session:expired:button': 'Войти',

  // Auth module
  'auth:email:label' : 'Адрес электронной почты',
  'auth:email:taken' : 'Этот адрес электронной почты недоступен.',
  'auth:email:notRegisterd' : 'Этот адрес электронной почты не зарегистрирован.',
  'auth:email:invalid' : 'Недействительный адрес электронной почты',
  'auth:password:label' : 'Пароль',
  'auth:password:reenter:label' : 'Введите пароль повторно',
  'auth:password:mismatch:label' : 'Пароли не совпадают',
  'auth:password:tooEasy:label' : 'Минимальное количество символов в пароле: {charLength}, в том числе 3 из них — следующего типа: буква в верхнем регистре, буква в нижнем регистре, цифра, специальный символ.',
  'auth:firstName:label' : 'Имя',
  'auth:lastName:label' : 'Фамилия',
  'auth:country:label' : 'Страна',
  'auth:country:select' : 'Выберите страну',
  'auth:language:label' : 'Язык',
  'auth:language:select' : 'Выберите язык',
  'auth:termsAndConditions:label' : 'Вы прочли <a href="{termsHref}">условия использования</a> компании Wacom и согласились с их положениями.',
  'auth:privacyPolicy:label' : 'Вы прочли <a href="{privacyHref}">политику безопасности</a> и <a href="{cookieHref}">политику в отношении файов cookie</a> компании Wacom и согласились с их положениями.',
  'auth:login:invalid' : 'Недействительный логин или пароль.',
  'auth:login:title' : 'Войдите в систему со своим идентификатором Wacom ID',
  'auth:login:allWacom:text' : 'Ваша учетная запись Wacom на все случаи жизни.',
  'auth:login:forgotPassword:link' : 'Я забыл(-а) пароль',
  'auth:login:button' : 'Логин',
  'auth:login:signup:button' : 'Регистрация',
  'auth:login:createNewAccount:label' : 'Вы еще не создали учетную запись?',
  'auth:login:alreadyHaveAccount:label' : 'У Вас уже есть учетная запись?',
  'auth:passwordReset:title' : 'Сброс пароля',
  'auth:passwordReset:text' : 'Введите адрес электронной почты, который Вы использовали для идентификатора Wacom ID. Мы отправим Вам ссылку для сброса пароля.',
  'auth:passwordReset:reset:button' : 'Сбросить',
  'auth:passwordReset:signIn:button' : 'Войти в систему',
  'auth:passwordReset:back:button' : 'Назад к',
  'auth:passwordReset:success:text' : 'На Ваш адрес электронной почты было отправлено письмо.',
  'auth:register:title' : 'Создайте свой профиль',
  'auth:register:success:title1' : 'Поздравляем!',
  'auth:register:success:title2' : 'Вы завершили регистрацию',
  'auth:register:success:message' : 'Чтобы продолжить, проверьте свою электронную почту для подтверждения регистрации и войдите в систему для просмотра Вашей панели инструментов.',
  'auth:errors:generic' : 'Что-то пошло не так. Повторите попытку.',
  'auth:policyUpdate:title': 'С момента Вашего последнего посещения мы изменили наши условия использования и политику безопасности',

  // Welcome screens
  'welcome:screen1:header': 'Функции Ink Collaboration',
  'welcome:screen1:text': 'Ink Collaboration позволяет вести совместную работу с кем угодно и где угодно. Выберите холст из своего контента или новый холст и начните рисовать вместе с другими пользователями.',
  'welcome:screen2:header': 'Синхронизация и передача контента',
  'welcome:screen2:text': 'С идентификатором Wacom ID и облачными приложениями Ваш контент будет доступен в Интернете в любое время и в любом месте. Экспортируйте его в другие форматы, чтобы продолжить работу со своими любимыми программными инструментами и приложениями.',
  'welcome:screen3:header': 'Получите доступ ко всему Вашему контенту',
  'welcome:screen3:text': 'Ваш идентификатор Wacom ID откроет множество новых функций и рабочих процессов для Вашего контента. Работайте со своим контентом из любого места, делитесь им с другими пользователями или защитите его при помощи резервного копирования в режиме онлайн. Идентификатор Wacom ID — Ваш ключ к облачным технологиям.',
  'welcome:button:next': 'Далее',
  'welcome:button:done': 'Выполнено',

  // Migration screen
  'migration:screen1:text1': 'Выполняется оптимизация контента. Ожидайте.',
  'migration:screen1:text2': 'Выполняется обработка...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Обновление службы',
  'whatIsNew:cloudMigration:mainText': 'Мы вносим усовершенствования в работу наших облачных служб. Возможны небольшие задержки в просмотре или использовании контента. Благодарим Вас за терпение.',
  'whatIsNew:cloudMigration:cloudTeam': '«- Облачная команда Wacom»',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Выдержка',
  'semantic:sidebar:confidence': 'уверенность',
  'semantic:sidebar:reference': 'Справка',

  // Semantic-ink icon description
  'semantic:type:organization': 'организация',
  'semantic:type:topic': 'тема',
  'semantic:type:person': 'лицо',
  'semantic:type:location': 'местоположение',
  'semantic:type:event': 'событие',
  'semantic:error:header': 'Распознавание Semantic Ink отсутствует',
  'semantic:error:text': 'Ваша заметка не требует улучшения с помощью Semantic Ink.',
  
  // Download my data
  'dataDownload:profile:option': 'Мои данные',
  'dataDownload:tabs:downloadData': 'Загрузить данные',
  'dataDownload:tabs:availableData': 'Доступные данные',
  'dataDownload:tabs:downloadData:description': 'Экспортируйте и загрузите копию контента Вашей учетной записи Inkspace.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Выберите...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Выбрать все',
  'dataDownload:processing:description': 'Загрузка файлов может занять некоторое время. Когда загрузка будет завершена, Вы получите электронное письмо со ссылкой для загрузки или сможете выполнить загрузку здесь.',
  'dataDownload:processing': 'Выполняется обработка...',
  'dataDownload:request:button': 'Загрузить данные',
  'dataDownload:tabs:availableData:description': 'Загрузите Ваши данные',
  'dataDownload:tabs:availableData:archiveFor': 'Архив для ',
  'global:myData': 'Мои данные'
}

export default messages;