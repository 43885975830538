import React, { useState, useEffect } from 'react';
import { Color } from 'digital-ink';
import Slider from './Slider';
import HSLView from './HSLView';

import './HSLColorPicker.scss';

function HLSColorPicker(props) {
    const { color, setColor } = props;
    const hsla = color.toHSLA();
    const [hue, setHue] = useState(hsla.hue / 3.6);
    const [saturation, setSaturation] = useState(hsla.saturation);
    const [lightness, setLightness] = useState(hsla.lightness);
    const [alpha] = useState(hsla.alpha);

    const styles = { backgroundColor: props.color.toString() };
    const sliderStyle = { backgroundColor: `hsl(${hsla.hue}, 100%, 50%)` };

    useEffect(() => {
        setColor(Color.fromHSLA(hue * 3.6, saturation, lightness, alpha));
    }, [setColor, hue, saturation, lightness, alpha]);


    return <div className={
        /*mobileBrowsers()*/ false ? 'hsl-picker hsl-mobile' : 'hsl-picker'
    }
    >
        <HSLView styles={styles} height={100} />
        <Slider id={'hue'} styles={sliderStyle} value={hue} onChange={setHue} />
        <Slider id={'saturation'} styles={sliderStyle} value={saturation} onChange={setSaturation} />
        <Slider id={'lightness'} styles={sliderStyle} value={lightness} onChange={setLightness} />
    </div>
}

export default HLSColorPicker;