import React, { useRef, useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './EyeDropper.scss';

const eyeDropperToolId = INK_EDITOR_CONFIG.controls.eyedropper;

function EyeDropper() {
    const toolRef = useRef();
    const { toolId, prevToolId, setToolId } = useContext(CanvasContext);
    const isActive = toolId === eyeDropperToolId;

    let classes = 'icon inactive';
    if (prevToolId === INK_EDITOR_CONFIG.controls.pen || prevToolId === INK_EDITOR_CONFIG.controls.highlighter) {
        classes = isActive ? 'icon active' : 'icon icon-eyedropper';
    }

    return <div className={'eyedropper-tool ink-tool'}>
        <i
            className={classes}
            ref={toolRef}
            onClick={() => setToolId(eyeDropperToolId)} />
    </div>
}

export default EyeDropper;