import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import LazyImage from 'common/components/lazyImage/LazyImage';
import Controls from './Controls';
import PageIndicator from 'routes/inkEditor/containers/WillCanvas/PageIndicator';
import { hasContextMenu } from '../home/documents/inkNoteHelper';
import { CSS_TRANSITION_ENTER_ANIMATION_TIME } from 'constants/constants';
import { addBpPaperTypeStyle } from 'common/bambooPaperHelper';


import './PreviewComponent.scss';

function PreviewComponent(props) {
    const previewWrapperRef = useRef();
    const [operationsQueue, setOperationQueue] = useState([]);
    const [animationName, setAnimationName] = useState('');
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const history = useHistory();
    const { modalState, actions, onDestroy } = props;
    const { document, prev, next, pagePreviews } = modalState.preview;
    const documentId = document.id;
    const isModalOpened = modalState.modals.isOpen;
    const { doSelectPreviewDoc, cancelMultiPagePreviewFetch } = actions;
    const setPageIdGlobal = actions.setCurrentPageId;

    const changeDocument = useCallback((docId) => {
        const animationName = docId === next ? 'slide-left' : 'slide-right';

        setAnimationName(animationName);

        setOperationQueue(queue => {
            return [...queue, { documentId: docId, pageIndex: 0, pageId: null }]
        });
    }, [next]);

    useEffect(() => {
        if (operationsQueue.length > 0) {
            const op = operationsQueue.pop();
            const { documentId, pageIndex, pageId } = op;

            if (documentId) {
                doSelectPreviewDoc(documentId);
            }
            setCurrentPageIndex(pageIndex);
            setPageIdGlobal(pageId);
            setOperationQueue(operationsQueue);
        }
    }, [
        document, animationName, doSelectPreviewDoc,
        setCurrentPageIndex, setPageIdGlobal,
        operationsQueue, setOperationQueue
    ])

    const changePage = useCallback((pageId) => {
        const targetIndex = document.pageIds.indexOf(pageId);
        const animationName = currentPageIndex < targetIndex ? 'slide-down' : 'slide-up';

        setAnimationName(animationName);
        setOperationQueue(queue => {
            return [...queue, { pageIndex: targetIndex, pageId: pageId }]
        });
    }, [currentPageIndex, document.pageIds]);

    const openContextMenu = (ev) => {
        setIsContextMenuOpen(true);
        actions.doShowContextMenu(document, { x: ev.clientX, y: ev.clientY });
    }

    const closeContextMenu = () => {
        if (isContextMenuOpen) {
            setIsContextMenuOpen(false);
            actions.doCloseContextMenu();
        }
    }

    // The keyHandler should be changed every time the document changes
    // otherwise the prev and next referenced on the handles point to stale data
    useEffect(() => {
        const keyHandler = (ev) => {
            let targetId;
            // ArrowLeft
            if (ev.keyCode === 37 && prev) {
                targetId = prev;
            }
            // ArrowRight
            else if (ev.keyCode === 39 && next) {
                targetId = next;
            }
            // Escape
            // preview modal is not closed on esc if another modal is also visible.
            else if (ev.keyCode === 27 && !isModalOpened) {
                ev.preventDefault();
                onDestroy();
                return;
            }

            if (targetId) {
                changeDocument(targetId);
            }
        }

        window.addEventListener('keyup', keyHandler);
        return () => { window.removeEventListener('keyup', keyHandler) };
    }, [prev, next, history, isModalOpened, changeDocument, onDestroy]);

    // Cancels any pending preview fetch
    useEffect(() => {
        return () => {
            cancelMultiPagePreviewFetch();
        }
    }, [cancelMultiPagePreviewFetch]);

    let mainImage;
    const paperTypeIndex = document?.paperTypeIndex ?? document?.properties?.paperTypeIndex ?? '';
    const mainImageStyles = addBpPaperTypeStyle(paperTypeIndex);

    if (document.htmlContentUrl) {
        mainImage = <iframe title={document.title} width={'620px'} className={'inknote-holder'} src={document.htmlContentUrl} />
    } else {
        let targetImage = document.previewUrl;
        const imageWidth = document?.width?.value?.value;
        const imageHeight = document?.height?.value?.value;

        if (currentPageIndex > 0 && document?.pageIds) {
            const pageId = document.pageIds[currentPageIndex];
            const targetPagePreview = pagePreviews && pagePreviews[pageId];
            if (targetPagePreview) {
                targetImage = targetPagePreview.previewUrl;

            }
        }
        // For semitranslucent images with highlighter some visual glitches appear
        // and placeholder images are not set for lazy loading
        mainImage = <LazyImage
            style={mainImageStyles}
            key={document.id}
            src={targetImage}
            width={imageWidth}
            height={imageHeight}
            alt={document.label ?? 'unnamed document'}
            error={animationName ? '' : 'blur'}
            effect={'blur'} />
    }

    const transitionKey = `${documentId || '_'}_${currentPageIndex || '0'}`;
    const isPageIndicatorVisible = pagePreviews && Object.keys(pagePreviews).length > 1;

    return <div id={'preview-modal-container'} onContextMenu={openContextMenu}>
        <button className='close' onClick={onDestroy} />
        <div className='preview-overlay' onClick={closeContextMenu}>
            <SwitchTransition mode={'out-in'}>
                <CSSTransition key={transitionKey} classNames={animationName} timeout={CSS_TRANSITION_ENTER_ANIMATION_TIME}>
                    <div ref={previewWrapperRef} className={`preview-wrapper`} >
                        {mainImage}
                    </div>
                </CSSTransition>
            </SwitchTransition>
            <Controls
                key={'controls'}
                title={document.label ?? document.title}
                creationDate={document.creationDate ?? document.created}
                prev={prev}
                next={next}
                onClose={onDestroy}
                onMoreClicked={openContextMenu}
                onChange={changeDocument}
                onOutsideClick={actions.hideContextMenu}
                isMoreButtonVisible={hasContextMenu(document)}
            />
            <CSSTransition in={isPageIndicatorVisible}
                timeout={CSS_TRANSITION_ENTER_ANIMATION_TIME}
                classNames={'fade-in-transition'}
                unmountOnExit
            >
                <PageIndicator
                    pageId={document?.pageIds[currentPageIndex]}
                    pageIds={document?.pageIds ?? []}
                    onPageChange={changePage}
                /></CSSTransition>
        </div>
    </div >
}

export default PreviewComponent;