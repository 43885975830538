import React from 'react';

import Filter from './filter/Filter';
import Sorter from './sort/Sorter';

import './NavigationComponent.scss';

function NavigationComponent(props) {

    return <div className={'navigation-container'}>
        <Filter {...props} />
        <Sorter {...props} />
    </div>
}

export default NavigationComponent;