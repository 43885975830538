import React from 'react';
import { useIntl } from 'react-intl';
import ModalBox from 'common/components/modalBox/ModalBox';
import { BAMBOO_STREAM } from 'constants/constants';
// import {
//     getToastSuccessMessageKey, TOASTER_ACTION_DEL_GROUP
// } from '../healpers/toastHelper';

import './DeleteGroup.scss';

const parseTitle = (selection) => {
    if (selection.label) {
        return selection.label;
    }

    return selection.selectedGroups.map(it => it.label).join(', ');
}

function DeleteGroup(props) {
    const { formatMessage } = useIntl();
    const { actions } = props;
    const { selectedGroups, selectedGroupIds, selectedIds } = props.selection;

    let mainMessageKey = 'modalbox:deleteGroup:message';

    const removeGroupsInternal = (deleteDocuments) => {
        const elementsToRemove = props.selection.id ? [props.selection] : selectedGroups;
        // const localizationKey = getToastSuccessMessageKey(TOASTER_ACTION_DEL_GROUP, [], elementsToRemove);
        actions.home.removeGroups(elementsToRemove, deleteDocuments)
    }
    const removeGroupOnly = () => removeGroupsInternal(false);
    const removeGroupAndDocs = () => removeGroupsInternal(true);

    const singleGroupMessages = {
        id: 'modal-delete-document',
        title: formatMessage({ id: 'modalbox:deleteGroup:title' }),
        buttons: [
            {
                onClick: removeGroupOnly,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:removeGroup' }),
                id: 'btn-delete-document'
            },
            {
                onClick: removeGroupAndDocs,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:removeAll' }),
                id: 'btn-delete-document'
            },
            {
                onClick: props.onDestroy,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:cancel' }),
                id: 'btn-delete-document-cancel'
            }
        ]
    }

    const multipleGroupsMessages = {
        id: 'modal-delete-document',
        title: formatMessage({ id: 'modalbox:deleteGroup:title:multiple' }),
        buttons: [
            {
                onClick: removeGroupOnly,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:removeGroup:multiple' }),
                id: 'btn-delete-document'
            },
            {
                onClick: removeGroupAndDocs,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:removeAll' }),
                id: 'btn-delete-document'
            },
            {
                onClick: props.onClose,
                title: formatMessage({ id: 'modalbox:deleteGroup:button:cancel' }),
                id: 'btn-delete-document-cancel'
            }
        ]
    };

    const isSingleGroupDelete = !!props.selection.id || selectedGroups.length === 1;
    const isGroupAndDocSelected = !isSingleGroupDelete && selectedIds.size > 0 && selectedGroupIds.size > 0;
    const hasBPDocs = selectedGroups.reduce((accumulator, item) => accumulator && item.applicationName === BAMBOO_STREAM);

    let popupMessages = null
    if (isSingleGroupDelete) {
        popupMessages = singleGroupMessages
    } else {
        popupMessages = multipleGroupsMessages
        mainMessageKey += ':multiple'
    }

    if (isGroupAndDocSelected) {
        popupMessages.title = formatMessage({ id: 'global:delete' })
    }

    if (hasBPDocs) {
        // Bamboo paper uses directories to simulate multi page documents
        // In BP stream documents are note visualized on the root level
        // That is why on directory remove all document that are inside are also deleted
        popupMessages.buttons = popupMessages.buttons.slice(1);
        mainMessageKey = 'modalbox:removeDocument:message';
    }

    const combinedTitles = parseTitle(props.selection);

    const localizedMainMessage = formatMessage(
        { id: mainMessageKey },
        {
            titles: combinedTitles,
            docTitle: combinedTitles,
            span: chunks => <span key={'delete-message'} className="group-name">{chunks}</span>,
        }
    );
    return <ModalBox {...popupMessages} onDestroy={props.onDestroy} >
        {localizedMainMessage}
    </ModalBox >
}

export default DeleteGroup;