import React from 'react';
import TagsSelection from './TagsSelection';
import SelectedDirectory from './SelectedDirectory';
import { ROOT_DIR_ID } from 'constants/constants';

import Stream from './Stream';

import './Filter.scss';

function showTags(props) {
    return !!props.tags.length
}

function showTerm(props) {
    return !!props.searchTerm.length
}

function showStream(props) {
    return !showTags(props) && !showTerm(props)
}

function getDirectoryName(props) {
    const { currentDirInfo } = props;
    if (currentDirInfo && currentDirInfo.id !== ROOT_DIR_ID) {
        return currentDirInfo.label;
    }

    return null;
}

function Filter(props) {
    const { query } = props.location;

    let state = {
        applicationName: query.applicationName,
        searchTerm: query.term ? [query.term] : [],
        tags: query.tags ? [query.tags] : [],
        title: query.s,
        selectedTag: [decodeURIComponent(query.tags)],
        groupId: query.mateGroupId
    };

    const directoryName = getDirectoryName(props);

    return <div className='filter-container'>
        {showTags(state) && <TagsSelection
            tags={state.selectedTag}
            tagClicked={props.actions.doRemoveTag}
            groupName={state.title} />}
        {showStream(state) && <Stream
            applicationName={state.applicationName}
            isClickable={directoryName}
            streamClicked={props.actions.doGoToStreamBase} />}
        {showTerm(state) && <TagsSelection
            tags={state.searchTerm}
            tagClicked={props.actions.doRemoveQueryTerm} />}
        <SelectedDirectory title={directoryName} />
    </div>
}

export default Filter;