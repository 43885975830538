import React from 'react';
import { useIntl } from 'react-intl';

function Controls(props) {
    const { title, prev, next, onChange, onMoreClicked } = props;
    let { creationDate } = props;
    creationDate = creationDate ?? Date.now();
    const date = new Date(creationDate);
    const { formatDate } = useIntl();
    const formattedDate = formatDate(date, { year: 'numeric', month: 'short', day: 'numeric' });

    return (
        <div className='controls'>
            {
                prev && <span className='pn prev' onClick={() => onChange(prev)} />
            }
            <span className='title'>
                {title}
            </span>
            <span className={'date'}>{formattedDate}</span>
            {props.isMoreButtonVisible && <span className={'icon more-btn'} onClick={onMoreClicked} />}
            {
                next && <span className='pn next' onClick={() => onChange(next)} />
            }
        </div>
    )
}


export default Controls;