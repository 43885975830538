import React from 'react';

import './HSLView.scss';

function HSLView(props) {

    return <div
        id={'hsl-view'}
        style={{
            ...props.styles,
            height: props.height
        }} />
}

export default HSLView;