import React from 'react';
import ButtonWithSpinner from 'common/components/buttons/ButtonWithSpinner';


import './ModalBox.scss'

function ModalBox(props) {

    const buttons = props.buttons ?? [];
    const mappedButtons = buttons.map((item, index) => {
        const isSpinnerVisible = item.isSubmit ? props.isSpinnerVisible : false;

        const handleButtonClick = (ev) => {
            if (!isSpinnerVisible && item.onClick) {
                item.onClick(ev);
            }
        };

        return <div key={'btn-' + index} style={{ display: 'inline-block' }}>
            <ButtonWithSpinner className={item.className ? item.className : null}
                id={item.id ? item.id : null}
                onClick={handleButtonClick}
                type={item.isSubmit ? 'submit' : 'button'}
                isSpinnerVisible={isSpinnerVisible}
            >
                {item.title}
            </ButtonWithSpinner>
        </div>
    });

    let modalBody = <span>
        <div className={'modal-box'} id={props.id ?? ''}>
            <div className={'modal-box-header'}>
                <h1>
                    {props.title}
                </h1>
                <button type={'button'} className={'icon clear-button'} onClick={props.onDestroy} />
            </div>
            <div className={'modal-box-body'}>
                {props.children}
            </div>
            <div className={'modal-box-footer'}>
                {mappedButtons}
            </div>
        </div>
    </span>;

    if (props.isSubmit) {
        modalBody = <form onSubmit={props.onFormSubmit}>{modalBody}</form>
    }

    return <>
        <div className={'modal-box-overlay'}>
            {modalBody}
        </div>
    </>
}

export default ModalBox;