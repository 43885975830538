import React, { useEffect } from 'react';
import useGlobal from 'store';
import { useIntl } from 'react-intl';
import { LANGUAGES } from 'constants/constants';
import LanguageDropDown from 'common/components/menu/languageDropDown/LanguageDropDown';

const mapState = (state) => state.localization.locale;
// const mapAction = (actions)


function ChooseLanguage() {
    const [state, globalActions] = useGlobal(mapState);
    const intl = useIntl();
    const { formatMessage } = intl;
    const selectedLanguage = state;
    const { showOverlay, hideOverlay } = globalActions.home;

    const activeLabel = formatMessage({ id: 'global:language' });

    useEffect(() => {
        globalActions.localization.changeGenericErrorMessage(formatMessage({ id: 'auth:errors:generic' }));
    }, [selectedLanguage, globalActions, formatMessage])

    return <LanguageDropDown
        changeLanguage={globalActions.localization.changeLocale}
        selectedLanguage={selectedLanguage}
        showOverlay={showOverlay}
        hideOverlay={hideOverlay}
        languages={LANGUAGES}
        showLanguageHead={true}
        activeLabel={activeLabel} />
}

export default ChooseLanguage;