import queryString from 'query-string';
import { HOME_PATH, PREVIEW_PATH, INK_EDITOR_PATH, SEMANTIC_PATH } from 'constants/constants';


const changeBaseAddress = (newBase, query) => {
    let newUrl = newBase;

    if (query) newUrl += `?${query}`;

    return newUrl;
}

export const setPreviewDocument = (documentId) => {
    const parsed = queryString.parse(window.location.search);
    parsed.docId = documentId;

    return changeBaseAddress(PREVIEW_PATH, queryString.stringify(parsed));
}

export const removePreviewDocument = () => {
    const parsed = queryString.parse(window.location.search);
    delete parsed.docId;

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const parseQueryParams = (url) => {
    return queryString.parse(url ?? window.location.search)
}

export const setOpenedGroup = (groupId, applicationName) => {
    const parsed = queryString.parse(window.location.search);

    delete parsed.query;
    delete parsed.term;

    parsed.dirId = groupId;

    if (applicationName) {
        parsed.applicationName = applicationName;
    }

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const removeOpenGroup = () => {
    const parsed = queryString.parse(window.location.search);
    delete parsed.dirId;

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const addQueryTerm = (term) => {
    const parsed = queryString.parse(window.location.search);
    parsed.term = term;

    delete parsed.dirId;
    delete parsed.tagId;
    delete parsed.tags;

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const removeQueryTerm = () => {
    const parsed = queryString.parse(window.location.search);
    delete parsed.term;

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const addTag = (tag) => {
    const parsed = queryString.parse(window.location.search);
    // only one active filter is supported
    delete parsed.term;
    parsed.tagId = tag.id;
    parsed.tags = encodeURIComponent(tag.label);

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const removeTag = () => {
    const parsed = queryString.parse(window.location.search);
    delete parsed.tagId;
    delete parsed.tags;

    return changeBaseAddress(HOME_PATH, queryString.stringify(parsed));
}

export const changeApplication = (application) => {
    return changeBaseAddress(HOME_PATH, queryString.stringify({ applicationName: application }));
}

const fillDocumentAndPage = (placeholder, documentId, pageId, locale) => {
    if (!locale) {
        placeholder = placeholder.replace('/:locale?', '');
    }
    if (!pageId) {
        placeholder = placeholder.replace('/:pageId?', '');
    }

    return placeholder.replace(':docId', documentId).replace(':pageId?', pageId).replace(':locale?', locale);
}
export const changeEditModePage = (documentId, pageId) => {
    const editorPath = fillDocumentAndPage(INK_EDITOR_PATH, documentId, pageId);
    return changeBaseAddress(editorPath);
}

export const changeSemanticView = (documentId, pageId, locale) => {
    const editorPath = fillDocumentAndPage(SEMANTIC_PATH, documentId, pageId, locale);
    return changeBaseAddress(editorPath);
}

export const openEditMode = (item, pageId) => {
    let editorPath = INK_EDITOR_PATH.replace(':docId', item.id);

    if (!pageId) {
        if (item.pageIds && item.pageIds.length > 0) {
            pageId = item.pageIds[0];
        } else {
            pageId = '';
        }
    }

    editorPath = editorPath.replace(':pageId?', pageId);

    return changeBaseAddress(editorPath);
}

export const isUrlActive = (url) => {
    if (!url) return false;

    const params = queryString.parse(decodeURIComponent(url));
    if (!params.se) return true;

    const urlDate = new Date(params.se);
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() - 5);

    if (urlDate.getTime() > currentDate.getTime()) {
        return true;
    }

    return false;
}