import React, { useEffect, useRef, useContext, useState } from 'react';
import { InputListener, InputDevice, InkCodec, BrushGL, Matrix, InkPathProducer } from 'digital-ink';
import CanvasContext from '../../context/CanvasContext';
import EyeDropperOverlay from './EyeDropperOverlay';
import InkCanvasRaster from 'routes/inkEditor/inking/InkCanvasRaster';
import InkCanvasVector from 'routes/inkEditor/inking/InkCanvasVector';
import PageIndicator from './PageIndicator';
import {
    INK_EDITOR_CONFIG, SPINNER_SETTINGS,
    IDENTITY_TRANSFORM_MATRIX, PUBLIC_URL
} from 'constants/constants';
import CloseButton from '../../components/CloseButton/CloseButton';
import ReactSpinner from 'react-spinjs-fix';
import config from '../../inking/Config';
import InkStorage from '../../inking/InkStorage';
import DataModel from '../../inking/DataModel';

import './WillCanvas.scss';

const identityTransform = IDENTITY_TRANSFORM_MATRIX;

const isSafari = () => {
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    return isSafari;
}

const rasterCanvasClassName = 'raster-canvas';
const vectorCanvasClassName = 'vector-canvas';


function applyImageTransform(ev) {
    const image = ev.target;
    const width = parseFloat(image.dataset.width);
    const height = parseFloat(image.dataset.height);

    // Scaling done with width and height property of the image
    // const scaleW = width / image.width;
    // const scaleH = height / image.height;
    // let matrix = Matrix.fromScale(scaleW, scaleH);
    // matrix = Matrix.multiply(Matrix.fromTranslate({
    //     x: parseFloat(image.dataset.x),
    //     y: parseFloat(image.dataset.y)
    // }), matrix);

    let matrix = Matrix.fromTranslate({
        x: parseFloat(image.dataset.x),
        y: parseFloat(image.dataset.y)
    })

    const transform = image.dataset.transform;
    if (transform) {
        matrix = Matrix.multiply(Matrix.fromMatrix(transform), matrix);
    }

    image.style.transform = matrix.toString();
    image.width = width;
    image.height = height;
}

async function initCanvas(canvasVector, canvasRaster, color, width, height, onRescale, images, isRaster = false) {
    let inkCanvas

    if (isRaster) {
        config.isRaster = true;
        config.tools.eraser = config.tools.eraserRaster;
        inkCanvas = new InkCanvasRaster(canvasRaster, width, height, onRescale);
        canvasVector.style.display = 'none';
        canvasRaster.style.display = 'block';
    } else {
        config.tools.eraser = config.tools.eraserVector;
        inkCanvas = new InkCanvasVector(canvasVector, width, height, onRescale);
        canvasVector.style.display = 'block';
        canvasRaster.style.display = 'none';
    }

    const inkStorage = new InkStorage(inkCanvas);

    let device = await InputDevice.createInstance({ "app.id": "will3-ink-space-web", "app.version": process.env.REACT_APP_VERSION });
    inkCanvas.init(device, "pen", color);

    InputListener.open(inkCanvas);

    inkCanvas.drawImages(images);
    inkCanvas.storage = inkStorage;

    return inkCanvas;
}

function destroy(inkCanvas) {
    if (inkCanvas) {
        if (inkCanvas.isRaster) {
            inkCanvas.strokeRenderer.delete();
            inkCanvas.strokesLayer.delete();
            inkCanvas.canvas.destroy();
        }
    }

    window?.wacom?.dataModel?.destroy();
}

function getCursor(toolId) {
    const { controls } = INK_EDITOR_CONFIG;

    switch (toolId) {
        case controls.pan:
            return 'move'
        default:
            return ''
    }
}

function getCssTransforms(transform) {
    const floats = transform.match(/-?\d*\.?\d+/g).map(Number);

    const transformMatrix = `matrix(${floats[0]}, ${floats[1]}, ${floats[2]}, ${floats[3]}, ${floats[4]}, ${floats[5]})`;

    return transformMatrix;
}

function hasRasterBrush(brushes) {
    let hasRasterStroke = false;
    for (let brush of brushes) {
        if (brush instanceof BrushGL) {
            hasRasterStroke = true;
            break;
        }
    }

    return hasRasterStroke;
}

async function handleInkChanges(canvas, setActivePage, pageIdRef) {
    const hasInkChanges = canvas.getHasInkChanges();

    if (setActivePage) {
        const inkModel = await canvas.storage.encode();
        const inkId = pageIdRef.current;

        if (inkModel) {
            setActivePage(inkId, inkModel, hasInkChanges);
        }
    }
}

const identityMatrix = 'matrix(1.0 0.0 1.0 0 0 0 0)';
let dataModel;

if (!window?.wacom?.dataModel) {
    if (!window.wacom) {
        window.wacom = {};
    }
    window.wacom.dataModel = new DataModel();
}

dataModel = window.wacom.dataModel;

function WillCanvas(props) {
    const {
        toolId, color, setScale, scale, setBaseScale,
        transform, setTransform
    } = useContext(CanvasContext);
    const canvasRef = useRef();
    const canvasAbsoluteRef = useRef();
    const canvasVectorHtmlElRef = useRef();
    const canvasRasterHtmlElRef = useRef();
    const imagesHtmlElRef = useRef();
    const pageIdRef = useRef();
    const colorRef = useRef(color);
    const [assetsLoaded, setAssetsLoaded] = useState(0);
    const [isCanvasReady, setIsCanvasReady] = useState(false);
    const {
        width, height, setActivePage, inkModels, showError, pageId,
        pageIds, images, isFetching, onPageChange
    } = props;
    const inkCodec = useRef(new InkCodec());
    const assetsToLoad = (images?.length ?? 0) + (inkModels?.length ?? 0);


    const onResize = () => {
        if (canvasRef.current) {
            canvasRef.current.lens.fit();
            canvasRef.current.drawImages(imagesHtmlElRef.current);
        }
    }

    useEffect(() => {
        if (assetsLoaded === assetsToLoad && assetsToLoad !== 0) {
            setIsCanvasReady(true);
        }
    }, [assetsLoaded, assetsToLoad, setIsCanvasReady])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        if (!isSafari()) {
            document.body.classList.add('ink-editor');
        }

        return () => {
            window.removeEventListener('resize', onResize);
            document.body.classList.remove('ink-editor');

            if (canvasRef.current) destroy(canvasRef.current);
        }
    }, []);

    useEffect(() => {
        if (canvasRef.current && toolId) {
            canvasRef.current.setTool(toolId);

            // Preserves the appropriate class name
            let baseClassName = rasterCanvasClassName;
            if (canvasRef.current.canvas.surface.className.contains(vectorCanvasClassName)) {
                baseClassName = vectorCanvasClassName;
            }

            canvasRef.current.canvas.surface.className = `${baseClassName} tool-${toolId}`;
            canvasAbsoluteRef.current.style.cursor = getCursor(toolId);
        }
    }, [toolId]);

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.setColor(color);
        }
    }, [color]);


    useEffect(() => {
        return () => destroy(canvasRef.current);
    }, []);

    // Used when changing document pages
    useEffect(() => {
        const asyncWrapper = async () => {
            if (inkModels && inkModels.length > 0) {
                // TODO: Add support for multiple ink models
                try {
                    const model = await inkCodec.current.decodeInkModel(inkModels[0]);
                    let hasRasterStroke = hasRasterBrush(model.brushes);

                    canvasRef.current = await initCanvas(
                        canvasVectorHtmlElRef.current,
                        canvasRasterHtmlElRef.current,
                        colorRef.current, width, height,
                        (scale, transform) => {
                            setScale(scale);
                            setBaseScale(oldScale => oldScale || scale);
                            setTransform(transform);
                        },
                        imagesHtmlElRef.current,
                        hasRasterStroke);

                    canvasRef.current.dataModel = dataModel;

                    if (canvasRef.current.storage.importBridge.status === InkPathProducer.Status.CLOSED) {
                        await canvasRef.current.storage.importBridge.open(`${PUBLIC_URL}/scripts/workers/InkPathProvider.js`);
                    }

                    await canvasRef.current.storage.openFile(model);

                    canvasRef.current.fit();
                    canvasRef.current.redraw();
                } catch (err) {
                    console.error(err);
                    showError && showError();
                } finally {
                    setAssetsLoaded(loadedCount => loadedCount + 1);
                }
            }
        };

        setAssetsLoaded(0);
        asyncWrapper();

        return () => {
            if (canvasRef.current) {
                InputListener.close(canvasRef.current);
            }
        }
    }, [inkModels, showError, width, height, setBaseScale, setScale, setTransform]);

    useEffect(() => {
        if (isFetching) {
            setIsCanvasReady(false);
        }
    }, [isFetching]);

    useEffect(() => {
        const asyncWrapper = async () => {
            if (canvasRef.current) {
                await handleInkChanges(canvasRef.current, setActivePage, pageIdRef);
            }

            pageIdRef.current = pageId;
        };

        asyncWrapper();
    }, [pageId, setActivePage]);

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.setScale(scale);
        }
    }, [scale]);

    useEffect(() => {
        const timeoutHandle = setTimeout(() => onResize(), 300);

        return () => {
            clearTimeout(timeoutHandle);
        }
    }, [props.isSematicInfoVisible]);


    const imagesElements = images.map(image => {
        let transform = identityTransform;

        if (image.transform) {
            transform = getCssTransforms(image.transform)
        }

        const inlineStyle = {
            position: 'absolute'
        };

        return <img alt={''} src={image.assetUri}
            key={image.id} style={inlineStyle} crossOrigin={'anonymous'}
            data-x={image.x}
            data-y={image.y}
            data-transform={transform}
            data-width={image.width}
            data-height={image.height}
            onLoad={ev => {
                applyImageTransform(ev);
                setAssetsLoaded(loadedCount => loadedCount + 1);
            }} />
    });

    let semanticData = null;

    if (props.isSematicInfoVisible && props.semanticData) {
        semanticData = props.semanticData.map((data, index) => {
            const inlineStyle = {
                position: 'absolute',
                top: data.boundingBox.y,
                left: data.boundingBox.x,
                width: data.boundingBox.width,
                height: data.boundingBox.height
            }
            let className = 'semantic-element-overlay';

            if (index === props.activeSemanticElement) {
                className += ' active';
            }

            return <div key={data.id} className={className} style={inlineStyle}
                onClick={(ev) => {
                    ev.stopPropagation();
                    let oldValue = null;

                    // Hack-y way to get the scroll to change pages smoothly
                    props.selectSematicEntity(value => {
                        oldValue = value;
                        return null;
                    });

                    setTimeout(() => {
                        props.selectSematicEntity(() => {
                            return oldValue !== index ? index : null;
                        });
                    }, 300);
                }}>
            </div>
        })
    }

    const getCloseContext = async () => {
        const hasInkChanges = canvasRef.current.getHasInkChanges();
        if (hasInkChanges) {
            await handleInkChanges(canvasRef.current, setActivePage, pageIdRef);
        }

        props.onClose();
    }

    // Used for transition animation
    const canvasStyle = {
        opacity: isCanvasReady ? 1 : 0,
        transform: isCanvasReady ? transform : identityMatrix,
        width: `${width}px`,
        height: `${height}px`
    };

    return <div>
        <div className={'canvas-relative'}
            onContextMenu={e => e.preventDefault()}
        >
            <div ref={canvasAbsoluteRef} className={'canvas-absolute'}
                style={canvasStyle}
            >
                <div ref={imagesHtmlElRef} id={'canvas-images'}>
                    {imagesElements}
                </div>
                <div id={'canvas-semantic-data'} onClick={() => props.selectSematicEntity && props.selectSematicEntity(null)}>
                    {semanticData}
                </div>
                <canvas ref={canvasVectorHtmlElRef} className={vectorCanvasClassName} />
                <canvas ref={canvasRasterHtmlElRef} className={rasterCanvasClassName} />
                {toolId === 'eyedropper' && <EyeDropperOverlay willCanvas={canvasRef.current != null ? canvasRef.current : null} />}
            </div>
            <CloseButton onClose={getCloseContext} />
            {pageIds && pageIds.length > 1 && <PageIndicator
                pageId={pageId}
                pageIds={pageIds}
                onPageChange={onPageChange}
            />}
            {!isCanvasReady && <ReactSpinner config={SPINNER_SETTINGS} />}
        </div >
    </div>
}

export default WillCanvas;