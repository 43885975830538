import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ModalBox from '../modalBox/ModalBox';

import { AUTH_PATH_BASE } from 'constants/constants';

function SessionExpired(props) {
    const history = useHistory();
    const { formatMessage } = useIntl();

    const redirectToLogin = () => {
        props.onDestroy();
        history.push(AUTH_PATH_BASE);
    };

    const title = formatMessage({ id: 'session:expired:title' });
    const expiredButtonTitle = formatMessage({ id: 'session:expired:button' });
    const buttons = [{
        onClick: redirectToLogin,
        id: 'login-redirect',
        title: expiredButtonTitle
    }];

    return <ModalBox title={title} buttons={buttons} onDestroy={props.onDestroy} />
}

export default SessionExpired;