import React from 'react'
import { LOGIN_VIEW } from 'constants/constants';
import { useIntl } from 'react-intl';
import { INSECURE_PASS } from '../containers/AuthContainer'

import {
    LANGUAGES, TERMS_OF_USE_LINK, COOKIE_NOTICE_LINK,
    PRIVACY_POLICY_LINK, MAX_PASS_LENGTH
} from '../../../constants/constants'


const Register = (props) => {
    const { formatMessage } = useIntl();

    const countries = Object.entries(props.nomenclature.countries || {});
    const languageList = Object.values(LANGUAGES);
    const hasPassReenterIssue = props.visibleErrors.passReenter.length !== 0;
    const emailErrorClass = (props.visibleErrors.email.length === 0 ? '' : 'not-valid-item ');
    const passwordErrorClass = (props.visibleErrors.password.length === 0 ? '' : 'not-valid-item ');
    const passwordInsecure = (props.visibleErrors.password.includes(INSECURE_PASS));
    const passwordReenterErrorClass = (!hasPassReenterIssue ? '' : 'not-valid-item ');
    const firstNameErrorClass = (props.visibleErrors.firstName.length === 0 ? '' : 'not-valid-item ');
    const lastNameErrorClass = (props.visibleErrors.lastName.length === 0 ? '' : 'not-valid-item ');

    const currentLocaleFull = LANGUAGES[props.locale].locale;
    const localeWithDash = currentLocaleFull.replace('_', '-');
    const termsOfUseLinkLocalized = TERMS_OF_USE_LINK.replace('{locale}', localeWithDash);
    const cookeNoticeLinkLocalized = COOKIE_NOTICE_LINK.replace('{locale}', localeWithDash);
    const privacyPolicyLinkLocalized = PRIVACY_POLICY_LINK.replace('{locale}', localeWithDash);

    // Values params don't work in v5
    const privacyPolicyLinkHtml = formatMessage(
        { id: 'auth:privacyPolicy:label' },
        {
            privacyHref: privacyPolicyLinkLocalized,
            cookieHref: cookeNoticeLinkLocalized,
        })
        .replace('{privacyHref}', privacyPolicyLinkLocalized)
        .replace('{cookieHref}', cookeNoticeLinkLocalized)
        .replaceAll('<a href', '<a target="_blank" href');

    let termsAndConditionsLinkHtml = formatMessage(
        { id: 'auth:termsAndConditions:label' },
        { termsHref: termsOfUseLinkLocalized })
        .replace('{termsHref}', termsOfUseLinkLocalized)
        .replace('<a href', '<a target="_blank" href');

    const passwordTooEasyErrorHtml = formatMessage(
        { id: 'auth:password:tooEasy:label' },
        { charLength: 9 });

    return (
        <div className='container-full form-wrapper'>
            <div>
                <h1>
                    {formatMessage({ id: 'auth:register:title' })}
                </h1>
            </div>
            <form id='sign-up' autoComplete='off' name='register' onSubmit={props.handleSubmit}>
                <div className='input-wrapper'>
                    <label htmlFor='email' className={emailErrorClass}>
                        {formatMessage({ id: 'auth:email:label' })}*
                    </label>
                    <input type='email' id='email' name='email'
                        autoComplete='new-email'
                        className={emailErrorClass + 'required-input-email'}
                        value={props.email}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur} />
                    {emailErrorClass ? <p className={'not-valid-item'}>
                        {formatMessage({ id: 'auth:email:invalid' })}
                    </p> : ''}
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='password' className={passwordErrorClass}>
                        {formatMessage({ id: 'auth:password:label' })}*
                    </label>
                    <input type='password' id='password' name='password'
                        autoComplete='new-password'
                        className={passwordErrorClass + 'required-password'}
                        value={props.password}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur}
                        maxLength={MAX_PASS_LENGTH} />
                    {passwordInsecure ? <p className={'not-valid-item'}>
                        <span dangerouslySetInnerHTML={
                            {
                                __html: passwordTooEasyErrorHtml
                            }} />
                    </p> : ''}
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='password-reenter' className={passwordReenterErrorClass}>
                        {formatMessage({ id: 'auth:password:reenter:label' })}*
                    </label>
                    <input type='password' id='password-reenter' name='passReenter'
                        value={props.passReenter}
                        className={passwordReenterErrorClass + 'required-password'}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur}
                        maxLength={MAX_PASS_LENGTH} />

                    {hasPassReenterIssue ? <p className={'pass-match-issue not-valid-item'}>
                        {formatMessage({ id: 'auth:password:mismatch:label' })}</p> : ''}
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='first-name' className={firstNameErrorClass}>
                        {formatMessage({ id: 'auth:firstName:label' })}*
                    </label>
                    <input type='text' id='first-name' name='firstName'
                        className={firstNameErrorClass}
                        value={props.firstName}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur} />
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='last-name' className={lastNameErrorClass}>
                        {formatMessage({ id: 'auth:lastName:label' })}*
                    </label>
                    <input type='text' id='last-name' name='lastName'
                        className={lastNameErrorClass}
                        value={props.lastName}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur} />
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='country'>
                        {formatMessage({ id: 'auth:country:label' })}*
                    </label>
                    <select className='select-required' id='country' name='country'
                        defaultValue='default' onChange={props.handleFieldChange} >
                        <option disabled value='default'>
                            {formatMessage({ id: 'auth:country:select' })}
                        </option>
                        {countries.map(country => <option value={country[0]} key={country[0]}> {country[1]} </option>)}
                    </select>
                </div>

                <div className='input-wrapper'>
                    <label htmlFor='language'>
                        {formatMessage({ id: 'auth:language:label' })}*
                    </label>
                    <select className='select-required' id='language'
                        defaultValue='default' name='language' onChange={props.handleFieldChange} >
                        <option disabled value='default'>
                            {formatMessage({ id: 'auth:language:select' })}
                        </option>
                        {languageList.map(language =>
                            <option value={language.code} key={language.code}>
                                {formatMessage({ id: language.value, code: language.value })}
                            </option>)
                        }
                    </select>
                </div>

                <div className="input-wrapper change-margin">
                    <div className="checkbox-wrapper flex-item">
                        <label htmlFor="termsOfUse" className="checkbox-label blueLink">
                            <span dangerouslySetInnerHTML={{ __html: termsAndConditionsLinkHtml }} />
                            <input type="checkbox" name='termsOfUse' id="termsOfUse"
                                checked={props.termsOfUse}
                                onChange={props.handleCheckboxChange} />
                            <span className="checkbox-mark"></span>
                        </label>
                    </div>
                </div>

                {localeWithDash.toLowerCase() === 'ko-kr' && <div className="korea-disclaimer">
                    <ul>
                        <li>
                            <strong>개인정보의 수집 및 이용목적</strong>
                            <ul>
                                <li>
                                    회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다
                                    <ul>
                                        <li>서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 </li>
                                        <li>회원 관리 회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 불만처리 등 민원처리 , 고지사항 전달</li>
                                        <li>마케팅 및 광고에 활용 이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>수집하는 개인정보 항목</strong>
                            <ul>
                                <li>
                                    회사는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
                                    <ul>
                                        <li>수집항목 : 이름, 로그인ID, 비밀번호, 유선전화번호, 휴대전화번호, 이메일, 직업, 신용카드 정보, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</li>
                                        <li>개인정보 수집방법 : 홈페이지(회원가입 , 대금결제, 배송)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>개인정보의 보유 및 이용기간</strong>
                            <ul>
                                <li>
                                    원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다
                                    <ul>
                                        <li>보존 항목 : 이름 , 로그인ID , 신용카드 정보 , 결제기록</li>
                                        <li>보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률</li>
                                        <li>보존 기간 : 5년</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>개인정보 수집 및 이용 동의를 거부할 권리</strong>
                            <ul>
                                <li>이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.</li>
                            </ul>
                        </li>
                    </ul>
                </div>}

                <div className="input-wrapper change-margin">
                    <div className="checkbox-wrapper flex-item">
                        <label htmlFor="privacyPolicy" className="checkbox-label blueLink">
                            <span dangerouslySetInnerHTML={{ __html: privacyPolicyLinkHtml }} />
                            <input type="checkbox" name='privacyPolicy' id="privacyPolicy"
                                checked={props.privacyPolicy}
                                onChange={props.handleCheckboxChange} />
                            <span className="checkbox-mark"></span>
                        </label>
                    </div>
                </div>

                <button type='submit'
                    className={(props.isSubmitActive ? '' : 'disable-btn ') + 'submit-btn btn-default'}
                    disabled={!props.isSubmitActive}>
                    {formatMessage({ id: 'auth:login:signup:button' })}
                </button>
                <p className='no-account-box'>
                    <span className='no-account-message'>
                        {formatMessage({ id: 'auth:login:alreadyHaveAccount:label' })}
                    </span>
                    <span className={'blueButton'} onClick={() => props.changeChildComponent(LOGIN_VIEW)}>
                        {formatMessage({ id: 'auth:passwordReset:signIn:button' })}
                    </span>
                </p>
            </form>
        </div>)
}

export default Register