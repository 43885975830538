import React from 'react';
import { useIntl } from 'react-intl'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import SemanticInfoComponent from './SemanticInfoComponent';
import TypeIconComponent from '../typeIcons/TypeIconComponent';

import './SemanticEntity.scss';

function SemanticEntity(props) {
    const { formatMessage } = useIntl();
    const { itemKey, item, selectedElement, setSelectedElement } = props;

    const handleActiveElementChange = ev => {
        let currentElement = ev.target;
        while (currentElement !== window && !currentElement.classList.contains('card-header')) {
            currentElement = currentElement.parentElement;
        }

        let elementKey = currentElement.dataset.elementKey;

        if (elementKey) {
            elementKey = parseInt(elementKey);
        }

        if (elementKey === selectedElement) elementKey = null;

        setSelectedElement(elementKey);
    }

    let cardClass = '';
    if (itemKey === selectedElement) {
        cardClass += ' active-card-element';
    }

    let confidence = item.confidence;

    const parsedConfidence = parseFloat(confidence);

    if (!Number.isNaN(parsedConfidence)) {
        if (parsedConfidence < 1) {
            confidence = Math.floor(parsedConfidence * 100)
        }
    }

    return <Card className={cardClass}>
        <Accordion.Toggle as={Card.Header} eventKey={itemKey} data-element-key={itemKey}
            onClick={handleActiveElementChange}>
            <div className={'semantic-entity'}>
                <h1 className={'flex'}>
                    <div className={'title flex-fill'}>
                        {item.label}
                    </div>
                    {
                        confidence &&
                        <span className={'confidence-metric'}>
                            {confidence}%
                        </span>
                    }
                </h1>
                <div className={'info-row flex'}>
                    <div className={'flex-fill'}>
                        <TypeIconComponent type={item.relevantConcept} />
                    </div>
                    {
                        confidence && <div>
                            {formatMessage({ id: 'semantic:sidebar:confidence' })}
                        </div>
                    }
                </div>
            </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={itemKey}>
            <Card.Body>
                <SemanticInfoComponent {...item} />
            </Card.Body>
        </Accordion.Collapse>
    </Card >
}

export default SemanticEntity;