export const TOASTER_ACTION_ADD = 'ADD'
export const TOASTER_ACTION_NEW = 'NEW'
export const TOASTER_ACTION_DEL = 'DEL'
export const TOASTER_ACTION_DEL_GROUP = 'DEL_GROUP'
export const TOASTER_ACTION_DEL_PAGE = 'DEL_PAGE'


// ------------------------------------
// Messages helper
const addSingleOrPluralPrefix = (string, isSingleDoc) => {
    const single = 'single'
    const plural = 'plural'
    string += ':'

    if (isSingleDoc) {
        string += single
    } else {
        string += plural
    }

    return string
}

export const getToastSuccessMessageKey = (action, docs, groups) => {
    const isSingleDoc = (docs.length && docs.length === 1) || (docs.size && docs.size === 1)
    const isSingleGroup = (Array.isArray(groups) && groups.length === 1) ||
        (typeof groups === 'string' || groups instanceof String)

    const isGroupArray = Array.isArray(groups) && groups.length > 1
    let resultKey = ''

    if (action === TOASTER_ACTION_ADD && isSingleGroup) {
        resultKey = 'toast:groupAddedSuccess'
        resultKey = addSingleOrPluralPrefix(resultKey, isSingleDoc)
    } else if (action === TOASTER_ACTION_DEL && isSingleGroup) {
        resultKey = 'toast:pagesRemovedFromGroup'
        resultKey = addSingleOrPluralPrefix(resultKey, isSingleDoc)
    } else if (action === TOASTER_ACTION_NEW && groups && !docs) {
        resultKey = 'toast:groupCreated'
    } else if (action === TOASTER_ACTION_DEL_GROUP && isSingleGroup && !docs) {
        resultKey = 'toast:groupRemoved'
    } else if (action === TOASTER_ACTION_DEL_GROUP && isSingleGroup && docs) {
        resultKey = 'toast:groupRemoved:andPages'
    } else if (action === TOASTER_ACTION_DEL_GROUP && isGroupArray) {
        resultKey = 'toast:groupRemoved:plural'
    } else if (action === TOASTER_ACTION_DEL_PAGE) {
        resultKey = 'toast:noteRemoved'
        resultKey = addSingleOrPluralPrefix(resultKey, isSingleDoc)
    }

    return resultKey
}
