export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const CREATE_UPDATE_TAG = 'CREATE_UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const RENAME_DIR = 'RENAME_DIR';
export const DELETE_DIR = 'DELETE_DIR';
export const DELETE_DOC = 'DELETE_DOC';
export const EXPORTS = 'EXPORTS';
export const INK_TO_TEXT = 'INK_TO_TEXT';
export const ON_PHONE_DETECTION = 'ON_PHONE_DETECTION';
export const RENAME_DOC = 'RENAME_DOC';
export const SHOW_DUPLICATE_AND_EDIT = 'SHOW_DUPLICATE_AND_EDIT';

export const hideModal = (store) => {
    store.actions.home.hideContextMenu();
    store.setState({ modals: { ...store.modals, isOpen: false } });
}

export const showModal = (store, type, data) => {
    store.setState({ modals: { ...store.state.modals, isOpen: true, data, type } });
}

export const showSessionExpired = (store) => {
    showModal(store, SESSION_EXPIRED);
}

export const showCreateNewTag = (store, selectedTag = {}) => {
    store.setState({ selectedTag });
    showModal(store, CREATE_UPDATE_TAG);
}

export const showDeleteTag = (store, tag) => {
    store.setState({ selectedTag: tag });
    showModal(store, DELETE_TAG);
}

export const showRenameGroup = (store, group) => {
    // store.actions.home.hideContextMenu();
    store.actions.home.hideSingleDocumentContextMenu();
    showModal(store, RENAME_DIR, group);
}

export const showDeleteGroup = (store, group) => {
    // store.actions.home.hideContextMenu();
    store.actions.home.hideSingleDocumentContextMenu();
    showModal(store, DELETE_DIR, group);
}

export const showDeleteDocument = (store, selection) => {
    // store.actions.home.hideContextMenu();
    store.actions.home.hideSingleDocumentContextMenu();
    showModal(store, DELETE_DOC, selection);
}

export const showExportModal = (store, selection) => {
    // store.actions.home.hideContextMenu();
    store.actions.home.hideSingleDocumentContextMenu();
    showModal(store, EXPORTS, selection);
}

export const showInkToTextModal = (store, selection) => {
    store.actions.home.hideContextMenu();
    showModal(store, INK_TO_TEXT, selection);
}

export const showPhoneDetectedModal = (store) => {
    store.actions.home.hideContextMenu();
    showModal(store, ON_PHONE_DETECTION);
}

export const showRenameDocumentModal = (store, document) => {
    store.actions.home.hideContextMenu();
    showModal(store, RENAME_DOC, document);
}

export const showDuplicateAndEdit = (store, options) => {
    store.actions.home.hideContextMenu();
    showModal(store, SHOW_DUPLICATE_AND_EDIT, options);
}