import React from 'react';

import './PanelItem.scss';

function PanelItem(props) {


    const { title, className, isExpandable } = props
    let elementClasses = className
    if (isExpandable) {
        elementClasses += ' expandable'
    }
    return <li className={elementClasses} onClick={props.onClick} >
        <span className={'link'}> {title} </span>
    </li>
}

export default PanelItem;