import React from 'react'
import FolderElement from './FolderElement'

import './Folders.scss'

const getTooltipMessage = stream =>
    ({
        web: 'streams:inkspaceWebStream',
        mate: 'streams:inkspaceAppStream',
        montblanc: 'streams:inkspaceMontBlanc',
        'bamboo_paper': 'streams:inkspaceBambooPaperStream',
        inknotefiles: 'streams:inkspaceInkNoteFilesStream',
        collaboration: 'streams:collaboration',
        'wacom_notes': 'streams:wacomnotes'
    }[stream])

const getText = stream =>
    ({
        web: 'W',
        mate: 'A',
        montblanc: '',
        'bamboo_paper': 'B',
        inknotefiles: 'I',
        collaboration: '',
        'wacom_notes': ''
    }[stream])

function Folders(props) {   
    const deactivateSelectedStream = props.query.tags || props.query.term;
    const folderData = props.folders
    .filter(folder => folder.visible)
    .map(folder => ({
        msg: getTooltipMessage(folder.link),
        streamId: folder.link,
        text: getText(folder.link),
        active: props.query.applicationName === folder.link && !deactivateSelectedStream,
        node: folder.node
      }))

    return (
        <div className='folders-wrapper'>
            {folderData.map((folder, i) => (
                <FolderElement
                    key={i}
                    folder={folder}
                    // onClick={() => {
                    //     destroyMultiSelectPanel()
                    //     if (folder.node !== undefined) {
                    //         setQueryInkNote(folder.streamId, folder.node)
                    //     } else {
                    //         setQueryStream(folder.streamId)
                    //     }
                    // }}
                />
            ))}
        </div>
    )
}

export default Folders;