import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Brand from '../../common/components/brand/Brand';
import Header from '../../common/components/header/Header';
import SearchBox from '../../common/components/search/SearchBox';
import ProfileComponent from '../../common/components/profile/ProfileComponent';
import NavigationComponent from '../../common/components/navigation/NavigationComponent';
import Footer from '../../common/components/footer/Footer';
import DocumentViewComponent from './documents/DocumentViewComponent';
import Folders from './folders/Folders';
import TagList from './tags/TagList';
import { isIOS, detectPhones } from 'common/browserHelpers';
import { getContextMenu } from '../../common/contextMenuHelpers/ContextMenuHelpers';
import ControlPanel from './documentPanel/ControlPanel';
import BlankEditorFlow from './blankEditorFlow/BlankEditorFlow';
import useGlobal from 'store';
import PreviewComponent from 'routes/preview/PreviewComponent';
// import WhatIsNew from '../../common/components/whatIsNew/WhatIsNew';

import {
    setPreviewDocument, removePreviewDocument, parseQueryParams,
    addQueryTerm, removeQueryTerm, addTag, removeTag, changeApplication,
    openEditMode
} from 'common/components/utilities/queryHelper'

import {
    getToastSuccessMessageKey, TOASTER_ACTION_ADD, TOASTER_ACTION_DEL
} from './helpers/toastHelper';

import {
    PREVIEW_PATH, PHONE_POPUP_DISMISSED, CSS_TRANSITION_ENTER_ANIMATION_TIME,
    COLLABORATION_LOADING_PATH
} from 'constants/constants';

import './HomeComponent.scss';

const animationTimeout = CSS_TRANSITION_ENTER_ANIMATION_TIME;


const manageDocumentAndGroupDelete = (selection, groupDelAction, docDelAction) => {
    if (selection.selectedGroupIds.size > 0) {
        groupDelAction(selection);
    } else {
        docDelAction(selection);
    }
}

const getGroupName = (groups, id) => {
    let groupName = '';
    const targetIndex = groups.findIndex(gr => gr.id === id);
    if (targetIndex !== -1) {
        groupName = groups[targetIndex].label;
    }
    return groupName;
}

const addToastMessageToAction = (callback, callbackParams, actionString, formatMessage, allGroups) => {
    const groupId = callbackParams[0];
    const docs = callbackParams[1];
    const localizationKey = getToastSuccessMessageKey(actionString, docs, groupId);
    const groupName = getGroupName(allGroups, groupId)
    const localizedMessage = formatMessage({ id: localizationKey }, { groupName });
    // TODO: Add generic error message
    // const onErrorMessage = formatMessage({ id: 'auth:errors:generic' });
    callback(...callbackParams, localizedMessage);
}

function HomeComponent(props) {
    const [globalState, globalActions] = useGlobal();
    const location = useLocation();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const isPreviewPath = location.pathname === PREVIEW_PATH;
    const parsedQueryTerms = location.query ?? parseQueryParams(location.search) ?? {};
    const querySearchTerm = parsedQueryTerms.term;
    const { contextMenu, tags, groups, fullGroupList, documents } = globalState;
    const { isControlPanelVisible } = contextMenu;
    const previewDocumentId = globalState.preview.document.id;
    const shouldShowPreview = isPreviewVisible && previewDocumentId !== null;
    const actions = mapActions(globalActions, history, formatMessage, groups, isPreviewVisible, setIsPreviewVisible);

    const folderData = { folders: globalState.folders, query: {} };
    const contextMenuProps = { contextMenu, tags, groups, fullGroupList, formatMessage, documents, actions };

    useEffect(() => {
        if (isIOS()) {
            document.body.classList.add('iOS-fix');
        }
        return () => {
            document.body.classList.remove('iOS-fix');
        }
    }, []);

    useEffect(() => {
        if (!isPreviewVisible && isPreviewPath && !previewDocumentId) {
            const queryParams = parseQueryParams(location.search);
            const documentId = queryParams.docId;
            actions.doSelectPreviewDoc(documentId);
            setIsPreviewVisible(true);
        }

        if (isPreviewVisible && !isPreviewPath) {
            setIsPreviewVisible(false);
        }
    }, [previewDocumentId, isPreviewPath, isPreviewVisible, actions, location.search]);

    useEffect(() => {
        const mobile = detectPhones();

        if (mobile.isPhone) {
            if (!window.localStore.getItem(PHONE_POPUP_DISMISSED)) {
                globalActions.modals.showPhoneDetectedModal();
            }
        }

    }, [globalActions.modals]);

    folderData.query = parsedQueryTerms;

    return <div className={'home'}>
        <div>
            <Header>
                <Brand />
                <SearchBox onSubmit={actions.doSetQueryTerm} term={querySearchTerm} />
                <ProfileComponent
                    lastName={globalState.auth.profile != null ? globalState.auth.profile.lastName : ''}
                    firstName={globalState.auth.profile != null ? globalState.auth.profile.firstName : ''}
                    onLogout={globalActions.auth.logout}
                />
            </Header>
        </div>
        <div className={'main-content'}>
            <div className={'flex-wrapper'}>
                <div className={'main-content-flex'}>
                    <div className={'page-sidebar'}>
                        <Folders {...folderData} />
                        <TagList actions={actions}
                            tags={globalState.tags}
                            shouldClose={globalState.modals.isOpen}
                            isFetching={globalState.isFetchingTags} />
                    </div>

                    <div className={'main-content-flex flex-col'}>
                        <div className={'page-panel'}>
                            <NavigationComponent
                                actions={actions}
                                location={globalState.location}
                                sort={globalState.sort}
                                currentDirInfo={globalState.currentDirInfo} />
                            <DocumentViewComponent actions={actions} queryParams={parsedQueryTerms} />
                        </div>
                        <Footer>
                            <BlankEditorFlow applicationName={parsedQueryTerms.applicationName} queryParams={parsedQueryTerms} />
                            {isControlPanelVisible && <ControlPanel {...contextMenuProps}
                                items={getContextMenu(contextMenuProps)} />}
                        </Footer>
                    </div>
                </div>
            </div>
        </div>
        <CSSTransition key={document.id} in={shouldShowPreview}
            timeout={animationTimeout}
            classNames={'fade-in-transition'}
            unmountOnExit
        >
            <PreviewComponent actions={actions} modalState={globalState} onDestroy={actions.doTogglePreview} />
        </CSSTransition>
    </div>
}

export default HomeComponent;




function mapActions(globalActions, history, formatMessage, groups, isPreviewVisible, setIsPreviewVisible) {
    return {
        doAddDocsToTag: globalActions.home.addDocumentsToTag,
        doRemoveDocsFromTag: globalActions.home.removeDocumentsFromTag,
        doDeleteGroup: globalActions.modals.showDeleteGroup,
        doRenameGroup: globalActions.modals.showRenameGroup,
        doDeleteDocument: globalActions.modals.showDeleteDocument,
        doShowExportModal: globalActions.modals.showExportModal,
        doExport: globalActions.home.exportDocuments,
        doShowInkToTextModal: globalActions.modals.showInkToTextModal,
        doShowContextMenu: globalActions.home.showContextMenu,
        doCloseContextMenu: globalActions.home.hideContextMenu,
        doStartCollaboration: (itemToEdit, isWill2EditMode) => {
            history.push(`/collaboration/${COLLABORATION_LOADING_PATH}`);
            globalActions.home.startCollaboration(itemToEdit, isWill2EditMode);
        },
        doOpenSemanticView: (item) => {
            globalActions.home.hideContextMenu();
            history.push({
                pathname: `/semantic/${item.id}/${item.pageIds[0]}/${item.locale}`,
                state: { opcUrl: item.temporaryOPCLink }
            });
        },
        doSelectPreviewDoc: (docId) => {
            history.push(setPreviewDocument(docId));
            globalActions.home.selectPreviewDoc(docId);
        },
        doAddDocsToGroup: (...args) => {
            addToastMessageToAction(globalActions.home.addDocsToGroup, args, TOASTER_ACTION_ADD, formatMessage, groups);
        },
        doRemoveDocsFromGroup: (...args) => {
            history.push(removePreviewDocument());
            addToastMessageToAction(globalActions.home.removeDocsFromGroup, args, TOASTER_ACTION_DEL, formatMessage, groups);
        },
        doDeleteDocsAndGroups: (selection) => manageDocumentAndGroupDelete(selection,
            globalActions.modals.showDeleteGroup,
            globalActions.modals.showDeleteDocument),
        doTogglePreview: (docId) => {
            if (!isPreviewVisible) {
                history.push(setPreviewDocument(docId));
            } else {
                globalActions.home.hideContextMenu();
                history.push(removePreviewDocument(docId));
            }

            setIsPreviewVisible(old => !old);
        },
        doSetQueryTerm: (term) => {
            if (term)
                history.push(addQueryTerm(term));
            else
                history.push(removeQueryTerm());
        },
        doRemoveQueryTerm: () => history.push(removeQueryTerm()),
        doAddTag: (tag) => history.push(addTag(tag)),
        doRemoveTag: () => history.push(removeTag()),
        doGoToStreamBase: (appName) => history.push(changeApplication(appName)),
        doSetFilter: globalActions.home.setFilter,
        showOverlay: globalActions.home.showOverlay,
        hideOverlay: globalActions.home.hideOverlay,
        openCreateNewTagModal: globalActions.modals.showCreateNewTag,
        doShowDeleteTagModal: (item) => globalActions.modals.showDeleteTag(item),
        doShowRenameTagModal: (item) => globalActions.modals.showCreateNewTag(item),
        doEdit: (item, pageId) => {
            globalActions.home.hideContextMenu();
            history.push({
                pathname: openEditMode(item, pageId),
                state: { originApplicationName: item.applicationName }
            });
        },
        doCopyDocument: (item) => {
            const onSubmit = () => {
                const redirectCallback = (newDoc, pageId) => {
                    history.push({
                        pathname: openEditMode(newDoc, pageId),
                        state: { originApplicationName: item.applicationName }
                    });

                    globalActions.home.hideContextMenu();
                    globalActions.modals.hideModal();
                };

                globalActions.inkEditor.copyDocument(item.id, item.pageIds[0], item.label, redirectCallback, item.paperTypeIndex);
            };

            globalActions.modals.showDuplicateAndEdit({ onSubmit });
        },
        doShowRenameDocumentModal: (item) => globalActions.modals.showRenameDocumentModal(item),
        cancelMultiPagePreviewFetch: globalActions.home.cancelMultiPagePreviewFetch,
        setCurrentPageId: globalActions.home.setCurrentPageId
    };
}
