import {
    INK_EDITOR_URL_BASE, DOCUMENT_EDITOR_URL_BASE, WEB_STREAM,
    IDENTITY_TRANSFORM_MATRIX, TEMPLATE_WIDTH, TEMPLATE_HEIGHT,
} from 'constants/constants';
import { bpPaperTypeUrl } from 'common/bambooPaperHelper';

import { createToast } from './home';

const identityTransform = IDENTITY_TRANSFORM_MATRIX;

const fetchCreateBlankDocument = async (store, name, width, height, transform, targetApplicationName) => {
    const applicationName = targetApplicationName ?? store.state.location.query.applicationName;
    const createDocFetch = await fetch(`${DOCUMENT_EDITOR_URL_BASE}?applicationName=${applicationName}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${store.state.auth.mainToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name,
            width,
            height,
            transform
        })
    });

    return await createDocFetch.json();
}

export const createBlankDocument = async (store, name, width, height, transform, callback) => {
    width = width ?? 1188;
    height = height ?? 864;
    transform = transform ?? identityTransform;
    store.setState({ isSpinnerVisible: true });

    try {
        const newDoc = await fetchCreateBlankDocument(store, name, width, height, transform);
        const { id, pageId } = newDoc;

        store.setState({ inkEditor: { ...store.state.inkEditor, documentId: id } });
        callback && callback(newDoc, pageId);
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    } finally {
        store.setState({ isSpinnerVisible: false });
    }
}

const fetchDocument = async (store, documentId, pageId) => {
    let address = `${INK_EDITOR_URL_BASE}/${documentId}`;

    if (pageId) address += `?pageId=${pageId}`;

    const getDocFetch = await fetch(address, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${store.state.auth.mainToken}`
        }
    });

    return await getDocFetch.json();
}

export const getDocument = async (store, documentId, pageId) => {
    const activePageInk = store.state.inkEditor.activePages[pageId];

    // if the page is already active and edited
    if (activePageInk) {
        store.setState({
            inkEditor: {
                ...store.state.inkEditor,
                inkModels: [activePageInk]
            }
        });

        return;
    }

    try {
        const documentJson = await fetchDocument(store, documentId, pageId);
        const inkEditorWithDocument = {
            inkEditor: { ...store.state.inkEditor, pageContents: documentJson, isFetching: true }
        };
        store.setState(inkEditorWithDocument);

        const inkModels = await Promise.all(documentJson.assets.filter(x => x.type === 'ink')
            .map(async asset => {
                const content = await fetch(asset.assetUri);

                return await content.arrayBuffer();
            }));

        store.setState({
            inkEditor: {
                ...inkEditorWithDocument.inkEditor,
                inkModels,
                isFetching: false,
                width: documentJson.width,
                height: documentJson.height
            }
        });
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }
}

export const updateInkModel = async (store, documentId, pageId, inkModel) => {
    try {
        let address = `${INK_EDITOR_URL_BASE}/${documentId}/strokes`;
        if (pageId) address += `?pageId=${pageId}`;

        await fetch(address, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            },
            body: inkModel
        });

    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }
}

export const updateMultiplePages = async (store, documentId, callback) => {
    store.setState({ isSpinnerVisible: true });

    try {
        let address = `${INK_EDITOR_URL_BASE}/${documentId}/update-multiple-pages`;
        const { activePages, changedPages } = store.state.inkEditor;

        const formData = new FormData();

        for (let pageId in activePages) {
            if (changedPages[pageId]) {
                const inkData = activePages[pageId];
                formData.append(pageId, new Blob([inkData]), pageId);
            }
        }

        await fetch(address, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`
            },
            body: formData
        });
    } catch (er) {

    } finally {
        store.setState({ isSpinnerVisible: false });
        callback && callback();
    }
}

export const setActivePage = (store, pageId, inkModel, hasInkChanges) => {
    store.state.inkEditor.activePages[pageId] = inkModel;
    store.state.inkEditor.changedPages[pageId] |= hasInkChanges;
}

export const clearInkEditorState = (store) => {
    store.setState({
        inkEditor: {
            ...store.state.inkEditor,
            documentId: null,
            pageContents: null,
            inkModels: [],
            width: 0,
            height: 0,
            activePages: {},
            changedPages: {}
        }
    });
}

const objectToQuery = obj => Object.keys(obj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&');

const addImageToDocument = async (store, documentId, pageId, imageData, imageType, x, y, width, height, transform, scaleType) => {
    if (!transform || transform === 'null') {
        transform = identityTransform
    }

    try {
        const documentInfo = { x, y, width, height, transform };
        if (scaleType) documentInfo.scaleType = scaleType;
        let address = `${INK_EDITOR_URL_BASE}/${documentId}/image?`;

        if (pageId) address += `pageId=${pageId}&`;
        address += objectToQuery(documentInfo);

        await fetch(address, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'ContentType': imageType
            },
            body: imageData
        });
    } catch (er) {
        console.log(er);
        createToast(store.state.localization.genericErrorMessage);
    }
}

export const copyDocument = async (store, documentId, pageId, docLabel, callback, bpPaperType = null) => {
    store.setState({ isSpinnerVisible: true, isDuplicatingDocument: true });

    let dupName = 'Copy';

    if (docLabel) {
        dupName += ` of ${docLabel}`;
    }

    const documentToCopy = await fetchDocument(store, documentId, pageId);
    const newPageDims = {
        width: 2 * Math.round(documentToCopy.width / 2),
        height: 2 * Math.round(documentToCopy.height / 2),
    };

    const createNewDocResponse = await fetchCreateBlankDocument(store,
        dupName,
        // Rounds to the nearest even number
        newPageDims.width, newPageDims.height,
        identityTransform,
        WEB_STREAM
    );

    const newDocId = createNewDocResponse.id;
    const newPageId = createNewDocResponse.pageId;

    if (!newDocId) {
        createToast(store.state.localization.genericErrorMessage);
        return;
    }

    if (bpPaperType) {
        const templateWidth = TEMPLATE_WIDTH;
        const templateHeight = TEMPLATE_HEIGHT;

        const x = (documentToCopy.width - templateWidth) / 2;
        const y = (documentToCopy.height - templateHeight) / 2;

        const bpPaperTypeAsset = {
            width: templateWidth,
            height: templateHeight,
            x, y, identityTransform,
            assetUri: bpPaperTypeUrl(bpPaperType),
            type: 'image/jpg',
            scaleType: 'center'
        };

        documentToCopy.assets.splice(0, 0, bpPaperTypeAsset);
    }

    for (const asset of documentToCopy.assets) {
        const assetData = await fetch(asset.assetUri);
        const assetDataBlob = await assetData.blob();

        if (asset.type.includes('image')) {
            const imageMime = assetData.headers.get('Content-Type');
            await addImageToDocument(store,
                newDocId, newPageId, assetDataBlob,
                imageMime, asset.x, asset.y, asset.width,
                asset.height, asset.transform, asset.scaleType);
        } else {
            await updateInkModel(store, newDocId, null, assetDataBlob);
        }
    }

    store.setState({ isSpinnerVisible: false, isDuplicatingDocument: false });

    callback && callback(createNewDocResponse, newPageId);
}