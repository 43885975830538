import React from 'react';

import './Overlay.scss';

function Overlay(props) {
    const { actions } = props;

    return <div className={'overlay'}
        onClick={actions.doCloseContextMenu}
        onContextMenu={ev => {
            ev.preventDefault();
            actions.doCloseContextMenu();
        }}
    >
    </div >
}

export default Overlay;