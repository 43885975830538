import React from 'react';

import './ZoomItem.scss';

function ZoomItem(props) {
    const { data } = props;

    return <li
        className={data.scaleFactor === props.index ? 'zoom-item active' : 'zoom-item'}
        onClick={() => props.onChange(data.scaleFactor)}>
        {data.label}
    </li>
}

export default ZoomItem;