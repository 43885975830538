import React, { useEffect } from 'react';
import Routes from './routes';
import { IntlProvider } from 'react-intl';
import { messagesLoader } from './services/MessagesLoader';
import useGlobal from 'store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import './styles/core.scss';
import 'react-tippy/dist/tippy.css';

function App() {
  const [globalState] = useGlobal();
  const usersLocale = globalState.localization.locale;

  useEffect(() => {
    // We listen to the resize event
    const resizeEvent = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.00999;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

    window.addEventListener('resize', resizeEvent);
    resizeEvent();
    return () => {
      window.removeEventListener('resize', resizeEvent);
    }
  }, []);


  return (
    <div className="App app-container">
      <IntlProvider
        locale={usersLocale}
        messages={messagesLoader(usersLocale)}>
        <Routes />
      </IntlProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
