import React from 'react';

import './PlusButton.scss';

function PlusButton(props) {
    return <div className='plus-button-wrapper'>
        <div>
            <div className='plus-button' onClick={props.onClick}>
                <div className='plus-button-icon' />
            </div>
        </div>
    </div>
}

export default PlusButton;