import React, { useRef, useEffect } from 'react';
import ContextMenu from 'common/components/menu/contextMenu/ContextMenu';

import './CanvasTypeMenu.scss';

function CanvasTypeMenu(props) {
    const wrapperRef = useRef(null);
    let contextMenuClasses = 'canvas-type-menu';
    if (props.menuElements.length === 1) {
        contextMenuClasses += ' is-single-item';
    }

    useEffect(() => {
        const manageOuterClick = (ev) => {
            if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
                ev.preventDefault();
                props.onOuterClick();
            }
        };

        setTimeout(() => {
            window.addEventListener('click', manageOuterClick);
        }, 0);

        return () => {
            window.removeEventListener('click', manageOuterClick);
        };
    }, [props])

    return <div ref={wrapperRef} className='canvas-type-menu-wrapper'>
        <ContextMenu className={contextMenuClasses} elements={props.menuElements} />
    </div>
}

export default CanvasTypeMenu;