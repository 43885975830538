import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ExportListItem from './ExportListItem';
import ModalBox from '../modalBox/ModalBox'
import {
    EXPORT_DOC_FORMATS, VIDEO_TYPE,
    BAMBOO_STREAM, BAMBOO_EXPORT_DOC_FORMATS, WACOM_NOTES_STREAM
} from 'constants/constants';

import './Export.scss';

function hasApplicationType(items, applicationNames) {
    let hasAppName = false;

    if (!Array.isArray(items)) {
        items = [items];
    }

    if (!Array.isArray(applicationNames)) {
        applicationNames = [applicationNames];
    }

    for (const item of items) {
        if (applicationNames.includes(item.applicationName)) {
            hasAppName = true;
            break;
        }
    }

    return hasAppName;
}

function ExportContainer(props) {
    const { formatMessage } = useIntl();
    const { actions } = props;
    const [selectedType, setSelectedType] = useState(null);
    const messageOnFailure = formatMessage({ id: 'modalbox:exportError:message' });
    const { isExportingDocuments } = props.modalState;

    const manageExportRequest = () => {
        let exportType = selectedType;
        if (!exportType) {
            exportType = EXPORT_DOC_FORMATS.filter(item => item.isDefault)[0].label;
        }

        exportType = exportType.substring('export:as'.length);
        actions.home.exportDocuments(props.selection, exportType, messageOnFailure);
    }

    const modalBoxOptions = {
        id: 'export-modal-container',
        title: formatMessage({ id: 'global:export' }),
        onDestroy: props.onDestroy,
        isSpinnerVisible: isExportingDocuments,
        buttons: [{
            onClick: manageExportRequest,
            id: 'export-modal',
            title: formatMessage({ id: 'global:export' }),
            isSubmit: true
        }]
    };

    const hasBambooPaper = hasApplicationType(props.selection, BAMBOO_STREAM);
    const hasWacomNotesStream = hasApplicationType(props.selection, WACOM_NOTES_STREAM);

    let exportFormats = hasBambooPaper ? BAMBOO_EXPORT_DOC_FORMATS : EXPORT_DOC_FORMATS;

    if (props.selection.isMateGroup || props.selection.length > 1 || hasWacomNotesStream) {
        exportFormats = exportFormats.filter(x => x.value !== VIDEO_TYPE);
    }

    let modalContents = <div className='export-items-container vertical'>
        {exportFormats.map((item, index) =>
            <ExportListItem
                key={'export-format-' + index}
                name={item.label}
                isSelected={selectedType ? item.label === selectedType : item.isDefault}
                title={formatMessage({ id: item.label }, { defaultMessage: item.label })}
                onChange={(ev) => setSelectedType(ev.target.id)} />
        )}
    </div>;

    return <ModalBox {...modalBoxOptions}>
        {modalContents}
    </ModalBox >
}

export default ExportContainer;