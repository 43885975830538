import React, { useEffect, useRef } from 'react'
import { Switch, Route, Router } from 'react-router-dom';
import PrivateRoute from "./private/PrivateRoute"
import HomeComponent from './home/HomeComponent';
import VideoComponent from './video/VideoComponent';
import AuthContainer from './auth/containers/AuthContainer';
import ModalWrapper from 'common/components/modals/ModalWrapper';
import InkEditor from './inkEditor/containers/InkEditor';
import Collaboration from './collaboration/containers/Collaboration';
import WelcomeComponent from './welcome/WelcomeComponent';
import SemanticComponent from './semantic/SemanticComponent';
import DownloadDataComponent from './dataDownload/DownloadDataComponent';
import { createBrowserHistory } from 'history';
import useGlobal from 'store';
import WindowContextProvider from 'common/components/utilities/WindowContext';

import {
    HOME_PATH, PREVIEW_PATH, AUTH_PATH_WITH_PARAMS,
    INK_EDITOR_PATH, WELCOME_PATH, DATA_DOWNLOAD_PATH,
    SEMANTIC_PATH, authRoutes, APP_VERSION
} from 'constants/constants';

const customHistory = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

// May be used for testing
// customHistory.listen(locationUpdater);

// function locationUpdater(newLocation) {
//     console.log(newLocation)
// }

function getExpiresAfter(exp) {
    if (!exp) return false;

    return Math.floor((exp - Date.now()) * 0.75);
}

function Routes() {
    const [globalState, globalActions] = useGlobal();
    // const [refreshHandle, setRefreshHandle] = useState(null);
    const refreshHandle = useRef(null);

    const { isLoggedIn, refreshToken, expiration } = globalState.auth;
    const { requestRefreshAccessToken, updateLoggedIn } = globalActions.auth;

    useEffect(() => {
        customHistory.listen(globalActions.location.changeLocation);

        // Load initial value
        globalActions.location.changeLocation({ search: window.location.search, pathname: window.location.pathname })
    },
        // The globalActions never change thus the effect is called only once
        [globalActions])

    // Initialize the login
    useEffect(() => {
        if (authRoutes.includes(window.location.pathname)) {
            updateLoggedIn(requestRefreshAccessToken);
        }
    }, [updateLoggedIn, requestRefreshAccessToken]);

    useEffect(() => {
        let expiresAfter = getExpiresAfter(expiration);

        const cleanTimeout = () => {
            if (refreshHandle.current) {
                clearTimeout(refreshHandle.current);
                refreshHandle.current = null;
            }
        }

        if (refreshToken && expiration && expiresAfter) {
            cleanTimeout();
            if (expiresAfter <= 0) {
                expiresAfter = 0;
            }

            refreshHandle.current = setTimeout(requestRefreshAccessToken, expiresAfter);
        }

        return cleanTimeout;
    }, [refreshToken, expiration, refreshHandle, requestRefreshAccessToken]);


    return <>
        <WindowContextProvider action={globalActions.auth.refreshIfNecessary} />
        <Router basename={process.env.PUBLIC_URL} history={customHistory}>
            <Switch>
                <Route path={AUTH_PATH_WITH_PARAMS}>
                    <AuthContainer />
                </Route>
                <Route path='/version' >
                    <span>{APP_VERSION}</span>
                </Route>
                <PrivateRoute path={WELCOME_PATH} isLoggedIn={isLoggedIn} component={WelcomeComponent} />
                <Route path='/video-export/:guid/:title?' component={VideoComponent} />
                <Route path='/beta/video/:guid/:title?' component={VideoComponent} />
                <PrivateRoute path={DATA_DOWNLOAD_PATH} isLoggedIn={isLoggedIn} component={DownloadDataComponent} />
                <PrivateRoute path={SEMANTIC_PATH} isLoggedIn={isLoggedIn} component={SemanticComponent} />
                <PrivateRoute path={INK_EDITOR_PATH} isLoggedIn={isLoggedIn} component={InkEditor} />
                <PrivateRoute path='/collaboration/:roomId' isLoggedIn={isLoggedIn} component={Collaboration} />
                <PrivateRoute path={PREVIEW_PATH} isLoggedIn={isLoggedIn} component={HomeComponent} />
                <PrivateRoute path={HOME_PATH} isLoggedIn={isLoggedIn} component={HomeComponent} />
            </Switch>
            <ModalWrapper key={`${globalState.modals.isOpen}_${globalState.modals.type}`} />
        </Router>
    </>
};

export default Routes;