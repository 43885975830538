import React from 'react';
import { useIntl } from 'react-intl';
import ModalBox from 'common/components/modalBox/ModalBox';

function SaveChanges(props) {
    const { formatMessage } = useIntl();

    const options = {
        id: 'modal-save-changes',
        title: formatMessage({ id: 'modalbox:saveChanges:title' }),
        onDestroy: props.onDestroy ?? props.onClose,
        isSpinnerVisible: props.isSpinnerVisible ?? false,
        buttons: [{
            onClick: props.onSaveChanges,
            title: formatMessage({ id: 'global:save' }),
            id: 'save',
            isSubmit: true
        }, {
            onClick: props.onDiscardChanges,
            title: formatMessage({ id: 'global:dontsave' }),
            id: 'dontsave',
            className: 'secondary-button'
        }]
    };

    return <ModalBox {...options} >
        {formatMessage({ id: 'modalbox:saveChanges:text' })}
    </ModalBox >
}

export default SaveChanges;