const messages = {
  'documents:empty:noresult:noresult' : 'KEINE ERGEBNISSE',
  'documents:empty:noresult:noresultfound' : 'Zu deinen Kriterien konnten keine Ergebnisse gefunden werden.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Sobald dein Gerät mit der Ink Space App verbunden ist und du dich mit deiner Wacom ID registriert hast, siehst du hier deinen gesamten Content.',
  'documents:empty:stream:explanation1:webstream' : 'Probiere Ink Collaboration aus. Arbeite mit anderen zusammen an deinen Kreationen. Versuche es noch heute',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Sobald du dich mithilfe deiner Wacom ID mit Bamboo Paper verbindest, werden deine gesamten Inhalte hier angezeigt.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Mach dir Notizen. Schaffe noch mehr. Wie auf Papier festhalten, nur besser.',
  'documents:empty:stream:tryitnow' : 'Versuche es jetzt',
  'documents:empty:stream:learnmore' : 'WEITERE INFOS',
  'documents:empty:group' : 'Deine Gruppe ist leer.',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Collaboration Stream',

  'filter:result' : 'ERGEBNIS FÜR',
  'filter:search' : 'SUCHE...',

  'footer:navigation:privacy' : 'DATENSCHUTZ',
  'footer:navigation:termOfUse' : 'NUTZUNGSBEDINGUNGEN',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie-Präferenzen',

  'global:convertTo' : 'UMWANDELN IN {language}',
  'global:convertToText' : 'In Text umwandeln',

  'context-menu:createNewCanvas' : 'Neue Arbeitsfläche erstellen',
  'context-menu:createNewGroup' : 'Gruppe erstellen',
  'context-menu:startNewCollaboration' : 'Neue Zusammenarbeit starten',
  'context-menu:startCollaboration' : 'Zusammenarbeit starten',
  'context-menu:edit' : 'Bearbeiten',

  'global:delete' : 'Löschen',
  'global:rename' : 'Umbenennen',
  'global:export' : 'Exportieren',
  'global:tags' : 'Tags',
  'global:groups': 'Gruppen',
  'global:shareLink' : 'Link teilen',
  'global:copy' : 'KOPIEREN',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Duplizieren und bearbeiten',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Speichern',
  'global:dontsave' : 'Nicht speichern',
  'global:proceed' : 'Fortfahren',
  'global:enterName' : 'NAME EINGEBEN',
  'global:browserUnsupported':'Browser nicht unterstützt',

  'global:language' : 'SPRACHE',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Abmelden',
  'global:login' : 'Anmelden',
  'global:myAccount' : 'Mein Konto',

  'inputField:existsName' : 'Fehler! Der Name existiert bereits!',
  'inputField:incorrectName' : 'Fehler! Der Name ist falsch!',
  'inputField:notInRange' : 'Fehler! Der Name muss zwischen {minLength} und {maxLength} Zeichen lang sein!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Verwende den Browser Chrome oder Firefox, um mit anderen zusammenzuarbeiten. Vielen Dank!',
  'modalbox:browser:unsupport:sf:text': 'Verwende den Browser Chrome oder Safari, um mit anderen zusammenzuarbeiten. Vielen Dank!',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Error!',
  'modalbox:error:buttonTitle' : 'Schließen',
  'modalbox:exportError:message' : 'Exportieren zurzeit nicht möglich',
  'modalbox:createTag:buttons:title' : 'ERSTELLEN',
  'modalbox:createTag:title' : 'NEUES TAG ERSTELLEN',
  'modalbox:deleteTag:message' : 'Du bist im Begriff, <span>"{tag}"</span> zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.',
  'modalbox:removeDocument:message' : 'Du bist im Begriff, <span>"{docTitle}"</span> zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.',
  'modalbox:shareLink:buttons:stopSharing' : 'Nicht mehr teilen',
  'modalbox:sessionFull:title' : 'Zusammenarbeit Voll',
  'modalbox:sessionFull:text' : 'Leider ist die Zusammenarbeitssitzung, auf die du zugreifen möchtest, bereits voll.',
  'modalbox:leaveSession:title' : 'Sitzung Verlassen',
  'modalbox:leaveSession:buttons:leave' : 'Verlassen',
  'modalbox:leaveSession:text' : 'Möchtest du die Zusammenarbeitssitzung verlassen?',
  'modalbox:endSession:title' : 'Sitzung Beenden',
  'modalbox:endSession:buttons:end' : 'Beenden',
  'modalbox:endSession:text' : 'Möchtest du die aktuelle Zusammenarbeitssitzung beenden? Der aktuelle Fortschritt wird automatisch in deinem Web-Stream gespeichert.',
  'modalbox:sessionEnded:title' : 'SITZUNG BEENDET',
  'modalbox:sessionEnded:text' : 'Möchtest du eine Kopie der Zusammenarbeitssitzung in deinem Web-Stream speichern?',
  'modalbox:accessDenied:title' : 'Zugriff verweigert',
  'modalbox:accessDenied:buttons:goBack' : 'Zurück',
  'modalbox:accessDenied:text' : 'Leider ist die Collaboration Sitzung, auf die du zugreifen möchtest, bereits abgelaufen',
  'modalbox:collaborationStarted:title' : 'Zusammenarbeit Gestartet',
  'modalbox:collaborationStarted:warn' : 'Du musst Ink Collaboration geöffnet lassen.  Wenn du diese Registerkarte schließt oder eine andere Registerkarte öffnest, wird Ink Collaboration beendet.',
  'modalbox:collaborationStarted:text' : ' Deine Zusammenarbeitssitzung wurde gestartet. Du kannst bis zu 10 Teilnehmer einladen, indem du ihnen die URL für die Zusammenarbeit aus deinem Browser gibst.',
  'modalbox:collaborationError:title' : 'Collaboration Fehler!',
  'modalbox:collaborationError:buttons:close' : 'Schließen',
  'modalbox:collaborationError:text' : 'Der von dir verwendete Browser wird nicht von Ink Collaboration unterstützt.  Diese Funktion funktioniert am besten mit Firefox /v55+/, Chrome /v60+/ und Safari /v11+/.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'NICHT ANZEIGEN',
  'modalbox:duplicateAndEdit:text' : 'Eine neue erweiterte Version der Seite wird in deinem Ink Space Web-Stream platziert, ohne das Original zu ändern.',
  'modalbox:saveChanges:title' : 'Änderungen speichern',
  'modalbox:saveChanges:text' : 'Möchtest du vor dem Schließen Änderungen am Dokument speichern?',
  'modalbox:WebGLError:title' : 'Fehler aufgetreten',
  'modalbox:WebGLError:text' : 'Es ist ein Fehler aufgetreten. Du musst den Inhalt mithilfe der Schaltfläche „Neu laden“ in der obigen Nachricht neu laden.',
  'modalbox:collaborationJoinFailed:title': 'Verbindung konnte nicht hergestellt werden.',
  'modalbox:collaborationJoinFailed:text': 'Du kannst nicht auf die gewünschte Sitzung zugreifen. Versuche es später erneut.',
  'modalbox:connectionLost:title': 'Verbindung getrennt',
  'modalbox:connectionLost:text': 'Die Verbindung wurde getrennt. Überprüfe deine Internetverbindung, und versuche es erneut.',
  'modalbox:phoneDetection:title': 'Mobile App verwenden',
  'modalbox:phoneDetection:btn:open': 'Öffnen',
  'modalbox:phoneDetection:btn:no-thanks': 'Nein, danke',
  'modalbox:phoneDetection:msg': 'Wir empfehlen, auf deinem Telefon unsere mobile App zu verwenden, um die Software optimal zu nutzen.',
  'modalbox:chooseVectorOrRaster:title' : 'Bearbeitungsmodus auswählen',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Annotieren',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vektorisieren',
  'modalbox:chooseVectorOrRaster:text' : 'Die Inhalte, die du bearbeiten möchtest, basieren auf einem Raster. Um deren ursprüngliches Erscheinungsbild zu wahren, empfehlen wir, sie mit Anmerkungen zu versehen. Zusätzlich könntest du die Inhalte vektorisieren. Das Ergebnis könnte sich jedoch je nach den verwendeten Tools vom ursprünglichen Erscheinungsbild unterscheiden.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Gruppe löschen',
  'modalbox:deleteGroup:title:multiple' : 'Gruppen löschen',
  'modalbox:deleteGroup:button:removeGroup' : 'Gruppe entfernen',
  'modalbox:deleteGroup:button:removeGroup:multiple': 'Gruppen entfernen',
  'modalbox:deleteGroup:message' : 'Möchtest du nur die Gruppe <span>„{titles}“</span> löschen oder auch ihre Inhalte? Diese Aktion kann nicht rückgängig gemacht werden.',
  'modalbox:deleteGroup:message:multiple' : 'Möchtest du nur die Gruppen <span>„{titles}“</span> löschen oder auch ihre Inhalte? Diese Aktion kann nicht rückgängig gemacht werden.',
  'modalbox:deleteGroup:button:removeAll' : 'Alles löschen',
  'modalbox:deleteGroup:button:cancel' : 'Abbrechen',
  'modalbox:deletePage:title' : 'Seite löschen',
  'modalbox:deletePage:message' : 'Möchtest du <span>„{titles}“</span> löschen oder sie aus dieser Gruppe entfernen?',
  'modalbox:deletePage:removePage' : 'Aus Gruppe entfernen',
  'modalbox:group:rename' : 'Gruppe umbenennen',
  'mailbox:exportLimit:title' : 'Limit erreicht',
  'mailbox:exportLimit:message' : 'Die maximale Anzahl von {maxExportPages} Notizen wurde überschritten. Verringere die Anzahl der ausgewählten Notizen und versuche es erneut.',

  'toast:groupAddedSuccess:single': 'Seite erfolgreich zu „{groupName}“ hinzugefügt',
  'toast:groupAddedSuccess:plural': 'Seiten erfolgreich zu „{groupName}“ hinzugefügt',
  'toast:pagesRemovedFromGroup:single': 'Seite erfolgreich aus „{groupName}“ entfernt',
  'toast:pagesRemovedFromGroup:plural': 'Seiten erfolgreich aus „{groupName}“ entfernt',
  'toast:groupCreated': '„{groupName}“ wurde erfolgreich erstellt.',
  'toast:groupRemoved': '„{groupName}“ wurde erfolgreich entfernt.',
  'toast:groupRemoved:andPages': '„{groupName}“ und Seiten wurden erfolgreich entfernt.',
  'toast:groupRemoved:plural': 'Die ausgewählten Gruppen wurden erfolgreich entfernt.',
  'toast:noteRemoved:single': 'Seite erfolgreich gelöscht',
  'toast:noteRemoved:plural': 'Seiten erfolgreich gelöscht',

  'sorter:ascending' : 'Aufsteigend',
  'sorter:date' : 'Datum',
  'sorter:descending' : 'Absteigend',
  'sorter:name' : 'Name',
  'sorter:size' : 'Grösse',
  'sorter:sortby' : 'Sortieren nach',
  'sorter:type' : 'Typ',

  'tagContainer:createTag' : 'TAG ERSTELLEN',
  'tagContainer:noTags' : 'Keine tags',
  'tagContainer:noTagsMessage' : 'Sobald du Tags erstellst, werden sie hier angezeigt.',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage' : 'Sobald du Gruppen erstellst, werden sie hier angezeigt.',

  'export:asDoc' : 'als DOC',
  'export:asPsd' : 'als PSD',
  'export:asJpg' : 'als JPG',
  'export:asPdf' : 'als PDF',
  'export:asPng' : 'als PNG',
  'export:asSvg' : 'als SVG',
  'export:asWill' : 'als WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'als PowerPoint',

  // Ink to Video
  'video:fileReady' : 'Deine Datei ist fertig.',
  'video:download'  : 'Herunterladen',
  'video:expirationMessage' : 'Der Download wird automatisch nach <span>{days}</span>Tagen gelöscht.',
  'video:generating' : 'Dein Video wird generiert',
  'video:timeNotice' : 'Dies kann einen Moment dauern.',
  'video:error' : 'Videogenerierung fehlgeschlagen',
  'video:estimatedTime.many': 'Dies kann bis zu  <span>{minutes}</span>Minuten dauern.',
  'video:estimatedTime.one': 'Dies kann bis zu einer Minute dauern.',

  // Session Expired Prompt
  'session:expired:title': 'Sitzung abgelaufen',
  'session:expired:message': 'Deine Sitzung ist abgelaufen. Melde dich erneut an.',
  'session:expired:button': 'Anmelden',

  // Auth module
  'auth:email:label' : 'E-Mail-Adresse',
  'auth:email:taken' : 'Diese E-Mail-Adresse ist nicht verfügbar.',
  'auth:email:notRegisterd' : 'Diese E-Mail-Adresse ist nicht registriert.',
  'auth:email:invalid' : 'Ungültige E-Mail-Adresse',
  'auth:password:label' : 'Passwort',
  'auth:password:reenter:label' : 'Passwort wiederholen',
  'auth:password:mismatch:label' : 'Passwörter stimmen nicht überein.',
  'auth:password:tooEasy:label' : 'Das Passwort muss aus mindestens {charLength} Zeichen bestehen und 3 der folgenden Zeichenarten enthalten: einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl, ein Sonderzeichen.',
  'auth:firstName:label' : 'Vorname',
  'auth:lastName:label' : 'Nachname',
  'auth:country:label' : 'Land',
  'auth:country:select' : 'Land auswählen',
  'auth:language:label' : 'Sprache',
  'auth:language:select' : 'Sprache auswählen',
  'auth:termsAndConditions:label' : 'Du hast die <a href="{termsHref}">Nutzungsbedingungen</a> von Wacom gelesen und akzeptiert.',
  'auth:privacyPolicy:label' : 'Du hast die <a href="{privacyHref}">Datenschutzrichtlinien</a> und <a href="{cookieHref}">Cookie-Richtlinie</a> von Wacom gelesen und akzeptiert.',
  'auth:login:invalid' : 'Ungültige Zugangsdaten oder ungültiges Passwort',
  'auth:login:title' : 'Mit Wacom ID anmelden',
  'auth:login:allWacom:text' : 'Dein Konto für alle Wacom Produkte.',
  'auth:login:forgotPassword:link' : 'Passwort vergessen',
  'auth:login:button' : 'Anmelden',
  'auth:login:signup:button' : 'Registrieren',
  'auth:login:createNewAccount:label' : 'Du hast noch kein Konto?',
  'auth:login:alreadyHaveAccount:label' : 'Du hast bereits ein Konto?',
  'auth:passwordReset:title' : 'Passwort zurücksetzen',
  'auth:passwordReset:text' : 'Gib die E-Mail-Adresse ein, die du für deine Wacom ID verwendet hast. Wir senden dir einen Link zum Zurücksetzen deines Passworts.',
  'auth:passwordReset:reset:button' : 'Zurücksetzen',
  'auth:passwordReset:signIn:button' : 'Anmelden',
  'auth:passwordReset:back:button' : 'Zurück zu',
  'auth:passwordReset:success:text' : 'Wir haben eine E-Mail an deine E-Mail-Adresse geschickt.',
  'auth:register:title' : 'Erstelle dein Profil',
  'auth:register:success:title1' : 'Herzlichen Glückwunsch!',
  'auth:register:success:title2' : 'Du hast die Registrierung abgeschlossen.',
  'auth:register:success:message' : 'Um fortfahren zu können, musst du dein E-Mail-Postfach öffnen und deine Registrierung bestätigen. Danach kannst du dich anmelden und auf dein Dashboard zugreifen.',
  'auth:errors:generic' : 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  'auth:policyUpdate:title': 'Unsere Nutzungsbedingungen sowie unsere Datenschutzrichtlinien haben sich seit deiner letzten Anmeldung geändert',

  // Welcome screens
  'welcome:screen1:header': 'Funktionen von Ink Collaboration',
  'welcome:screen1:text': 'Mit Ink Collaboration kannst du von jedem Ort aus mit jeder beliebigen Person zusammenarbeiten. Verwende eine Leinwand mit deinen Inhalten oder erstelle eine neue Leinwand und beginne zusammen mit anderen ein neues Projekt.',
  'welcome:screen2:header': 'Synchronisieren und Inhalte teilen',
  'welcome:screen2:text': 'Dank deiner Wacom ID und den mit der Cloud verbundenen Apps stehen dir deine Online-Inhalte jederzeit und überall zur Verfügung. Exportiere Inhalte in andere Formate und bearbeite sie mit deinen bevorzugten Software-Tools und Apps.',
  'welcome:screen3:header': 'Zugriff auf all deine Inhalte',
  'welcome:screen3:text': 'Dank deiner Wacom ID kannst du neue Funktionen und Arbeitsabläufe für deine Inhalte nutzen. Du kannst jederzeit auf deine Inhalte zugreifen, sie mit anderen teilen oder online Sicherheitskopien deiner Arbeit speichern. Über deine Wacom ID erhältst du Zugriff auf die Cloud.',
  'welcome:button:next': 'Weiter',
  'welcome:button:done': 'Fertig',

  // Migration screen
  'migration:screen1:text1': 'Deine Inhalte werden optimiert. Bitte warte.',
  'migration:screen1:text2': 'Verarbeitung läuft ...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Service-Update',
  'whatIsNew:cloudMigration:mainText': 'Wir nehmen Verbesserungen an unseren Cloud-Diensten vor. Es kann zu kurzen Verzögerungen bei der Anzeige oder Nutzung deiner Inhalte kommen. Wir danken dir für deine Geduld.',
  'whatIsNew:cloudMigration:cloudTeam': '„– das Wacom Cloud Team“',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Kurzfassung',
  'semantic:sidebar:confidence': 'Zuversicht',
  'semantic:sidebar:reference': 'Referenz',

  // Semantic-ink icon description
  'semantic:type:organization': 'Organisation',
  'semantic:type:topic': 'Thema',
  'semantic:type:person': 'Person',
  'semantic:type:location': 'Standort',
  'semantic:type:event': 'Ereignis',
  'semantic:error:header': 'Keine Semantic Ink-Erkennung',
  'semantic:error:text': 'Deine Notiz braucht keine Verbesserung durch Semantic Ink.',
    
  // Download my data
  'dataDownload:profile:option': 'Meine Daten',
  'dataDownload:tabs:downloadData': 'Daten herunterladen',
  'dataDownload:tabs:availableData': 'Verfügbare Daten',
  'dataDownload:tabs:downloadData:description': 'Exportiere und lade eine Kopie der Inhalte deines InkSpace-Kontos herunter.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Bitte wählen …',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Alle auswählen',
  'dataDownload:processing:description': 'Der Download deiner Dateien kann eine Weile dauern. Sobald dein Download bereit ist, erhältst du eine E-Mail mit dem Link zur Downloadseite.',
  'dataDownload:processing': 'Verarbeitung läuft...',
  'dataDownload:request:button': 'Daten herunterladen',
  'dataDownload:tabs:availableData:description': 'Deine Daten herunterladen',
  'dataDownload:tabs:availableData:archiveFor': 'Archivieren für ',
  'global:myData': 'Meine Daten'
}

export default messages;