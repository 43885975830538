import React from 'react';
import EmptyTags from '../components/tags/EmptyTags';

function MateGroupContextMenu(props) {
  const { formatMessage, contextMenu, item } = props;

  return [
    {
      id: 'ctrl-button-export',
      title: formatMessage({ id: 'global:export' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doShowExportModal(item)
    },
    {
      id: 'ctrl-button-rename',
      title: formatMessage({ id: 'global:rename' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doRenameGroup(item)
    },
    {
      id: 'ctrl-button-delete',
      title: formatMessage({ id: 'global:delete' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doDeleteGroup(contextMenu)
    },
    {
      id: 'ctrl-button-tags',
      title: formatMessage({ id: 'global:tags' }),
      className: 'doc-ctrl-button children',
      emptyView: <EmptyTags style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    }
  ]
}

export default MateGroupContextMenu;
