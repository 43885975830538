import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useGlobal from 'store';
import { changeSemanticView } from 'common/components/utilities/queryHelper';
import MenuBar from '../../common/components/menuBar/MenuBar';
import Brand from 'common/components/brand/Brand';
import SideBar from './components/sidebar/SideBar';
import ZoomTool from 'routes/inkEditor/components/Zoom/ZoomTool';
import CanvasContextProvider from 'routes/inkEditor/context/CanvasContextProvider';
import SemanticContextProvider from './context/SemanticContextProvider';
import SemanticCanvasWrapper from '../semantic/components/canvas/SemanticCanvasWrapper';
import { ReactComponent as SemanticIcon } from './components/sidebar/assets/semantic_ink.svg';

import './SemanticComponent.scss';

const allTypesFilter = 'ALL';

function filterSemanticData(elements, filters) {
    if (!elements) return [];

    if (filters.has(allTypesFilter) || filters.size === 0)
        return elements;

    return elements.filter(e => filters.has(e.relevantConcept));
}

function SemanticComponent(props) {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [semanticDataFiltered, setSemanticDataFiltered] = useState([]);
    const [semanticFilter, setSemanticFilter] = useState(new Set([allTypesFilter]));
    const [isSematicInfoVisible, setIsSematicInfoVisible] = useState(true);
    const { docId, pageId, locale } = props.match.params;
    const [globalState, globalActions] = useGlobal();
    const {
        fetchSemantics, abortFetchDocumentUrl
    } = globalActions.semantic;
    const { isFetching } = globalState.semantic;
    const semanticData = globalState.semantic.data;
    const opcUrl = props.location.state?.opcUrl;
    const pageIds = globalState.inkEditor?.pageContents?.allPageIds;
    const { clearInkEditorState } = globalActions.inkEditor;

    const brand = <Brand brandName='Brand' className={'semantic-mode'} />;
    const errorMessage = useCallback(() => <div>
        <h1>
            {formatMessage({ id: 'semantic:error:header' })}
        </h1>
        <br />
        {formatMessage({ id: 'semantic:error:text' })}
    </div>, [formatMessage]);

    const handleFilterChange = (type) => {
        if (type === allTypesFilter) {
            semanticFilter.clear();
            semanticFilter.add(allTypesFilter);
        } else {
            semanticFilter.delete(allTypesFilter);

            if (semanticFilter.has(type)) {
                semanticFilter.delete(type)
            } else {
                semanticFilter.add(type);
            }
        }

        const filteredData = filterSemanticData(semanticData, semanticFilter);

        setSemanticFilter(semanticFilter);
        setSemanticDataFiltered(filteredData);
    }

    const onPageChange = (newPage) => {
        history.push(changeSemanticView(docId, newPage, locale));
    }

    const onChangeSemanticLanguage = (targetLocale) => {
        let location = props.match.url;
        location = location.replace(`/${locale}`, `/${targetLocale}`);

        history.push(location);
    }

    useEffect(() => {
        return () => {
            clearInkEditorState();
        }
    }, [clearInkEditorState]);

    useEffect(() => {
        fetchSemantics(docId, opcUrl, locale, errorMessage);

        return () => {
            abortFetchDocumentUrl();
        }
    }, [docId, opcUrl, fetchSemantics, locale, abortFetchDocumentUrl, errorMessage]);

    useEffect(() => {
        const data = semanticData ?? [];
        const filteredData = filterSemanticData(data, semanticFilter);

        setSemanticDataFiltered(filteredData);

        // in order to show the preloader
        const isSidebarVisible = semanticData === null || (semanticData !== null && semanticData.length > 0);
        setIsSematicInfoVisible(isSidebarVisible)
    }, [semanticData, setSemanticDataFiltered, setIsSematicInfoVisible, semanticFilter])

    let semanticButtonClasses = 'semantic-button';
    if (isSematicInfoVisible) semanticButtonClasses += ' isActive';

    const menuChildren = <>
        <div className={'menu-tools menu-1'}>
            <div className={semanticButtonClasses} onClick={() => setIsSematicInfoVisible(val => !val)}>
                <SemanticIcon />
            </div>
        </div>
        <div className={'menu-tools menu-2'}>
        </div>
        <ZoomTool />
    </>

    const sidebarInlineStyles = {};

    if (!isSematicInfoVisible) {
        sidebarInlineStyles.width = 0;
        sidebarInlineStyles.minWidth = 0;
        sidebarInlineStyles.border = 'unset';
    }

    return <div className={'semantic-main'}>
        <CanvasContextProvider>
            <SemanticContextProvider>
                <MenuBar brand={brand} elements={menuChildren} />
                <div className={'semantic-main-content'}>
                    <SemanticCanvasWrapper
                        pageId={pageId}
                        pageIds={pageIds}
                        onPageChange={onPageChange}
                        isSematicInfoVisible={isSematicInfoVisible}
                        semanticData={semanticDataFiltered} />
                </div>
                <div className={'sidebar-wrapper'} style={sidebarInlineStyles}>
                    <SideBar
                        isFetching={isFetching}
                        data={semanticDataFiltered}
                        semanticFilter={semanticFilter}
                        handleFilterChange={handleFilterChange}
                        onChangeSemanticLanguage={onChangeSemanticLanguage}
                        currentLocale={props.match.params.locale}
                    />
                </div>
            </SemanticContextProvider>
        </CanvasContextProvider>
    </div>
}

export default SemanticComponent;