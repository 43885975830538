import React from 'react';
import { useIntl } from 'react-intl';
import TextInputModal from 'common/components/modals/TextInputModal';
import { MIN_INPUT_FIELD_LENGTH, MAX_INPUT_FIELD_LENGTH } from 'constants/constants';

function RenameGroup(props) {
    const { id, label, applicationName } = props.group;
    const { formatMessage } = useIntl();
    const { actions } = props;

    const onSubmitAction = (groupName) => {
        actions.home.renameGroup(id, groupName, applicationName);
    }

    const buttons = [{
        // onClick: onSubmitAction,
        title: formatMessage({ id: 'modalbox:group:rename' }),
        id: 'proceed',
        isSubmit: true
    }];

    const minValidationLen = MIN_INPUT_FIELD_LENGTH;
    const maxValidationLen = MAX_INPUT_FIELD_LENGTH;

    return <TextInputModal title={formatMessage({ id: 'modalbox:group:rename' })}
        minLength={minValidationLen}
        maxLength={maxValidationLen}
        buttons={buttons}
        initialValue={label}
        onSubmit={onSubmitAction}
        onDestroy={props.onDestroy}
        isSpinnerVisible={props.modalState.isSpinnerVisible}
        errorMessage={formatMessage({ id: 'inputField:notInRange' }, {
            minLength: minValidationLen,
            maxLength: maxValidationLen
        })} />
}

export default RenameGroup;