import React from 'react';
import ContentLoader from 'react-content-loader'

function ListPreloader(props) {
    let { width, height } = props;

    width = width ?? 300;
    height = height ?? 700;

    const widthSpacing = 30;
    const spacing = 20;

    const elements = [];
    const lineElementHeight = 50;
    let topOffset = 0;
    const mainWidth = (width * .65) - widthSpacing;
    const secondX = spacing + mainWidth + 15;
    const secondWidth = (width * .3);

    for (let index = 0; index < 10; index++) {

        elements.push(<rect key={`${index}_1`} x={spacing} y={topOffset} rx="3" ry="3" width={mainWidth} height={lineElementHeight} />);
        elements.push(<rect key={`${index}_2`} x={secondX} y={topOffset} rx="3" ry="3" width={secondWidth} height={lineElementHeight} />);

        elements.push(<rect key={`${index}_3`} x={spacing + 10} y={topOffset + lineElementHeight + 10} rx="3" ry="3" width={mainWidth - 20} height={lineElementHeight / 2} />);
        elements.push(<rect key={`${index}_4`} x={secondX - 10} y={topOffset + lineElementHeight + 10} rx="3" ry="3" width={secondWidth} height={lineElementHeight / 2} />);

        topOffset += lineElementHeight * 2;
    }

    return <ContentLoader
        uniqueKey={'preloader-key'}
        backgroundColor={'#EEE'}
        foregroundColor={'#AAA'}
        viewBox={`0 0 ${width} ${height * .9}`}
    >
        {elements}
    </ContentLoader>
}

export default ListPreloader;