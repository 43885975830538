// true is used if all docs have the tag
// false is some docs have the tag
// undefined is used then the tag was not seen in any document
export const checkForFullTag = (usedTags, docsCount) => {
    for (let key in usedTags) {
        if (usedTags[key] === docsCount && docsCount !== 0) {
            usedTags[key] = { full: true };
        } else {
            usedTags[key] = { full: false };
        }
    }

    return usedTags;
}

export const mergeTagMaps = (tagMaps) => {
    let mergedTags = {}
    if (Array.isArray(tagMaps) && tagMaps.length === 1) {
        return tagMaps[0];
    }

    for (const map of tagMaps) {
        for (let tag of Object.keys(map)) {
            const value = map[tag].full ? 1 : 0

            if (!mergedTags[tag]) {
                mergedTags[tag] = value;
            } else {
                mergedTags[tag] += value;
            }
        }
    }

    const usedTags = checkForFullTag(mergedTags, tagMaps.length);
    return usedTags;
}

export const getDocsCommonTags = (documents, tags) => {
    let docsCount = documents.length
    let usedTags = {}

    for (const tag of tags) {
        for (const doc of documents) {
            if (tag.documents.includes(doc.id)) {
                if (usedTags[tag.id]) {
                    usedTags[tag.id]++;
                } else {
                    usedTags[tag.id] = 1;
                }
            }
        }
    }

    usedTags = checkForFullTag(usedTags, docsCount);

    return usedTags;
}