import React from 'react';
import { useIntl } from 'react-intl';

import './EmptyTags.scss';


function EmptyTags(props) {
    const { style } = props;
    const { formatMessage } = useIntl();

    return <div className='no-tags' style={style}>
        <p className='head'>{formatMessage({ id: 'tagContainer:noTags' })}</p>
        <p className='body'>{formatMessage({ id: 'tagContainer:noTagsMessage' })}</p>
    </div>
}

export default EmptyTags;