import React, { useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './PenTool.scss';

const penToolId = INK_EDITOR_CONFIG.controls.pen;

function PenTool(props) {
    const { toolId, setToolId } = useContext(CanvasContext);
    const isActive = toolId === penToolId;

    return <div className={'pen-tool ink-tool'}>
        <i
            className={isActive ? 'icon active' : 'icon icon-pen'}
            // ref={node => this.$node = node} 
            onClick={() => setToolId(penToolId)}
        />
    </div>
}

export default PenTool;