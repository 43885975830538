import React, { useRef, useEffect, useCallback } from 'react';

import { ReactComponent as IconUp } from './assets/btn_chevron_up.svg';
import { ReactComponent as IconDown } from './assets/btn_chevron_down.svg';

import './PageIndicator.scss';

const trackIndicatorClassName = 'track';

function PageIndicator(props) {
    const { pageId, pageIds, onPageChange } = props;
    const isMouseDown = useRef(false);
    const circleElementRef = useRef(null);
    const trackIndicatorRef = useRef(null);

    const currentPageIndex = pageIds.indexOf(pageId);
    const totalPages = pageIds.length;
    const isTopActive = currentPageIndex !== 0;
    const circleHeight = circleElementRef.current?.clientHeight ?? 0;
    const trackIndicatorHeight = trackIndicatorRef.current?.clientHeight ?? 0;
    const isBottomActive = currentPageIndex !== totalPages - 1;
    let fillHeight = trackIndicatorHeight * (currentPageIndex / (totalPages - 1));

    // This prevents the circle indicator from overflowing parent's bounds
    fillHeight -= circleHeight;
    fillHeight = Math.max(fillHeight, 0);


    const trackFillStyle = {
        height: `${fillHeight}px`
    };

    const handlePageChange = useCallback((newPageIndex) => {
        if (newPageIndex >= 0 && newPageIndex < totalPages) {
            const newPageId = pageIds[newPageIndex];
            if (newPageIndex !== currentPageIndex) {
                onPageChange && onPageChange(newPageId);
            }
        }
    }, [onPageChange, currentPageIndex, pageIds, totalPages]);

    const handelPageUp = useCallback(() => {
        handlePageChange(currentPageIndex + 1)
    }, [currentPageIndex, handlePageChange]);

    const handelPageDown = useCallback(() => {
        handlePageChange(currentPageIndex - 1)
    }, [currentPageIndex, handlePageChange]);

    const onMouseDown = (ev) => {
        isMouseDown.current = true;
        onMouseMove(ev);
    }

    const onMouseUp = () => {
        isMouseDown.current = false;
    }

    const onMouseMove = (ev) => {
        if (isMouseDown.current) {
            handleScroll(ev);
        }
    }

    const onMouseLeave = () => {
        isMouseDown.current = false
    }

    const handleScroll = (ev) => {
        let { target } = ev;

        while (!target.classList.contains(trackIndicatorClassName)) {
            target = target.parentElement;
        }

        const rect = target.getBoundingClientRect();
        const divisions = rect.height / pageIds.length;
        const y = ev.clientY - rect.top;
        const newPageIndex = parseInt((y / divisions));

        handlePageChange(newPageIndex);
    }

    useEffect(() => {
        const keyHandler = (ev) => {

            // ArrowUp
            if (ev.keyCode === 38) {
                handelPageDown();
            }
            // ArrowDown
            else if (ev.keyCode === 40) {
                handelPageUp();
            }
        }

        window.addEventListener('keyup', keyHandler);
        return () => { window.removeEventListener('keyup', keyHandler) };
    }, [currentPageIndex, handelPageDown, handelPageUp]);

    const pageIndicatorText = `${currentPageIndex + 1} / ${totalPages}`;

    return <div className={'page-indicator-wrapper'}>
        <div className={'page-numbers'}>
            {pageIndicatorText}
        </div>
        <div className={'scroll-indicator'}>
            <div className={'scroll-button'}
                onClick={handelPageDown}>
                <IconUp
                    id={'page-indicator-up'}
                    className={!isTopActive ? 'inactive' : ''}
                />
            </div>
            <div className={trackIndicatorClassName}
                onClick={handleScroll}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
            >
                <div ref={trackIndicatorRef} className={'track-indicator'}>
                    <div className={'track-indicator-fill'} style={trackFillStyle} />
                    <div ref={circleElementRef} className={'track-indicator-circle'} />
                </div>
            </div>
            <div className={'scroll-button'}
                onClick={handelPageUp} >
                <IconDown
                    id={'page-indicator-up'}
                    alt={'arrow down'}
                    className={!isBottomActive ? 'inactive' : ''}
                />
            </div>
        </div>
    </div >
}

export default PageIndicator;