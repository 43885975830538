import React from 'react';
import { useIntl } from 'react-intl';

import './DownloadListItem.scss';

function download(dataurl, filename) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
}

function DownloadListItem(props) {
    const { formatMessage } = useIntl();
    const { name, downloadLink, symbol, localizedMessage, archiveForMessage } = props;
    console.log(props)
    // const checkBoxId = `app-name-checkbox-${name}`;

    return <span key={name} className={'application-selection-item download-list-item'}>
        <span className={`app-name-icon icon app-name-checkbox-${name}`}>
            <div className={'app-name-letter'}>
                {symbol}
            </div>
        </span>
        <span>
            {archiveForMessage} {localizedMessage}
        </span>

        <span className={'save-button-wrapper'}>
            <button
                className={'blue-button'}
                // disabled={percentage !== null}
                // onClick={handleOnDownloadClick}
                onClick={() => download(downloadLink, `${name}.zip`)}>
                {formatMessage({ id: 'global:save'})}
            </button>
        </span>
    </span>
}

export default DownloadListItem;