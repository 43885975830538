const messages = {
  'documents:empty:noresult:noresult' : 'AUCUN RÉSULTAT',
  'documents:empty:noresult:noresultfound' : 'Aucun résultat ne correspond à vos critères.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : 'Une fois que votre appareil sera connecté à l\'application Ink Space et que vous serez inscrit(e) avec votre identifiant Wacom, tout votre contenu sera affiché ici.',
  'documents:empty:stream:explanation1:webstream' : 'Essayez Ink Collaboration. Partagez vos créations et collaborez avec d\'autres personnes. Essayer aujourd\'hui',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Lorsque vous vous connectez à votre Bamboo Paper avec votre Wacom ID, votre contenu s\'affiche ici.',
  'documents:empty:stream:explanation1:wacom_notes' : 'Prenez des notes. Faites-en plus. Comme sur le papier, mais en mieux.',
  'documents:empty:stream:tryitnow' : 'Essayer maintenant',
  'documents:empty:stream:learnmore' : 'EN SAVOIR PLUS',
  'documents:empty:group' : 'Votre groupe est vide',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': 'Collaboration Stream',

  'filter:result' : 'RÉSULTAT POUR',
  'filter:search' : 'RECHERCHE...',

  'footer:navigation:privacy' : 'CONFIDENTIALITÉ',
  'footer:navigation:termOfUse' : 'CONDITIONS D\'UTILISATION',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Préférences en matière de cookies',

  'global:convertTo' : 'CONVERTIR EN {language}',
  'global:convertToText' : 'Convertir en texte',

  'context-menu:createNewCanvas' : 'Créer des toiles',
  'context-menu:createNewGroup' : 'Créer un groupe',
  'context-menu:startNewCollaboration' : 'Commencer une nouvelle collaboration',
  'context-menu:startCollaboration' : 'Commencer la collaboration',
  'context-menu:edit' : 'Modifier',

  'global:delete' : 'Supprimer',
  'global:rename' : 'Renommer',
  'global:export' : 'Exporter',
  'global:tags' : 'Vignettes',
  'global:groups': 'Groupes',
  'global:shareLink' : 'Partager le lien',
  'global:copy' : 'COPIER',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : 'Dupliquer et modifier',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : 'Enregistrer',
  'global:dontsave' : 'Ne pas enregistrer',
  'global:proceed' : 'CONTINUER',
  'global:enterName' : 'SAISIR UN NOM',
  'global:browserUnsupported' : 'Navigateur non pris en charge',

  'global:language' : 'LANGUE',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : 'Déconnexion',
  'global:login' : 'Connexion',
  'global:myAccount' : 'Mon compte',

  'inputField:existsName' : 'Erreur. Ce nom existe déjà.',
  'inputField:incorrectName' : 'Erreur. Nom incorrect.',
  'inputField:notInRange' : 'Erreur. Le nom doit comporter ( {minLength} - {maxLength} ) caractères.',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Veuillez utiliser Chrome ou Firefox pour vous connecter à cette collaboration. Merci',
  'modalbox:browser:unsupport:sf:text': 'Veuillez utiliser Chrome ou Safari pour vous connecter à cette collaboration. Merci',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : 'Erreur!',
  'modalbox:error:buttonTitle' : 'Fermer',
  'modalbox:exportError:message' : 'L\'action d\'exportation n\'est pas disponible pour le moment',
  'modalbox:createTag:buttons:title' : 'CRÉER',
  'modalbox:createTag:title' : 'CRÉER UNE NOUVELLE VIGNETTE',
  'modalbox:deleteTag:message' : 'Vous êtes sur le point de supprimer <span>"{tag}"</span>. Cette action est irréversible.',
  'modalbox:removeDocument:message' : 'Vous êtes sur le point de supprimer <span>"{docTitle}"</span>. Cette action est irréversible.',
  'modalbox:shareLink:buttons:stopSharing' : 'Arrêter le partage',
  'modalbox:sessionFull:title' : 'Collaboration complète',
  'modalbox:sessionFull:text' : 'Malheureusement, la séance de collaboration à laquelle vous tentez d\'accéder est complète.',
  'modalbox:leaveSession:title' : 'Quitter la séance',
  'modalbox:leaveSession:buttons:leave' : 'Quitter',
  'modalbox:leaveSession:text' : 'Voulez-vous quitter la séance de collaboration ?',
  'modalbox:endSession:title' : 'Terminer la séance',
  'modalbox:endSession:buttons:end' : 'Terminer',
  'modalbox:endSession:text' : 'Voulez-vous mettre un terme à la séance de collaboration actuelle ? La progression actuelle sera automatiquement enregistrée dans votre flux Web.',
  'modalbox:sessionEnded:title' : 'Séance terminée',
  'modalbox:sessionEnded:text' : 'Voulez-vous enregistrer une copie de la séance de collaboration dans votre flux Web ?',
  'modalbox:accessDenied:title' : 'Accès refusé',
  'modalbox:accessDenied:buttons:goBack' : 'Retour',
  'modalbox:accessDenied:text' : 'Malheureusement, la séance de collaboration à laquelle vous tentez d\'accéder a expiré.',
  'modalbox:collaborationStarted:title' : 'COLLABORATION DÉMARRÉE',
  'modalbox:collaborationStarted:warn' : 'Ink Collaboration doit rester ouvert.  Si vous fermez cet onglet ou si vous changez d\'onglet, Ink Collaboration se fermera.',
  'modalbox:collaborationStarted:text' : ' Votre séance de collaboration a démarré. Vous pouvez inviter jusqu\'à 10 participants en leur donnant l\'URL de collaboration depuis votre navigateur Web',
  'modalbox:duplicateAndEdit:buttons:dontshow' : 'Ne pas afficher',
  'modalbox:duplicateAndEdit:text' : 'Une nouvelle version étendue de cette page sera placée dans votre flux Ink Space Web sans modifier la version originale',
  'modalbox:saveChanges:title' : 'Enregistrer les modifications',
  'modalbox:saveChanges:text' : 'Voulez-vous enregistrer les modifications apportées au document avant de fermer ?',
  'modalbox:collaborationError:title' : 'Erreur de collaboration !',
  'modalbox:collaborationError:buttons:close' : 'Fermer',
  'modalbox:collaborationError:text' : 'Le navigateur que vous utilisez n\'est pas pris en charge par Ink Collaboration.  Cette fonctionnalité est optimale avec Firefox /v55+/, Chrome /v60+/ et Safari /v11+/',
  'modalbox:WebGLError:title' : 'Une erreur est survenue',
  'modalbox:WebGLError:text' : 'Une erreur est survenue, vous devez recharger le contenu en utilisant le bouton « Recharger » du message ci-dessus.',
  'modalbox:collaborationJoinFailed:title': 'Impossible d\'établir la connexion',
  'modalbox:collaborationJoinFailed:text': 'Impossible d\'accéder à la séance. Veuillez recommencer plus tard.',
  'modalbox:connectionLost:title': 'Connexion perdue',
  'modalbox:connectionLost:text': 'La connexion a été perdue, veuillez vérifier votre connexion Internet puis recommencez.',
  'modalbox:phoneDetection:title': 'Utiliser l\'application mobile',
  'modalbox:phoneDetection:btn:open': 'Ouvrir',
  'modalbox:phoneDetection:btn:no-thanks': 'Non merci',
  'modalbox:phoneDetection:msg': 'Nous vous recommandons d\'utiliser notre application mobile dédiée sur votre téléphone pour profiter d\'une expérience optimale.',
  'modalbox:chooseVectorOrRaster:title' : 'Choisir un mode d\'édition',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : 'Annoter',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : 'Vectoriser',
  'modalbox:chooseVectorOrRaster:text' : 'Le contenu que vous essayez de modifier est un contenu matriciel. Afin de conserver l\'apparence d\'origine, nous vous recommandons de procéder à son annotation. De plus, vous pouvez également choisir de le convertir vers une base vectorielle, mais le résultat pourrait être légèrement différent de l\'aspect d\'origine, selon les outils utilisés.',

  // Groups Strings
  'modalbox:deleteGroup:title' : 'Supprimer le groupe',
  'modalbox:deleteGroup:title:multiple' : 'Supprimer les groupes',
  'modalbox:deleteGroup:button:removeGroup' : 'Supprimer un groupe',
  'modalbox:deleteGroup:button:removeGroup:multiple' : 'Retirer les groupes',
  'modalbox:deleteGroup:message' : 'Voulez-vous supprimer uniquement le groupe <span>"{titles}"</span> ou également son contenu ? Cette action est irréversible.',
  'modalbox:deleteGroup:message:multiple': 'Voulez-vous supprimer uniquement les groupes "<span>"{titles}"</span>" ou également leur contenu ? Cette action est irréversible.',
  'modalbox:deleteGroup:button:removeAll': 'Tout supprimer',
  'modalbox:deleteGroup:button:cancel': 'Annuler',
  'modalbox:deletePage:title': 'Supprimer la page',
  'modalbox:deletePage:message': 'Voulez-vous supprimer "<span>"{titles}"</span>" ou le retirer de ce groupe ?',
  'modalbox:deletePage:removePage': 'Retirer du groupe',
  'modalbox:group:rename': 'Renommer le groupe ',
  'mailbox:exportLimit:title': 'Limite atteinte',
  'mailbox:exportLimit:message': 'Le nombre maximal de {maxExportPages} notes a été dépassé. Veuillez réduire le nombre de notes sélectionnées et recommencer.',


  'toast:groupAddedSuccess:single': 'Page ajoutée au groupe "{groupName}"',
  'toast:groupAddedSuccess:plural': 'Pages ajoutées au groupe "{groupName}"',
  'toast:pagesRemovedFromGroup:single': 'Page retirée du groupe "{groupName}"',
  'toast:pagesRemovedFromGroup:plural': 'Pages retirées du groupe "{groupName}"',
  'toast:groupCreated': 'Le groupe "{groupName}" a été créé',
  'toast:groupRemoved': 'Le groupe "{groupName}" a été retiré',
  'toast:groupRemoved:andPages': 'Le groupe "{groupName}" et les pages ont été retirés',
  'toast:groupRemoved:plural': 'Les groupes sélectionnés ont été retirés',
  'toast:noteRemoved:single': 'Page supprimée',
  'toast:noteRemoved:plural': 'Pages supprimées',

  'sorter:ascending' : 'Croissant',
  'sorter:date' : 'Date',
  'sorter:descending' : 'Décroissant',
  'sorter:name' : 'Nom',
  'sorter:size' : 'Taille',
  'sorter:sortby' : 'Trier par',
  'sorter:type' : 'Type',

  'tagContainer:createTag' : 'CRÉER VIGNETTE',
  'tagContainer:noTags' : 'Aucune balise',
  'tagContainer:noTagsMessage' : 'Une fois créées, les balises s\'afficheront ici',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': 'Une fois que vous créez des groupes, ils apparaissent ici.',

  'export:asDoc' : 'en tant que DOC',
  'export:asPsd' : 'en tant que PSD',
  'export:asJpg' : 'en tant que JPG',
  'export:asPdf' : 'en tant que PDF',
  'export:asPng' : 'en tant que PNG',
  'export:asSvg' : 'en tant que SVG',
  'export:asWill' : 'en tant que WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'en tant que PowerPoint',

  // Ink to Video
  'video:generating' : 'Génération en cours de votre vidéo',
  'video:timeNotice' : 'Cela peut prendre un certain temps',
  'video:estimatedTime.one': 'Cela peut prendre jusqu\'à une minute',
  'video:estimatedTime.many': 'Cela peut prendre jusqu\'à <span>{minutes}</span>minutes',
  'video:fileReady' : 'Votre fichier est prêt',
  'video:download'  : 'Télécharger',
  'video:expirationMessage' : 'Le téléchargement sera automatiquement supprimé au bout de <span>{days}</span>jours',
  'video:error' : 'Échec de génération de la vidéo',

  // Session Expired Prompt
  'session:expired:title': 'Session expirée',
  'session:expired:message': 'Votre session a expiré. Veuillez vous reconnecter.',
  'session:expired:button': 'Connexion',

  // Auth module
  'auth:email:label' : 'E-mail',
  'auth:email:taken' : 'Cette adresse e-mail n\'est pas disponible.',
  'auth:email:notRegisterd' : 'Cette adresse e-mail n\'est pas enregistrée.',
  'auth:email:invalid' : 'E-mail invalide',
  'auth:password:label' : 'Mot de passe',
  'auth:password:reenter:label' : 'Saisissez de nouveau le mot de passe',
  'auth:password:mismatch:label' : 'Les mots de passe ne correspondent pas',
  'auth:password:tooEasy:label' : 'Le mot de passe doit contenir au moins {charLength} caractères et 3 des caractères suivants : une majuscule, une minuscule, un chiffre, un symbole spécial.',
  'auth:firstName:label' : 'Prénom',
  'auth:lastName:label' : 'Nom',
  'auth:country:label' : 'Pays',
  'auth:country:select' : 'Sélectionnez un pays',
  'auth:language:label' : 'Langue',
  'auth:language:select' : 'Sélectionnez la langue',
  'auth:termsAndConditions:label' : 'Vous avez lu et accepté les <a href="{termsHref}">conditions d\'utilisation</a> de Wacom.',
  'auth:privacyPolicy:label' : 'Vous avez lu et accepté les <a href="{privacyHref}">directives relatives à la protection de l\'information</a> et la <a href="{cookieHref}">politique sur les cookies</a> de Wacom.',
  'auth:login:invalid' : 'Identifiant ou mot de passe invalide.',
  'auth:login:title' : 'Créez votre Wacom ID',
  'auth:login:allWacom:text' : 'Votre compte pour tout ce qui concerne Wacom.',
  'auth:login:forgotPassword:link' : 'Mot de passe oublié',
  'auth:login:button' : 'Connexion',
  'auth:login:signup:button' : 'Inscription',
  'auth:login:createNewAccount:label' : 'Vous n\'avez pas de compte ?',
  'auth:login:alreadyHaveAccount:label' : 'Vous avez déjà un compte ?',
  'auth:passwordReset:title' : 'Réinitialisation du mot de passe',
  'auth:passwordReset:text' : 'Entrez l\'adresse e-mail que vous avez utilisée pour Wacom ID. Nous vous enverrons un lien pour réinitialiser votre mot de passe.',
  'auth:passwordReset:reset:button' : 'Réinitialiser',
  'auth:passwordReset:signIn:button' : 'Connexion',
  'auth:passwordReset:back:button' : 'Retour',
  'auth:passwordReset:success:text' : 'Un e-mail vous a été envoyé à votre adresse électronique.',
  'auth:register:title' : 'Créez votre profil',
  'auth:register:success:title1' : 'Félicitations !',
  'auth:register:success:title2' : 'Vous avez terminé votre inscription',
  'auth:register:success:message' : 'Pour continuer, veuillez vérifier votre boîte mail pour confirmer votre inscription, puis ouvrez une session pour accéder à votre tableau de bord.',
  'auth:errors:generic' : 'Un problème est survenu. Veuillez réessayer.',
  'auth:policyUpdate:title': 'Nos conditions d\'utilisation et nos directives relatives à la protection de l\'information ont changé depuis votre dernière connexion',

  // Welcome screens
  'welcome:screen1:header': 'Fonctionnalités d\'Ink Collaboration',
  'welcome:screen1:text': 'Ink Collaboration vous permet de travailler avec n\'importe quelle personne, où que vous soyez. Lancez une toile à partir de votre contenu ou créez une nouvelle toile et utilisez les outils graphiques avec d\'autres personnes.',
  'welcome:screen2:header': 'Synchronisez et partagez du contenu',
  'welcome:screen2:text': 'Grâce à Wacom ID et à vos applications connectées au cloud, votre contenu est disponible en ligne à tout moment, à n\'importe quel endroit. Exportez dans d\'autres formats pour continuer à travailler avec vos applications et vos outils logiciels préférés.',
  'welcome:screen3:header': 'Accédez à tout votre contenu',
  'welcome:screen3:text': 'Votre Wacom ID débloque de nombreuses nouvelles fonctions et flux de travail pour votre contenu. Vous avez la possibilité d\'y accéder n\'importe où, de partager avec d\'autres ou bien de sauvegarder votre contenu en ligne en toute sécurité. Le Wacom ID est votre clé pour le cloud.',
  'welcome:button:next': 'Suivant',
  'welcome:button:done': 'Terminé',

  // Migration screen
  'migration:screen1:text1': 'Votre contenu est en cours d\'optimisation. Veuillez patienter.',
  'migration:screen1:text2': 'Traitement en cours...',

  // What is new screen
  'whatIsNew:cloudMigration:title': 'Mise à jour du service',
  'whatIsNew:cloudMigration:mainText': 'Nous sommes en train d\'optimiser nos services de cloud. Il peut y avoir un court délai dans la visualisation ou l\'utilisation de vos contenus. Merci pour votre patience.',
  'whatIsNew:cloudMigration:cloudTeam': '« L\'équipe Wacom Cloud »',

  // Semantic-ink
  'semantic:sidebar:abstract': 'Extrait',
  'semantic:sidebar:confidence': 'confiance',
  'semantic:sidebar:reference': 'Référence',

  // Semantic-ink icon description
  'semantic:type:organization': 'organisation',
  'semantic:type:topic': 'sujet',
  'semantic:type:person': 'personne',
  'semantic:type:location': 'emplacement',
  'semantic:type:event': 'événement',
  'semantic:error:header': 'Pas de reconnaissance Semantic Ink',
  'semantic:error:text': 'Votre note n\'a pas besoin d\'être améliorée avec Semantic Ink.',
  
  // Download my data
  'dataDownload:profile:option': 'Mes données',
  'dataDownload:tabs:downloadData': 'Télécharger les données',
  'dataDownload:tabs:availableData': 'Données disponibles',
  'dataDownload:tabs:downloadData:description': 'Exporter et télécharger une copie du contenu de votre compte Ink Space.',
  'dataDownload:tabs:downloadData:chooseProduct': 'Veuillez sélectionner...',
  'dataDownload:tabs:downloadData:chooseProduct:all': 'Sélectionner tout',
  'dataDownload:processing:description': 'Le téléchargement de vos fichiers peut prendre un certain temps. Lorsque votre téléchargement sera prêt, vous recevrez un e-mail comportant un lien de téléchargement, ou vous pouvez télécharger vos fichiers à partir d\'ici.',
  'dataDownload:processing': 'Traitement en cours...',
  'dataDownload:request:button': 'Télécharger les données',
  'dataDownload:tabs:availableData:description': 'Télécharger vos données',
  'dataDownload:tabs:availableData:archiveFor': 'Archives pour ',
  'global:myData': 'Mes données'
}

export default messages;