import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { PHONE_POPUP_DISMISSED, ANDROID_APP_URI, IOS_APP_URI } from 'constants/constants';

import ModalBox from 'common/components/modalBox/ModalBox'

import './PhoneDetection.scss'

function PhoneDetection(props) {
    const { formatMessage } = useIntl();
    const { onDestroy } = props;
    const appStoreLinks = {
        'AndroidOS': ANDROID_APP_URI,
        'iOS': IOS_APP_URI
    };

    const onMessageDismissed = useCallback(() => {
        window.localStore.setItem(PHONE_POPUP_DISMISSED, 'dismissed');
        onDestroy();
    }, [onDestroy]);

    const onRedirectToApp = () => {
        if (appStoreLinks[props.type]) {
            let win = window.open(appStoreLinks[props.type], '_blank')

            win.focus();
        }
    }

    const options = {
        id: 'phone-detection',
        title: formatMessage({ id: 'modalbox:phoneDetection:title' }),
        onClose: onDestroy,
        buttons: [{
            onClick: onRedirectToApp,
            id: 'btn-open',
            title: formatMessage({ id: 'modalbox:phoneDetection:btn:open' }),
        }, {
            onClick: onMessageDismissed,
            id: 'btn-no-thanks',
            title: formatMessage({ id: 'modalbox:phoneDetection:btn:no-thanks' }),
        }],
        onDestroy: onDestroy
    };


    return <ModalBox {...options}>
        <div className={'phone-detection'} >
            {formatMessage({ id: 'modalbox:phoneDetection:msg' })}
        </div>
    </ModalBox>
}

export default PhoneDetection;