const messages = {
  'documents:empty:noresult:noresult' : '검색 결과 없음',
  'documents:empty:noresult:noresultfound' : '귀하의 검색 기준에 맞는 검색 결과가 없습니다.',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : '귀하의 기기를 Ink Space 앱과 연결하고 Wacom 아이디로 등록을 하면, 귀하의 모든 컨텐츠를 여기서 보실 수 있습니다.',
  'documents:empty:stream:explanation1:webstream' : 'Ink Collaboration을 사용해 보세요.  창작물을 다른 사람과 공유하고 협업해 보세요. 오늘 사용하기',
  'documents:empty:stream:explanation1:bamboopaperstream' : 'Bamboo Paper와 Wacom ID를 연결하면 여기서 모든 콘텐츠를 볼 수 있습니다.',
  'documents:empty:stream:explanation1:wacom_notes' : '필기와 그 외 많은 것들을 마치 종이 위에서 하는 것처럼 할 수 있습니다. 그리고 모든 면에서 종이보다 편리합니다.',
  'documents:empty:stream:tryitnow' : '지금 사용하기',
  'documents:empty:stream:learnmore' : '자세히 알아보기',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'documents:empty:group' : '그룹이 비어 있습니다.',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom 노트',
  'streams:collaboration': '협업 스트림',

  'filter:result' : '검색 결과',
  'filter:search' : '검색...',

  'footer:navigation:privacy' : '개인정보 보호',
  'footer:navigation:termOfUse' : '이용 약관',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : '쿠키 기본설정',

  'global:convertTo' : '변환 {language}',
  'global:convertToText' : 'TEXT로 변환',

  'context-menu:createNewCanvas' : '새 캔버스 만들기',
  'context-menu:createNewGroup' : '그룹 생성',
  'context-menu:startNewCollaboration' : '새 협업 시작',
  'context-menu:startCollaboration' : '협업 시작',
  'context-menu:edit' : '편집',

  'global:delete' : '삭제',
  'global:rename' : '명칭 변경',
  'global:export' : '내보내기',
  'global:tags' : '태그',
  'global:groups': '그룹',
  'global:shareLink' : '링크 공유',
  'global:copy' : '복사',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : '복제 및 편집',
  'global:semantic-ink' : '시맨틱 잉크',

  'global:save' : '저장',
  'global:dontsave' : '저장하지 않음',
  'global:proceed' : '진행',
  'global:enterName' : '이름 입력',
  'global:browserUnsupported' : '지원되지 않는 브라우저',

  'global:language' : '언어',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : '로그아웃',
  'global:login' : '로그인',
  'global:myAccount' : '내 계정',

  'inputField:existsName' : '오류! 이름이 이미 존재합니다.',
  'inputField:incorrectName' : '오류! 이름이 틀렸습니다.',
  'inputField:notInRange' : '오류! 이름이 {minLength}~{maxLength}자 사이여야 합니다.',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': 'Chrome 또는 Firefox 브라우저를 사용하여 이 협업에 연결해 주시기 바랍니다.  감사합니다.',
  'modalbox:browser:unsupport:sf:text': 'Chrome 또는 Safari 브라우저를 사용하여 이 협업에 연결해 주시기 바랍니다.  감사합니다.',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : '오류!',
  'modalbox:error:buttonTitle' : '닫기',
  'modalbox:exportError:message' : '현재 내보내기 작업을 사용할 수 없습니다.',
  'modalbox:createTag:buttons:title' : '생성',
  'modalbox:createTag:title' : '새로운 태그 생성',
  'modalbox:deleteTag:message' : '<span>"{tag}"</span>를 삭제하려고 합니다. 이 작업은 취소할 수 없습니다.',
  'modalbox:removeDocument:message' : '<span>"{docTitle}"</span>를 삭제하려고 합니다. 이 작업은 취소할 수 없습니다.',
  'modalbox:shareLink:buttons:stopSharing' : '공유 중단',
  'modalbox:sessionFull:title' : '협업 세션이 꽉 참',
  'modalbox:sessionFull:text' : '액세스하려는 협업 세션이 꽉 찼습니다.',
  'modalbox:leaveSession:title' : '세션에서 나가기',
  'modalbox:leaveSession:buttons:leave' : '나가기',
  'modalbox:leaveSession:text' : '협업 세션에서 나가시겠습니까?',
  'modalbox:endSession:title' : '세션 종료',
  'modalbox:endSession:buttons:end' : '종료',
  'modalbox:endSession:text' : '현재 협업 세션을 종료하시겠습니까? 현재 진행 과정이 Web Stream에 자동으로 저장됩니다.',
  'modalbox:sessionEnded:title' : '세션이 종료됨',
  'modalbox:sessionEnded:text' : 'Web Stream에 협업 세션의 사본을 저장하시겠습니까?',
  'modalbox:accessDenied:title' : '액세스가 거부됨',
  'modalbox:accessDenied:buttons:goBack' : '뒤로 가기',
  'modalbox:accessDenied:text' : '엑세스하려는 협업 세션이 이미 만료되었습니다.',
  'modalbox:collaborationStarted:title' : '협업이 시작됨',
  'modalbox:collaborationStarted:warn' : 'Ink 협업을 열어두어야 합니다.  이 탭을 닫거나 다른 탭으로 변경할 경우 Ink 협업은 종료됩니다.',
  'modalbox:collaborationStarted:text' : ' 협업 세션이 시작되었습니다. 브라우저에서 협업 URL을 지정하여 최대 10명의 참가자를 초대할 수 있습니다.',
  'modalbox:duplicateAndEdit:buttons:dontshow' : '표시 안 함',
  'modalbox:duplicateAndEdit:text' : '원본을 변경하지 않고 페이지의 새 확장 버전이 Ink Space Web Stream에 배치됩니다.',
  'modalbox:saveChanges:title' : '변경 사항 저장',
  'modalbox:saveChanges:text' : '문서를 닫기 전에 변경 사항을 저장하시겠습니까?',
  'modalbox:collaborationError:title' : '협업 오류!',
  'modalbox:collaborationError:buttons:close' : '닫기',
  'modalbox:collaborationError:text' : 'Ink Collaboration 기능이 현재 사용 중인 브라우저를 지원하지 않습니다.  해당 기능은 Firefox /v55+/, Chrome /v60+/, Safari /v11+/와 호환됩니다.',
  'modalbox:WebGLError:title' : '오류 발생',
  'modalbox:WebGLError:text' : '오류가 발생했습니다. 위 메시지의 "다시 로드" 버튼을 이용하여 콘텐츠를 다시 로드하세요.',
  'modalbox:collaborationJoinFailed:title': '연결을 설정할 수 없습니다.',
  'modalbox:collaborationJoinFailed:text': '엑세스하려는 세션에 엑세스할 수 없습니다. 다음에 다시 시도하세요.',
  'modalbox:connectionLost:title': '연결 끊김',
  'modalbox:connectionLost:text': '연결이 끊겼습니다. 인터넷 연결을 확인한 후 다시 시도하세요.',
  'modalbox:phoneDetection:title': '모바일 앱 사용',
  'modalbox:phoneDetection:btn:open': '열기',
  'modalbox:phoneDetection:btn:no-thanks': '거절',
  'modalbox:phoneDetection:msg': '보다 나은 경험을 위해 Wacom 스마트폰 전용 모바일 앱의 사용을 권합니다.',
  'modalbox:chooseVectorOrRaster:title' : '편집 모드 선택',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : '주석 달기',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : '벡터화',
  'modalbox:chooseVectorOrRaster:text' : '편집하려는 콘텐츠는 래스터 기반입니다. 원본 모습을 보존하려면 위에 주석 달기로 진행하시기를 권합니다. 물론 벡터로 변환하는 방법을 선택할 수 있지만, 사용된 도구에 따라 결과물이 원본과 달라질 수 있습니다.',

  // Groups Strings
  'modalbox:deleteGroup:title' : '그룹 삭제',
  'modalbox:deleteGroup:title:multiple' : '그룹 삭제',
  'modalbox:deleteGroup:button:removeGroup' : 'E그룹 제거',
  'modalbox:deleteGroup:button:removeGroup:multiple' : '그룹 제거',
  'modalbox:deleteGroup:message' : '그룹 <span>"{titles}"</span> 만 삭제하시겠습니까 아니면 내용도 삭제하시겠습니까? 이 작업은 실행을 취소할 수 없습니다.',
  'modalbox:deleteGroup:message:multiple': '그룹 <span>"{titles}"</span>만 삭제하시겠습니까 아니면 내용도 삭제하시겠습니까? 이 작업은 실행을 취소할 수 없습니다.',
  'modalbox:deleteGroup:button:removeAll': '모두 삭제',
  'modalbox:deleteGroup:button:cancel': '취소',
  'modalbox:deletePage:title': '페이지 삭제',
  'modalbox:deletePage:message': '<span>"{titles}"</span>을(를) 삭제하거나 이 그룹에서 제거하시겠습니까?',
  'modalbox:deletePage:removePage': '그룹에서 제거',
  'modalbox:group:rename': '그룹 이름 바꾸기 ',
  'mailbox:exportLimit:title': '한도에 도달함',
  'mailbox:exportLimit:message': '최대한도인 {maxExportPages}개의 메모를 초과했습니다. 선택한 메모의 수를 줄이고 다시 시도해 주십시오.',

  'toast:groupAddedSuccess:single': '"{groupName}"에 페이지가 추가됨',
  'toast:groupAddedSuccess:plural': '"{groupName}"에 페이지가 추가됨',
  'toast:pagesRemovedFromGroup:single': '"{groupName}"에서 페이지가 제거됨',
  'toast:pagesRemovedFromGroup:plural': '"{groupName}"에서 페이지가 제거됨',
  'toast:groupCreated': '"{groupName}"이(가) 생성됨',
  'toast:groupRemoved': '"{groupName}"이(가) 제거됨',
  'toast:groupRemoved:andPages': '"{groupName}"과(와) 페이지가 제거됨',
  'toast:groupRemoved:plural': '선택한 그룹이 제거됨',
  'toast:noteRemoved:single': '페이지가 삭제됨',
  'toast:noteRemoved:plural': '페이지가 삭제됨',


  'sorter:ascending' : '오름차순',
  'sorter:date' : '날짜',
  'sorter:descending' : '내림차순',
  'sorter:name' : '이름',
  'sorter:size' : '크기',
  'sorter:sortby' : '날짜로',
  'sorter:type' : '종류',

  'tagContainer:createTag' : '태그 생성',
  'tagContainer:noTags' : '태그 없음',
  'tagContainer:noTagsMessage' : '태그를 만들면 여기에 나타납니다.',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': '그룹을 만들면 여기에 표시됨',

  'export:asDoc' : 'DOC으로',
  'export:asPsd' : 'PSD로',
  'export:asJpg' : 'JPG로',
  'export:asPdf' : 'PDF로',
  'export:asPng' : 'PNG로',
  'export:asSvg' : 'SVG로',
  'export:asWill' : 'WILL로',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : 'PowerPoint로',

  // Ink to Video
  'video:generating' : '동영상을 생성하는 중',
  'video:timeNotice' : '이 작업은 약간의 시간이 소요됩니다.',

  'video:estimatedTime.one': '이 작업은 최대 1분이 소요됩니다.',
  'video:estimatedTime.many': '이 작업은 최대 <span>{minutes}</span>분이 소요됩니다.',
  'video:fileReady' : '파일이 준비되었습니다.',
  'video:download'  : '다운로드',
  'video:expirationMessage' : '<span>{days}</span>일 후에 다운로드 파일이 자동으로 삭제됩니다.',
  'video:error' : '동영상 생성에 실패했습니다.',

  // Session Expired Prompt
  'session:expired:title': '세션이 만료됨',
  'session:expired:message': '세션이 만료되었습니다. 다시 로그인하십시오.',
  'session:expired:button': '로그인',

  // Auth module
  'auth:email:label' : '이메일',
  'auth:email:taken' : '해당 이메일 주소는 사용할 수 없습니다.',
  'auth:email:notRegisterd' : '해당 이메일은 등록되지 않았습니다.',
  'auth:email:invalid' : '잘못된 이메일',
  'auth:password:label' : '비밀번호',
  'auth:password:reenter:label' : '비밀번호 재입력',
  'auth:password:mismatch:label' : '비밀번호가 일치하지 않습니다',
  'auth:password:tooEasy:label' : '비밀번호는 최소한 {charLength}자이고 다음 중 3가지를 포함해야 합니다: 대문자, 소문자, 숫자, 특수기호.',
  'auth:firstName:label' : '이름',
  'auth:lastName:label' : '성',
  'auth:country:label' : '국가',
  'auth:country:select' : '국가 선택',
  'auth:language:label' : '언어',
  'auth:language:select' : '언어 선택',
  'auth:termsAndConditions:label' : '귀하는 <a href="{termsHref}">이용 약관</a>을 읽고 동의하셨습니다.',
  'auth:privacyPolicy:label' : '귀하는 Wacom의 <a href="{privacyHref}">개인정보 보호정책</a> 및 <a href="{cookieHref}">쿠키 정책</a>을 읽고 동의하셨습니다.',
  'auth:login:invalid' : '잘못된 로그인 또는 비밀번호입니다.',
  'auth:login:title' : 'Wacom ID로 로그인',
  'auth:login:allWacom:text' : '계정으로 Wacom의 모든 것을 사용하십시오.',
  'auth:login:forgotPassword:link' : '비밀번호를 잊어버렸습니다',
  'auth:login:button' : '로그인',
  'auth:login:signup:button' : '회원가입',
  'auth:login:createNewAccount:label' : '계정이 없으십니까?',
  'auth:login:alreadyHaveAccount:label' : '이미 계정이 있으십니까?',
  'auth:passwordReset:title' : '비밀번호 재설정',
  'auth:passwordReset:text' : 'Wacom Id용으로 사용하신 이메일 주소를 입력하십시오. 비밀번호 재설정 링크를 보내 드리겠습니다.',
  'auth:passwordReset:reset:button' : '재설정',
  'auth:passwordReset:signIn:button' : '로그인',
  'auth:passwordReset:back:button' : '돌아가기',
  'auth:passwordReset:success:text' : '확인 이메일을 귀하의 이메일 주소로 보냈습니다.',
  'auth:register:title' : '프로필을 작성하십시오',
  'auth:register:success:title1' : '축하합니다!',
  'auth:register:success:title2' : '등록을 완료하셨습니다',
  'auth:register:success:message' : '계속하시려면 이메일 확인 후 회원가입을 인증한 다음 로그인하면 대시보드에 접속됩니다.',
  'auth:errors:generic' : '문제가 발생했습니다. 다시 시도하십시오.',
  'auth:policyUpdate:title': '귀하의 마지막 로그인 이후로 당사의 이용 약관 및 개인정보 보호정책이 변경되었습니다.',

  // Welcome screens
  'welcome:screen1:header': 'Ink Collaboration 기능',
  'welcome:screen1:text': 'Ink Collaboration 기능을 통해 어디서나 누구와도 협업할 수 있습니다. 컨텐츠 캔버스 또는 새로운 캔버스를 시작하고 다른 사람과 잉크 입력을 시작하십시오.',
  'welcome:screen2:header': '콘텐츠의 동기화 및 공유',
  'welcome:screen2:text': 'Wacom ID와 클라우드에 연결된 앱을 사용하면 온라인에서 언제 어디서나 콘텐츠를 사용할 수 있습니다. 즐겨찾는 소프트웨어 도구 및 앱에서 작업을 계속하려면 다른 형식으로 내보내십시오.',
  'welcome:screen3:header': '모든 콘텐츠에 액세스',
  'welcome:screen3:text': 'Wacom ID는 귀하의 컨텐츠를 위한 다양한 새로운 기능과 워크플로를 잠금 해제합니다. 어디서나 액세스하거나, 다른 사람과 공유하거나, 콘텐츠를 온라인으로 백업하여 보안을 유지할 수 있습니다. Wacom ID는 클라우드를 위한 여러분의 열쇠입니다. ',
  'welcome:button:next': '다음',
  'welcome:button:done': '실행 완료',

  // Migration screen
  'migration:screen1:text1': '귀하의 컨텐츠가 최적화 중입니다. 잠시 기다려 주십시오.',
  'migration:screen1:text2': '작업 중...',

  // What is new screen
  'whatIsNew:cloudMigration:title': '서비스 업데이트',
  'whatIsNew:cloudMigration:mainText': '당사에서는 클라우드 서비스를 개선하고 있습니다. 이러한 이유때문에 귀하의 컨텐츠를 보거나 사용하는데 약간의 지연이 발생할 수 있습니다. 양해해 주셔서 감사합니다. ',
  'whatIsNew:cloudMigration:cloudTeam': '"- Wacom 클라우드 팀"',

  // Semantic-ink
  'semantic:sidebar:abstract': '추상적',
  'semantic:sidebar:confidence': '신뢰',
  'semantic:sidebar:reference': '참조',

  // Semantic-ink icon description
  'semantic:type:organization': '조직',
  'semantic:type:topic': '토픽',
  'semantic:type:person': '담당자',
  'semantic:type:location': '위치',
  'semantic:type:event': '이벤트',
  'semantic:error:header': '시맨틱 잉크가 인식되지 않았습니다',
  'semantic:error:text': '귀하의 노트는 시맨틱 잉크 강화가 필요하지 않습니다.',
  
  // Download my data
  'dataDownload:profile:option': '내 정보',
  'dataDownload:tabs:downloadData': '다운로드 정보',
  'dataDownload:tabs:availableData': '이용 가능한 정보',
  'dataDownload:tabs:downloadData:description': '귀하의 Ink Space 계정 컨텐츠 사본을 내보내기 및 다운로드 하십시오.',
  'dataDownload:tabs:downloadData:chooseProduct': '선택해주십시오...',
  'dataDownload:tabs:downloadData:chooseProduct:all': '모두 선택',
  'dataDownload:processing:description': '파일을 다운로드하기 위해서는 시간이 걸릴 수 있습니다. 다운로드가 준비되면, 다운로드 링크가 첨부된 이메일을 받게되거나 여기에서 다운로드 하실 수 있습니다.',
  'dataDownload:processing': '작업 중...',
  'dataDownload:request:button': '다운로드 정보',
  'dataDownload:tabs:availableData:description': '귀하의 정보 다운로드',
  'dataDownload:tabs:availableData:archiveFor': '보관 ',
  'global:myData': '내 정보'
}

export default messages;