import {
    InkCodec, RMSEBasedPrecisionCalculator, InkPathProducer, BrushGL,
    TypedArrayCodec
} from 'digital-ink';

class InkStorage {
    constructor(inkController) {
        this.inkController = inkController;

        this.codec = new InkCodec();
        this.codec.precisionCalculator = new RMSEBasedPrecisionCalculator();

        this.importBridge = InkPathProducer.getInstance();
        // TODO: 
        // this.importBridge.updateProgress = console.log;
    }

    async encode() {
        return await this.codec.encodeInkModel(this.inkController.dataModel.inkModel);
    }

    decode(buffer) {
        return this.codec.decodeInkModel(buffer);
    }

    async openFile(inkModel) {
        this.inkController.clear();

        let { brushes, strokes } = inkModel;

        await this.pipeline(strokes, brushes);

        for (let brush of brushes) {
            if (brush instanceof BrushGL) {
                await brush.configure(this.inkController.canvas.ctx);
            }
        }

        this.inkController.dataModel.importModel(inkModel);
        this.inkController.hasInkChanges = false;
    }

    async pipeline(strokes, brushes) {
        if (strokes.length === 0) return;

        if (typeof SharedArrayBuffer === 'undefined') {
            brushes.forEach(brush => {
                brush.encoding = TypedArrayCodec.Encoding.NONE;
            });
        }

        await this.importBridge.importBrushes(brushes);
        await this.importBridge.build(strokes);
    }
}

export default InkStorage;