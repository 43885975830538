import React from 'react';
import { useIntl } from 'react-intl';
import { LOGIN_VIEW } from 'constants/constants';

function PasswordSent(props) {
    const { formatMessage } = useIntl();
    return (
        <div className='container-full form-wrapper password-sent'>
            <div>
                <h1>
                    {formatMessage({ id: 'auth:passwordReset:title' })}
                </h1>
                <p>
                    {formatMessage({ id: 'auth:passwordReset:success:text' })}
                </p>
            </div>
            <div className='inner-button-wrapper center'>
                <span onClick={() => props.changeChildComponent(LOGIN_VIEW)} className='btn-default'>
                    {formatMessage({ id: 'auth:passwordReset:signIn:button' })}
                </span>
            </div>
        </div>
    )
}

export default PasswordSent