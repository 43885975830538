import React from 'react';
import { useIntl } from 'react-intl'

import EventTypeIcon from './EventTypeIcon';
import PlaceTypeIcon from './PlaceTypeIcon';
import OrgTypeIcon from './OrgTypeIcon';
import PersonTypeIcon from './PersonTypeIcon';
import TermTypeIcon from './TermTypeIcon';


import './TypeIconComponent.scss';


function TypeIconComponent(props) {
    const { formatMessage } = useIntl();
    let { type } = props;
    let icon = <></>

    type = type?.toLowerCase();
    const typeLocalizationKey = `semantic:type:${type}`;

    switch (type) {
        case 'event':
            icon = <EventTypeIcon />;
            break;
        case 'location':
            icon = <PlaceTypeIcon />;
            break;
        case 'organization':
            icon = <OrgTypeIcon />;
            break;
        case 'person':
            icon = <PersonTypeIcon />;
            break;
        case 'topic':
            icon = <TermTypeIcon />;
            break;
        default:
            icon = <TermTypeIcon />;
            break;
    }

    return <div className={'icon-chooser flex'}>
        {icon}
        <div>
            { formatMessage({ id: typeLocalizationKey }) }
        </div>
    </div>
}

export default TypeIconComponent;