import jwtDecode from 'jwt-decode'

export const checkResponse = (response) => {
  if (!response.ok) throw response
  return response
}

export const codingJWT = (secret) => { }
export const decodeJWT = (token, expiration = {}) => {
  const decode = jwtDecode(token);

  return {
    uuid: decode.sub,
    extSub: decode['ext-sub'],
    firstName: decode.profile.firstName,
    lastName: decode.profile.lastName,
    email: decode.profile.email,
    country: decode.profile.country,
    authCenter: decode.profile.authCenter,
    access: {
      token,
      expiration: decode.exp * 1000,
      rights: decode.rights,
      ...expiration
    }
  };
}

// TODO check if needed
export const decodeExportsJwt = (token) => {
  const decode = jwtDecode(token)

  return {
    uuid: decode.sub,
    expiration: decode.exp * 1000
  }
}

export const getUserId = () => {
  return JSON.parse(window.localStore.getItem('inkspace:auth:profile')).uuid
}

export const getUserInfo = () => {
  const profile = JSON.parse(window.localStore.getItem('inkspace:auth:profile'))

  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    uuid: profile.uuid,
    email: profile.email,
    authCenter: profile.authCenter
  }
}

