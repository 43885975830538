import React from 'react';
import { useIntl } from 'react-intl';
import { LOGIN_VIEW } from 'constants/constants';

const PasswordReset = (props) => {
    const { formatMessage } = useIntl();
    const hasEmailErrors = props.visibleErrors.email.length > 0;
    const emailErrorClass = (!hasEmailErrors ? '' : 'not-valid-item ');

    return (
        <div className='container-full form-wrapper'>
            <div>
                <h1>{formatMessage({ id: 'auth:passwordReset:title' })}</h1>
                <p>{formatMessage({ id: 'auth:passwordReset:text' })}</p>
            </div>
            <form id='reset' name='reset' onSubmit={props.handleSubmit}>
                <div className='input-wrapper'>
                    <label htmlFor='email' className={emailErrorClass}>
                        {formatMessage({ id: 'auth:email:label' })}
                    </label>
                    <input type='email' id='email' name='email'
                        className={emailErrorClass + 'required-input-email'}
                        value={props.email}
                        onChange={props.handleFieldChange}
                        onBlur={props.handleFieldBlur} />
                    {hasEmailErrors ? <p className={'not-valid-item'}>
                        {formatMessage({ id: 'auth:email:invalid' })}
                    </p> : ''}
                </div>

                <button type='submit'
                    className={(props.isSubmitActive ? '' : 'disable-btn ') + 'submit-btn btn-default'}
                    disabled={props.isSubmitActive ? '' : 'disabled'}>
                    {formatMessage({ id: 'auth:passwordReset:reset:button' })}
                </button>
            </form>
            <p className='no-account-box'>
                {formatMessage({ id: 'auth:passwordReset:back:button' })}
                {' '}
                <span className={'blueButton'}
                    onClick={() => props.changeChildComponent(LOGIN_VIEW)}>
                    {formatMessage({ id: 'auth:passwordReset:signIn:button' })}
                </span>
            </p>
        </div>
    )
}

export default PasswordReset;