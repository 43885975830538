import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import useGlobal from 'store';
import { CSSTransition } from 'react-transition-group';

import {
    SESSION_EXPIRED, CREATE_UPDATE_TAG, DELETE_TAG, RENAME_DIR, DELETE_DIR,
    DELETE_DOC, EXPORTS, INK_TO_TEXT, ON_PHONE_DETECTION, RENAME_DOC,
    SHOW_DUPLICATE_AND_EDIT
} from 'actions/modals';

import { CSS_TRANSITION_ENTER_ANIMATION_TIME } from 'constants/constants'

import SessionExpired from 'common/components/session/SessionExpired';
import CreateUpdateTag from 'routes/home/tags/CreateUpdateTag';
import DeleteTag from 'routes/home/tags/DeleteTag';
import RenameGroup from 'routes/home/documents/RenameGroup';
import DeleteGroup from 'routes/home/documents/DeleteGroup';
import DeleteDocument from 'routes/home/documents/DeleteDocument';
import RenameDocument from 'routes/home/documents/RenameDocument';
import ExportContainer from 'common/components/exports/ExportContainer';
import InkToTextConverterComponent from 'common/components/inkToTextConvertion/InkToTextConverterComponent';
import PhoneDetection from 'common/components/phoneDetection/PhoneDetection';
import DuplicateAndEdit from 'routes/home/duplicateAndEdit/DuplicateAndEdit';

const animationTimeout = CSS_TRANSITION_ENTER_ANIMATION_TIME;


function ModalWrapper(props) {
    const childRef = useRef();
    const destroyChildHandle = useRef();
    const [globalState, globalActions] = useGlobal();
    const { isOpen, type } = globalState.modals;
    const [childElement, setChildElement] = useState(<></>);
    const onDestroy = globalActions.modals.hideModal;
    const { data } = globalState.modals;


    useEffect(() => {
        let modalContent = null;

        if (isOpen) {
            switch (type) {
                case SESSION_EXPIRED:
                    modalContent = <SessionExpired />
                    break;
                case CREATE_UPDATE_TAG:
                    modalContent = <CreateUpdateTag />
                    break;
                case DELETE_TAG:
                    modalContent = <DeleteTag />
                    break;
                case RENAME_DIR:
                    modalContent = <RenameGroup group={data} />
                    break;
                case DELETE_DIR:
                    modalContent = <DeleteGroup selection={data} />
                    break;
                case DELETE_DOC:
                    modalContent = <DeleteDocument selection={data} />
                    break;
                case EXPORTS:
                    modalContent = <ExportContainer selection={data} />
                    break;
                case INK_TO_TEXT:
                    modalContent = <InkToTextConverterComponent selection={data} />
                    break;
                case ON_PHONE_DETECTION:
                    modalContent = <PhoneDetection />
                    break;
                case RENAME_DOC:
                    modalContent = <RenameDocument document={data} />
                    break;
                case SHOW_DUPLICATE_AND_EDIT:
                    modalContent = <DuplicateAndEdit {...data} />
                    break;
                default:
                    modalContent = null;
            }
        }

        if (!isOpen) {
            // otherwise the child element stays in the virtual DOM
            // and subsequent modals reuse the old setState
            // instead of initializing a new state for the new modal
            destroyChildHandle.current = setTimeout(() => {
                setChildElement(null)
            }, animationTimeout);
        }

        if (modalContent) {
            setChildElement(modalContent);
        }

        return () => {
            if (destroyChildHandle.current) {
                clearTimeout(destroyChildHandle.current)
            }
        }
    }, [isOpen, destroyChildHandle, type, data]);


    useEffect(() => {
        const handleEsc = (ev) => {
            if (ev.keyCode === 27) {
                ev.preventDefault();
                onDestroy();
            }
        }

        window.addEventListener('keydown', handleEsc);
        return () => { window.removeEventListener('keydown', handleEsc) };
    }, [onDestroy, childElement]);


    let wrappedChildElement = null;
    if (childElement) {
        wrappedChildElement = React.cloneElement(childElement, { onDestroy, actions: globalActions, modalState: globalState });
    }

    return ReactDOM.createPortal(
        <CSSTransition in={isOpen} timeout={animationTimeout} classNames={'fade-in-transition'} unmountOnExit>
            <div ref={childRef}>
                {wrappedChildElement}
            </div>
        </CSSTransition>,
        document.getElementById('modal-root')
    );
};

export default ModalWrapper;