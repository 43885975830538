import React from 'react';
import './ProgressComponent.scss';

function ProgressComponent(props) {
    const { current, max } = props;

    let elements = [];

    for (let index = 0; index < max; index++) {
        let classNames = 'dot';

        if (index === current) classNames += ' dot-current';

        const elem = <div key={index} className={classNames} />

        elements.push(elem);
    }

    return <div className={'progress-wrapper'}>
        {elements}
    </div>
}

export default ProgressComponent;