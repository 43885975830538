import {
    AUTH_SERVICE, UNAUTHORIZED, RESET_PASS_SUCCESS_VIEW,
    AUTH_PATH, PUBLIC_URL,
    LS_KEY_PROFILE, LS_KEY_PROFILE_OLD, PLUS_SUBSCRIPTION
} from 'constants/constants';
import { decodeJWT } from 'utils/HttpUtils';

import { showSessionExpired } from './modals';


export const fetchNomenclature = async (store) => {
    try {
        const nomenclatureResponse = await fetch(`${AUTH_SERVICE}/users/nomenclature`);
        const nomenclatureResult = await nomenclatureResponse.json();

        store.setState({ auth: { ...store.state.auth, isFetched: true, hasError: false, nomenclature: { ...nomenclatureResult } } });
    } catch (er) {
        store.setState({ auth: { ...store.state.auth, hasError: true } })
    }
}

export const fetchLogin = async (store, email, pass) => {
    store.setState({ auth: { ...store.state.auth, isFetching: true, loginActiveErrors: [] } });
    try {
        const loginResult = await fetch(`${AUTH_SERVICE}/nimbus/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: pass
            })
        });

        if (loginResult.ok) {
            const tokenResult = await loginResult.json();

            updateProfileState(store, tokenResult);
        } else {
            let errorsList = [];
            if (loginResult.status === 401) {
                errorsList.push(UNAUTHORIZED);
            } else {
                errorsList = await loginResult.json();
            }
            store.setState({ auth: { ...store.state.auth, isFetching: false, isLoggedIn: false, loginActiveErrors: errorsList } });
        }
    } catch (er) {
        console.log(er)
    }
}

export const refreshIfNecessary = (store) => {
    const { expiration, refreshToken, profile } = store.state.auth;
    let expiresAfter = getExpiration(profile);

    if (refreshToken && expiration && expiresAfter < 0) {
        requestRefreshAccessToken(store);
    }
};

export const requestRefreshAccessToken = async (store) => {
    store.setState({ auth: { ...store.state.auth, isRefreshingToken: true, isRefreshTokenInvalid: false } });
    try {
        const refreshToken = loadProfile()?.access?.refreshToken;
        const response = await fetch(`${AUTH_SERVICE}/nimbus/refresh-access-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refreshToken: refreshToken ?? store.state.auth.refreshToken
            })
        })
        if (response.ok) {
            const tokenResult = await response.json()
            updateProfileState(store, tokenResult);
            console.log("dismiss session expired message")
        } else {
            if (store.state.location.pathname !== PUBLIC_URL + AUTH_PATH) {
                showSessionExpired(store);
            }

            throw new Error('Token not refreshed');
        }
    } catch (err) {
        store.setState({ auth: { ...store.state.auth, isRefreshingToken: false, isRefreshTokenInvalid: true } });
    }
}

export const updateLoggedIn = async (store) => {
    try {
        const profile = loadProfile();

        if (!profile) {
            console.log("redirect to login page");
        } else if (isAboutToExpire(profile) && !!profile.access.refreshToken) {
            console.log("trying to refresh");
            await requestRefreshAccessToken(store);
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const fetchRegistration = async (store, registrationForm, successCallback = null, failCallback = null) => {
    store.setState({ auth: { ...store.state.auth, isFetching: true } });

    const response = await fetch(`${AUTH_SERVICE}/nimbus/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(registrationForm)
    });

    if (response.ok) {
        successCallback && successCallback();
        // setActiveView(store, REGISTER_SUCCESS_VIEW);
    } else {
        const content = await response.json();
        store.setState({ auth: { ...store.state.auth, isLoggedIn: false, isFetching: false, loginActiveErrors: content.errors.Email } });
        failCallback && failCallback();
    }
}

export const fetchPasswordReset = async (store, email, onSuccess) => {
    const response = await fetch(`${AUTH_SERVICE}/nimbus/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    if (response.ok && onSuccess) {
        // setActiveView(store, RESET_PASS_SUCCESS_VIEW);
        onSuccess(RESET_PASS_SUCCESS_VIEW)
    } else {
        const contentText = await response.text()
        const content = JSON.parse(contentText.toLowerCase())
        const errors = []

        for (const key in content.errors) {
            content.errors[key].forEach(err => errors.push(err))
        }
        store.setState({ auth: { ...store.state.auth, isLoggedIn: false, loginActiveErrors: errors } });
    }
}

export const setActiveView = async (store, view) => {
    store.setState({ auth: { ...store.state.auth, activeView: view, isFetching: false } });
}

export const logout = (store) => {
    window.localStore.removeItem(LS_KEY_PROFILE);
    window.localStore.removeItem(LS_KEY_PROFILE_OLD);

    cleanLoginState(store);
}


const parseJwtToken = (tokenResult) => {
    const profile = decodeJWT(tokenResult.mainToken, { "refreshToken": tokenResult.refreshToken, "oldToken": tokenResult.oldToken });
    window.localStore.setItem(LS_KEY_PROFILE, JSON.stringify(profile));

    // old token for compatibility
    window.localStore.setItem(LS_KEY_PROFILE_OLD, JSON.stringify({
        ...profile,
        uuid: profile.extSub,
        access: {
            ...profile.access,
            token: tokenResult.oldToken
        },
    }));

    return profile;
}

export const getExpiration = (profile) => {
    if (!profile) return -1;

    return Math.floor((profile.access.expiration - Date.now()) * 0.75);
}

const isAboutToExpire = (profile) => {
    return getExpiration(profile) <= 0;
}

const containsAll = (superset, subset) => subset.every((value) => (superset.includes(value)));

const isInkspacePlusUser = (profile) => {
    return !!profile && containsAll(profile.access.rights, PLUS_SUBSCRIPTION);
}

export const loadProfile = () => {
    const stringVal = window.localStore.getItem(LS_KEY_PROFILE);
    if (stringVal) {
        return JSON.parse(stringVal);
    }

    return null;
}

const updateProfileState = (store, tokenResult) => {
    const profile = parseJwtToken(tokenResult);
    const expiresAfter = getExpiration(profile);

    store.setState({
        auth: {
            ...store.state.auth,
            mainToken: tokenResult.mainToken,
            oldToken: tokenResult.oldToken,
            refreshToken: tokenResult.refreshToken,
            isInkspacePlusUser: isInkspacePlusUser(profile),
            profile: profile,
            isLoggedIn: true,
            loginActiveErrors: [],
            expiration: profile ? profile.access.expiration : -1,
            expiresAfter: expiresAfter,
            isRefreshingToken: false,
            isRefreshTokenInvalid: false,
            isFetching: false
        }
    });

    return profile;
}

const cleanLoginState = (store) => {
    store.setState({
        auth: {
            ...store.state.auth,
            isFetching: false,
            isLoggedIn: false,
            profile: null,
            mainToken: null,
            oldToken: null,
            refreshToken: null,
            isRefreshTokenInvalid: true,
            isRefreshingToken: false
        },
        migration: {
            isFetched: false,
            isStarted: false,
            isReady: false,
            migratedDocuments: 0,
            totalDocuments: 0
        }
    });
}
