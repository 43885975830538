import { DOWNLOAD_MY_DATA_URL } from 'constants/constants';

let downloadAbortController = null;

export const abortDownloadStatus = () => {
    if (downloadAbortController) downloadAbortController.abort();
}

export const fetchDownloadStatus = async (store) => {
    abortDownloadStatus();

    downloadAbortController = new AbortController();

    try {
        const resp = await fetch(`${DOWNLOAD_MY_DATA_URL}/progress`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            },
            signal: downloadAbortController.signal
        })

        if (resp.ok) {
            const content = await resp.json();

            store.setState({ dataDownload: { ...store.state.dataDownload, ...content } });
        }
    } catch (err) {

    }
}