import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import DropDown from 'common/components/menu/dropDown/DropDown';
import DropDownContent from 'common/components/menu/dropDown/DropDownContent';
import GenericList from 'common/components/menu/genericList/GenericList';
import GenericListItem from 'common/components/menu/genericList/GenericListItem';
import useDropdown from 'common/components/menu/dropDown/use-dropdown';

import './SorterDropDown.scss';

function renderFields(props) {
    return ['name', 'date'].map((field, i) => (
        <GenericListItem
            key={i}
            onClick={() => props.onFieldClick(field)}
            active={props.activeField === field} >
            {props.formatMessage({ id: `sorter:${field}` })}
            <div className='spacer' />
        </GenericListItem>
    ))
}

function renderOrders(props) {
    return ['ascending', 'descending'].map((order, i) => (
        <GenericListItem
            key={i}
            onClick={() => props.onOrderClick(order)}
            active={props.activeOrder === order} >
            {props.formatMessage({ id: `sorter:${order}` })}
            <div className='spacer' />
        </GenericListItem>
    ))
}

function SorterDropDown(props) {
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    const { formatMessage } = useIntl();
    const [isDropOpen, toggleDrop] = useDropdown(triggerRef, contentRef);
    const { showOverlay, hideOverlay } = props;

    useEffect(() => {
        if (isDropOpen) {
            showOverlay();
        } else {
            hideOverlay();
        }
    }, [isDropOpen, showOverlay, hideOverlay]);


    const childProps = {
        formatMessage,
        activeOrder: props.activeOrder,
        activeField: props.activeField,
        onFieldClick: (ev) => { props.onFieldClick(ev); toggleDrop(); },
        onOrderClick: (ev) => { props.onOrderClick(ev); toggleDrop(); }
    };

    return <>
        <DropDown>
            <div ref={triggerRef}
                className={'dropdown-trigger'}
                onClick={toggleDrop}>
                <div className='sorter-head'>
                    {
                        props.activeField &&
                        (<span>
                            {formatMessage({ id: 'sorter:sortby' })}
                            {' '}
                            {formatMessage({ id: `sorter:${props.activeField}` })}
                        </span>)
                    }
                    <span className='arrow-down' />
                </div>
            </div>
            <div ref={contentRef}>
                <DropDownContent
                    transitionsEffects={{ transitionName: 'slide-in-top' }}
                    show={isDropOpen}
                    className='dropdown-content'
                    position={'bottom-overlap'}
                >
                    <div>
                        <div className='sorter-head' >
                            {formatMessage({ id: 'sorter:sortby' })}
                        </div>
                        <GenericList className='sorter-generic-list'>
                            {renderFields(childProps)}
                            <GenericListItem />
                            {renderOrders(childProps)}
                            <GenericListItem />
                        </GenericList>
                    </div>
                </DropDownContent>
            </div>
        </DropDown>
    </>
}

export default SorterDropDown;