import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl'
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import { HOME_PATH_WITH_APP_NAME } from 'constants/constants';
import { markWelcomeSkipped } from 'common/welcomeScreenHelper';
import ProgressComponent from './ProgressComponent'

import './WelcomeComponent.scss';

const stepsMessages = [
    {
        header: 'welcome:screen1:header',
        text: 'welcome:screen1:text',
        button: 'welcome:button:next'
    },
    {
        header: 'welcome:screen2:header',
        text: 'welcome:screen2:text',
        button: 'welcome:button:next'
    },
    {
        header: 'welcome:screen3:header',
        text: 'welcome:screen3:text',
        button: 'welcome:button:done'
    }
];


function WelcomeComponent(props) {
    const { formatMessage } = useIntl();
    const [step, setStep] = useState(0);
    const history = useHistory();

    const onClick = () => {
        setStep(step => step + 1);
    }

    useEffect(() => {
        if (step === 3) {
            markWelcomeSkipped();
            history.push(HOME_PATH_WITH_APP_NAME);
        }
    }, [history, step]);

    const messages = stepsMessages[step % stepsMessages.length];

    return <div className={'welcome-main'}>
        <SwitchTransition mode={'out-in'}>
            <CSSTransition key={step} classNames='slide-left' timeout={300}>
                <div className='welcome-wrapper'>
                    <div className={'image-portion'}>
                        <div className={`oval-image oval-image-${step}`}>
                        </div>
                    </div>
                    <div className={'text-wrapper'}>
                        <div className={'text'}>
                            <h1 className={'heading'}>
                                {formatMessage({ id: messages.header })}
                            </h1>
                            <div className={'main-text'}>
                                {formatMessage({ id: messages.text })}
                            </div>
                            <button className={'blue-button'} onClick={onClick}>
                                {formatMessage({ id: messages.button })}
                            </button>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </SwitchTransition>
        <div className={'progress'}>
            <ProgressComponent current={step} max={3} />
        </div>
    </div>
}

export default WelcomeComponent;