import React from 'react';
import { useIntl } from 'react-intl';
import TextInputModal from 'common/components/modals/TextInputModal';
import { MIN_INPUT_FIELD_LENGTH, MAX_INPUT_FIELD_LENGTH } from 'constants/constants';

function CreateUpdateTag(props) {
    const { modalState, actions } = props;
    const { formatMessage } = useIntl();

    const selectedTag = modalState.selectedTag;
    const isUpdate = !!selectedTag.id;
    const titleTextKey = isUpdate ? 'global:rename' : 'modalbox:createTag:title';
    const buttonLabelKey = isUpdate ? 'global:rename' : 'modalbox:createTag:buttons:title';

    const onSubmitAction = (tagName) => {
        if (isUpdate) {
            actions.home.renameTag(selectedTag.id, tagName);
        } else {
            actions.home.createTag(tagName);
        }
    }

    const createTagMessage = formatMessage({ id: buttonLabelKey });
    const buttons = [{
        // onClick: onSubmitAction,
        id: 'create-update-tag',
        title: createTagMessage,
        isSubmit: true
    }];

    const minValidationLen = MIN_INPUT_FIELD_LENGTH;
    const maxValidationLen = MAX_INPUT_FIELD_LENGTH;

    return <TextInputModal
        minLength={minValidationLen}
        maxLength={maxValidationLen}
        title={formatMessage({ id: titleTextKey })}
        buttons={buttons}
        initialValue={selectedTag.label}
        onSubmit={onSubmitAction}
        onDestroy={props.onDestroy}
        isSpinnerVisible={props.modalState.isSpinnerVisible}
        errorMessage={formatMessage({ id: 'inputField:notInRange' }, {
            minLength: minValidationLen,
            maxLength: maxValidationLen
        })}
    />
}

export default CreateUpdateTag;