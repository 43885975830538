import React from 'react';



import './MenuBar.scss';

function MenuBar(props) {
    return <div className={'menu-bar-wrapper'}>
        {props.brand}
        <div className={'ink-editor-sidebar'}>
            {props.elements}
        </div>
    </div>
}

export default MenuBar;