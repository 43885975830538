import React from 'react'

const style = {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    borderLeft: '.1rem solid #d9d9d9',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2.5rem',
    wordWrap: 'break-word',
    whiteSpace: 'initial'
}

function SelectedDirectory(props) {
    return <div style={style}>{props.title}</div>
}

export default SelectedDirectory;