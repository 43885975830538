import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import DropDown from 'common/components/menu/dropDown/DropDown';
import DropDownContent from 'common/components/menu/dropDown/DropDownContent';
import GenericList from 'common/components/menu/genericList/GenericList';
import GenericListItem from 'common/components/menu/genericList/GenericListItem';
import useDropdown from 'common/components/menu/dropDown/use-dropdown';
import { bodyHeight } from 'common/browserHelpers';


import './LanguageDropDown.scss';

function LanguageDropDown(props) {
    const triggerRef = useRef(null);
    const contentRef = useRef(null);
    const { formatMessage } = useIntl();
    const [isDropOpen, toggleDrop] = useDropdown(triggerRef, contentRef);
    const { showOverlay, hideOverlay, selectedLanguage, languages, activeLabel, showLanguageHead } = props;


    useEffect(() => {
        if (isDropOpen) {
            showOverlay && showOverlay();
        } else {
            hideOverlay && hideOverlay();
        }

    }, [isDropOpen, showOverlay, hideOverlay]);

    const onLanguageChange = (key) => {
        props.changeLanguage(key);
        toggleDrop();
    }

    return <>
        <DropDown>
            <div ref={triggerRef}
                className={'dropdown-trigger'}
                onClick={toggleDrop}>
                <div className='language-head'>
                    {activeLabel}
                    <span className='arrow-up' />
                </div>
            </div>
            <div ref={contentRef}>
                <DropDownContent
                    transitionsEffects={{ transitionName: 'slide-in-bottom' }}
                    show={isDropOpen}
                    className='dropdown-content'
                    position={'top-left'}>
                    <Scrollbars autoHeight autoHide autoHeightMax={bodyHeight()}>
                        <GenericList className='language-generic-list'>
                            {
                                Object.keys(languages).map((key, i) => (
                                    <GenericListItem
                                        className='language-generic-list-item'
                                        key={i}
                                        hideDropDown
                                        onClick={() => onLanguageChange(key)}
                                        active={selectedLanguage === languages[key].code}>
                                        {formatMessage({ id: languages[key].value })}
                                        <div className='spacer' />
                                    </GenericListItem>
                                ))
                            }
                            {
                                !!showLanguageHead ?? <div className='language-head' onClick={toggleDrop}>
                                    {activeLabel}
                                </div>
                            }
                        </GenericList>
                    </Scrollbars>
                </DropDownContent>
            </div>
        </DropDown>
    </>
}

export default LanguageDropDown;