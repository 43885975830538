import appCtxMenuItems from './AppContextMenu';
import webCtxMenuItems from './WebContextMenu';
import groupCtxMenuItems from './GroupContextMenu';
import bambooCtxMenuItems from './BambooContextMenu';
import mateGroupCtxMenuItems from './MateGroupContextMenu';
import controlPanelMenuItems from './ControlPanelMenu';

import {
    WEB_STREAM, BAMBOO_STREAM, MATE_STREAM, MONT_BLANC_STREAM,
    // BAMBOO_COLLABORATION_MODE, MAX_EXPORT_NOTES, STREAMS_WITH_GROUPS
} from 'constants/constants';

import { getDocsCommonTags, mergeTagMaps } from './TagsHelper';

const TAGS_ELEMENT = 'ctrl-button-tags';
const GROUPS_ELEMENT = 'ctrl-button-groups';
const TAGS_PLAIN = 'tags';
const GROUPS_PLAIN = 'groups';
const EXPORTS_ELEMENT = 'ctrl-button-export';

function isDocumentWithGroupOption(item) {
    const { streamType } = item;

    if (streamType === MATE_STREAM || streamType === MONT_BLANC_STREAM) {
        return true;
    }

    return false;
}

export function getSelectedDocTypes(docs, selectedItems) {
    const selectedDocsInfo = {};

    for (const doc of docs) {
        const selectedItemFilter = selectedItems.filter(selected => selected.id === doc.id)
        if (selectedItemFilter.length > 0) {
            selectedDocsInfo[doc.applicationName] = true;
        }
    }

    return selectedDocsInfo;
}

export const getSelectedDocsInGroups = (selectedDocs, selectedGroups, allGroups) => {
    if (!selectedDocs || !allGroups || !selectedDocs) {
        return {}
    }

    const allSelectedDocs = new Set(selectedDocs)
    const groupsMap = {}

    selectedGroups.forEach(grId => {
        const index = allGroups.findIndex(el => el.id === grId)
        if (index !== -1) {
            allGroups[index].documents.forEach(docId => allSelectedDocs.add(docId))
        }
    })

    for (const group of allGroups) {
        const groupMapDetails = {
            title: group.title || group.label,
            isFullGroupRef: undefined
        }

        let containsSomeDocs = false
        let containsAllDocs = false
        let checkedDocs = 0
        let matchedDocs = 0
        for (const doc of allSelectedDocs) {
            checkedDocs++
            const currentDocIsFromGroup = group.documentIds.includes(doc)
            if (currentDocIsFromGroup) {
                matchedDocs++
                containsSomeDocs = true
            }

            if (!currentDocIsFromGroup && containsSomeDocs) {
                break
            }
        }

        if (checkedDocs > 0 && matchedDocs === checkedDocs) {
            containsAllDocs = true
        }

        if (containsSomeDocs) {
            groupMapDetails.isFullGroupRef = false
        }
        if (containsAllDocs) {
            groupMapDetails.isFullGroupRef = true
        }

        groupsMap[group.id] = groupMapDetails
    }

    return groupsMap
}

export function getGroupProps(selectedGroupIds, groups) {
    const groupProps = {
        hasEmptyGroup: false,
        hasGroupWithElements: false
    };

    for (const group of groups) {
        if (selectedGroupIds.includes(group.id)) {
            const groupElementCount = group.documents.length;
            if (groupElementCount === 0) {
                groupProps.hasEmptyGroup = true;
            } else if (groupElementCount !== 0) {
                groupProps.hasGroupWithElements = true;
            }

            if (groupProps.hasGroupWithElements && groupProps.hasEmptyGroup) {
                break;
            }
        }
    }

    return groupProps;
}

function getCtxMenuMainItems(props) {
    const { selectedItems, selectedGroups, isSingleDoc } = props.contextMenu;
    if (isSingleDoc) {
        const item = selectedItems[0] || selectedGroups[0];
        const { applicationName, isMateGroup } = item;
        const menuProps = { ...props, item };

        if (applicationName === WEB_STREAM) {
            return webCtxMenuItems(menuProps);
        }

        if (applicationName === BAMBOO_STREAM) {
            return isMateGroup
                ? groupCtxMenuItems(menuProps)
                : bambooCtxMenuItems(menuProps)
        }

        //Is Mate Group
        if (item.isMateGroup) {
            const indexOfTargetGroup = props.fullGroupList.findIndex(gr => gr.id === item.id);
            const hasGroupElements = indexOfTargetGroup !== -1 && props.fullGroupList[indexOfTargetGroup].documentIds.length > 0;
            const contextMenu = mateGroupCtxMenuItems(menuProps);

            if (!hasGroupElements) {
                const tagsOption = contextMenu.find(item => item.id === TAGS_ELEMENT)
                tagsOption.isInactive = true;

                const exportOption = contextMenu.find(item => item.id === EXPORTS_ELEMENT)
                exportOption.isInactive = true;
            }

            return contextMenu;
        }
        else if (applicationName || isDocumentWithGroupOption(menuProps)) {
            return appCtxMenuItems(menuProps);
        }
    } else {
        return controlPanelMenuItems(props);
    }
}

function parseTagsSubMenu(menuItem, tags, selectedItems, selectedGroups, selectedGroupIds, fullGroupList, actions) {
    const documentTags = selectedItems && selectedItems.length > 0 ? getDocsCommonTags(selectedItems, tags) : null;

    const selectedDocTagsInGroupsSet = fullGroupList.reduce((accumulated, item) => {
        if (selectedGroupIds.has(item.id)) {
            item.documents.forEach(doc => accumulated.add(doc));
        }
        return accumulated;
    }, new Set());

    const selectedDocTagsInGroups = [...selectedDocTagsInGroupsSet];
    const groupTags = selectedDocTagsInGroups.length > 0 ? getDocsCommonTags(selectedDocTagsInGroups, tags) : null;
    const allTagsMaps = [];
    if (documentTags) allTagsMaps.push(documentTags);
    if (groupTags) allTagsMaps.push(groupTags);
    const allSelectedDocuments = [...new Set([...selectedItems, ...selectedDocTagsInGroups])];

    const commonTags = mergeTagMaps(allTagsMaps);

    menuItem.buttons = tags.map((tagItem, index) => {
        const tagIsUsed = commonTags[tagItem.id] ? commonTags[tagItem.id].full : undefined;

        let classNames = 'ctrl-button-tag';

        if (tagIsUsed === true) {
            classNames += ' exists-everywhere';
        } else if (tagIsUsed === false) {
            classNames += ' exists-somewhere';
        }

        let callbackAction = !tagIsUsed ? actions.doAddDocsToTag : actions.doRemoveDocsFromTag;

        const subItem = {
            id: `ctx-menu-tag-${index}`,
            className: classNames,
            title: tagItem.label,
            callback: menuItem.isMateGroup ?
                () => { } :
                () => callbackAction(tagItem.id, allSelectedDocuments)
        }

        return subItem;
    })
}

function parseGroupsSubMenu(menuItem, selectedItems, selectedGroups, fullGroupList, selectedDocTypes, actions) {
    const activeStreams = [];

    for (const docTypeKey in selectedDocTypes) {
        const selected = selectedDocTypes[docTypeKey]
        if (selected) {
            activeStreams.push(docTypeKey)
        }
    }

    const filteredGroups = fullGroupList;//.filter(gr => activeStreams.includes(gr.stream))
    const groupsMap = getSelectedDocsInGroups(selectedItems.map(doc => doc.id), selectedGroups, filteredGroups)

    menuItem.buttons = filteredGroups.reduce((res, item) => {
        let classNames = 'ctrl-button-groups'
        let groupCallback = () => { actions.doAddDocsToGroup(item.id, selectedItems, item.applicationName) };
        const groupContainsValue = groupsMap[item.id]
        if (groupContainsValue) {
            const isFullReference = groupContainsValue.isFullGroupRef
            if (isFullReference) {
                classNames += ' exists-everywhere'
                groupCallback = () => { actions.doRemoveDocsFromGroup(item.id, selectedItems, item.applicationName) };
            } else if (isFullReference === false) {
                classNames += ' exists-somewhere'
            }
        }

        const newEntity = {
            id: 'ctrl-button-' + item.id,
            title: item.name || item.title || item.label,
            className: classNames,
            callback: groupCallback
        }

        if (activeStreams.includes(item.applicationName)) {
            res.push(newEntity)
        }

        return res
    }, [])
}

function parseSubMenus(menuItems, documents, tags, fullGroupList, selectedItems, selectedGroups, selectedGroupIds, actions) {
    for (let item of menuItems) {
        if (item.id === TAGS_ELEMENT || item.id === TAGS_PLAIN) {
            parseTagsSubMenu(item, tags, selectedItems, selectedGroups, selectedGroupIds, fullGroupList, actions);
        } else if (item.id === GROUPS_ELEMENT || item.id === GROUPS_PLAIN) {
            const selectedTypes = getSelectedDocTypes(documents, selectedItems);
            parseGroupsSubMenu(item, selectedItems, selectedGroups, fullGroupList, selectedTypes, actions);
        }
    }
}

export function getContextMenu(props) {
    const { tags, fullGroupList, documents, actions } = props;
    const menuItems = getCtxMenuMainItems(props);

    parseSubMenus(menuItems, documents, tags, fullGroupList, props.contextMenu.selectedItems, props.contextMenu.selectedGroups, props.contextMenu.selectedGroupIds, actions);

    return menuItems;
}