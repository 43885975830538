import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { MONT_BLANC_LEGACY_BASE_URL } from 'constants/constants';
import { tokensQueryParameterKey, getAuthTokens } from '../../../actions/home';

import './EmptyStream.scss';

const openMontBlanc = () => {
  return `${MONT_BLANC_LEGACY_BASE_URL}/?stream=montblanc&${tokensQueryParameterKey}=${getAuthTokens()}`;
}

const learnMoreUrl = 'http://www.wacom.com/inkspace';

const webStream = (<div className='empty'>
  <span className='stream' />
  <p className='stream heading'>
    <FormattedMessage id='documents:empty:stream:webstream' />
  </p>
  <p className='stream body'>
    <FormattedMessage id='documents:empty:stream:explanation1:webstream' />
    <br />
  </p>
</div>)

const collaborationStream = (<div className='empty'>
  <span className='stream collaboration' />
  <p className='stream heading'>
    <FormattedMessage id='streams:collaboration' />
  </p>
  <p className='stream body'>
    <FormattedMessage id='documents:empty:stream:explanation1:webstream' />
    <br />
  </p>

</div>)

const appStream = (<div className='empty'>
  <span className='stream' />
  <p className='stream heading'>
    <FormattedMessage id='documents:empty:stream:appstream' />
  </p>
  <p className='stream body'>
    <FormattedMessage id='documents:empty:stream:explanation1:appstream' />
    <br />
  </p>
  <p className='stream learn'>
    <a href={learnMoreUrl} target={'_blank'} rel="noopener noreferrer">
      <FormattedMessage id='documents:empty:stream:learnmore' />
    </a>
  </p>
</div>)

const montblanc = (<div className='empty montblanc'>
  <span className='stream montblanc' />
  <p className='stream heading'>
    <FormattedMessage id='documents:empty:stream:montblanc' />
  </p>
  <p className='stream body'>
    <FormattedMessage id='documents:empty:stream:explanation1:montblanc' />
    <br />
    {/* !!!!!MESSAGE TO SHOW BEFORE MONT BLANC MIGRATION */}
    <span className={'montblanc_update_message'}>
      Dear Montblanc Hub user,
      <br />
      <br />
      Wacom is currently updating Inkspace's cloud infrastructure.
      <br />
      <br />
      You will be able to use the new cloud infrastructure, through Montblanc Hub, after updating your application to the upcoming version 4.1.1
      <br />
      <br />
      In the meantime, you can access your content
      <button className={'montblanc_gold_color'}
        onClick={() => { window.location.href = openMontBlanc() }}
        target='_blank' rel="noopener noreferrer">here
      </button>.
    </span>
  </p>
  {/* <p className='stream learn'>
  <a href={learnMoreUrl} target={'_blank'}>
    <FormattedMessage id='documents:empty:stream:learnmore' />
  </a>
</p> */}
</div>)

const bambooPaperStream = (<div className='empty'>
  <span className='stream bamboo' />
  <p className='stream heading'>
    <FormattedMessage id='documents:empty:stream:bamboopaperstream' />
  </p>
  <p className='stream body' style={{ width: '38rem', textAlign: 'center', lineHeight: '18px' }}>
    <FormattedMessage id='documents:empty:stream:explanation1:bamboopaperstream' />
    <br />
  </p>
  <p className='stream learn' >
    <a href={learnMoreUrl} target={'_blank'} rel="noopener noreferrer">
      <FormattedMessage id='documents:empty:stream:learnmore' />
    </a>
  </p>
</div>)

const group = (<div className='empty'>
  <p className='stream heading group'>
    <FormattedMessage id='documents:empty:group' />
  </p>
</div>)

const wacomNotes = (<div className='empty'>
  <span className='stream wacomnotes' />
  <p className='stream heading'>
    <FormattedMessage id='streams:wacomnotes' />
  </p>
  <p className='stream body'>
    <FormattedMessage id='documents:empty:stream:explanation1:wacom_notes' />
    <br />
  </p>
</div>)

export default class EmptyStream extends Component {
  static displayName = 'EmptyStream'

  render() {
    return {
      'web': webStream,
      'mate': appStream,
      'montblanc': montblanc,
      'bamboo_paper': bambooPaperStream,
      'group': group,
      'wacom_notes': wacomNotes,
      'collaboration': collaborationStream
    }[this.props.stream] || null
  }
}
