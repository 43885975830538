import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import './SearchBox.scss'

function SearchBox(props) {
    const { formatMessage } = useIntl();
    const [searchTerm, setSearchTerm] = useState(props.term ?? '');
    const placeholder = formatMessage({ id: 'filter:search' });

    const onKeyDown = (ev) => {
        if (ev.keyCode === 13) {
            props.onSubmit(searchTerm);
        }
    }

    // Resets the internal state if the search term is removed after the
    // component is mounted
    useEffect(() => {
        if (!props.term) {
            setSearchTerm('');
        }
    }, [props.term]);

    return <div className='search-box-container'>
        <div className='search-input-field'>
            <span className={'search-icon'} />
            <input
                value={searchTerm}
                className={'search-field'}
                type={'text'}
                maxLength={250}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onChange={ev => setSearchTerm(ev.target.value)}
            />
        </div>
    </div>
}

export default SearchBox;