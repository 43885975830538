import React from 'react';
import ContextMenu from 'common/components/menu/contextMenu/ContextMenu';

import './DocumentContextMenu.scss';

function DocumentContextMenu(props) {
    const { coordinates, elements, actions } = props;

    return <ContextMenu coordinates={coordinates} elements={elements} actions={actions} />
}

export default DocumentContextMenu;