import React, { useEffect, useState, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { CSSTransition } from 'react-transition-group';
import { CSS_TRANSITION_ENTER_ANIMATION_TIME } from 'constants/constants';

import './ButtonWithSpinner.scss';

const animationTimeout = CSS_TRANSITION_ENTER_ANIMATION_TIME;

function ButtonWithSpinner({ children, isSpinnerVisible, ...props }) {
    /* Capture the dimensions of the button before the loading happens
    so it doesn’t change size when showing the loader */
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const ref = useRef(null);

    // Save the dimensions here
    useEffect(() => {
        const boundingBox = ref?.current?.getBoundingClientRect();

        // TODO: see why width and height are doubled
        if (!width && boundingBox?.width) {
            setWidth(boundingBox.width / 2);
        }
        if (!height && boundingBox?.height) {
            setHeight(boundingBox.height / 2);
        }
    },
        // children are a dep so dimensions are updated if initial contents change
        [children, width, height]
    );

    return <button
        ref={ref}
        className={'button'}
        style={
            width && height
                ? {
                    width: `${width}px`,
                    height: `${height}px`,
                }
                : {}
        }
        {...props}
    >
        {!isSpinnerVisible && children}

        <CSSTransition in={isSpinnerVisible}
            timeout={animationTimeout}
            classNames={'fade-in-transition'}
            unmountOnExit
        >
            <Spinner animation="border" />
        </CSSTransition>
    </button>
}

export default ButtonWithSpinner;