import React, { useState, useCallback } from 'react';
import { Color } from 'digital-ink';
import CanvasContext from './CanvasContext';

import { INK_EDITOR_CONFIG } from 'constants/constants';

const penColor = INK_EDITOR_CONFIG.defaultColor.pen;
const penColorDigitalInk = Color.fromHSLA(
    penColor.h,
    penColor.s,
    penColor.l,
    penColor.a);

const highlighterColor = INK_EDITOR_CONFIG.defaultColor.highlighter;
const highlighterColorDigitalInk = Color.fromHSLA(
    highlighterColor.h,
    highlighterColor.s,
    highlighterColor.l,
    highlighterColor.a);


const CanvasContextProvider = ({ children }) => {
    const [toolId, setToolIdInternal] = useState(INK_EDITOR_CONFIG.controls.pen);
    const [prevToolId, setPrevToolId] = useState(penColorDigitalInk);
    const [penColor, setPenColor] = useState(penColorDigitalInk);
    const [highlighterColor, setHighlighterColor] = useState(highlighterColorDigitalInk);
    const [scale, setScale] = useState(1);
    const [baseScale, setBaseScale] = useState(null);
    const [transform, setTransform] = useState(null);

    const setColor = useCallback((color) => {
        if (toolId === INK_EDITOR_CONFIG.controls.pen
            || (toolId === INK_EDITOR_CONFIG.controls.eyedropper && prevToolId === INK_EDITOR_CONFIG.controls.pen)) {
            setPenColor(color);
        } else if (toolId === INK_EDITOR_CONFIG.controls.highlighter
            || (toolId === INK_EDITOR_CONFIG.controls.eyedropper && prevToolId === INK_EDITOR_CONFIG.controls.highlighter)) {
            setHighlighterColor(color);
        }
    }, [setPenColor, setHighlighterColor, prevToolId, toolId]);

    const setToolId = useCallback((id) => {
        setPrevToolId(toolId);
        setToolIdInternal(id);
    }, [setPrevToolId, setToolIdInternal, toolId]);

    const isPen = toolId === INK_EDITOR_CONFIG.controls.pen;
    const color = isPen ? penColor : highlighterColor;


    const context = {
        toolId, setToolId, prevToolId,
        color, setColor,
        scale, setScale,
        baseScale, setBaseScale,
        transform, setTransform
    };

    return <CanvasContext.Provider value={context}>
        {children}
    </CanvasContext.Provider>
}
export default CanvasContextProvider;