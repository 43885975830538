import React from 'react';
import { useIntl } from 'react-intl';
import AllTypesIcon from './typeIcons/AllTypesIcon';
import EventTypeIcon from './typeIcons/EventTypeIcon';
import PlaceTypeIcon from './typeIcons/PlaceTypeIcon';
import OrgTypeIcon from './typeIcons/OrgTypeIcon';
import PersonTypeIcon from './typeIcons/PersonTypeIcon';
import TermTypeIcon from './typeIcons/TermTypeIcon';
import ListPreloader from './ListPreloader';
import useDimensions from 'react-use-dimensions';
import { CONVERT_TO_TEXT_LANGUAGES } from 'constants/constants'
import LanguageDropDown from 'common/components/menu/languageDropDown/LanguageDropDown';


import SemanticEntityList from './entities/SemanticEntitiesList';

import './SideBar.scss';

function SideBar(props) {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { semanticFilter, handleFilterChange, isFetching, data, currentLocale } = props;
    const [ref, allDims] = useDimensions();
    const { width, height } = allDims;
    const currentLanguageObject = Object.values(CONVERT_TO_TEXT_LANGUAGES).filter(x => x.locale === currentLocale)[0];
    const dropDownLabel = formatMessage({ id: 'global:convertTo' },
        { language: formatMessage({ id: currentLanguageObject?.value ?? 'en' }) });

    return <div className={'semantic-sidebar'}>
        <div className={'semantic-type-control'}>
            <AllTypesIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
            <EventTypeIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
            <PersonTypeIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
            <TermTypeIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
            <PlaceTypeIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
            <OrgTypeIcon semanticFilter={semanticFilter} onClick={handleFilterChange} />
        </div>
        <div ref={ref} className={'sematic-entities-list-wrap'}>
            {isFetching && <ListPreloader height={height} width={width} />}
            {!isFetching && <SemanticEntityList data={data} height={height} width={width} />}
        </div>
        <div className={'language-dropdown'}>
            <LanguageDropDown
                languages={CONVERT_TO_TEXT_LANGUAGES}
                changeLanguage={code => props.onChangeSemanticLanguage(CONVERT_TO_TEXT_LANGUAGES[code].locale)}
                label={dropDownLabel}
                activeLabel={dropDownLabel}
                showLanguageHead={false}
                selectedLanguage={currentLanguageObject.code}
            />
        </div>
    </div>
}

export default SideBar;