import React, { useRef, useEffect, useState } from 'react';
import ContextMenuItem from './ContextMenuItem';
import { bodyHeight } from 'common/browserHelpers';
import { Scrollbars } from 'react-custom-scrollbars';

import './ContextMenu.scss';

function ContextMenu(props) {
    const { coordinates, className, mouseLeave, elements, emptyView, isChild, moveToEnd, customStyles } = props;
    const isVisible = elements && elements.length;
    const [isMenuPositioned, setIsMenuPositioned] = useState(false);
    const menuRef = useRef(null);

    const maxHeight = .95 * bodyHeight();
    const style = {
        overflow: 'none',
        opacity: 0
    };

    if (coordinates) {
        if (coordinates.y) style.top = coordinates.y + 'px';
        if (coordinates.x) style.left = coordinates.x + 'px';
    }

    useEffect(() => {
        const newStyle = {
            overflow: 'none',
            opacity: 0,
            ...customStyles
        };

        if (coordinates) {
            if (coordinates.y) newStyle.top = coordinates.y + 'px';
            if (coordinates.x) newStyle.left = coordinates.x + 'px';

            delete newStyle.overflow;
        }

        // skips rearrangement if alreadyCalculated
        if (isMenuPositioned) return;

        // Do not move context menu if there are no coordinates to pivot around
        if (!(!coordinates && !isChild && !moveToEnd)) {
            const menuBoundingBox = menuRef.current.getBoundingClientRect();
            if (moveToEnd) {
                let parentElementBBox = menuRef.current.parentElement.getBoundingClientRect();
                if (isChild) {
                    newStyle.left = parentElementBBox.x - parentElementBBox.width + 'px';
                } else {
                    newStyle.left = parentElementBBox.x + parentElementBBox.width + 'px';
                }
            } else if (!isChild) {
                if (menuBoundingBox.y + menuBoundingBox.height > window.innerHeight) {
                    newStyle.top = (menuRef.current.offsetTop - menuBoundingBox.height) + 'px';
                }
                if (menuBoundingBox.x + menuBoundingBox.width > window.innerWidth) {
                    newStyle.left = (menuRef.current.offsetLeft - menuBoundingBox.width) + 'px';
                }
            } else if (isChild) {
                // Gets the parent context menu
                // maxHeight = menuBoundingBox.bottom;
                const currentElement = menuRef.current;
                const currentElementBBox = currentElement.getBoundingClientRect();
                let parentElement = currentElement.parentElement;
                while (!parentElement.classList.contains('contextmenu-wrapper')) {
                    parentElement = parentElement.parentElement;
                }
                const menuWrapper = parentElement;
                const parentBBox = menuRef.current.parentElement.getBoundingClientRect();
                const menuWrapperBoundingBox = menuWrapper.getBoundingClientRect();

                if (menuBoundingBox.bottom > window.innerHeight) {
                    newStyle.top = (-menuBoundingBox.height + 2 * parentBBox.height) + 'px';
                }
                if (menuBoundingBox.right > window.innerWidth) {
                    // TODO: Remove magic number
                    newStyle.left = (-menuWrapperBoundingBox.width - currentElementBBox.width + 33) + 'px';
                }
            }
        }

        newStyle.position = isChild ? 'absolute' : 'fixed';
        newStyle.opacity = 1;
        newStyle.maxHeight = maxHeight + 'px';

        for (let prop in newStyle) {
            menuRef.current.style[prop] = newStyle[prop];
        }

        setIsMenuPositioned(true);
    }, [menuRef, coordinates, isChild, moveToEnd, maxHeight, customStyles, isMenuPositioned]);

    const menuItems = elements.filter(x => x).map((item, index) => {
        return <ContextMenuItem
            id={item.id}
            isInactive={item.isInactive}
            key={'context-menu-item-' + index}
            className={item.className ? item.className : null}
            onClick={(ev) => {
                if (item.callback) item.callback(ev)
                // this.onMouseLeave(ev)
            }}
            emptyView={item.emptyView}
            buttons={item.buttons ? item.buttons : null}
            title={item.title ?? item.name} />
    });

    const listContainerStyles = { maxHeight };
    let listItems = <ul style={listContainerStyles}>
        {menuItems}
    </ul>;

    if (isChild) {
        listItems = <Scrollbars autoHeight>
            <ul style={listContainerStyles}>
                {menuItems}
            </ul>
        </Scrollbars>;
    }

    const visibilityTest = isVisible
        ? listItems : emptyView;

    return <div id={'context-menu'}
        ref={menuRef}
        className={`contextmenu-wrapper ${className || ''}`}
        onMouseLeave={mouseLeave}
        style={style}
    >
        {visibilityTest}
    </ div>
}

export default ContextMenu;