import React, { useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import './EraserTool.scss';

const penToolId = INK_EDITOR_CONFIG.controls.eraser;

function EraserTool() {
    const { toolId, setToolId } = useContext(CanvasContext);
    const isActive = toolId === penToolId;

    return <div className={'eraser-tool ink-tool'}>
        <i
            className={isActive ? 'icon active' : 'icon icon-eraser'}
            // ref={node => this.$node = node}
            onClick={() => setToolId(penToolId)}
        />
    </div>
}

export default EraserTool;