import React from 'react'

import './FooterNavigationItem.scss'

function FooterNavigationItem(props) {
  const { title, to, index, callback } = props;


  const anchorProps = {
    href: to,
    target: '_blank',
    fel: 'noopener noreferrer'
  };

  if (callback) {
    anchorProps.onClick = callback;
    delete anchorProps.href;
  }

  return (
    <li className={'footer-nav-item-' + index}>
      <a {...anchorProps} >{title}</a>
    </li>
  )
}

export default FooterNavigationItem;