import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './DropDownContent.scss'

function DropDownContent(props) {
    const { children, show } = props;
    const duration = 100;

    let position = props.position || 'bottom';
    let cn = `dropdown-content ${position}`;

    return <CSSTransition
        key={cn}
        classNames='animated-node'
        in={show}
        timeout={duration}
        unmountOnExit
    >
        <div className={cn}>
            {children}
        </div>
    </CSSTransition>
}

export default DropDownContent;