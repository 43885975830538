const messages = {
  'documents:empty:noresult:noresult' : '無結果',
  'documents:empty:noresult:noresultfound' : '未找到符合您的標準的結果。',
  'documents:empty:stream:appstream' : 'INK SPACE APP STREAM',
  'documents:empty:stream:webstream' : 'INK SPACE WEB STREAM',
  'documents:empty:stream:bamboopaperstream': 'BAMBOO PAPER STREAM',
  'documents:empty:stream:inknotefiles': 'INK NOTE APP STREAM',
  'documents:empty:stream:explanation1:appstream' : '您的裝置一旦與 Ink Space App 連接並使用 Wacom ID 登入，您將在此看到您的所有內容。',
  'documents:empty:stream:explanation1:webstream' : '試用 Ink Collaboration。利用您的創作分享和協同作業其他內容。今天試用',
  'documents:empty:stream:explanation1:bamboopaperstream' : '一旦使用您的 Wacom ID 連接 Bamboo Paper，您將在此看到所有您的內容。',
  'documents:empty:stream:explanation1:wacom_notes' : '記筆記。完成更多工作。就像在紙上一樣，但會更好。',
  'documents:empty:stream:tryitnow' : '現在試用',
  'documents:empty:stream:learnmore' : '了解更多',
  'documents:empty:group' : '您的群組是空的',
  'streams:inkspaceAppStream' : 'Ink Space App Stream',
  'streams:inkspaceWebStream' : 'Ink Space Web Stream',
  'streams:inkspaceBambooPaperStream': 'Bamboo Paper Stream',
  'streams:inkspaceInkNoteFilesStream': 'Ink Note Stream',
  'streams:inkspaceMontBlanc' : 'Montblanc Stream',
  'streams:wacomnotes': 'Wacom Notes',
  'streams:collaboration': '協同作業流',

  'filter:result' : '符合結果',
  'filter:search' : '搜尋...',

  'footer:navigation:privacy' : '隱私',
  'footer:navigation:termOfUse' : '使用條款',
  'footer:navigation:cookies' : 'Cookies',
  'footer:navigation:cookiePreferences' : 'Cookie偏好設定',

  'global:convertTo' : '轉換為 {language}',
  'global:convertToText' : '轉換為文字',

  'context-menu:createNewCanvas' : '建立新的畫布',
  'context-menu:createNewGroup' : '建立群組',
  'context-menu:startNewCollaboration' : '啟動新的協同作業',
  'context-menu:startCollaboration' : '啟動協同作業',
  'context-menu:edit' : '編輯',

  'global:delete' : '刪除',
  'global:rename' : '重新命名',
  'global:export' : '匯出',
  'global:tags' : '標籤',
  'global:groups': '群組',
  'global:shareLink' : '分享連結',
  'global:copy' : '複製',
  'global:ok' : 'OK',
  'global:duplicateAndEdit' : '複製與編輯',
  'global:semantic-ink' : 'Semantic Ink',

  'global:save' : '儲存',
  'global:dontsave' : '不儲存',
  'global:proceed' : '繼續',
  'global:enterName' : '輸入名稱',
  'global:browserUnsupported' : '不支援的瀏覽器',

  'global:language' : '語言',
  'global:language:english' : 'English',
  'global:language:japanese' : '日本語',
  'global:language:korean' : '한국어',
  'global:language:chineseTraditional' : '中文（繁體）',
  'global:language:chineseSimplified' : '中文（简体）',
  'global:language:german' : 'Deutsch',
  'global:language:french' : 'Français',
  'global:language:russian' : 'Русский',
  'global:language:dutch' : 'Dutch',
  'global:language:italian' : 'Italiano',
  'global:language:polish' : 'Polski',
  'global:language:portuguese' : 'Português',
  'global:language:portuguese_BR' : 'português do Brasil',
  'global:language:spanish' : 'Español',
  'global:language:danish' : 'Dansk',
  'global:language:finnish' : 'Suomen kieli',
  'global:language:norwegian' : 'Norsk',
  'global:language:swedish' : 'Svenska',
  'global:language:arabic' : "اَلْعَرَبِيَّةُ",
  'global:language:bulgarian' : 'Български',
  'global:language:turkish' : 'Türkçe',
  'global:language:vietnamese' : 'tiếng Việt',

  'global:logout' : '註銷',
  'global:login' : '登錄',
  'global:myAccount' : '我的帳戶',

  'inputField:existsName' : '錯誤!此名稱已存在!',
  'inputField:incorrectName' : '錯誤!名稱不正確!',
  'inputField:notInRange' : '錯誤!名稱必須介於 ( {minLength} - {maxLength} ) 個字元之間!',

  'modalbox:browser:unsupport:title': 'Browser Unsupported',
  'modalbox:browser:unsupport:text': '請使用 Chrome 或 Firefox 瀏覽器連接到此 Collaboration。謝謝',
  'modalbox:browser:unsupport:sf:text': '請使用 Chrome 或 Safari 瀏覽器連接到此 Collaboration。謝謝',
  'modalbox:tablets:warning:title': 'Important',
  'modalbox:tablets:warning:text': 'Switching this browser tab or minimising the browser will disconnect you from this Collaboration.',

  'modalbox:error:title' : '錯誤!',
  'modalbox:error:buttonTitle' : '關閉',
  'modalbox:exportError:message' : '導出動作現在不可用',
  'modalbox:createTag:buttons:title' : '建立',
  'modalbox:createTag:title' : '建立新標籤',
  'modalbox:deleteTag:message' : '您將刪除 <span>{tag}</span>, 此動作將無法復原。',
  'modalbox:removeDocument:message' : '您將刪除 <span>{docTitle}</span>, 此動作將無法復原。',
  'modalbox:shareLink:buttons:stopSharing' : '停止分享',
  'modalbox:sessionFull:title' : '協同作業已滿',
  'modalbox:sessionFull:text' : '很抱歉，您嘗試存取的協同作業工作階段已滿。',
  'modalbox:leaveSession:title' : '離開工作階段',
  'modalbox:leaveSession:buttons:leave' : '離開',
  'modalbox:leaveSession:text' : '是否要離開協同作業工作階段？',
  'modalbox:endSession:title' : '結束工作階段',
  'modalbox:endSession:buttons:end' : '結束',
  'modalbox:endSession:text' : '是否要結束目前的協同作業工作階段？目前的進度將自動儲存在您的 Web Stream 中。',
  'modalbox:sessionEnded:title' : '工作階段結束',
  'modalbox:sessionEnded:text' : '是否要將協同作業工作階段複本儲存在您的 Web Stream 中？',
  'modalbox:accessDenied:title' : '訪問被拒絕',
  'modalbox:accessDenied:buttons:goBack' : '返回',
  'modalbox:accessDenied:text' : '很遺憾，您嘗試訪問的協作會話已到期。',
  'modalbox:collaborationStarted:title' : '協同作業已啟動',
  'modalbox:collaborationStarted:warn' : '您必須保持 Ink Collaboration 為開啟狀態。如果您關閉此標籤，或切換為不同標籤，Ink Collaboration 將會結束。',
  'modalbox:collaborationStarted:text' : ' 您的協同作業階段已啟動。您可以邀請多達 10 名參與者，只要提供您瀏覽器的協同作業 URL 即可。',
  'modalbox:duplicateAndEdit:buttons:dontshow' : '不顯示',
  'modalbox:duplicateAndEdit:text' : '新的頁面延長版將放置在您的 Ink Space Web Stream 中，無需變更原始狀態',
  'modalbox:saveChanges:title' : '儲存變更',
  'modalbox:saveChanges:text' : '是否要在關閉之前儲存對文件所做的變更？',
  'modalbox:collaborationError:title' : '協同作業錯誤！',
  'modalbox:collaborationError:buttons:close' : '關閉',
  'modalbox:collaborationError:text' : 'Ink Collaboration 不支援您所使用的瀏覽器。此項功能在 Firefox /v55+/、Chrome /v60+/和Safari /v11+/ 上運作最佳',
  'modalbox:WebGLError:title' : '發生錯誤',
  'modalbox:WebGLError:text' : '發生錯誤，您需要使用以上訊息中的「重新載入」按鈕重新載入內容。',
  'modalbox:collaborationJoinFailed:title': '連線無法建立',
  'modalbox:collaborationJoinFailed:text': '您嘗試存取的工作階段無法存取。請稍後再試一次。',
  'modalbox:connectionLost:title': '連線遺失',
  'modalbox:connectionLost:text': '連線已遺失，請檢查您的 Internet 連線，並再試一次。',
  'modalbox:phoneDetection:title': '使用行動應用程式',
  'modalbox:phoneDetection:btn:open': '開啟',
  'modalbox:phoneDetection:btn:no-thanks': '不，謝謝',
  'modalbox:phoneDetection:msg': '我們建議使用在您的手機上使用我們專用的行動應用程式，以享受更佳的體驗。',
  'modalbox:chooseVectorOrRaster:title' : '選擇編輯模式',
  'modalbox:chooseVectorOrRaster:buttons:annotate' : '註解',
  'modalbox:chooseVectorOrRaster:buttons:vectorize' : '向量化',
  'modalbox:chooseVectorOrRaster:text' : '您嘗試編輯的內容為點陣式，為保留原有的外觀，我們建議加上註解。此外，您也可以選擇將其轉換為向量，但是結果可能依所使用的工具而有所不同。',

  // Groups Strings
  'modalbox:deleteGroup:title' : '刪除群組',
  'modalbox:deleteGroup:title:multiple' : '刪除群組',
  'modalbox:deleteGroup:button:removeGroup' : '移除群組',
  'modalbox:deleteGroup:button:removeGroup:multiple' : '移除群組',
  'modalbox:deleteGroup:message' : '是否只要刪除群組 <span>「{titles}」</span> 或者也要刪除其內容？此動作無法復原。',
  'modalbox:deleteGroup:message:multiple': '是否只要刪除群組<span>「{titles}」</span>或者也要刪除其內容？此動作無法復原。',
  'modalbox:deleteGroup:button:removeAll': '全部刪除',
  'modalbox:deleteGroup:button:cancel': '取消',
  'modalbox:deletePage:title': '刪除頁面',
  'modalbox:deletePage:message': '是否要刪除<span>「{titles}」</span>或從此群組中移除？',
  'modalbox:deletePage:removePage': '從群組中移除',
  'modalbox:group:rename': '重新命名群組',
  'mailbox:exportLimit:title': '已達限制',
  'mailbox:exportLimit:message': '已達 {maxExportPages} 個筆記的數量上限。請減少所選筆記的數量並重試。',

  'toast:groupAddedSuccess:single': '頁面已成功新增至「{groupName}」',
  'toast:groupAddedSuccess:plural': '頁面已成功新增至「{groupName}」',
  'toast:pagesRemovedFromGroup:single': '已成功從「{groupName}」移除頁面',
  'toast:pagesRemovedFromGroup:plural': '已成功從「{groupName}」移除頁面',
  'toast:groupCreated': '「{groupName}」已成功建立',
  'toast:groupRemoved': '「{groupName}」已成功移除',
  'toast:groupRemoved:andPages': '「{groupName}」和頁面已成功移除',
  'toast:groupRemoved:plural': '所選群組已成功移除',
  'toast:noteRemoved:single': '頁面已成功刪除',
  'toast:noteRemoved:plural': '頁面已成功刪除',



  'sorter:ascending' : '遞增',
  'sorter:date' : '日期',
  'sorter:descending' : '遞減',
  'sorter:name' : '名稱',
  'sorter:size' : '大小',
  'sorter:sortby' : '排序依據',
  'sorter:type' : '類型',

  'tagContainer:createTag' : '建立標籤',
  'tagContainer:noTags' : '無標籤',
  'tagContainer:noTagsMessage' : '建立好的標籤會出現在這裡',

  'groupContainer:noGroups' : 'No groups',
  'groupContainer:noGroupsMessage': '建立好的群組會出現在這裡',

  'export:asDoc' : '為DOC',
  'export:asPsd' : '為PSD',
  'export:asJpg' : '為JPG',
  'export:asPdf' : '為PDF',
  'export:asPng' : '為PNG',
  'export:asSvg' : '為 SVG',
  'export:asWill' : '為WILL',
  'export:asVideo' : 'Ink to Video',
  'export:asPptx' : '為PowerPoint',

  // Ink to Video
  'video:generating' : '產生影片',
  'video:timeNotice' : '這可能需要一些時間',
  'video:estimatedTime.one': '這可能需要一分鐘時間',
  'video:estimatedTime.many': '這可能需要 <span>{minutes}</span>分鐘時間',
  'video:fileReady' : '您的檔案已就緒',
  'video:download'  : '下載',
  'video:expirationMessage' : '下載內容將會在 <span>{days}</span>天後自動刪除',
  'video:error' : '影片產生失敗',

  // Session Expired Prompt
  'session:expired:title': '工作階段已到期',
  'session:expired:message': '您的工作階段已到期。請再次登錄。',
  'session:expired:button': '登入',

  // Auth module
  'auth:email:label' : '電子郵件',
  'auth:email:taken' : '無法使用該電子郵件地址。',
  'auth:email:notRegisterd' : '未註冊該電子郵件地址。',
  'auth:email:invalid' : '無效的電子郵件格式',
  'auth:password:label' : '密碼',
  'auth:password:reenter:label' : '再次輸入密碼',
  'auth:password:mismatch:label' : '密碼不符',
  'auth:password:tooEasy:label' : '密碼必須包含至少 {charLength} 個字元和以下符號中的三種：一個大寫字母、一個小寫字母、一個數字、一個特殊符號。',
  'auth:firstName:label' : '名字',
  'auth:lastName:label' : '姓氏',
  'auth:country:label' : '國家',
  'auth:country:select' : '選擇國家',
  'auth:language:label' : '語言',
  'auth:language:select' : '選擇語言',
  'auth:termsAndConditions:label' : '您已閱讀並同意Wacom的<a href="{termsHref}">使用條款</a>。',
  'auth:privacyPolicy:label' : '您已閱讀並同意Wacom的<a href="{privacyHref}">隱私政策</a>與<a href="{cookieHref}">Cookie 政策</a>。',
  'auth:login:invalid' : '無效的登入或密碼。',
  'auth:login:title' : '登入Wacom ID',
  'auth:login:allWacom:text' : '在Wacom所有產品中共用的帳戶。',
  'auth:login:forgotPassword:link' : '忘記密碼',
  'auth:login:button' : '登入',
  'auth:login:signup:button' : '註冊',
  'auth:login:createNewAccount:label' : '尚未擁有帳戶？',
  'auth:login:alreadyHaveAccount:label' : '已擁有帳戶？',
  'auth:passwordReset:title' : '重設密碼',
  'auth:passwordReset:text' : '輸入您在Wacom ID中使用的電子郵件地址。我們將寄給您一個重設密碼的連結。',
  'auth:passwordReset:reset:button' : '重設',
  'auth:passwordReset:signIn:button' : '登入',
  'auth:passwordReset:back:button' : '返回至',
  'auth:passwordReset:success:text' : '已發送一封電子郵件至您的電子郵件地址。',
  'auth:register:title' : '創建您的個人資料',
  'auth:register:success:title1' : '恭喜您！',
  'auth:register:success:title2' : '您已完成註冊',
  'auth:register:success:message' : '若要繼續，請檢視您的電子郵件並確認您的註冊，之後登入至您的儀表板。',
  'auth:errors:generic' : '發生錯誤。請再試一次。',
  'auth:policyUpdate:title': '我們的使用條款與隱私權政策在您上次登入後有所變動',

  // Welcome screens
  'welcome:screen1:header': 'Ink Collaboration功能',
  'welcome:screen1:text': '利用Ink Collaboration，您可以與任何人協同作業。從您的內容開啟一個畫布，或者創建一個新的畫布並開始與其他人進行墨蹟繪圖。',
  'welcome:screen2:header': '内容同步和分享',
  'welcome:screen2:text': '使用Wacom ID和雲端連線的應用程式，您可以隨時隨地在線上獲取您的內容。以其他格式匯出內容，以便在喜歡的軟體工具和應用程式中繼續工作。',
  'welcome:screen3:header': '存取您的所有內容',
  'welcome:screen3:text': 'Wacom ID為您的內容解鎖許多新功能和工作流。隨時隨地存取、與他人共用或為安全起見線上備份您的內容。Wacom ID是雲服務的鑰匙。 ',
  'welcome:button:next': '下一步',
  'welcome:button:done': '完成',

  // Migration screen
  'migration:screen1:text1': '內容優化中。請稍候。',
  'migration:screen1:text2': '處理中......',

  // What is new screen
  'whatIsNew:cloudMigration:title': '服務更新',
  'whatIsNew:cloudMigration:mainText': '我們正在對雲服務進行改進。查看或使用內容時可能會有短暫延遲。感謝您的耐心。',
  'whatIsNew:cloudMigration:cloudTeam': '"- Wacom雲團隊"',

  // Semantic-ink
  'semantic:sidebar:abstract': '摘要',
  'semantic:sidebar:confidence': '保密',
  'semantic:sidebar:reference': '參考',

  // Semantic-ink icon description
  'semantic:type:organization': '組織',
  'semantic:type:topic': '主題',
  'semantic:type:person': '人',
  'semantic:type:location': '位置',
  'semantic:type:event': '活動',
  'semantic:error:header': '無Semantic Ink識別',
  'semantic:error:text': '您的筆記無需任何Semantic Ink增強。',
    
  // Download my data
  'dataDownload:profile:option': '我的資料',
  'dataDownload:tabs:downloadData': '下載資料',
  'dataDownload:tabs:availableData': '可用資料',
  'dataDownload:tabs:downloadData:description': '匯出並下載您的Ink Space帳戶內容的拷貝。',
  'dataDownload:tabs:downloadData:chooseProduct': '請選擇......',
  'dataDownload:tabs:downloadData:chooseProduct:all': '全選',
  'dataDownload:processing:description': '下載檔案可能需要一些時間。當您的下載準備好後，您將收到一封包含下載連結的電子郵件，或者可從此處下載。',
  'dataDownload:processing': '處理中......',
  'dataDownload:request:button': '下載資料',
  'dataDownload:tabs:availableData:description': '下載您的資料',
  'dataDownload:tabs:availableData:archiveFor': '存檔 ',
  'global:myData': '我的資料'
}

export default messages;