import {
	InkBuilder, InkCanvas2D
} from 'digital-ink';
import InkCanvasRasterRuntime from './InkCanvasRasterRuntime';

class InkCanvasRaster extends InkCanvasRasterRuntime {
	constructor(canvas, width, height, onRescale) {
		super(canvas, width, height);
		this.isRaster = true;

		this.canvasWrapper = canvas.parentElement.parentElement;
		this.onRescale = onRescale;

		const inkCanvas = InkCanvas2D.createInstance(new OffscreenCanvas(width, height));
		this.eyeDropperLayer = inkCanvas.createLayer(width, height);
		this.imageRendererLayer = inkCanvas.createLayer(width, height);

		this.strokesLayer = this.canvas.createLayer({ width, height });
	}

	present(dirtyArea, phase) {
		if (phase === InkBuilder.Phase.END) {
			this.strokeRenderer.blendStroke(this.strokesLayer);
		}

		this.canvas.clear();
		this.canvas.blend(this.strokesLayer, { transform: this.transform });

		if (phase === InkBuilder.Phase.UPDATE) {
			this.canvas.blend(this.strokeRenderer.layer, { mode: this.strokeRenderer.blendMode, transform: this.transform });
		}
	}
}

export default InkCanvasRaster;