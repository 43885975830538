import React, { useState, useEffect, useRef, useContext } from 'react';
import CanvasContext from '../../context/CanvasContext';
import { INK_EDITOR_CONFIG } from 'constants/constants';

import HLSColorPicker from './HSLColorPicker/HSLColorPicker';

import './ColorTool.scss';

function ColorTool() {
    const parentRef = useRef();
    const [isToolOpened, setToolOpened] = useState(false);
    const { color, setColor, toolId } = useContext(CanvasContext);

    useEffect(() => {
        const checkForCloseEvent = ev => {
            if (isToolOpened && !parentRef.current.contains(ev.target)) {
                setToolOpened(false);
            }
        };

        window.addEventListener('mousedown', checkForCloseEvent);
        window.addEventListener('click', checkForCloseEvent);

        return () => {
            window.removeEventListener('mousedown', checkForCloseEvent);
            window.removeEventListener('click', checkForCloseEvent);
        }
    }, [isToolOpened]);

    let activeBackgroundToolColor = 'transparent';

    if (toolId === INK_EDITOR_CONFIG.controls.pen || toolId === INK_EDITOR_CONFIG.controls.highlighter) {
        activeBackgroundToolColor = color.toString();
    }


    const wrapperStyle = {
        backgroundColor: activeBackgroundToolColor
    };

    const activeClass = isToolOpened ? 'active' : ''

    return <div ref={parentRef} className={'color-tool ink-tool'}>
        <i className={'icon icon-color color-tool ' + activeClass} style={wrapperStyle} onMouseDown={() => setToolOpened(true)} />
        {isToolOpened && <HLSColorPicker color={color} setColor={setColor} />}
    </div>
}

export default ColorTool;