import { VIDEO_SERVICE_URL, INKSPACE_INITIAL_STATE } from 'constants/constants';

export const fetchInkToVideoState = async (store, id) => {
    try {
        const inkToVIdeoProgressResult = await fetch(`${VIDEO_SERVICE_URL}/progress/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${store.state.auth.mainToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (inkToVIdeoProgressResult.ok) {
            var response = await inkToVIdeoProgressResult.json();

            store.setState({
                video: {
                    ...store.state.video,
                    encodeState: response.state,
                    progress: response.progress,
                    etaSeconds: response.etaSeconds,
                    resultLocation: response.resultLocation
                }
            });
        }
        else {
            store.setState({
                video: {
                    ...store.state.video,
                    encodeState: 'failed',
                    progress: 0,
                    etaSeconds: 0,
                    resultLocation: ''
                }
            });
            throw inkToVIdeoProgressResult.statusText;
        }
    } catch (er) {
        store.setState({ video: { ...store.state.video, encodeState: 'failed' } });
        console.error(er)
    } finally {
        store.setState({ video: { ...store.state.video, responseReceived: true } });
    }
}

export const clearState = async (store) => {
    store.setState({ video: INKSPACE_INITIAL_STATE.video });
}

