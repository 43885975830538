// import EmptyTags from '../../components/tags/EmptyTags

function GroupContextMenu(props) {
  const { formatMessage, item, contextMenu } = props;

  return [
    {
      id: 'ctrl-button-export',
      title: formatMessage({ id: 'global:export' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doShowExportModal(item)
    },
    {
      id: 'ctrl-button-delete',
      title: formatMessage({ id: 'global:delete' }),
      className: 'doc-ctrl-button',
      callback: () => props.actions.doDeleteGroup(contextMenu)
    }//,
    // {
    //   id: 'ctrl-button-tags',
    //   title: <FormattedMessage id={'global:tags'} />,
    //   className: 'doc-ctrl-button children',
    //   emptyView: <EmptyTags style={{ padding: '5rem', width: '21.5rem', height: '14.7rem' }} />
    // }
  ]
}

export default GroupContextMenu;