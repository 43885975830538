import React from 'react';
import ReactSlider from 'react-slider'

import './Slider.scss';

function Slider(props) {
    return <div id={props.id} className={'slider-wrapper'} style={props.styles}>
        <ReactSlider
            value={props.value}
            onChange={props.onChange}
            step={0.1}
            defaultValue={props.value}
            renderMark={() => <span className={'mark'} />}
        />
    </div>
}

export default Slider;