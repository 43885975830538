import React, { useState, useLayoutEffect, useRef } from 'react';
import ModalBox from 'common/components/modalBox/ModalBox';

import './TextInputModal.scss';

function TextInputModal(props) {
    const inputRef = useRef();
    const [isValidState, setIsValidState] = useState(true);
    const [inputValue, setInputValue] = useState(props.initialValue ?? '');

    useLayoutEffect(() => {
        if (inputRef) {
            inputRef.current.focus();
        }
    }, []);

    const onFormSubmit = (ev) => {
        ev.preventDefault();
        if (validate()) {
            props.onSubmit(inputValue);
        }
    };

    const validate = () => {
        if (props.minLength && props.maxLength) {
            const trimmedInput = inputValue.trim();
            if (trimmedInput.length < props.minLength || inputValue.length > props.maxLength) {
                setIsValidState(false);

                return false;
            } else {
                setIsValidState(true);
            }
        }

        return true;
    }

    return <ModalBox title={props.title}
        buttons={props.buttons}
        onDestroy={props.onDestroy}
        isSubmit
        onFormSubmit={onFormSubmit}
        isSpinnerVisible={props.isSpinnerVisible}
    >
        <input id={props.targetId} ref={inputRef} type='text' value={inputValue}
            onBlur={validate}
            onChange={e => setInputValue(e.target.value)}
            className={isValidState ? 'text-field' : 'text-field invalid-filed'}
        />
        {!isValidState ? <label className={'error-msg'}>{props.errorMessage}</label> : ''}
    </ModalBox>
}

export default TextInputModal;