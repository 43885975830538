import React from 'react';
import ActiveCheck from './ActiveCheck';

import './GenericListItem.scss';

function GenericListItem(props) {
    let baseClassName = props.classNameOverrides
        ? ''
        : `generic-list-item ${props.active ? 'active' : ''}`;

    let cn = `${baseClassName} ${props.className || ''}`;

    return <li className={cn} onClick={props.onClick}>
        {props.children}
        {props.active ? <ActiveCheck /> : null}
    </li>
}

export default GenericListItem;