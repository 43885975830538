import React from 'react';
import { useIntl } from 'react-intl'

import './SemanticInfoComponent.scss';

function SemanticInfoComponent(props) {
    const { formatMessage } = useIntl();
    const { abstract, article, thumbnail, label } = props;

    return <div className={'semantic-details-wrapper'}>
        <div className={'semantic-details'}>
            {
                thumbnail && <span className={'image-holder'}>
                    <img alt={label} src={thumbnail} />
                </span>
            }
            {abstract && <>
                <div className={'text-l'}>
                    {formatMessage({ id: 'semantic:sidebar:abstract' })}
                </div>
                <span className={'text-abstract'}>
                    {abstract}
                </span>
            </>
            }
        </div>
        <div>
            {article && <div>
                <div className={'text-l'}>
                    {formatMessage({ id: 'semantic:sidebar:reference' })}
                </div>
                <a href={article} target='_blank' rel='noreferrer'>
                    {article}
                </a>
            </div>
            }
        </div>
    </div>
}

export default SemanticInfoComponent;