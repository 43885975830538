// ------------------------------------
// URL constants
// ------------------------------------
export const PRIVACY_POLICY_LINK = '//www.wacom.com/{locale}/privacy'
export const TERMS_OF_USE_LINK = '//www.wacom.com/{locale}/terms-of-use'
export const COOKIE_NOTICE_LINK = '//www.wacom.com/{locale}/cookie-notice'
export const CALIFORNIA_PRIVACY_LINK = '//www.wacom.com/en-us/privacy/california-privacy'
export const USER_ACCOUNT_LINK = '//account.wacom.com/{locale}'
export const WEB_STREAM = 'web'
export const APPLICATION_NAME = WEB_STREAM
export const WACOM_NOTES_STREAM = 'wacom_notes'
export const INKSPACE_STREAM = 'mate'
export const MATE_STREAM = 'mate'
export const MONT_BLANC_STREAM = 'montblanc'
export const BAMBOO_STREAM = 'bamboo_paper'
export const COLLABORATION_STREAM = 'collaboration'
export const NODE_TYPE_PAGE = 'page'
export const NODE_TYPE_DOCUMENT = 'document'
export const NODE_TYPE_GROUP = 'group'
export const INK_NOTE_FILE_STREAM = 'inknotefiles'

export const IDENTITY_TRANSFORM_MATRIX = 'matrix(1.0 0.0 0.0 1.0 0.0 0.0)';
export const BP_PAGE = 'bp-page';
export const BP_DOC = 'bp-doc';

export const STREAMS_WITH_GROUPS = new Set([INKSPACE_STREAM, MONT_BLANC_STREAM, MATE_STREAM, WACOM_NOTES_STREAM]);

export const STREAMS_WITH_DUPLICATE_AND_EDIT = new Set([INKSPACE_STREAM, MONT_BLANC_STREAM, MATE_STREAM]);

export const STREAMS_WITH_EDIT = new Set([WEB_STREAM, COLLABORATION_STREAM, WACOM_NOTES_STREAM]);

const applicationNames = [{ name: WACOM_NOTES_STREAM, isDefault: false }, { name: WEB_STREAM, isDefault: true }, { name: INKSPACE_STREAM, isDefault: false }]
export const DEFAULT_APPLICATION_NAME = applicationNames.filter(s => s.isDefault)[0].name

export const STORAGE_API_BASE_URL = process.env.REACT_APP_STORAGE_API_BASE_URL;

export const BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const APP_VERSION = process.env.REACT_APP_VERSION;

export const BASE_HOME_LOCATION = `/?applicationName=${DEFAULT_APPLICATION_NAME}`

export const ACCOUNTS_SERVER_URL = process.env.ACCOUNTS_SERVER_URL

export const IMG_API_URL = process.env.IMG_API_URL //STORAGE_API_BASE_URL + '/wacom-conversion-service'

export const EXPORT_BASE_URL = process.env.REACT_APP_EXPORTS_BASE_URL;

export const WILL2_EXPORT_SERVICES_BASE_URL = process.env.REACT_APP_WILL2_EXPORTS_BASE_URL;

export const SOCKET_SYNC_URL = process.env.REACT_APP_WEB_SOCKET_SYNC_URL;

export const SSO_URL = '/sso'

export const GENERATED_RESOURCES_POLLING_INTERVAL = 5000

export const DOCUMENT_UPDATE_POLLING_INTERVAL = 30_000;

export const DOCUMENT_UPDATE_LONG_POLLING_INTERVAL = 10 * 60 * 1000;

export const CSS_TRANSITION_ENTER_ANIMATION_TIME = 150

// Fetch the all tags
export const TAGS_URL = STORAGE_API_BASE_URL + '/tags'

// Fetch the all documents
export const DOCUMENTS_URL = STORAGE_API_BASE_URL + '/documents'
export const INK_NOTE_FILES_URL = STORAGE_API_BASE_URL + '/inknotefiles'

// Fetch the all mate documents
export const STREAM_MATE_URL = STORAGE_API_BASE_URL + '/streams/inkspace'

// Fetch the all existing folders
export const FOLDERS_URL = STORAGE_API_BASE_URL + '/streams'


// export as text url
export const EXPORT_AS_FILE = EXPORT_BASE_URL + '/conversion/by-url/export-wacom-docs'

export const WILL2_EXPORT_SERVICES_URL = WILL2_EXPORT_SERVICES_BASE_URL + '/exportBambooPaper'

export const ZIP_OPC_FILES = EXPORT_BASE_URL + '/conversion/by-url/zip-wacom-docs';

export const HWR_OPC_FILE = EXPORT_BASE_URL + '/ink-to-text/by-url/wacom-docs-to-text';

// generate image api
export const GENERATE_IMAGE_API_URL = STORAGE_API_BASE_URL + '/generate-img/generate-img';

// Groups api
export const GROUPS_URL = STORAGE_API_BASE_URL + '/groups';

// Tempalte image location
export const TEMPLATE_URL_BASE = STORAGE_API_BASE_URL + '/template';

// Thumb jwt issue endpoint
export const THUMB_JWT_URL = STORAGE_API_BASE_URL + '/exportsJwt';

// Ink Editor
export const DOCUMENT_EDITOR_URL_BASE = BACKEND_API_BASE_URL + '/document';

export const INK_EDITOR_URL_BASE = BACKEND_API_BASE_URL + '/ink-editor';

export const INK_NOTE_URL_BASE = BACKEND_API_BASE_URL + '/inkNote';

// Collaboration 
export const COLLABORATION_BASE_URL = process.env.REACT_APP_COLLABORATION_BASE_URL;
export const MONT_BLANC_LEGACY_BASE_URL = process.env.REACT_APP_COLLABORATION_BASE_URL;
export const LEGACY_API_BASE_URL = process.env.REACT_APP_LEGACY_API_BASE_URL;

export const MIGRATION_BASE_URL = process.env.REACT_APP_MIGRATION_BASE_URL;
export const DOWNLOAD_MY_DATA_URL = process.env.REACT_APP_DOWNLOAD_MY_DATA_URL;

// Max export pages
export const MAX_EXPORT_NOTES = 40

export const ROOT_DIR_ID = 'root';

export const MIN_INPUT_FIELD_LENGTH = 1;
export const MAX_INPUT_FIELD_LENGTH = 16;

export const DOCUMENT_DETAILS = docId => (`${DOCUMENTS_URL}/${docId}/details`)
export const STROKES = docId => (`${DOCUMENTS_URL}/${docId}/strokes`)
export const COPY_DOCUMENT_URL = docId => (`${DOCUMENTS_URL}/${docId}/copy`)
export const COPY_BAMBOO_URL = docId => (`${DOCUMENTS_URL}/${docId}/copyBamboo`)

// generate video
export const VIDEO_SERVICE_URL = process.env.REACT_APP_VIDEO_BASE_URL
export const VIDEO_TYPE = 'video'
export const ANALYTICS_TRACKING_ID = 'UA-41423377-1' // Wacom ID

export const LS_KEY_SESSION_ID = 'inkspace:auth:sessionId:v3'
export const LS_KEY_PROFILE = 'inkspace:auth:profile:v3'
export const LS_KEY_PROFILE_OLD = 'inkspace:auth:profile'
export const LS_KEY_THUMB_TOKEN = 'inkspace:auth:thumbToken:v3'

export const PHONE_POPUP_DISMISSED = 'inkspace:phone:redirectToMobileApp:v3'

export const SHOW_EN_GB = process.env.SHOW_EN_GB === 'true'

export const UNAUTHORIZED = 'unauthorized_user'

export const COLLABORATION_INIT_ELEMENT_ID = 'collaboration-init-element';
export const COLLABORATION_LOADING_PATH = 'creating-room';

// export const ANALYTICS_TRACKING_ID = 'UA-104240803-1' // Demo ID
// ------------------------------------
// Init constants - They will occurs when the app is ready
// ------------------------------------

// delimiter thats is used to split tags in the query string
export const QUERY_STRING_DELIMITER = '|'
export const ASK_ACCESS_TOKEN_VIA_HEADER = process.env.ASK_ACCESS_TOKEN_VIA_HEADER
const refreshBase = process.env.USE_ACCOUNTS_SERVER_URL_FOR_REFRESH ? `${ACCOUNTS_SERVER_URL}/wacomid-iams` : `${STORAGE_API_BASE_URL}/refreshserver`

// AUTH CONSTANTS
export const SECRET = 'pChcbN*}y*T(QLqGIF|mhtROvdCzVrQV'
export const LOGIN_URL = ACCOUNTS_SERVER_URL + '/wacom-id-ui/'
export const QUERY_ACCESS_TOKEN_URL = ACCOUNTS_SERVER_URL + '/wacom-id-ui/api/get-access-token'
export const REFRESH_ACCESS_TOKEN_URL = refreshBase + '/refresh-access-token'
export const POLLING_INTERVAL = 700
export const CREATE_ASSET_URL = ACCOUNTS_SERVER_URL + '/wacom-id-ui/api/create-asset'
export const AUTH_PATH = '/authentication/login'
export const AUTH_PATH_WITH_PARAMS = '/authentication/:view?'
export const AUTH_PATH_BASE = '/authentication'
// export const HOME_PATH = '/'
export const AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE ?? 'https://stage-inkspace.wacom.com/wacomid'
export const MIN_PASS_LENGTH = 9
export const MAX_PASS_LENGTH = 100

export const ANDROID_APP_URI = 'https://play.google.com/store/apps/details?id=com.wacom.mate&hl=en';
export const IOS_APP_URI = 'https://itunes.apple.com/us/app/wacom-inkspace-app/id1029007767?mt=8';

export const WIKI_API_URI = 'https://{0}.wikipedia.org/w/api.php';

// Paper Types for Bamboo Paper
export const TEMPLATE_WIDTH = 1440;
export const TEMPLATE_HEIGHT = 2560;

export const BASIC_SUBSCRIPTION = [
    'SYNC-NODES',
    'WILL-TO-SVG',
    'WILL-TO-PSD',
    'WILL-TO-PNG',
    'WILL-TO-JPG',
    'STORAGE-5GB'
]

export const PLUS_SUBSCRIPTION = [
    'SEARCH-TEXT',
    'INK-TO-FORMATLESS-TEXT',
    'INK-TO-HTML',
    'SYNC-NODES',
    'WILL-TO-SVG',
    'WILL-TO-PSD',
    'WILL-TO-PNG',
    'WILL-TO-JPG',
    'STORAGE-50GB'
]

export const BAMBOO_COLLABORATION_MODE = {
    RASTER: 'RASTER',
    VECTOR: 'VECTOR'
}

export const INKSPACE_INI_BEGAN = 'INKSPACE_INI_BEGAN'
export const INKSPACE_INI_FINISHED = 'INKSPACE_INI_FINISHED'
export const INKSPACE_INI_FAILED = 'INKSPACE_INI_FAILED'
export const INKSPACE_INITIAL_STATE = {
    documents: {
        overlay: false,
        data: [],
        pageData: [],
        isShowContextMenu: false,
        controlPanel: {
            isShow: false,
            selectedItems: [],
            selectedGroups: []
        },
        size: 0,
        pageSize: 0,
        groups: [],
        allGroups: [],
        selectedGroup: '',
        groupsFilterTerm: '',
        selectedTag: '',
        isImagePushActive: false,
        updatedImages: new Set()
    },
    tags: [],
    folders: [],
    stateOfStore: {
        isFetching: false,
        isFetched: false,
        isFetchingFailed: false,
        error: null,
        dateOfUpdate: (new Date()).getTime(),
        blankEditor: { mode: 0, initialRender: true, type: 0 }
    },
    preview: {
        document: {
            id: null,
            title: '',
            creationDate: new Date().valueOf(),
            boundingBox: {
                width: 500,
                height: 500
            },
            pageIds: [0]
        },
        isExistsPreviewImage: false
    },
    export: {
        text: { content: '', isFetching: false, lang: null }
    },
    sso: {},
    video: {
        guid: '',
        encodeState: '',
        progress: '',
        etaSeconds: '',
        resultLocation: '',
        responseReceived: false
    }
}

export const LOCAL_STORAGE = {
    SORT: 'inkspace:sort',
    INITIAL_SYNC_READY: 'inkspace:initialSyncReady',
    LOCALE: 'inkspace:locale'
}

export const LANGUAGES = {
    en: { code: 'en', value: 'global:language:english', locale: 'en_US' },
    ja: { code: 'ja', value: 'global:language:japanese', locale: 'ja_JP' },
    ko: { code: 'ko', value: 'global:language:korean', locale: 'ko_KR' },
    'zh-Hant': { code: 'zh-Hant', value: 'global:language:chineseTraditional', locale: 'zh_TW' },
    'zh-Hans': { code: 'zh-Hans', value: 'global:language:chineseSimplified', locale: 'zh_CN' },
    de: { code: 'de', value: 'global:language:german', locale: 'de_DE' },
    fr: { code: 'fr', value: 'global:language:french', locale: 'fr_FR' },
    ru: { code: 'ru', value: 'global:language:russian', locale: 'ru_RU' },
    // nl: { code:'nl', value: 'global:language:dutch', locale:'nl_NL' },
    it: { code: 'it', value: 'global:language:italian', locale: 'it_IT' },
    pl: { code: 'pl', value: 'global:language:polish', locale: 'pl_PL' },
    pt: { code: 'pt', value: 'global:language:portuguese', locale: 'pt_PT' }, // Portuguese - Brazil
    es: { code: 'es', value: 'global:language:spanish', locale: 'es_ES' }
    // da: { code:'da', value: 'global:language:danish', locale:'da_DA' },
    // fi: { code:'fi', value: 'global:language:finnish', locale:'fi_FI' },
    // nn: { code:'nn', value: 'global:language:norwegian', locale:'no_NO' },
    // sv: { code:'sv', value: 'global:language:swedish', locale:'sv_SE' }
}

const getExportLanguages = () => {
    let languages = {
        en: { code: 'en', value: 'global:language:english', locale: 'en_US' },
        ja: { code: 'ja', value: 'global:language:japanese', locale: 'ja_JP' },
        ko: { code: 'ko', value: 'global:language:korean', locale: 'ko_KR' },
        'zh-Hant': { code: 'zh-Hant', value: 'global:language:chineseTraditional', locale: 'zh_TW' },
        'zh-Hans': { code: 'zh-Hans', value: 'global:language:chineseSimplified', locale: 'zh_CN' },
        de: { code: 'de', value: 'global:language:german', locale: 'de_DE' },
        fr: { code: 'fr', value: 'global:language:french', locale: 'fr_FR' },
        ru: { code: 'ru', value: 'global:language:russian', locale: 'ru_RU' },
        nl: { code: 'nl', value: 'global:language:dutch', locale: 'nl_NL' },
        it: { code: 'it', value: 'global:language:italian', locale: 'it_IT' },
        pl: { code: 'pl', value: 'global:language:polish', locale: 'pl_PL' },
        pt: { code: 'pt', value: 'global:language:portuguese', locale: 'pt_PT' },
        'pt_BR': { code: 'pt_BR', value: 'global:language:portuguese_BR', locale: 'pt_BR' }, // Portuguese - Brazil
        es: { code: 'es', value: 'global:language:spanish', locale: 'es_ES' },
        da: { code: 'da', value: 'global:language:danish', locale: 'da_DA' },
        fi: { code: 'fi', value: 'global:language:finnish', locale: 'fi_FI' },
        nn: { code: 'nn', value: 'global:language:norwegian', locale: 'no_NO' },
        sv: { code: 'sv', value: 'global:language:swedish', locale: 'sv_SE' },
        ar: { code: 'ar', value: 'global:language:arabic', locale: 'ar' },
        bg: { code: 'bg', value: 'global:language:bulgarian', locale: 'bg_BG' },
        tr: { code: 'tr', value: 'global:language:turkish', locale: 'tr_TR' },
        vi: { code: 'vi', value: 'global:language:vietnamese', locale: 'vi_VN' },
    }

    if (SHOW_EN_GB) {
        languages = {
            en: { code: 'en', value: 'global:language:english:US', locale: 'en_US' },
            'en-gb': { code: 'en-gb', value: 'global:language:english:GB', locale: 'en_GB' },
            ja: { code: 'ja', value: 'global:language:japanese', locale: 'ja_JP' },
            ko: { code: 'ko', value: 'global:language:korean', locale: 'ko_KR' },
            'zh-Hant': { code: 'zh-Hant', value: 'global:language:chineseTraditional', locale: 'zh_TW' },
            'zh-Hans': { code: 'zh-Hans', value: 'global:language:chineseSimplified', locale: 'zh_CN' },
            de: { code: 'de', value: 'global:language:german', locale: 'de_DE' },
            fr: { code: 'fr', value: 'global:language:french', locale: 'fr_FR' },
            ru: { code: 'ru', value: 'global:language:russian', locale: 'ru_RU' },
            nl: { code: 'nl', value: 'global:language:dutch', locale: 'nl_NL' },
            it: { code: 'it', value: 'global:language:italian', locale: 'it_IT' },
            pl: { code: 'pl', value: 'global:language:polish', locale: 'pl_PL' },
            pt: { code: 'pt', value: 'global:language:portuguese', locale: 'pt_PT' },
            'pt_BR': { code: 'pt_BR', value: 'global:language:portuguese_BR', locale: 'pt_BR' }, // Portuguese - Brazil
            es: { code: 'es', value: 'global:language:spanish', locale: 'es_ES' },
            da: { code: 'da', value: 'global:language:danish', locale: 'da_DA' },
            fi: { code: 'fi', value: 'global:language:finnish', locale: 'fi_FI' },
            nn: { code: 'nn', value: 'global:language:norwegian', locale: 'no_NO' },
            sv: { code: 'sv', value: 'global:language:swedish', locale: 'sv_SE' },
            ar: { code: 'ar', value: 'global:language:arabic', locale: 'ar' },
            bg: { code: 'bg', value: 'global:language:bulgarian', locale: 'bg_BG' },
            tr: { code: 'tr', value: 'global:language:turkish', locale: 'tr_TR' },
            vi: { code: 'vi', value: 'global:language:vietnamese', locale: 'vi_VN' },
        }
    }

    return languages
}

export const CONVERT_TO_TEXT_LANGUAGES = getExportLanguages()

export const EXPORT_DOC_FORMATS = [
    { label: 'export:asDoc', value: 'doc', isDefault: false },
    { label: 'export:asPsd', value: 'psd', isDefault: false },
    { label: 'export:asJpg', value: 'jpg', isDefault: true },
    { label: 'export:asPdf', value: 'pdf', isDefault: false },
    { label: 'export:asPng', value: 'png', isDefault: false },
    { label: 'export:asSvg', value: 'svg', isDefault: false },
    { label: 'export:asWill', value: 'will', isDefault: false },
    // { label: 'export:asPptx', value: 'pptx', isDefault: false },
    { label: 'export:asVideo', value: VIDEO_TYPE, isDefault: false }
]

export const BAMBOO_EXPORT_DOC_FORMATS = [
    { label: 'export:asPsd', value: 'psd', isDefault: false },
    { label: 'export:asJpg', value: 'jpg', isDefault: true },
    { label: 'export:asPdf', value: 'pdf', isDefault: false },
    { label: 'export:asPng', value: 'png', isDefault: false }]

// Inkspace editor & collaboration settings are described below

export const INK_EDITOR_CONFIG = {
    MIN_SCALE_FACTOR: 0.5,
    MAX_SCALE_FACTOR: 8,
    // BACKGROUND_COLOR: Module.Color.WHITE,
    PAGE_FORMAT_A4: {
        width: 864,
        height: 1188
    },
    defaultColor: {
        pen: {
            h: 15,
            s: 5,
            l: 5,
            a: 1
        },
        highlighter: {
            h: 240,
            s: 100,
            l: 50,
            a: 0.3
        }
    },
    zoom: [
        { label: '800%', scaleFactor: 8 },
        { label: '400%', scaleFactor: 4 },
        { label: '200%', scaleFactor: 2 },
        { label: '100%', scaleFactor: 1 }
    ],
    controls: {
        pen: 'pen',
        highlighter: 'highlighter',
        eraser: 'eraser',
        pointer: 'pointer',
        pan: 'pan',
        eyedropper: 'eyedropper'
    }
}
export const INK_COLLABORATION_CONFIG = {
    host: process.env.COLLABORATION_URL,
    // userColor: Module.Color.random(false),
    collaborators: [],
    collaborate: true,
    isOrganiser: false,
    changes: []
}

export const INK_EDITOR_STATE = {
    collaboration: INK_COLLABORATION_CONFIG,
    activeTool: INK_EDITOR_CONFIG.controls['pen'], // Init active tool
    toolColor: INK_EDITOR_CONFIG.defaultColor,
    userColor: INK_COLLABORATION_CONFIG.userColor,
    documentDetails: {},
    mode: null,
    toggleColorPicker: false,
    scaleFactor: null,
    pickColor: null
}

//Ink to video
export const URL_VALIDITY_DAYS = 30

export const SPINNER_SETTINGS = {
    lines: 9, // The number of lines to draw
    length: 0, // The length of each line
    width: 6, // The line thickness
    radius: 16, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    color: '#00A3E0', // CSS color or array of colors
    speed: 1.2, // Rounds per second
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    // corners: 1, // Corner roundness (0..1)
    // fadeColor: 'transparent', // CSS color or array of colors
    // rotate: 0, // The rotation offset
    // direction: 1, // 1: clockwise, -1: counterclockwise
    // zIndex: 2e9, // The z-index (defaults to 2000000000)
    // className: 'spinner', // The CSS class to assign to the spinner
    // top: '50%', // Top position relative to parent
    // left: '50%', // Left position relative to parent
    // shadow: '0 0 1px transparent', // Box-shadow for the lines
    // position: 'absolute' // Element positioning
}

export const initialSelectedDocument = {
    id: null,
    title: '',
    creationDate: new Date().valueOf(),
    boundingBox: {
        width: 500,
        height: 500
    }
};

export const authLocalizationMappings = {
    email_taken: 'auth:email:taken',
    not_registered: 'auth:email:notRegisterd',
    unauthorized_user: 'auth:login:invalid'
};

// Possible Auth Views
export const LOGIN_VIEW = 'login'
export const REGISTER_VIEW = 'register'
export const REGISTER_SUCCESS_VIEW = 'register-success'
export const RESET_PASS_VIEW = 'reset-pass'
export const RESET_PASS_SUCCESS_VIEW = 'reset-pass-success'


// Route constants
export const INK_EDITOR_PATH_BASE = '/ink-editor'
export const SEMANTIC_PATH_BASE = '/semantic'
export const HOME_PATH = '/';
export const HOME_PATH_WITH_APP_NAME = `/?applicationName=${DEFAULT_APPLICATION_NAME}`;
export const PREVIEW_PATH = '/preview';
export const VIDEO_PATH = '/beta/video';
export const INK_EDITOR_PATH = INK_EDITOR_PATH_BASE + '/:docId/:pageId?';
export const SEMANTIC_PATH = SEMANTIC_PATH_BASE + '/:docId/:pageId?/:locale?';
export const WELCOME_PATH = '/welcome';
export const MIGRATION_PATH = '/migration';
export const DATA_DOWNLOAD_PATH = '/data-download';

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const authRoutes = [
    process.env.PUBLIC_URL + HOME_PATH,
    process.env.PUBLIC_URL + INK_EDITOR_PATH_BASE
];