import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ContextMenu from 'common/components/menu/contextMenu/ContextMenu';

import './TagListItem.scss';

function TagListItem(props) {
    const { className, custom, item, onClick } = props;
    const { formatMessage } = useIntl();
    const [contextMenuState, setContextMenuState] = useState({
        isShowContextMenu: false,
        coordinates: {
            x: 0,
            y: 0
        }
    });

    const toggleContextMenu = (ev, newMenuState) => {
        let x = 0;
        let y = 0;

        if (newMenuState) {
            const ul = ev.target.closest("UL");
            const ulWrapper = ul.parentElement;
            const ulRect = ul.getBoundingClientRect();
            const ulWrapperRect = ulWrapper.getBoundingClientRect();
            const scrollOffset = ulWrapperRect.top - ulRect.top;
            y = scrollOffset;
        }

        setContextMenuState({
            isShowContextMenu: newMenuState,
            coordinates: { x, y }
        })
    };

    const toggleShowContextMenu = (ev) => toggleContextMenu(ev, true)
    const toggleHideContextMenu = (ev) => toggleContextMenu(ev, false)

    const showContextMenu = (itemObject) => {
        const { renameTag, removeTag } = props
        return (
            <ContextMenu
                className={'context-menu-tags'}
                mouseLeave={toggleHideContextMenu}
                coordinates={contextMenuState.coordinates}
                elements={[
                    {
                        title: formatMessage({ id: 'global:rename' }),
                        data: itemObject,
                        className: 'tag-rename',
                        callback: renameTag
                    }, {
                        title: formatMessage({ id: 'global:delete' }),
                        data: itemObject,
                        className: 'rag-remove',
                        callback: removeTag
                    }]
                } />
        )
    }

    return <li id={!custom ? item.id : null} className={className}
        mouseLeave={toggleHideContextMenu}
    >
        <span className={'link'}>
            <span onClick={onClick}> {item.label}</span>
            {!custom ? <span onClick={onClick} className={'tags-count'}>({item.length})</span> : null}
            {!custom ? <span className={'btn-more'} onClick={toggleShowContextMenu} /> : null}
            {contextMenuState.isShowContextMenu ? showContextMenu(item) : null}
        </span>
    </li>
}

export default TagListItem;