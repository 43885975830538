import React, { useEffect, useRef, useContext } from 'react';
// import { Scrollbars } from 'react-custom-scrollbars';
import Accordion from 'react-bootstrap/Accordion';
import SemanticEntity from './SemanticEntity';
import SemanticContext from '../../../context/SemanticContext';

import 'bootstrap/dist/css/bootstrap.min.css';

import './SemanticEntitiesList.scss';


function SemanticEntityList(props) {
    const listRef = useRef();
    const { selectedElement, setSelectedElement } = useContext(SemanticContext);

    useEffect(() => {
        if (listRef.current && selectedElement) {
            // the first childNodes refers to the accordion element
            const targetElement = listRef?.current?.children[0].children[selectedElement]; //?.childNodes[selectedElement];

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }

    }, [selectedElement, listRef]);


    return <div ref={listRef} className={'semantic-entities-list'}>
        <Accordion activeKey={selectedElement?.toString()}>
            {props.data.map((item, index) => {
                return <SemanticEntity
                    key={item.id}
                    itemKey={index.toString()}
                    item={item}
                    selectedElement={selectedElement}
                    setSelectedElement={setSelectedElement}
                />
            })}
        </Accordion>
    </div>
}

export default SemanticEntityList;