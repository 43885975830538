export function bpPaperTypeUrl(paperTypeIndex) {
    return `${process.env.PUBLIC_URL}/bambooPaper/paperTypes/${paperTypeIndex}.jpg`
}

export function addBpPaperTypeStyle(paperTypeIndex) {
    const style = {};

    if (paperTypeIndex) {
        style.backgroundImage = `url("${bpPaperTypeUrl(paperTypeIndex)}")`;
    }

    return style;
}

export function getTemplatePosition(width, height, pageWidth, pageHeight) {
    const xOffset = (pageWidth - width) / 2;
    const yOffset = (pageHeight - height) / 2;

    return {
        x: xOffset,
        y: yOffset,
        transform: 'matrix(1 0 0 1 0 0)',
        width, height
    }
}