import MobileDetect from 'mobile-detect';

export const bodyHeight = () => {
  const height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  return height;
}

export const bodyWidth = () => {
  const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  return width
}

export const isIOS = () => (/iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

export const detectPhones = () => {
  let md = new MobileDetect(window.navigator.userAgent)

  return {
    isPhone: md.phone() !== null ? true : false,
    type: md.os()
  }
}

export const detectTablets = () => {
  let md = new MobileDetect(window.navigator.userAgent)

  return md.tablet() !== null ? true : false
}