import React from 'react'
import { useIntl } from 'react-intl'

import './EmptySearchResult.scss'

function EmptySearchResult() {
    const { formatMessage } = useIntl();

    return (
        <div className='empty'>
            <span className='no-result' />
            <br />
            <p className='no-result bolder'>
                {formatMessage({ id: 'documents:empty:noresult:noresult' })}
            </p>
            <br />
            <p className='no-result'>
                {formatMessage({ id: 'documents:empty:noresult:noresultfound' })}
            </p>
        </div>
    )
}

export default EmptySearchResult;