import {
	InkBuilder, InkController, Color, Rect, Matrix, InkCodec
} from 'digital-ink';

import { IDENTITY_TRANSFORM_MATRIX } from 'constants/constants';

import { zipObject } from 'lodash';

import config from './Config';
import Lens from './LensSurface';

class InkCanvas extends InkController {
	constructor(canvas, width, height, onRescale) {
		super(canvas, width, height);
		this.canvasWrapper = canvas.parentElement.parentElement;
		this.isDrawableTool = false;
		this.hasImagesOnLayer = false;
		this.hasInkChanges = false;
		this.onRescale = onRescale;

		// Object.defineProperty(this, "transform", { get: () => this.lens.transform, set: value => (this.lens.transform = value), enumerable: true });
		Object.defineProperty(this, "baseTransform", { get: () => this.lens.transform, enumerable: true })

		this.builder = new InkBuilder();

		this.builder.onComplete = (pathPart) => {
			if (this.intersector)
				this.erase(pathPart);
			else if (this.selector)
				this.select(pathPart);
			else
				this.draw(pathPart);
		};

		Object.defineProperty(this, "strokes", { get: () => this.dataModel.inkModel.content, enumerable: true });

		this.codec = new InkCodec();
	}

	init(device, toolID, color) {
		this.device = device;
		this.builder.device = device;

		this.lens = new Lens(this.canvas, this.canvasWrapper, transform => {
			const scale = (transform.a + transform.d) / 2;

			this.canvas.surface.dataset.scale = scale;

			if (this.onRescale) {
				this.onRescale(scale, transform);
			}
		}, this.abort.bind(this));

		this.setTool(toolID);
		this.setColor(color);
	}

	setTool(toolID) {
		this.toolID = toolID;
		this.builder.device = this.device;

		if (config.tools[toolID]) {
			this.intersector = config.tools[toolID].intersector;
			this.selector = config.tools[toolID].selector;
			this.isDrawableTool = true;
		} else {
			this.isDrawableTool = false;
		}

		this.lens.toggleOnPrimaryMouseButton(toolID === 'pan');
	}

	setColor(color) {
		this.color = color;
	}

	setScale(scale) {
		if (this.canvas.surface.dataset.scale !== scale) {
			this.lens.setScale(scale);
		}
	}

	registerInputProvider(pointerID, isPrimary) {
		if (Array.isArray(pointerID)) {
			// multi-touch should handle all changedTouches and to assign builders for each other
			if (isNaN(this.builder.pointerID))
				this.builder.pointerID = pointerID.first;
		}
		else {
			if (isPrimary)
				this.builder.pointerID = pointerID;
		}
	}

	getInkBuilder(pointerID) {
		if (Array.isArray(pointerID)) {
			if (pointerID.length > 0 && !pointerID.includes(this.builder.pointerID)) return undefined;
			return this.builder;
		}
		else
			return (this.builder.pointerID === pointerID) ? this.builder : undefined;
	}

	reset(sensorPoint) {
		let options = config.getOptions(sensorPoint, this.toolID, this.color);

		this.builder.configure(options.inkBuilder);
		this.strokeRenderer.configure(options.strokeRenderer);

		if (this.intersector) {
			this.intersector.reset(this.dataModel.manipulationsContext);

			this.builder.prediction = false;
		}
		else
			this.builder.prediction = true;

		if (this.selector)
			this.selector.reset(this.dataModel.manipulationsContext);
	}

	begin(sensorPoint) {
		if (!this.isDrawableTool) return;

		this.reset(sensorPoint);

		this.builder.add(sensorPoint);
		this.builder.build();
	}

	move(sensorPoint, prediction) {
		if (!this.isDrawableTool) return;

		this.builder.add(sensorPoint);

		if (!this.requested) {
			this.requested = true;

			this.builder.build();

			requestAnimationFrame(() => (this.requested = false));
		}
	}

	end(sensorPoint) {
		if (!this.isDrawableTool) return;

		this.builder.add(sensorPoint);
		this.builder.build();
	}

	draw(pathPart) {
		if (!this.isDrawableTool) return;

		this.drawPath(pathPart);

		if (pathPart.phase === InkBuilder.Phase.END) {
			if (this.toolID === 'pointer') {
				return
			}

			if (this.strokeRenderer) {
				let stroke = this.strokeRenderer.toStroke(this.builder);
				this.dataModel.add(stroke)
			}
		}
	}

	drawPath(pathPart) {
		this.strokeRenderer.draw(pathPart.added, pathPart.phase === InkBuilder.Phase.END);

		if (pathPart.phase === InkBuilder.Phase.UPDATE) {
			this.strokeRenderer.drawPreliminary(pathPart.predicted);

			let dirtyArea = this.canvas.bounds.intersect(this.strokeRenderer.updatedArea);

			if (dirtyArea) {
				this.present(dirtyArea, pathPart.phase);
			}
		}
		else if (pathPart.phase === InkBuilder.Phase.END) {
			this.hasInkChanges = true;

			let dirtyArea = this.canvas.bounds.intersect(this.strokeRenderer.strokeBounds.union(this.strokeRenderer.updatedArea));

			if (dirtyArea) {
				if (this.toolID === 'pointer') {
					this.abort();
				}
				else if (!this.selector && !this.intersector) {
					this.present(dirtyArea, pathPart.phase);
				}
			}
		}
	}

	getHasInkChanges() {
		return this.hasInkChanges;
	}

	present(dirtyArea, phase) {
		if (phase === InkBuilder.Phase.END) {
			this.strokeRenderer.blendStroke(this.strokesLayer);
		}

		this.canvas.clear(dirtyArea);
		this.canvas.blend(this.strokesLayer, { rect: dirtyArea });

		if (phase === InkBuilder.Phase.UPDATE)
			this.strokeRenderer.blendUpdatedArea();
	}

	erase(pathPart) {
		this.drawPath(pathPart);

		this.intersector.updateSegmentation(pathPart.added);

		if (pathPart.phase === InkBuilder.Phase.END) {
			let intersection = this.intersector.intersectSegmentation(this.builder.getInkPath());
			this.split(intersection);

			this.abort();
		}

		this.hasInkChanges = true;
	}

	split(intersection) {
		let split = this.dataModel.update(intersection.intersected, intersection.selected);
		let dirtyArea = split.dirtyArea;

		if (dirtyArea) {
			dirtyArea.model = true;
			this.redraw(dirtyArea);
		}
	}

	select(pathPart) {
		this.drawPath(pathPart);

		this.selector.updateSegmentation(pathPart.added);

		if (pathPart.phase === InkBuilder.Phase.END) {
			let stroke = this.strokeRenderer.toStroke(this.builder);

			this.selection.open(stroke, this.selector);

			this.abort();
		}
	}

	abort() {
		if (!this.builder.phase) return;

		let dirtyArea;

		if (this.strokeRenderer.strokeBounds)
			dirtyArea = this.strokeRenderer.strokeBounds.union(this.strokeRenderer.preliminaryDirtyArea);

		this.strokeRenderer.abort();
		this.builder.abort();

		if (dirtyArea)
			this.refresh(dirtyArea);
	}

	fit() {
		this.lens.fit();
	}

	redraw(dirtyArea = this.canvas.bounds, excludedStrokes = []) {
		let modelArea = dirtyArea;
		let viewArea = dirtyArea;

		this.strokesLayer.clear(viewArea);

		let strokes = this.strokes.filter(stroke => !excludedStrokes.includes(stroke) && stroke.style.visibility && (!modelArea || stroke.bounds.intersect(modelArea)));

		this.strokeRenderer.blendStrokes(strokes, this.strokesLayer, { rect: viewArea }, this.inkCanvasRaster ? this.inkCanvasRaster.strokeRenderer : undefined);

		this.refresh(viewArea);
	}

	refresh(dirtyArea = this.canvas.bounds) {
		this.canvas.clear(dirtyArea);
		this.canvas.blend(this.strokesLayer, { rect: dirtyArea });
	}

	clear() {
		this.strokesLayer?.clear();
		this.canvas?.clear();

		this.dataModel?.reset();
	}

	import(input) {
		let reader = new FileReader();

		reader.onload = (e) => {
			this.openFile(e.target.result);
		}
		reader.readAsArrayBuffer(input[0]);
	}

	parseFloats(string) {
		return string.match(/-?\d*\.?\d+/g).map(Number);
	}

	drawImages(imageHolder) {
		this.hasImagesOnLayer = true;
		this.imageRendererLayer.clear(Color.WHITE);
		const imageContext = this.imageRendererLayer.ctx;
		imageContext.save();
		let firstPassed = false;

		for (const image of imageHolder.children) {
			if (!firstPassed) imageContext.restore();
			firstPassed = true;

			let transform = image.style.transform;
			const x = image.offsetLeft;
			const y = image.offsetTop;

			if (!transform) {
				transform = IDENTITY_TRANSFORM_MATRIX;
			}

			const elementMatrix = this.parseStringMatrix(transform);
			const matrixWithOffsets = Matrix.fromMatrix({
				a: elementMatrix.a,
				b: elementMatrix.b,
				c: elementMatrix.c,
				d: elementMatrix.d,
				tx: elementMatrix.tx + x,
				ty: elementMatrix.ty + y
			})

			imageContext.setTransform(matrixWithOffsets);
			imageContext.drawImage(image, 0, 0, image.width, image.height);
		}

		imageContext.restore();
	}

	parseStringMatrix(matrixString) {
		const transformMatrix = zipObject(
			['a', 'b', 'c', 'd', 'tx', 'ty'],
			this.parseFloats(matrixString));

		return transformMatrix;
	}


	getEyeDropperCanvas() {
		if (this.backgroundLayer) {
			this.eyeDropperLayer.clear(Color.TRANSPERENT);
			this.eyeDropperLayer.blend(this.backgroundLayer);
		} else {
			this.eyeDropperLayer.clear(Color.WHITE);
		}

		if (this.hasImagesOnLayer && this.eyeDropperLayer) {
			this.eyeDropperLayer.blend(this.imageRendererLayer);
		}

		this.eyeDropperLayer.blend(this.strokesLayer);

		return this.getImageCanvas(this.eyeDropperLayer, this.canvas.bounds);
	}

	getImageCanvas(layer, rect) {
		var canvas = document.createElement("canvas");
		var context = canvas.getContext("2d");

		if (!layer) {
			layer = this.canvas;
			rect = this.canvas.bounds;
		}

		canvas.width = rect.width;
		canvas.height = rect.height;

		var pixels = layer.readPixels(rect);

		var imageData = context.createImageData(rect.width, rect.height);
		imageData.data.set(pixels);
		context.putImageData(imageData, 0, 0);

		return canvas;
	}


	extractColor(point) {
		const rect = new Rect(point.x, point.y, 1, 1);
		let pixel = this.canvas.readPixels(rect);

		// if there is noting on the visible canvas get precalculated values with background
		if (pixel[3] === 0) {
			pixel = this.eyeDropperLayer.readPixels(rect);
		}

		let alpha = pixel[3];
		if (alpha === 0) alpha = 255

		const red = parseInt((pixel[0] * 255) / alpha);
		const green = parseInt((pixel[1] * 255) / alpha);
		const blue = parseInt((pixel[2] * 255) / alpha);
		const color = new Color(Math.min(red, 255), Math.min(green, 255), Math.min(blue, 255), alpha / 255);

		return color;
	}
}

export default InkCanvas;