import React from 'react';
import { useIntl } from 'react-intl';

import './TagsSelection.scss';

function Label(props) {
    const { tag, onClick } = props;
    return <>
        <li className='tag-selection-item' onClick={() => onClick(tag)}>
            {tag}
        </li>
    </>
}


function TagsSelection(props) {
    const { tags, tagClicked, groupName } = props;
    const { formatMessage } = useIntl();
    const filterResultMessage = formatMessage({ id: 'filter:result' });

    return <>
        <div className='tag-selection-container'>
            <span className='tag-selection-result'>
                {filterResultMessage}
            </span>
            {groupName ? <li className='groupName'>{`${groupName}`}</li> : null}
            <ul className='tag-selection'>
                {tags.map((t, i) => <Label tag={t} onClick={tagClicked} key={i} />)}
            </ul>
        </div>
    </>
}

export default TagsSelection;