import React from 'react';
import { useIntl } from 'react-intl';

import './Stream.scss';


const streamNames = {
    inkspace: 'documents:empty:stream:appstream',
    montblanc: 'streams:inkspaceMontBlanc',
    web: 'documents:empty:stream:webstream',
    'bamboo_paper': 'documents:empty:stream:bamboopaperstream',
    inknotefiles: 'documents:empty:stream:inknotefiles',
    'wacom_notes': 'streams:wacomnotes',
    collaboration: 'streams:collaboration'
}

function Stream(props) {
    const { applicationName, streamClicked, isClickable } = props;
    const { formatMessage } = useIntl();

    const streamMessageKey = streamNames[applicationName] || streamNames.inkspace;

    return <>
        <div className='filter-result'>
            <div className={`stream-name ${isClickable ? 'is-clickable' : ''} `} onClick={() => { streamClicked(applicationName) }}>
                <span>
                    {formatMessage({ id: streamMessageKey })}
                </span>
            </div>
        </div>
    </>
}

export default Stream;