import React from 'react';

import './ExportListItem.scss';

function ExportListItem(props) {
    const { onChange, title, name, isSelected } = props;

    return <div className='radio'>
        <input id={name} type='radio' name={name} checked={isSelected} onChange={onChange} />
        <label htmlFor={name}>
            <div className={'radio-btn-cycle'} />
            <span>
                {title}
            </span>
        </label>
    </div>
}

export default ExportListItem;