import React from 'react';

import CanvasContextProvider from '../../context/CanvasContextProvider';
import WillCanvas from './WillCanvas';
import MenuBar from '../../../../common/components/menuBar/MenuBar';

import HighlighterTool from '../../components/Highlighter/HighlighterTool';
import PenTool from '../../components/Pen/PenTool';
import EraserTool from '../../components/Eraser/EraserTool';
import ColorTool from '../../components/Color/ColorTool';
import PanTool from '../../components/Pan/PanTool';
import ZoomTool from '../../components/Zoom/ZoomTool';
import EyeDropper from '../../components/EyeDropper/EyeDropper';

import './WillCanvasEditor.scss';


function WillCanvasEditor(props) {
    const menuChildren = <>
        <div className={'menu-tools menu-1'}>
                <PenTool />
                <HighlighterTool />
                <ColorTool />
                <EyeDropper />
                <EraserTool />
                <PanTool />
            </div>
            <div className={'menu-tools menu-2'}>
                <ZoomTool />
            </div>
        </>

    return <div className={'ink-editor-wrapper'}>
        <React.StrictMode>
            <CanvasContextProvider>
                <MenuBar brand={props.brand} elements={menuChildren} />
                <WillCanvas inkModels={props.inkModels}
                    width={props.width}
                    height={props.height}
                    updateInk={props.updateInk}
                    images={props.images}
                    onClose={props.onClose}
                    isFetching={props.isFetching}
                    pageId={props.pageId}
                    pageIds={props.pageIds}
                    setActivePage={props.setActivePage}
                    onPageChange={props.onPageChange}/>
            </CanvasContextProvider>
        </React.StrictMode>
    </div>
}

export default WillCanvasEditor;